import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { SearchContextProvider } from "./context/SearchContext";
import { AuthProvider } from "./context/AuthContext";
import { MantineProvider, ColorSchemeProvider, Affix } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <React.StrictMode>  
  <MantineProvider>
	<ModalsProvider>
		<NotificationsProvider autoClose={4000} position="bottom-right">
  <AuthProvider>
  <SearchContextProvider>
    <App />
    </SearchContextProvider>
    </AuthProvider> 
    </NotificationsProvider>
					</ModalsProvider>
    </MantineProvider>
  </React.StrictMode>
);
