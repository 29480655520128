import "./footer.css";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchContext } from "../../context/SearchContext";
import styled from "styled-components";


const Tab = styled.button`
  cursor: pointer;
  background: white;
  border: 0;
  outline: 0;
  border-bottom: 2px solid transparent;
  transition: ease border-bottom 250ms;
`;

const Footer = () => {
  const navigate = useNavigate();
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [openOptions, setOpenOptions] = useState(false);
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
  });

  const { dispatch } = useContext(SearchContext);

  const handleDestination = (destination) => {
    dispatch({ type: "NEW_SEARCH", payload: { destination, dates, options } });
    navigate("/holidays", { state: { destination, dates, options } });
  };

  const handleTab = () => {
    navigate("/contactUs");
  }


  return (
    <div className="footer">
      <div className="fLists">
        <ul className="fList">
              <Link to={`/`}>
                <img
                  src={"/logo_footer.png"}
                  alt=""
                  className="fImg"
                />
              </Link>
        <li><p></p></li>
        <li className="fListItem"><h8><b>Your Ultimate Marketplace For All Travel Experience.</b></h8></li>
        </ul>
        <ul className="fList">
          <li className="fListItem"><h8><b>Popular Holidays</b></h8></li>
          <li className="fListItem">
              <Tab onClick={() => handleDestination("Agra")}>Agra</Tab></li>
          <li className="fListItem">
              <Tab onClick={() => handleDestination("Bangalore")}>Bangalore</Tab></li>
          <li className="fListItem">
              <Tab onClick={() => handleDestination("Chandigarh")}>Chandigarh</Tab></li>
          <li className="fListItem">
              <Tab onClick={() => handleDestination("Chennai")}>Chennai</Tab></li>
          <li className="fListItem">
              <Tab onClick={() => handleDestination("Kanyakumari")}>Kanyakumari</Tab></li>
          <li className="fListItem">
              <Tab onClick={() => handleDestination("Ooty")}>Ooty</Tab></li>
          <li className="fListItem">
              <Tab onClick={() => handleDestination("Chardham")}>Chardham</Tab></li>
          <li className="fListItem">
              <Tab onClick={() => handleDestination("Rajasthan")}>Ladakh</Tab>
          </li>
        </ul>
        <ul className="fList">
          <li className="fListItem"><h8><b>Popular Holidays</b></h8></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Andaman")}>Andaman</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Goa")}>Goa</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Gujarat")}>Gujarat</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Kerala")}>Kerala</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Maharashtra")}>Maharashtra</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Rajasthan")}>Rajasthan</Tab></li>
        </ul>
        <ul className="fList">
          <li className="fListItem"><h8><b>Religious Tours</b></h8></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Kevadia")}>Kevadia</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Puri")}>Puri</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Rameswaram")}>Rameswaram</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Shirdi")}>Shirdi</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Tirupati")}>Tirupati</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Ujjain")}>Ujjain</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Vaishno Devi")}>Vaishno Devi</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("Varanasi")}>Varanasi</Tab></li>
        </ul>
        {/*<ul className="fList">
          <li className="fListItem"><h8><b>Pan-India Tours</b></h8></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("East")}>East India</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("West")}>West India</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("North")}>North India</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("South")}>South India</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("North-East")}>North-East</Tab></li>
        </ul>*/}
        <ul className="fList">
          <li className="fListItem"><h8><b>Additional Information</b></h8></li>
          <li className="fListItem">
            <Tab onClick={() => handleTab()}>Contact Us</Tab></li>
          {/*<li className="fListItem">
            <Tab onClick={() => handleDestination("East")}>Partner Help</Tab></li>
          <li className="fListItem">
            <Tab onClick={() => handleDestination("East")}>Terms & conditions</Tab></li>*/}
        </ul>
      </div>
      <div className="fText"><b>Copyright © 2024 Holidyz.com</b></div>
    </div>
  );
};

export default Footer;