import Featured from "../../components/featured/Featured";
import FeaturedProperties from "../../components/featuredProperties/FeaturedProperties";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import MailList from "../../components/mailList/MailList";
import Navbar from "../../components/navbar/Navbar";
import PropertyList from "../../components/propertyList/PropertyList";
import "./home.css";
import Banner from "../../components/banner/Banner";
import BookNowComponent from "../../components/banner/BookNowComponent";

const Home = () => {
  return (
    <div>
      <Navbar />
      <Header/>
      <div className="homeContainer">
        <Featured/>
         <h1 className="homeTitle">Popular Destinations</h1>
        <FeaturedProperties/>
         
         <BookNowComponent />
        
       <Banner />
       <h1 className="homeTitle">Browse by Package Type</h1>
        <PropertyList/>
        <MailList/>
        <Footer/>
      </div>
    </div>
  );
};

export default Home;
