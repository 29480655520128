import "./list.css";
import useFetch from "../../hooks/useFetch";
import Navbar from "../../components/navbar/Navbar";
import Header from "../../components/header/Header";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import SearchItem from "../../components/searchItem/SearchItem";

const ListHolidaysByCity = () => {
  const location = useLocation();
  const query = location.pathname.split("/").slice(-1)[0];
  console.log(query)

  const [sortCriteria, setSortCriteria] = useState('name'); // Default sorting by name

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const typeCategory = ["romantic","religious","family","solo"];
  const regionCategory = ["east","west","north","south","north-east"];

  const { data, loading, error, reFetch } = useFetch("/holidays?type="+query);
  const handleClick = () => {
    reFetch();
  };

  const sortedPackages = [...data].sort((a, b) => {
    if (sortCriteria === 'name') {
      return a.name.localeCompare(b.name);
    } else if (sortCriteria === 'price') {
      return a.price - b.price;
    }
    // Add more sorting criteria if needed
    return 0;
  });

  return (
    <div>
     <Navbar />
      <div className="listContainer">
        <div className="listWrapper">
          <div className="listResult">
          	<div className="sort-container">
          		<label className="label">
	        		Sort by  : &nbsp;
	        		<select value={sortCriteria} onChange={handleSortChange} className="select">
	          			<option value="name">Name</option>
	          			<option value="price">Price</option>
	          		</select>
	      		</label>
	      	</div>
            {loading ? (
              "loading"
            ) : (
              <>
                {sortedPackages.map((item) => (
                  <SearchItem item={item} key={item._id} />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListHolidaysByCity;
