import "./searchItem.css";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const SearchItem = ({item}) => {
console.log(item)
const { setAuthModalOpened, currentUser } = useAuth();
  return (
    <div className="searchItem">
      <img
        src={"/destinations/"+item.photos[0]}
        alt=""
        className="siImg"
      />
      <div className="siDesc">
        <h1 className="siTitle">{item.name}</h1>
        <span className="siDistance"><b>Duration: </b><span>{item.duration}</span></span>
        <span className="siDistance"><b>Starting Point: </b><span>{item.fromcity}</span></span>
        <span className="siFeatures">
          {item.subtitle}
        </span>
        </div>
      <div className="siDetails">
        {item.rating && <div className="siRating">
          <span>Excellent</span>
          <button>{item.rating}</button>
        </div>}
        <div className="siDetailTexts">
         <span className="siPrice">₹{item.price}</span>
          <span className="siTaxOp">Per Person</span>
          <span className="siTaxOp">Includes taxes and fees</span>
          {currentUser ? (
              <Link to={`/holidays/${item._id}`}>
                  <button className="siCheckButton">More Details</button>
              </Link>
            ):(
                <button className="siCheckButton" onClick={() => setAuthModalOpened(true)}>More Details</button>
                
            )}
        </div>
      </div>
    </div>
  );
};

export default SearchItem;
