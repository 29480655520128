import React from "react";
import {
	Text,
	Divider,
	Center,
	Loader,
} from "@mantine/core";
import { SearchResultCard } from "./SearchResultCard";

const SearchResult = ({
	number,
	from,
	to,
	fromStation,
	toStation,
	from_std,
	to_std,
	startDate,
	result,
	setResult,
	loading,
}) => {
	return (
		<>
			{result && result.length > 0 ? (
			<Divider
				my="xs"
				label={`Search Result ${result.length} (Showing results)`}
				labelPosition="center"
			/>):
			(
				
					<Divider
				my="xs"
				label={`Search Result 0 (Showing results)`}
				labelPosition="center"
			/>
				
			)}
			{result && result.length > 0 ? (
				<div style={{ width: "100%", textAlign: "center", top: "50%", gap: "10px", transform: "translate(0%, 0%)"}}>
					<div>
						<br></br>
						{result.map((item, index) => (
								<SearchResultCard key={index}
									item={item}
									from = {item.from}
									to = {item.to}
									number = {item.train_number}
									fromStation={item.train_source}
									toStation={item.train_destination}
									from_std = {item.from_std}
									to_std = {item.to_std}
									startDate={item.train_date}
									name={item.train_name}
									duration={item.duration}
									setResult={setResult}
								/>
							))}
					</div>
				</div>
			) : loading ? (
				<Center
					sx={(theme) => ({ height: "100px" })}
					component={Text}
					color="dimmed"
				>
					<b>no results</b>
					<Loader />
				</Center>
			) : (
				
					<i>No Trains Found on this Route.</i>
			
			)}
		</>
	);
};

export default SearchResult;