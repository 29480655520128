import "./navbar.css"
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

import {
  createStyles,
  Group,
  Button,
  Menu,
  ActionIcon,
} from "@mantine/core";

import to from "../helpers/to";
import { BsChevronDown } from "react-icons/bs";
import { MdOutlineLogout } from "react-icons/md";

const HEADER_HEIGHT = 120;

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.md,
    fontWeight: 700,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.fn.rgba(theme.colors[theme.primaryColor][9], 0.25)
          : theme.colors[theme.primaryColor][0],
      color:
        theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 3 : 7],
    },
  },

  linkLabel: {
    marginRight: 5,
  },

  button: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    borderLeft: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
    }`,
  },
}));


const Navbar = () => {
  const { classes, cx, theme } = useStyles();
  const { setAuthModalOpened, currentUser, logOut } = useAuth();

  return (
    <div className="navbar">
      <div className="navContainer">
         <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
           <img
                  src={"/logo.png"}
                  alt=""
                  className="fImg"
                />
        </Link>
       
       {currentUser ? (
          <Group noWrap spacing={0}>
            <Link href="/dashboard" passHref>
              <Button className={classes.button}>Dashboard</Button>
            </Link>
            <Menu
              control={
                <ActionIcon
                  variant="filled"
                  color={theme.primaryColor}
                  size={36}
                  className={classes.menuControl}
                >
                  <BsChevronDown size={16} />
                </ActionIcon>
              }
              transition="pop"
              placement="end"
            >
              <Menu.Item
                color="red"
                icon={<MdOutlineLogout size={14} />}
                onClick={async () => {
                  const [data, error] = await to(
                    logOut(),
                    "Logged Out Successfully",
                    "An Error Occured"
                  );
                }}
              >
                Logout
              </Menu.Item>
            </Menu>
          </Group>
        ) : (
        <Group>
          

          <Button
            radius="xl"
            sx={{ height: 36 }}
            onClick={() => setAuthModalOpened(true)}
          >
            Login / Register
          </Button>


          
          
        </Group>
        )}



      </div>
    </div>
  )
}

export default Navbar