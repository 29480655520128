import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC-84XeuoPbQAR4I4emhrTAgNFoWIctljY",
  authDomain: "k2ktrips.firebaseapp.com",
  projectId: "k2ktrips",
  storageBucket: "k2ktrips.appspot.com",
  messagingSenderId: "398438914308",
  appId: "1:398438914308:web:6e190613bd8e2f0d835851",
  measurementId: "G-TL3PWNRWRW"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore();
export const storage = getStorage();

export default app;
