import "./list.css";
import Navbar from "../../components/navbar/Navbar";
import Header from "../../components/header/Header";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import SearchItem from "../../components/searchItem/SearchItem";
import useFetch from "../../hooks/useFetch";

const List = () => {
  const location = useLocation();
  const [destination, setDestination] = useState(location.state.destination);
  const [dates, setDates] = useState(location.state.dates);
  const [openDate, setOpenDate] = useState(false);
  const [options, setOptions] = useState(location.state.options);
  const [min, setMin] = useState(undefined);
  const [max, setMax] = useState(undefined);

  //const { data, loading, error, reFetch } = useFetch(`/hotels?city=${destination}`);

  const { data, loading, error, reFetch } = useFetch(
    `/holidays?max=${max}&tocity=${destination}`
  );

  console.log(data)
  const handleClick = () => {
    reFetch();
  };

  const [sortCriteria, setSortCriteria] = useState('name'); // Default sorting by name

  const handleSortChange = (event) => {
    setSortCriteria(event.target.value);
  };

  const sortedPackages = [...data].sort((a, b) => {
    if (sortCriteria === 'name') {
      return a.name.localeCompare(b.name);
    } else if (sortCriteria === 'price') {
      return a.price - b.price;
    }
    // Add more sorting criteria if needed
    return 0;
  });


  return (
    <div>
      <Navbar />
      <Header type="list" />
      <div className="listContainer">
        <div className="listWrapper">
          <div className="listResult">
            <div className="sort-container">
              <label className="label">
                Sort by  : &nbsp;
                <select value={sortCriteria} onChange={handleSortChange} className="select">
                    <option value="name">Name</option>
                    <option value="price">Price</option>
                </select>
              </label>
            </div>
            {loading ? (
              "loading"
            ) : (
              <>
                {sortedPackages.map((item) => (
                  <SearchItem item={item} key={item._id} />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
