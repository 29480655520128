import "./mailList.css"

import {
  createStyles,
  TextInput,
  Group,
  Button,
} from "@mantine/core";

import { useForm } from "@mantine/hooks";
import { addDoc, collection } from "firebase/firestore";

import { db } from "../../firebase";
import { useNotifications } from "@mantine/notifications";
import { BsCheck2, BsX } from "react-icons/bs";

const useStyles = createStyles((theme) => ({
  wrapper: {
    minHeight: 400,
    boxSizing: "border-box",
    backgroundImage: `linear-gradient(-60deg, ${
      theme.colors[theme.primaryColor][4]
    } 0%, ${theme.colors[theme.primaryColor][7]} 100%)`,
    borderRadius: theme.radius.md,
    padding: theme.spacing.xl * 2.5,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      padding: theme.spacing.xl * 1.5,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.white,
    lineHeight: 1,
  },

  description: {
    color: theme.colors[theme.primaryColor][0],
    maxWidth: 300,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  form: {
    backgroundColor: theme.white,
    padding: theme.spacing.xl,
    borderRadius: theme.radius.md,
    boxShadow: theme.shadows.lg,
  },

  social: {
    color: theme.white,

    "&:hover": {
      color: theme.colors[theme.primaryColor][1],
    },
  },

  input: {
    backgroundColor: theme.white,
    borderColor: theme.colors.gray[4],
    color: theme.black,

    "&::placeholder": {
      color: theme.colors.gray[5],
    },
  },

  inputLabel: {
    color: theme.black,
  },

  control: {
    backgroundColor: theme.colors[theme.primaryColor][6],
  },
}));

const MailList = () => {
  
  const dateValue= new Date();
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      email: "",
      date: dateValue.toISOString(),
    },
  });

  const notifications = useNotifications();

  return (
    <div className="mail">
      <h1 className="mailTitle">Save time, save money!</h1>
      <span className="mailDesc">Sign up and we'll send the best deals to you</span>
      <form
      onSubmit={form.onSubmit(async (values) => {
        const docRef = await addDoc(collection(db, "subscribe"), values);
        if (docRef) {
          notifications.showNotification({
            radius: "md",
            icon: <BsCheck2 size={18} />,
            color: "teal",
            title: "Subscribed Successfully",
            message: "Our team will send you great deals very soon.",
          });
        } else {
          notifications.showNotification({
            radius: "md",
            icon: <BsX size={18} />,
            color: "red",
            title: "An Error Occured",
            message: "Please try again after some time.",
          });
        }
        form.reset();
      })}
    >
      <div className="mailInputContainer">

        <TextInput
                name="email"
                placeholder="Your Email"
                required
                classNames={{ input: classes.input, label: classes.inputLabel }}
                {...form.getInputProps("email")}
              />
        <Group position="center" mt="md">
          <Button type="submit" className={classes.control}>Subscribe</Button>
        </Group>
      </div>
    
    </form>
    </div>
  )
}

export default MailList