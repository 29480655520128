export const cities = [
{name: 'Amb Andavra'},
{name: 'Ambikapur'},
{name: 'Abu Road'},
{name: 'ABOHAR'},
{name: 'Adoni'},
{name: 'Adilabad'},
{name: 'Ahmedabad'},
{name: 'Adra'},
{name: 'Agra'},
{name: 'Agartala'},
{name: 'ACHHNERA JN'},
{name: 'Ajmer'},
{name: 'AIT'},
{name: 'Jhajha J'},
{name: 'Arakkonam'},
{name: 'Ajni'},
{name: 'Akola'},
{name: 'Anakapalle'},
{name: 'Allahabad'},
{name: 'Aligarh'},
{name: 'Alappuzha'},
{name: 'Azamgarh'},
{name: 'Amravati'},
{name: 'Amla'},
{name: 'Samuktala Road'},
{name: 'Amalner'},
{name: 'ADARSH NAGAR DELHI'},
{name: 'Anand Nagar'},
{name: 'Ahmadnagar'},
{name: 'Angul'},
{name: 'Anand'},
{name: 'Annavaram'},
{name: 'Anand Vihar Terminus'},
{name: 'Alipurduar Jn.'},
{name: 'ANUPGARH'},
{name: 'Anuppur'},
{name: 'Ara'},
{name: 'Aunrihar'},
{name: 'Arvi'},
{name: 'AISHBAGH'},
{name: 'Arsikere'},
{name: 'Asansol'},
{name: 'Amritsar'},
{name: 'Anantapur'},
{name: 'ATARI'},
{name: 'ALUABARI ROAD'},
{name: 'AVADI'},
{name: 'Abhanpur Junction'},
{name: 'Aurangabad'},
{name: 'Alwar'},
{name: 'Aluva'},
{name: 'Ayodhya'},
{name: 'Azimganj Jn.'},
{name: 'Banka'},
{name: 'Balugaon'},
{name: 'BRAHMAPUR'},
{name: 'Belapur'},
{name: 'Bari'},
{name: 'Burhanpur'},
{name: 'Bellary Jn.'},
{name: 'Baran'},
{name: 'BENOY BADAL DINESH GARDENS'},
{name: 'Barabanki Jn.'},
{name: 'Barabil'},
{name: 'Bhubaneswar'},
{name: 'BAREILLY CITY'},
{name: 'Mumbai Central'},
{name: 'VARANASI CITY'},
{name: 'Badnera Jn.'},
{name: 'Bandel Jn.'},
{name: 'Bhadrachalam Road'},
{name: 'Bodeli'},
{name: 'Bodhan'},
{name: 'Vadakara'},
{name: 'VINDHYACHAL'},
{name: 'Budhma'},
{name: 'Baidyanathdham'},
{name: 'Bhadran'},
{name: 'Bandra (T)'},
{name: 'Bareilly'},
{name: 'Beas'},
{name: 'Budaun'},
{name: 'Beawar'},
{name: 'Budge Budge'},
{name: 'Baghbahar'},
{name: 'Bagalkot'},
{name: 'Bhagat Ki Kothi'},
{name: 'BHAGAT KI KOTHI'},
{name: 'Belgaum'},
{name: 'Balgona'},
{name: 'Bhagalpur'},
{name: 'BEGU SARAI'},
{name: 'BAIRGANIA'},
{name: 'Bangriposi'},
{name: 'Bharuch'},
{name: 'Bathua Bazar'},
{name: 'Bhadrak'},
{name: 'Bihariganj'},
{name: 'Barhaj Bazar'},
{name: 'Bhilwara'},
{name: 'Bolpur Shantiniketan'},
{name: 'Bhairabi'},
{name: 'Vidisha'},
{name: 'Bhuj'},
{name: 'BARHARWA JN'},
{name: 'BARI SADRI'},
{name: 'BILIMORA JN'},
{name: 'Bina'},
{name: 'Bir'},
{name: 'Biraul'},
{name: 'Bhind'},
{name: 'Bhojudih Junction'},
{name: 'Bijnor'},
{name: 'Bijapur'},
{name: 'BAIJNATH PAPROLA'},
{name: 'Barauni Jn.'},
{name: 'Bhikhna Thori'},
{name: 'Bandikui Jn.'},
{name: 'Bikaner'},
{name: 'Bakthiyarpur'},
{name: 'Bokaro Steel City'},
{name: 'Valsad'},
{name: 'BHILAD'},
{name: 'BHILDI'},
{name: 'BALANGIR'},
{name: 'Balurghat'},
{name: 'BALAMU JN'},
{name: 'Balasore'},
{name: 'Barmer'},
{name: 'BOLARUM'},
{name: 'Badampahar'},
{name: 'Bangalore'},
{name: 'Banda Jn.'},
{name: 'Banmankhi'},
{name: 'Banarhat'},
{name: 'Binnaguri'},
{name: 'BHANVAD'},
{name: 'Bhiwani'},
{name: 'Barhni'},
{name: 'Barsoi'},
{name: 'Barog'},
{name: 'BHOGPUR'},
{name: 'Bhadohi'},
{name: 'Badarpur'},
{name: 'Berhampore Court'},
{name: 'Bhopal'},
{name: 'Baghpat Road'},
{name: 'Baripada'},
{name: 'Bapatla'},
{name: 'Balharshah'},
{name: 'Bankura'},
{name: 'Vadodara'},
{name: 'Bargarh Road'},
{name: 'Barkakana'},
{name: 'Biramitrapur'},
{name: 'Baramati'},
{name: 'Barapa Lli'},
{name: 'Barapalli'},
{name: 'Biyavara Rajgarh'},
{name: 'Barwadih'},
{name: 'BAREILLY'},
{name: 'Varanasi'},
{name: 'Bulandshahr'},
{name: 'Bhusaval'},
{name: 'Bilaspur Jn.'},
{name: 'Banspani'},
{name: 'Vasai Road'},
{name: 'Basti'},
{name: 'Basar'},
{name: 'Barasat'},
{name: 'Balaghat'},
{name: 'Botad'},
{name: 'BOTAD JN'},
{name: 'Bharatpur'},
{name: 'Bettiah'},
{name: 'Bathinda Jn.'},
{name: 'Bhatkal'},
{name: 'Bhatni'},
{name: 'Bitragunta'},
{name: 'Baraut'},
{name: 'Bundi'},
{name: 'Ballia'},
{name: 'Burhwal'},
{name: 'Bhavnagar (T)'},
{name: 'Borivali'},
{name: 'BHAVANAGAR PARA'},
{name: 'Bhimavaram Jn.'},
{name: 'Bhimavaram Town'},
{name: 'Barddhaman Jn.'},
{name: 'Bhawanipatna'},
{name: 'Bangarapet'},
{name: 'Barsuan'},
{name: 'Bayana'},
{name: 'Buxar'},
{name: 'Bamanhat'},
{name: 'Bhatapara'},
{name: 'Siswa Bazar S'},
{name: 'Vijayawada'},
{name: 'Betul'},
{name: 'Chakia'},
{name: 'Chanda Fort'},
{name: 'Kannur'},
{name: 'Chatrapur'},
{name: 'Kanyakumari'},
{name: 'Chunar'},
{name: 'Coimbatore Jn.'},
{name: 'Chik Ballapur'},
{name: 'Sitapur Cantt. S'},
{name: 'KAKINADA TOWN'},
{name: 'Chandrapur'},
{name: 'Chandod'},
{name: 'Chandigarh'},
{name: 'Chidambaram'},
{name: 'Canning'},
{name: 'Chengalpattu Jn.'},
{name: 'Chandausi'},
{name: 'CHANDIA ROAD'},
{name: 'Srikakulam Road'},
{name: 'Sri'},
{name: 'Chiplun'},
{name: 'Chakki Bank'},
{name: 'Chirmiri'},
{name: 'CHARVATTUR'},
{name: 'Kanchipuram'},
{name: 'Chakradharpur'},
{name: 'Chitrakootdham Karvi'},
{name: 'Silchar S'},
{name: 'CASTLE ROCK'},
{name: 'Kozhikode'},
{name: 'Chirala'},
{name: 'CHAMARAJANAGAR'},
{name: 'Chanderiya'},
{name: 'Kanpur Central'},
{name: 'Chengannur'},
{name: 'Chandil'},
{name: 'Canacona'},
{name: 'Kakinada Port'},
{name: 'Chittaurgarh'},
{name: 'Kanpur Anwarganj'},
{name: 'Champa'},
{name: 'Kaptanganj'},
{name: 'Chaparmukh'},
{name: 'Chhapra'},
{name: 'Chopan'},
{name: 'Chittaranjan'},
{name: 'Choranda Junction'},
{name: 'Chandrapura'},
{name: 'Shahu Maharaj (T)'},
{name: 'Chalisgaon'},
{name: 'Mumbai Cst'},
{name: 'Chitradurg'},
{name: 'Cuttack'},
{name: 'Chhota Udepur'},
{name: 'Chittoor'},
{name: 'Cuddalore Port'},
{name: 'Churu'},
{name: 'Chhindwara'},
{name: 'Dharmabad'},
{name: 'Dhasa'},
{name: 'Dabhoi Junction'},
{name: 'Dhubri'},
{name: 'Darbhanga'},
{name: 'Dibrugarh'},
{name: 'Dibrugarh Town'},
{name: 'Daund'},
{name: 'Dehradun'},
{name: 'MUMBAI DADAR WEST'},
{name: 'Delhi Cantt.'},
{name: 'Delhi Sarai Rohilla'},
{name: 'Deoriasadar'},
{name: 'Dindigul Jn.'},
{name: 'Dongargarh'},
{name: 'Sri Dungargarh S'},
{name: 'Digha'},
{name: 'Durgapur'},
{name: 'Diamond Harbour'},
{name: 'Dhaca Cantt.'},
{name: 'Dahod'},
{name: 'Dhrangadhra'},
{name: 'Dhule'},
{name: 'Devanhalli'},
{name: 'Dhanbad'},
{name: 'Dhone'},
{name: 'Dhaulpur'},
{name: 'DIVA'},
{name: 'Darjeeling'},
{name: 'To Darjeeling'},
{name: 'DANKUNI'},
{name: 'DEKARGAON'},
{name: 'Dornakal'},
{name: 'Dullabcherra'},
{name: 'Delhi'},
{name: 'Dhola'},
{name: 'Dildarnagar'},
{name: 'Dalgaon'},
{name: 'Dauram Madhepura'},
{name: 'Dharmavaram'},
{name: 'Dhamangaon'},
{name: 'Damoh'},
{name: 'Dharmanagar'},
{name: 'Dimapur'},
{name: 'DEGANA JN'},
{name: 'Danapur'},
{name: 'Dungarpur'},
{name: 'Dhenuanal'},
{name: 'Dehri-On-Sone'},
{name: 'Dharmapuri'},
{name: 'Dadar'},
{name: 'Dahanu Road'},
{name: 'Dallirajhara'},
{name: 'Delhi Shahdara'},
{name: 'Daltonganj'},
{name: 'Dhamtari'},
{name: 'Dhuri'},
{name: 'Dumka'},
{name: 'Durg'},
{name: 'Delvada'},
{name: 'Duvvada'},
{name: 'Davangere'},
{name: 'Devlali'},
{name: 'Dwarka'},
{name: 'Dharwad'},
{name: 'Erode'},
{name: 'Ledo L'},
{name: 'Eluru'},
{name: 'Achalpur'},
{name: 'Ernakulam Town'},
{name: 'Ernakulam Jn.'},
{name: 'Itarsi'},
{name: 'Etah'},
{name: 'Etawah'},
{name: 'Farrukhabad'},
{name: 'Forbesganj'},
{name: 'Faizabad'},
{name: 'Faridabad'},
{name: 'Lower Halflong L'},
{name: 'Fazilka'},
{name: 'Furkating'},
{name: 'FAKIRAGRAM JN'},
{name: 'Phulera'},
{name: 'KACHEGUDA FALAKNUMA'},
{name: 'Farukhnagar'},
{name: 'Fatehabad Chand'},
{name: 'Fatehpur'},
{name: 'Fatuha'},
{name: 'Firozabad'},
{name: 'Firozpur City'},
{name: 'Firozpur Cantt.'},
{name: 'Gondia'},
{name: 'Gevra Road'},
{name: 'Gandhinagar'},
{name: 'Gaya'},
{name: 'GARHBETA'},
{name: 'Gonda'},
{name: 'Godhra'},
{name: 'Gadag'},
{name: 'Gudur'},
{name: 'Gudivada'},
{name: 'Gede'},
{name: 'GANDHIGRAM'},
{name: 'Gangapur City'},
{name: 'Gurgaon'},
{name: 'Garwa Road'},
{name: 'Guwahati'},
{name: 'Gandhidham'},
{name: 'GANDHIDHAM BG'},
{name: 'Gajraula'},
{name: 'Gola Gokarannath'},
{name: 'Gorakhpur'},
{name: 'Lalgola L'},
{name: 'Goalpara Town'},
{name: 'Chandra Bose Gomoh'},
{name: 'Gandhinagar Capital'},
{name: 'Gunupur'},
{name: 'Guntur'},
{name: 'Sagour S'},
{name: 'Gossaingaon Hat'},
{name: 'GUMMIDIPUNDI'},
{name: 'Gulbarga'},
{name: 'Giridih'},
{name: 'Guntakal'},
{name: 'Ghatsila'},
{name: 'Gua'},
{name: 'Guna'},
{name: 'Siliguri Town S'},
{name: 'Guruvay Ur'},
{name: 'Gwalior'},
{name: 'Gwalior NG'},
{name: 'Gooty'},
{name: 'Gyanpur Road'},
{name: 'Ghaziabad'},
{name: 'Hapa'},
{name: 'Hoshangabad'},
{name: 'Howbadh'},
{name: 'Habibganj'},
{name: 'Harda'},
{name: 'Haldibari'},
{name: 'Hilsa'},
{name: 'Hajipur'},
{name: 'Jakhal J'},
{name: 'Haldia'},
{name: 'Hanumangarh'},
{name: 'Himmat Nagar'},
{name: 'Hasanabad'},
{name: 'Hingoli'},
{name: 'Hamiltonganj'},
{name: 'Harpalpur'},
{name: 'Hospet'},
{name: 'Hapur'},
{name: 'Hathras Kila'},
{name: 'Harihar'},
{name: 'HATHRAS JN'},
{name: 'Hasimara'},
{name: 'Hisar'},
{name: 'Hosur'},
{name: 'Hoshiarpur'},
{name: 'Hatia'},
{name: 'Silghat S'},
{name: 'Hindupur'},
{name: 'Haridwar'},
{name: 'Howrah'},
{name: 'Cuddapah'},
{name: 'Hyderabad'},
{name: 'HAZARIBAGH ROAD'},
{name: 'Indara'},
{name: 'Igatpuri'},
{name: 'Indore'},
{name: 'Indore Junction MG'},
{name: 'Islampur'},
{name: 'ITWARI'},
{name: 'Jalna'},
{name: 'YAMUNA BRIDGE AGRA'},
{name: 'JHAJHA'},
{name: 'Jamnagar'},
{name: 'Jammu Tawi'},
{name: 'Jogbani'},
{name: 'Jabalpur'},
{name: 'Jagdalpur'},
{name: 'JOGINDER NAGAR'},
{name: 'Jhargram'},
{name: 'JAKHAL JN'},
{name: 'Jhansi'},
{name: 'Jind'},
{name: 'Jaijon Doaba'},
{name: 'Jajpur Keonjhar Road'},
{name: 'Jhanjharpur'},
{name: 'Jalgaon'},
{name: 'Jetalsar'},
{name: 'Jalamb'},
{name: 'Jambusar Junction'},
{name: 'JAWLMUKHI ROAD'},
{name: 'Jamner'},
{name: 'Jamalpur'},
{name: 'JUNAGADH JN'},
{name: 'Janghai'},
{name: 'Junnor Deo'},
{name: 'Junagarh Jn. J'},
{name: 'Jaunpur Jn.'},
{name: 'Jaipur'},
{name: 'Jalpaiguri'},
{name: 'JIRIBAM'},
{name: 'Jalandhar Cantt. J'},
{name: 'Chikjajur J'},
{name: 'Jharsuguda Js'},
{name: 'Jaisalmer'},
{name: 'Jasidih'},
{name: 'Jolarpettai'},
{name: 'Jorhat Town'},
{name: 'Jodhpur'},
{name: 'Jalandhar City J'},
{name: 'Jaynagar'},
{name: 'Jaypur'},
{name: 'Kavi'},
{name: 'Karwar'},
{name: 'Kabakaputtur'},
{name: 'KADAMBATTUR'},
{name: 'Kalchini'},
{name: 'Kacheguda'},
{name: 'Kochuveli'},
{name: 'Khed Brahma'},
{name: 'Kendujhargarh'},
{name: 'Khekra'},
{name: 'Khagaria'},
{name: 'Kathgodam'},
{name: 'Kurseong'},
{name: 'Kharagpur'},
{name: 'Kasaragod'},
{name: 'KOLAGHAT GOODS'},
{name: 'Karaikal'},
{name: 'Katihar'},
{name: 'Kiul'},
{name: 'Krishnarajapuram'},
{name: 'KARJAT'},
{name: 'Khijadiya Junction'},
{name: 'Kurukshetra'},
{name: 'Karaikkudi Jn.'},
{name: 'Kot Kapura'},
{name: 'Khalilabad'},
{name: 'Kalka'},
{name: 'Kalol'},
{name: 'KAMAREDDI'},
{name: 'Khammam'},
{name: 'Kumbakonam'},
{name: 'Katni Murwara'},
{name: 'Kishanganj'},
{name: 'Konch'},
{name: 'Krishnanagar City'},
{name: 'KANALAS JN'},
{name: 'Khandwa'},
{name: 'KOLKATA CHITPUR'},
{name: 'Kolkata'},
{name: 'Kodinar'},
{name: 'C SHAHU M RAJ KOLHAPUR TERM'},
{name: 'Kota'},
{name: 'Katpadi'},
{name: 'Kopergaon'},
{name: 'KASHIPUR'},
{name: 'Kandhla'},
{name: 'Kodaikanal Road'},
{name: 'KODERMA'},
{name: 'Korba'},
{name: 'Kirandul'},
{name: 'Khurja'},
{name: 'Kurnool Town'},
{name: 'Koraput'},
{name: 'Karur'},
{name: 'KOSAMBA JN'},
{name: 'Kishangarh'},
{name: 'Kasganj'},
{name: 'Kasganj Mg'},
{name: 'Kesinga'},
{name: 'KOSI KALAN'},
{name: 'Katni'},
{name: 'Kathana'},
{name: 'Kotdwara'},
{name: 'Kottayam'},
{name: 'Kundapura'},
{name: 'Kumarghat'},
{name: 'Karnal'},
{name: 'Khurda Road'},
{name: 'KHAJURAHO'},
{name: 'Katwa'},
{name: 'Kurduwadi'},
{name: 'Karimganj'},
{name: 'Kalyani'},
{name: 'KAYANKULAM JN'},
{name: 'Kalyan'},
{name: 'Kamakhya'},
{name: 'Kazipet'},
{name: 'Sirpur Kagaznagar S'},
{name: 'Surathkal S'},
{name: 'Lohardaga'},
{name: 'LAKHO'},
{name: 'Lalitpur'},
{name: 'Londa'},
{name: 'Ludhiana'},
{name: 'Ledo'},
{name: 'Lower Haflong'},
{name: 'Lalgarh'},
{name: 'Lalgola'},
{name: 'Balangir B'},
{name: 'Loharu'},
{name: 'LUCKNOW JN'},
{name: 'Lucknow'},
{name: 'Lal Kuan'},
{name: 'LUCKNOW'},
{name: 'Laukaha Bazar'},
{name: 'Lalkuan Jn.'},
{name: 'Lumding'},
{name: 'LAKHIMPUR'},
{name: 'LOHIAN KHAS JN'},
{name: 'LINGAMPALLI'},
{name: 'LAJPAT NAGAR'},
{name: 'Laksar'},
{name: 'LOKMANYA TILAK TERM'},
{name: 'Luni'},
{name: 'Latur'},
{name: 'Alnawar Jn.'},
{name: 'Maddur'},
{name: 'Matheran'},
{name: 'Mangalore Jn.'},
{name: 'Manu'},
{name: 'Madgaon (Goa)'},
{name: 'Mangalore Central'},
{name: 'Chennai'},
{name: 'Mau'},
{name: 'Moradabad'},
{name: 'Mahoba'},
{name: 'Munabao'},
{name: 'Madhubani'},
{name: 'Shri Mahabirji S'},
{name: 'Mahbubnagar'},
{name: 'Mairabari'},
{name: 'MANCHIRYAL'},
{name: 'Macherla'},
{name: 'Manendragarh'},
{name: 'Mandar Hill'},
{name: 'Midnapore'},
{name: 'Madhupur'},
{name: 'Modasa'},
{name: 'Madarihat'},
{name: 'Madurai Jn.'},
{name: 'MEDCHAL'},
{name: 'Vanchi Maniyachchi Jn.'},
{name: 'Shimoga Town S'},
{name: 'Muzaffarpur'},
{name: 'Mandla Fort'},
{name: 'Munger'},
{name: 'Mughalsarai'},
{name: 'Manihari'},
{name: 'Mhow'},
{name: 'Mahuva'},
{name: 'Sitamarhi S'},
{name: 'Marwar Jn.'},
{name: 'Mokama'},
{name: 'Maksi'},
{name: 'Motihari'},
{name: 'Marikuppam'},
{name: 'Manikpur'},
{name: 'Moti Koral'},
{name: 'Malda Town'},
{name: 'Mailani'},
{name: 'Malsar'},
{name: 'Manmad'},
{name: 'Mansi'},
{name: 'Muniguda'},
{name: 'Somnath S'},
{name: 'Manamadurai'},
{name: 'Moga'},
{name: 'Muzaffar Nagar'},
{name: 'Lakhimpur L'},
{name: 'Mannargudi'},
{name: 'Miryalaguda'},
{name: 'Murliganj'},
{name: 'Miraj'},
{name: 'MARKAPUR ROAD'},
{name: 'Mahesana'},
{name: 'Mahasamund'},
{name: 'Meerut City'},
{name: 'Merta Road'},
{name: 'METUR DAM'},
{name: 'Mathura'},
{name: 'Machilipatnam'},
{name: 'Mettupalayam'},
{name: 'Mudkhed'},
{name: 'Manuguru'},
{name: 'Mankapur'},
{name: 'Muri'},
{name: 'MEERUT CANT'},
{name: 'Manduadih'},
{name: 'Mayiladuturai Jn.'},
{name: 'MAVLI JN'},
{name: 'Mariani'},
{name: 'MIYAGAM KARJAN'},
{name: 'MIYAGAM KARJAN JUNCTION NG'},
{name: 'MEMARI'},
{name: 'Mysore'},
{name: 'MIRZAPALI'},
{name: 'Mirzapur'},
{name: 'Murtajapur'},
{name: 'Murkeongselek'},
{name: 'Sultanpur Sl'},
{name: 'Nagbhir'},
{name: 'Nagda'},
{name: 'Najibabad'},
{name: 'New Bongaigaon'},
{name: 'New Coochbehar'},
{name: 'Nagercoil Jn.'},
{name: 'Nagore'},
{name: 'Nadiad'},
{name: 'Nabadwipdham'},
{name: 'Nandurbar'},
{name: 'Nidadavolu'},
{name: 'Nadikudi'},
{name: 'Nandyal'},
{name: 'New Delhi'},
{name: 'Nidubrolu'},
{name: 'Hazur Sahib Nanded'},
{name: 'New Farakka'},
{name: 'Nandgaon'},
{name: 'Nagpur'},
{name: 'Nagappatt Inam'},
{name: 'NANDOL DEHEGAM'},
{name: 'Nilambur Road'},
{name: 'Nainpur'},
{name: 'New Jalpaiguri'},
{name: 'Nasik Road'},
{name: 'Nagarkata'},
{name: 'Narkatiaganj'},
{name: 'Lonavla L'},
{name: 'Nalanda'},
{name: 'Nalgonda'},
{name: 'Nangal Dam'},
{name: 'North Lakhimpur'},
{name: 'Nellore'},
{name: 'Nirmali'},
{name: 'Nimach'},
{name: 'Nidamangalam'},
{name: 'Namkhana'},
{name: 'Namakkal'},
{name: 'Newmal Jn.'},
{name: 'Noli'},
{name: 'New Alipurduar'},
{name: 'Nepalganj Road'},
{name: 'Nandalur'},
{name: 'NERAL'},
{name: 'Narwana'},
{name: 'Narasapur'},
{name: 'Nagarsol'},
{name: 'New Tinsukia'},
{name: 'Nautanwa'},
{name: 'NANJANGUD TOWN'},
{name: 'Narsingpur'},
{name: 'Naugarh'},
{name: 'Nizamabad'},
{name: 'Nizamuddin'},
{name: 'Samalkot Sl'},
{name: 'Sojat Road S'},
{name: 'Odlabari'},
{name: 'Ongole'},
{name: 'Sihor Gujarat S'},
{name: 'Okha'},
{name: 'Unnao'},
{name: 'Boinda B'},
{name: 'Coonoor'},
{name: 'Orai'},
{name: 'Purna'},
{name: 'Pilibhit'},
{name: 'Pratapgarh'},
{name: 'Parbhani'},
{name: 'Porbandar'},
{name: 'Pachora'},
{name: 'Pudukottai'},
{name: 'Puducherry'},
{name: 'Phaphamau'},
{name: 'PRAGATI MAIDAN'},
{name: 'Palakkad'},
{name: 'Palakkad Town'},
{name: 'Palitana'},
{name: 'PANSKURA'},
{name: 'Phalodi'},
{name: 'Paliakalan'},
{name: 'Palani'},
{name: 'Pulgaon'},
{name: 'Patna'},
{name: 'Patna Sahib'},
{name: 'Parasnath'},
{name: 'Panipat'},
{name: 'Palanpur'},
{name: 'Panvel'},
{name: 'PONNERI'},
{name: 'POLLACHI JN'},
{name: 'Puranpur'},
{name: 'Pipariya'},
{name: 'PIPAR ROAD JN'},
{name: 'PATLIPUTRA'},
{name: 'Sri Sathyasai'},
{name: 'Paradeep'},
{name: 'Pattabiram East'},
{name: 'PRAYAG'},
{name: 'PARLI VAIJNATH'},
{name: 'Purnia'},
{name: 'Purulia'},
{name: 'Pratapnagar'},
{name: 'Palasa'},
{name: 'Patiala'},
{name: 'Podanur'},
{name: 'Pathankot'},
{name: 'PATAN'},
{name: 'Pune Jn.'},
{name: 'Puri'},
{name: 'Punalur'},
{name: 'PALLAVARAM'},
{name: 'Parvatipuram Town'},
{name: 'Pandharapur'},
{name: 'PALWAL'},
{name: 'KULEM'},
{name: 'Llam'},
{name: 'Raipur'},
{name: 'Repalle'},
{name: 'Rae Bareli'},
{name: 'Raichur'},
{name: 'Ramagundam'},
{name: 'Radhikapur'},
{name: 'Rewari'},
{name: 'Rewa'},
{name: 'Sagar Jambagaru S'},
{name: 'Rajgir'},
{name: 'Rayagada'},
{name: 'Ringus'},
{name: 'Rathopur'},
{name: 'Ranaghat'},
{name: 'Raigarh'},
{name: 'Rajim'},
{name: 'Rawatganj'},
{name: 'Raja-Ka-Sahaspur'},
{name: 'Rajmahal'},
{name: 'Rajnandgaon'},
{name: 'RAJENDRA NAGAR BIHAR'},
{name: 'Rajendranagar'},
{name: 'Rajkot'},
{name: 'Rajula City'},
{name: 'Rajahmundry'},
{name: 'Roorkee'},
{name: 'Raja-Ki-Mandi'},
{name: 'RISHIKESH'},
{name: 'Ramanathapuram'},
{name: 'Rameswaram'},
{name: 'Ramakona'},
{name: 'Ramnagar'},
{name: 'Rampur Jn.'},
{name: 'Ratnagiri'},
{name: 'Ranchi'},
{name: 'Rangiya'},
{name: 'Raninagar'},
{name: 'Rohtak'},
{name: 'Rourkela'},
{name: 'ROZA JN'},
{name: 'Rangapara North'},
{name: 'Rampurhat'},
{name: 'Rajpura'},
{name: 'RAGHUNATHPUR'},
{name: 'Shoranur Jn. S'},
{name: 'Birur'},
{name: 'Raghuraj Singh'},
{name: 'Ratangarh'},
{name: 'Ramtek'},
{name: 'Ratlam'},
{name: 'Renigunta'},
{name: 'RANUJ'},
{name: 'Runnisaidpur'},
{name: 'Raxaul'},
{name: 'Salem'},
{name: 'Sattenapalle'},
{name: 'Sahibganj'},
{name: 'Subrahmanya Road'},
{name: 'SABARMATI JN'},
{name: 'Sabalgarh'},
{name: 'Sambalpur'},
{name: 'Sambalpur City'},
{name: 'Secunderabad'},
{name: 'SITAPUR CANT'},
{name: 'Sensoa'},
{name: 'SILCHAR'},
{name: 'Sengottai'},
{name: 'Sealdah'},
{name: 'Shahabad'},
{name: 'SHAHDOL'},
{name: 'Sadulpur'},
{name: 'Sonpur'},
{name: 'Sewagram'},
{name: 'Sagauli'},
{name: 'Sriganga Nagar'},
{name: 'SAUGOR'},
{name: 'Singrauli'},
{name: 'Siliguri'},
{name: 'Shamgarh'},
{name: 'Saharsa'},
{name: 'Shahganj'},
{name: 'Shalimar'},
{name: 'Sambhal Hatim Sarai'},
{name: 'Silghat Town'},
{name: 'Sikar'},
{name: 'Singur'},
{name: 'Shikohabad'},
{name: 'SAKRI JN'},
{name: 'Shaktinagar'},
{name: 'SIRPUR KAGAZNAGAR'},
{name: 'Simaluguri'},
{name: 'Sangli'},
{name: 'SULTANPUR'},
{name: 'Shimoga Town'},
{name: 'SITAMARHI JN'},
{name: 'Shimla'},
{name: 'SOMNATH'},
{name: 'Shamli'},
{name: 'Samdari'},
{name: 'Sindri Town'},
{name: 'Sai Nagar Shirdi'},
{name: 'Sheopur Kalan'},
{name: 'Suratgarh'},
{name: 'Solan'},
{name: 'Sitapur City S'},
{name: 'SULLURUPETA'},
{name: 'Samastipur'},
{name: 'SHAHJEHANPUR'},
{name: 'Shahpur Patoree'},
{name: 'Sonarpur Junction'},
{name: 'Sompeta'},
{name: 'Santragachi'},
{name: 'Sardarshahr'},
{name: 'Saharanpur S'},
{name: 'Sirmuttra'},
{name: 'Sarnath'},
{name: 'SHORANUR JN'},
{name: 'Salempur S'},
{name: 'SIRPUR TOWN'},
{name: 'Sirsa'},
{name: 'SHAKURBASTI'},
{name: 'SASARAM'},
{name: 'Nilayam Ss'},
{name: 'Surat'},
{name: 'Satna'},
{name: 'Shantipur'},
{name: 'ST THOMAS MOUNT'},
{name: 'SITARAMPUR'},
{name: 'SITAPUR'},
{name: 'Satara'},
{name: 'Neta Ji'},
{name: 'Sujangarh'},
{name: 'Surendra Nagar'},
{name: 'Solapur'},
{name: 'Siuri'},
{name: 'Siwan'},
{name: 'S M V D KATRA'},
{name: 'Shivpuri'},
{name: 'Sawaimadhopur'},
{name: 'SAWANTWADI ROAD'},
{name: 'Shahjahanpur S'},
{name: 'Jorhat J'},
{name: 'Tarakeswar'},
{name: 'Tatanagar'},
{name: 'Telibandha'},
{name: 'Tambaram'},
{name: 'Tiruchendur'},
{name: 'Thrisur'},
{name: 'Tadepalligudem'},
{name: 'Tundla'},
{name: 'TANDUR'},
{name: 'Tenali'},
{name: 'Tirunelveli'},
{name: 'Thawe Junction'},
{name: 'Titlagarh'},
{name: 'Tirur'},
{name: 'Thanjavur'},
{name: 'TUMKUR'},
{name: 'TILAK BRIDGE'},
{name: 'Talguppa'},
{name: 'TALCHER'},
{name: 'Thalassery'},
{name: 'TIRUMALPUR'},
{name: 'Tumsar Road'},
{name: 'Tuticorin'},
{name: 'Tiruvannama Lai'},
{name: 'TINPAHAR JN'},
{name: 'Tiruchchirappalli Jn.'},
{name: 'Tantpur'},
{name: 'TIRUPATTUR JN'},
{name: 'Tirupati'},
{name: 'Tanakpur'},
{name: 'TIKUNIA'},
{name: 'Tirodi'},
{name: 'Tarighat'},
{name: 'TIRUVALLUR'},
{name: 'TIRUTTANI'},
{name: 'Tenkasi'},
{name: 'Tinsukia Junction'},
{name: 'Lokmanya Tilak (T) L'},
{name: 'Tiruturaipudi Junction'},
{name: 'Tuni'},
{name: 'Tiruppur'},
{name: 'Thiruvananthapuram'},
{name: 'Thiruvarur.'},
{name: 'Tezpur'},
{name: 'Udagamandalam'},
{name: 'Hubli'},
{name: 'Unchahar'},
{name: 'Udupi'},
{name: 'ANDAL JN'},
{name: 'Udhna'},
{name: 'Udaipur City'},
{name: 'Una Himachal'},
{name: 'Udhampur'},
{name: 'Ujjain'},
{name: 'Ambala Cantt.'},
{name: 'Umarpada'},
{name: 'Una'},
{name: 'Varkala'},
{name: 'VAPI'},
{name: 'Bobbili'},
{name: 'VERNA'},
{name: 'Viramgam'},
{name: 'VIKARABAD JN'},
{name: 'Velachery'},
{name: 'Velankanni'},
{name: 'VELANKANNI'},
{name: 'VELLORE CANT'},
{name: 'Villupuram Jn.'},
{name: 'Virudunagar Jn.'},
{name: 'VIRAR'},
{name: 'Vriddhachalam Jn.'},
{name: 'Veraval'},
{name: 'Vasco-Da-Gama'},
{name: 'Visakhapatnam'},
{name: 'Vadtal Swaminarayan'},
{name: 'Vizianagaram'},
{name: 'Wadi'},
{name: 'Waghai'},
{name: 'Wankaner'},
{name: 'Warangal'},
{name: 'Wardha Jn.'},
{name: 'Wadsa'},
{name: 'Sawantwadi Road S'},
{name: 'Yesvantpur'},
{name: 'Yavatmal'},
{name: 'Zafarabad'},
{name: 'Mumbai'},
{name: 'Sullurpeta'},
{name: 'Naidupeta'},
{name: 'Guduru(Nellore District)'},
{name: 'Tirupathi'},
{name: 'Kadapa'},
{name: 'Vizag'},
{name: 'Ponnur'},
{name: 'Razole'},
{name: 'Amalapuram'},
{name: 'Kakinada'},
{name: 'Gokavaram'},
{name: 'Kavali'},
{name: 'S.Konda'},
{name: 'Anakapalli'},
{name: 'Jaggampet (Andhra Pradesh)'},
{name: 'Kathipudi'},
{name: 'Ulavapadu'},
{name: 'Railway Koduru'},
{name: 'Rajampet'},
{name: 'Vetapalem'},
{name: 'Sathenapalli'},
{name: 'A.S.Peta'},
{name: 'Atmakur Nellore'},
{name: 'Vinjamoor'},
{name: 'Kaligiri (Andhra Pradesh)'},
{name: 'Samarlakota'},
{name: 'Peddapuram'},
{name: 'Rangampeta(East Godavari)'},
{name: 'Rajanagaram (Andhra Pradesh)'},
{name: 'Kovvuru (Rajahmundry)'},
{name: 'Pangidi (Andhra Pradesh)'},
{name: 'Jangareddy Gudem'},
{name: 'Shirdi'},
{name: 'Srikakulam'},
{name: 'Pune'},
{name: 'Vijayanagaram'},
{name: 'Inkollu'},
{name: 'Narasaraopet'},
{name: 'Chilakaluripet'},
{name: 'Kurnool'},
{name: 'Yanam'},
{name: 'Prathipadu East Godavari Dist'},
{name: 'Tada (Andhra Pradesh)'},
{name: 'Elamanchili'},
{name: 'Edurlanka'},
{name: 'Muramalla'},
{name: 'Mummidivaram'},
{name: 'Ambajipeta'},
{name: 'Kothapeta (Andhra Pradesh)'},
{name: 'Ravulapalem'},
{name: 'Tanuku'},
{name: 'T.P. Gudem'},
{name: 'P. Gannavaram'},
{name: 'Tatipaka'},
{name: 'Malikipuram (East Godavari Dist'},
{name: 'Chinchinada'},
{name: 'Palakollu'},
{name: 'Bhimavaram'},
{name: 'Juvvalapalem'},
{name: 'Kalidindi'},
{name: 'Mudinepalli (Andhra Pradesh)'},
{name: 'Nellore (By-pass)'},
{name: 'Guduru (By-Pass)'},
{name: 'Naidupeta (By-Pass)'},
{name: 'Sullurupeta (By-Pass)'},
{name: 'Appanapalli'},
{name: 'Mamidikuduru'},
{name: 'Nagaram (Telangana)'},
{name: 'Lakkavaram (East Godavari Dist'},
{name: 'Akivedu'},
{name: 'Kaikaluru'},
{name: 'Undi (Andhra Pradesh)'},
{name: 'Mandavalli (Andhra Pradesh)'},
{name: 'Veeravasaram'},
{name: 'Srungavruksham'},
{name: 'Munganda'},
{name: 'Aurangabad (Maharastra)'},
{name: 'Goa'},
{name: 'Solapur (Maharashtra)'},
{name: 'Nanded'},
{name: 'Burhanpur(Madhya Pradesh)'},
{name: 'Chehgaon'},
{name: 'Ananthapur (Andhra Pradesh)'},
{name: 'Parchur'},
{name: 'Kavali (Bye Pass)'},
{name: 'Bus Stand'},
{name: 'NRT Centre'},
{name: 'Opp Bus Stand'},
{name: 'Ongole(By-Pass)'},
{name: 'Chittoor (Andhra Pradesh)'},
{name: 'Hanuman Junction (Andhra Pradesh)'},
{name: 'Gannavaram (Vijayawada AP)'},
{name: 'Narsapuram (Andhra Pradesh)'},
{name: 'Kalla'},
{name: 'Kallakuru'},
{name: 'Korukollu'},
{name: 'Bhuranpur'},
{name: 'Partur (Maharastra)'},
{name: 'Khamgaon (Maharashtra)'},
{name: 'Bhadwa (Rajasthan)'},
{name: 'Washim'},
{name: 'Khandwa (Madhya Pradesh)'},
{name: 'Sanawat'},
{name: 'Chegaon'},
{name: 'Bhorgaon'},
{name: 'T P Gudem (Bye-Pass)'},
{name: 'Anaparthi'},
{name: 'Dwarapudi'},
{name: 'Tanuku (By-Pass)'},
{name: 'Gajuwaka'},
{name: 'Nagpur (Maharastra)'},
{name: 'Pandar Koda'},
{name: 'Hinghanghat'},
{name: 'Jaam'},
{name: 'Humnabad'},
{name: 'Umerga(Maharastra)'},
{name: 'Zaheerabad'},
{name: 'Nirmal'},
{name: 'Kamareddy'},
{name: 'Medchal'},
{name: 'Asansol (West Bengal)'},
{name: 'Durgapur (West Bengal)'},
{name: 'Burdwan'},
{name: 'Bokaro'},
{name: 'Siliguri (West Bengal)'},
{name: 'Babughat'},
{name: 'Panagarh'},
{name: 'Neamatpur (West Bengal)'},
{name: 'Govindpur (Gujarat)'},
{name: 'Chas Bokaro'},
{name: 'Ramgarh (Haryana)'},
{name: 'Krishna Nagar'},
{name: 'Berhampur (Orissa)'},
{name: 'Malda (West Bengal)'},
{name: 'Islampur (Bihar)'},
{name: 'Raigunj (WestBengal)'},
{name: 'Simhapuri'},
{name: 'Mayiladuthurai'},
{name: 'Thiruvarur'},
{name: 'Vedharanyam'},
{name: 'Nagore (Tamil Nadu)'},
{name: 'Nagapattinam'},
{name: 'Velankkani'},
{name: 'Ammapattinam'},
{name: 'Lakshmangudi (Tamil Nadu)'},
{name: 'Vadacheri'},
{name: 'Pattukottai'},
{name: 'Athirampattinam'},
{name: 'Kattumavadi'},
{name: 'Muthupettai (Tamil Nadu)'},
{name: 'Araku'},
{name: 'Dhaka (Bihar)'},
{name: 'Bngtesting'},
{name: 'Vjatesting'},
{name: 'Vsptesting'},
{name: 'Gnttesting'},
{name: 'Thopputhurai'},
{name: 'Manamelkudi'},
{name: 'Kakinada & Yanam'},
{name: 'Yanam & Kakinada'},
{name: 'Mayapur (Madhya Pradesh)'},
{name: 'Tarapith'},
{name: 'Suri (West Bengal)'},
{name: 'Farakka'},
{name: 'Kharagpur (West Bengal)'},
{name: 'Baleswar'},
{name: 'Barhampur'},
{name: 'Digha (West Bengal)'},
{name: 'Jakkaram'},
{name: 'Elurupadu'},
{name: 'Nizampet'},
{name: 'Mahipalacheruvu'},
{name: 'Anathavaram'},
{name: 'Mukkamala (Andhra Pradesh)'},
{name: 'Avidi'},
{name: 'Sidhantham'},
{name: 'Agra'},
{name: 'Jaipur (Rajasthan)'},
{name: 'Nashik'},
{name: 'Ahmednagar (Maharastra)'},
{name: 'Amaravathi(MH)'},
{name: 'Dhulia'},
{name: 'Malegaon'},
{name: 'Dharni (Maharashtra)'},
{name: 'Parathwada'},
{name: 'Shivpuri (Madhya Pradesh)'},
{name: 'Morena'},
{name: 'Bhushwal'},
{name: 'Kota(Rajastan)'},
{name: 'Nadiyad'},
{name: 'Navuduru'},
{name: 'Palamuru'},
{name: 'Brahmana Cheruvu'},
{name: 'Natta Rameswaram'},
{name: 'Penumatra'},
{name: 'Attili'},
{name: 'Ardhavan'},
{name: 'Ganapavaram (Andhra Pradesh)'},
{name: 'Nidamaru'},
{name: 'Rachoor'},
{name: 'Narayanapuram (West Godavari)'},
{name: 'Palla'},
{name: 'Komaragiri'},
{name: 'Kothakalwa'},
{name: 'Bondaikodu'},
{name: 'Bandarulanka'},
{name: 'Pulletikurru'},
{name: 'Palevela'},
{name: 'Peruru'},
{name: 'Siddhamtam'},
{name: 'Annavarapadu'},
{name: 'Khandavalli'},
{name: 'Peravali'},
{name: 'Vatlapalem'},
{name: 'Bikkavole'},
{name: 'Kadiyam'},
{name: 'Balabadrapuram'},
{name: 'Dawaleswaram'},
{name: 'Kotipalli (Andhra Pradesh)'},
{name: 'Tallapudi'},
{name: 'Kanuru (Vijayawada)'},
{name: 'Undrajavaram'},
{name: 'Tetali'},
{name: 'Duvva'},
{name: 'Narasapuram'},
{name: 'Chikkala'},
{name: 'Pennade'},
{name: 'Guravayyepalem'},
{name: 'Pasarlapudi'},
{name: 'Buttelanka'},
{name: 'Sivakodu'},
{name: 'Cherukuwada'},
{name: 'Bujabalapatnam'},
{name: 'Mandapeta'},
{name: 'Alamuru (East Godavari)'},
{name: 'Kaikaram'},
{name: 'Pulla'},
{name: 'Turangi'},
{name: 'Gollapalem'},
{name: 'Draksharamam'},
{name: 'Machavaram(E.G. Dist.)'},
{name: 'Kolaghat'},
{name: 'Kantai'},
{name: 'Shaktigarh (West Bengal)'},
{name: 'Murari'},
{name: 'Sultanpur (Uttar Pradesh)'},
{name: 'Jaunpur'},
{name: 'Kanpur (Uttar pradesh)'},
{name: 'Auraiya (Uttar Pradesh)'},
{name: 'Dakshineshwar'},
{name: 'Shantiniketan'},
{name: 'Beharampur'},
{name: 'Krishnanagar (West Bengal)'},
{name: 'Datiya'},
{name: 'Jhansi (Uttar Pradesh)'},
{name: 'Khethwadi'},
{name: 'Rampurghat'},
{name: 'Raebareli'},
{name: 'Kunda (Uttar Pradesh)'},
{name: 'Mohan Nagar (Uttar Pradesh)'},
{name: 'Muradabad'},
{name: 'Rampur'},
{name: 'Shajhanpur'},
{name: 'Sitapur (Uttar Pradesh)'},
{name: 'Karamchedu'},
{name: 'Alambagh(Lko)'},
{name: 'Haidergarh (Uttar Pradesh)'},
{name: 'Jagdishpur (Uttar Pradesh)'},
{name: 'Orai (Uttar Pradesh)'},
{name: 'Kannauj (Uttar Pradesh)'},
{name: 'Bevar'},
{name: 'Eta'},
{name: 'Aligarh (Uttar Pradesh)'},
{name: 'Bulandsahar'},
{name: 'Basti (Uttar Pradesh)'},
{name: 'Gorakhpur (Uttar Pradesh)'},
{name: 'Padrauna'},
{name: 'Dewariya'},
{name: 'Jhakarkati(Kanpur)'},
{name: 'Rawatpur(Kanpur)'},
{name: 'Chunniganj(Kanpur)'},
{name: 'Kosi (Uttar Pradesh)'},
{name: 'Palval'},
{name: 'Faridabad (Haryana)'},
{name: 'Noida'},
{name: 'Sadabad (Uttar Pradesh)'},
{name: 'Hathras'},
{name: 'Sasni'},
{name: 'Meerut'},
{name: 'Tundla (Uttar Pradesh)'},
{name: 'Kasganj (Uttar Pradesh)'},
{name: 'Vadaun'},
{name: 'Meerut (S)'},
{name: 'Meerut (W)'},
{name: 'Khatoli (Rajasthan)'},
{name: 'Mujaffarnagar (Bulandshahar'},
{name: 'Deovand'},
{name: 'Sahranpur'},
{name: 'Roorkee (Uttarakhand)'},
{name: 'Chuthmalpur'},
{name: 'Atrauli (Uttar Pradesh)'},
{name: 'Naroura'},
{name: 'Babrala (Uttar Pradesh)'},
{name: 'Bahjoi'},
{name: 'Chandousi'},
{name: 'Vilaspur'},
{name: 'Cheeni Mill'},
{name: 'Rudrapur (Uttar Pradesh)'},
{name: 'Haldwani'},
{name: 'RC Puram'},
{name: 'Koyyalagudem (Andhra Pradesh)'},
{name: 'Rajupalem'},
{name: 'Tanguturu'},
{name: 'Eluru (By-Pass)'},
{name: 'Khatauli (Uttar Pradesh)'},
{name: 'Rishikesh'},
{name: 'Bilaspur(Himachal Pradesh)'},
{name: 'Brijghat'},
{name: 'Garhchopla'},
{name: 'Kotdwar'},
{name: 'Murad Nagar'},
{name: 'Mawana'},
{name: 'Meerapur (Uttar Pradesh)'},
{name: 'Bijnaur'},
{name: 'Kiratpur'},
{name: 'Nazibabad'},
{name: 'S.Gate'},
{name: 'Kithore (Uttar Pradesh)'},
{name: 'Garh (Madhya Pradesh)'},
{name: 'Gajraula (Uttar Pradesh)'},
{name: 'Joya (Uttar Pradesh)'},
{name: 'Milak'},
{name: 'Fatehganj'},
{name: 'Gabhana (Uttar Pradesh)'},
{name: 'Relaince A-1 Restaurent'},
{name: 'Katra (Jammu and kashmir)'},
{name: 'Ghazibad'},
{name: 'Tilhar (Uttar Pradesh)'},
{name: 'J P Ganj'},
{name: 'Maholi'},
{name: 'Sindholi'},
{name: 'Bhadrachalam'},
{name: 'Palavancha'},
{name: 'Kothagudem (Telangana)'},
{name: 'Shahganj (Uttar Pradesh)'},
{name: 'Ghazipur (Uttar Pradesh)'},
{name: 'Farrukhabad (Uttar Pradesh)'},
{name: 'Jalalabad (Punjab)'},
{name: 'Inamarugu'},
{name: 'Lankala Koderu'},
{name: 'Pennada'},
{name: 'IDPL'},
{name: 'ECIL'},
{name: 'Moula-Ali'},
{name: 'Nallakunta'},
{name: 'CBS Hyderabad'},
{name: 'RTC X Roads'},
{name: 'Shivakodu'},
{name: 'Achanta'},
{name: 'Marteru'},
{name: 'Bhimadolu'},
{name: 'Rachuru (Andhra Pradesh)'},
{name: 'Chebrolu (Guntur Dist)'},
{name: 'Eleswaram'},
{name: 'Vetlapalem'},
{name: 'Bikkavolu'},
{name: 'Madduru Bridge'},
{name: 'Brahmanagudem'},
{name: 'Oonakatla'},
{name: 'Chagallu'},
{name: 'Pallamkuru'},
{name: 'Katrenikona'},
{name: 'Cheyyeru'},
{name: 'Devarapally'},
{name: 'Nidamarru'},
{name: 'Padamata Palem'},
{name: 'Unagatala'},
{name: 'Madduru (Andhra Pradesh)'},
{name: 'Kotturu (Andhra Pradesh)'},
{name: 'Gopalapuram West Godavari Dist.'},
{name: 'Kandavelli (Andhra Pradesh)'},
{name: 'Gopiganj (Uttar Pradesh)'},
{name: 'Addavaram'},
{name: 'Kadamanchali'},
{name: 'Aswaraopet'},
{name: 'Sathupalli'},
{name: 'Jagganathagiri'},
{name: 'Drakshramam'},
{name: 'Kapileswarapuram'},
{name: 'Angara (Jharkhand)'},
{name: 'Jagannapeta'},
{name: 'Erravaram'},
{name: 'Mydukur'},
{name: 'Pandithavalluru'},
{name: 'Gouripatnam'},
{name: 'Kandikuppa'},
{name: 'Atreyapuram'},
{name: 'Akbarpur (Ambedkar Nagar)'},
{name: 'Mau (Uttar Pradesh)'},
{name: 'Kandukuru (Prakasam Dist'},
{name: 'Medarametla'},
{name: 'Addanki'},
{name: 'Injamuru'},
{name: 'Nellore Palem'},
{name: 'Khaga(Uttar Pradesh)'},
{name: 'Saini'},
{name: 'Lal Ganj'},
{name: 'Fatehpur (Uttar Pradesh)'},
{name: 'Vedullapally'},
{name: 'Lonagatla'},
{name: 'Gowripatanam'},
{name: 'Lucknow (Quaiserbagh)'},
{name: 'Lucknow (Alambagh)'},
{name: 'Barabanki'},
{name: 'Jarwal Road'},
{name: 'Nanpara'},
{name: 'Rupidiha'},
{name: 'Bahraich'},
{name: 'Jangareddy Gudem (Bye Pass)'},
{name: 'Ernakulam'},
{name: 'Cochin'},
{name: 'Hosur (Tamil Nadu)'},
{name: 'Kovvuru Toll Gate'},
{name: 'Sandolu'},
{name: 'Reddypalem'},
{name: 'Karlapalem'},
{name: 'Epurupalem'},
{name: 'Jandrapeta'},
{name: 'Nellore (Mini By-Pass)'},
{name: 'Sonauli'},
{name: 'Marturu'},
{name: 'Sri Balaji'},
{name: 'Bharatpur (Rajasthan)'},
{name: 'Dausa'},
{name: 'Kolkata(Saltlake)'},
{name: 'Kolkata(Esplanade)'},
{name: 'Kolkata(Airport)'},
{name: 'Kolkata(Babughat)'},
{name: 'Rudrapur (Uttarakhand)'},
{name: 'Bhilore (Uttar Pradesh)'},
{name: 'Guru Sahay Ganj'},
{name: 'Bahedi'},
{name: 'Kichha (Uttarakhand)'},
{name: 'Mati (Uttar Pradesh)'},
{name: 'Pukhrayan'},
{name: 'Kalpi (Madhya Pradesh)'},
{name: 'Moth (Uttar Pradesh)'},
{name: 'Chirgaon (Uttar Pradesh)'},
{name: 'Thrissur'},
{name: 'Coimbatore'},
{name: 'Alapuzha'},
{name: 'Anakara'},
{name: 'Angamaly'},
{name: 'Attingal'},
{name: 'Calicut'},
{name: 'Alathur'},
{name: 'Chalakudy'},
{name: 'Changanassery'},
{name: 'Cherthala'},
{name: 'Cumbam'},
{name: 'Dindigul'},
{name: 'Mallappally'},
{name: 'Kuravilagad'},
{name: 'Mananthavaadi'},
{name: 'Moonupeedika'},
{name: 'Moovattupuzha'},
{name: 'Munnar (van drop)'},
{name: 'Pala'},
{name: 'Kalpetta'},
{name: 'Kanjirappally'},
{name: 'Karukachal'},
{name: 'Karunagapilly'},
{name: 'Karur(byepass)'},
{name: 'Kattappana'},
{name: 'Kayamkulam'},
{name: 'Kazhakoottam'},
{name: 'Kodungalore'},
{name: 'Kollam'},
{name: 'Koothattukulam'},
{name: 'Kothamangalam'},
{name: 'Kottakkal'},
{name: 'Kottarakkara'},
{name: 'Kozhenchery'},
{name: 'Kumily'},
{name: 'Palarivattom'},
{name: 'Pandalam'},
{name: 'Paravoor'},
{name: 'Pathanapuram'},
{name: 'Pathanamthitta'},
{name: 'Perumbavoor'},
{name: 'Pondichery'},
{name: 'Puthupally'},
{name: 'Ranny'},
{name: 'Sulthan Bathery'},
{name: 'Thamarassery'},
{name: 'Theni'},
{name: 'Thiruvalla'},
{name: 'Thodupuzha'},
{name: 'Thottekkad'},
{name: 'Muringassery'},
{name: 'Tripunithura'},
{name: 'Vaikom'},
{name: 'Vythiri'},
{name: 'Erumely'},
{name: 'Guruvayoor'},
{name: 'Haripad'},
{name: 'Irinjalakuda'},
{name: 'Kalamassery'},
{name: 'Adoor'},
{name: 'Kunnamangalam'},
{name: 'vadakkanchery'},
{name: 'Malaparambu'},
{name: 'Koduvally'},
{name: 'Eengapuzha'},
{name: 'Kaithapoyil'},
{name: 'Komaragiri Patnam'},
{name: 'Allavaram'},
{name: 'Thalayolaparambu'},
{name: 'Perurupeta'},
{name: 'kalady'},
{name: 'Edapal'},
{name: 'Kunnamkulam'},
{name: 'Ettumanoor'},
{name: 'Nedarametla'},
{name: 'Nallajarla'},
{name: 'Velankanni to Ernakulam'},
{name: 'Kadiri'},
{name: 'Pulivendula'},
{name: 'Yarravaram'},
{name: 'Tirupathi By-Pass'},
{name: 'Madanapalli'},
{name: 'Kondotty'},
{name: 'Malapuram'},
{name: 'Perinthalmanna'},
{name: 'Mannarkad'},
{name: 'Sri Kalahasthi'},
{name: 'Tirupathi Balaji Darshan'},
{name: 'Kallissery'},
{name: 'Mahableshwar'},
{name: 'Baroda'},
{name: 'Kolhapur'},
{name: 'Sawantwadi'},
{name: 'Kankawali'},
{name: 'Kudal (Maharashtra)'},
{name: 'Mapusa (Goa)'},
{name: 'Panjim'},
{name: 'Madgaon'},
{name: 'Panchgani'},
{name: 'Vai (Maharashtra)'},
{name: 'Zairabad'},
{name: 'Thane'},
{name: 'Dombivali'},
{name: 'Sangamner'},
{name: 'Ankleshwar'},
{name: 'Lonavala'},
{name: 'Kamrej'},
{name: 'Godbandar'},
{name: 'Mulund'},
{name: 'Bhusawal'},
{name: 'Hatkhambha'},
{name: 'Pali (Maharashtra)'},
{name: 'Lanja (Maharashtra)'},
{name: 'Rajapur (Maharastra)'},
{name: 'Kankavali'},
{name: 'Bangla'},
{name: 'Kamlapur (Uttar Pradesh)'},
{name: 'Mahagaon (Maharastra)'},
{name: 'Kadganchi'},
{name: 'Aland'},
{name: 'Shirpur'},
{name: 'Julwaniya'},
{name: 'Dhamnod (Madhya Pradesh)'},
{name: 'Yeola'},
{name: 'Vaijapur'},
{name: 'Pune(Aundh)'},
{name: 'Peth Naka (Satara)'},
{name: 'Karad (Satara'},
{name: 'Mahabaleshwar'},
{name: 'Pune(Chandni Chowk)'},
{name: 'Wai'},
{name: 'Panchgini'},
{name: 'Udgir'},
{name: 'Barshi'},
{name: 'Yadshi'},
{name: 'Mulshi'},
{name: 'Ahmedpur (Maharastra)'},
{name: 'Sitanagaram (Vizianagaram Dist'},
{name: 'Muggulla (West Godavari)'},
{name: 'Iligantipeta'},
{name: 'Katavaram (Andhra Pradesh)'},
{name: 'Bobbililanka'},
{name: 'Thorredu'},
{name: 'Katheru'},
{name: 'Mallaiahpeta'},
{name: 'Viggaswaram'},
{name: 'Duddukuru (West Godavari)'},
{name: 'Mumbai(Borivali)'},
{name: 'Tuljapur'},
{name: 'Osmanabad'},
{name: 'Perumanallur(Tirupur)'},
{name: 'Avinashi'},
{name: 'Jhulwaniya'},
{name: 'Ullasnagar'},
{name: 'Adhiramapattinam'},
{name: 'Mumbai Darshan'},
{name: 'Navsari (Gujarat)'},
{name: 'Vashi'},
{name: 'Rajkot (Gujarat)'},
{name: 'Kadodara'},
{name: 'Dharampur'},
{name: 'Sinnar'},
{name: 'Limdi'},
{name: 'Chotila (Gujarat)'},
{name: 'Loni (Ahmednagar'},
{name: 'Watar'},
{name: 'Sindhkhed Raja'},
{name: 'Mehkar (Maharashtra)'},
{name: 'Karanja'},
{name: 'Nakkapalli'},
{name: 'Kovvuru Bye-Pass'},
{name: 'Vadugapatti'},
{name: 'Halli (Maharashtra)'},
{name: 'Jaamb'},
{name: 'Mukhed'},
{name: 'Ausha'},
{name: 'Lamjana (Latur Dist'},
{name: 'Nilanga'},
{name: 'Aurad (Maharastra)'},
{name: 'Gangapur (Maharastra)'},
{name: 'Waluz'},
{name: 'Pimpalgaon'},
{name: 'Parola (Jalgaon)'},
{name: 'Erandol (Jalgaon)'},
{name: 'Yedsi'},
{name: 'Shirur Ghodnadi'},
{name: 'Nalegaon (Latur)'},
{name: 'Kandivali(W)'},
{name: 'Kankavli'},
{name: 'Thuraiyur'},
{name: 'Musiri (Tamil Nadu)'},
{name: 'kulithali'},
{name: 'Tadipatri'},
{name: 'Vijayawada Testing'},
{name: 'Uravakonda'},
{name: 'Trichy'},
{name: 'Kumarakam'},
{name: 'Raghudevapuram'},
{name: 'Ambalapuzha'},
{name: 'Mehkar'},
{name: 'Wani'},
{name: 'Yawatmal'},
{name: 'Digras'},
{name: 'Pusad'},
{name: 'Wardha'},
{name: 'Ajanta (Aurangabad)'},
{name: 'Pathiripala'},
{name: 'Palapuram'},
{name: 'Ottapaalam'},
{name: 'Shornur(kolapully)'},
{name: 'Kolapully(Shornur)'},
{name: 'Pattambi'},
{name: 'Vadanapilly'},
{name: 'Thriprayar'},
{name: 'Mathilakam'},
{name: 'Neri (Maharashtra)'},
{name: 'Sillod'},
{name: 'Darwha'},
{name: 'Chavakad'},
{name: 'Koottanad(edapal road)'},
{name: 'Edapalroad(Koottanad)'},
{name: 'Walayar'},
{name: 'K.G Chavadi'},
{name: 'Kanjikode'},
{name: 'Edamuttam'},
{name: 'Sagmeshwer'},
{name: 'Kharepatan'},
{name: 'Taral (Maharastra)'},
{name: 'Kasal (Maharashtra)'},
{name: 'Shiroda (Maharashtra)'},
{name: 'Vengurla'},
{name: 'Dharmavaram(Anantapuram)'},
{name: 'Edappally'},
{name: 'KHAMGAW'},
{name: 'Dhule (Maharastra)'},
{name: 'Machavaram Down'},
{name: 'Koratty'},
{name: 'Nandaluru'},
{name: 'Allagadda'},
{name: 'Hanmakonda'},
{name: 'S.Konda By-Pass'},
{name: 'Bathal Gundu'},
{name: 'J P Nagar 4th Block'},
{name: 'Coimbatore L&T byepass'},
{name: 'Ollur'},
{name: 'Pollachi'},
{name: 'Kodai Road'},
{name: 'palamaner'},
{name: 'Madurai'},
{name: 'kodakara'},
{name: 'Kupam Byepass'},
{name: 'Shirdi Sai Paradise Hotel'},
{name: 'Baliya'},
{name: 'kattappana (by pickup bus)'},
{name: 'Thekkady'},
{name: 'Mattancherry'},
{name: 'Oddanchathiram'},
{name: 'Renigunta(Bye-Pass)'},
{name: 'Vellore'},
{name: 'Pileru'},
{name: 'Rayachoti'},
{name: 'Ponkunnam'},
{name: 'Rajula'},
{name: 'Mahua (Uttar Pradesh)'},
{name: 'Tirupur'},
{name: 'Monipalli'},
{name: 'Baidyanath Square'},
{name: 'Punganuru'},
{name: 'Palamaneru'},
{name: 'Krishnagiri (Tamil Nadu)'},
{name: 'Tirupur (Avinashi)'},
{name: 'Bidar'},
{name: 'Mangalore'},
{name: 'Proddatur'},
{name: 'Yetukuru'},
{name: 'Varagani'},
{name: 'Pedanandipadu'},
{name: 'Kommuru'},
{name: 'Kakumanu'},
{name: 'Apparapuram'},
{name: 'Returu'},
{name: 'Jammulapalem'},
{name: 'Chitra Durga'},
{name: 'Davanagere'},
{name: 'Piduguralla'},
{name: 'Singarayapalem (Andhra Pradesh)'},
{name: 'Kodamanchili'},
{name: 'Ardavaram'},
{name: 'Kamavarapukota'},
{name: 'Thadekelapudi'},
{name: 'Alathur(Kerala)'},
{name: 'Changanassery (Kerala)'},
{name: 'Cumbum (Tamilnadu)'},
{name: 'Mallappilly'},
{name: 'Kuruvilangad'},
{name: 'Munnar (by pickup van)'},
{name: 'Karukachal (Kerala)'},
{name: 'Kodungallur'},
{name: 'Kothamangalam (Kerala)'},
{name: 'Kumily (Kerala)'},
{name: 'Pondicherry(Puducherry)'},
{name: 'Sultan Batheri'},
{name: 'Haripad (Kerala)'},
{name: 'Vadakkanchery'},
{name: 'Kalady'},
{name: 'Edappal (Kerala)'},
{name: 'Kondotti'},
{name: 'Malappuram (Kerala)'},
{name: 'Kalliasseri (Kerala)'},
{name: 'Kumarakom'},
{name: 'Palappuram'},
{name: 'Chavadi'},
{name: 'Koratty (Kerala)'},
{name: 'Coimbatore byepass'},
{name: 'Kodakara (Kerala)'},
{name: 'Kopam Byepass'},
{name: 'Monippally (Kerala)'},
{name: 'Krishnagiri'},
{name: 'Chithode'},
{name: 'Avinasi'},
{name: 'Chikhli (Bilmora)'},
{name: 'Bejai'},
{name: 'Surathkal'},
{name: 'Mulki (Karnataka)'},
{name: 'Padubidri'},
{name: 'Brahmavara (Karnataka)'},
{name: 'Baindur'},
{name: 'Honavar'},
{name: 'Kumta'},
{name: 'Ankola'},
{name: 'Anandapuram'},
{name: 'Mannakali'},
{name: 'Vyttila'},
{name: 'Vijaywada (By-Pass)'},
{name: 'Vijayawada (By-Pass)'},
{name: 'Ravulapalem (By-Pass)'},
{name: 'Rajahmundry (By-Pass)'},
{name: 'Tuni (By-Pass)'},
{name: 'Annavaram (By-Pass)'},
{name: 'Anakapalli ByePass'},
{name: 'Bellary'},
{name: 'Sindhanur'},
{name: 'Gangawati'},
{name: 'Aluru (Nellore)'},
{name: 'Yemmiganur'},
{name: 'Covai (Coimbatore)'},
{name: 'Dharmapuri (Tamil Nadu)'},
{name: 'Erode Bypass(Sithode)'},
{name: 'Perundurai'},
{name: 'Dobcherla'},
{name: 'Yernagudem'},
{name: 'Davanagiri'},
{name: 'Hindupur (Andhra Pradesh)'},
{name: 'Tirupur (Avinasi)'},
{name: 'Sithode'},
{name: 'N.G.O Quarters Clt'},
{name: 'Wayanad'},
{name: 'Nallapadu'},
{name: 'Percherla'},
{name: 'Mangalagiri (Bye-Pass)'},
{name: 'Kakani'},
{name: 'Nambur X Roads'},
{name: 'Revendrapadu'},
{name: 'Chiluvuru'},
{name: 'Thummapudi'},
{name: 'Morampudi'},
{name: 'Nandivelugu'},
{name: 'Miraj (Maharastra)'},
{name: 'Ichalkaranji'},
{name: 'Jaisingpur (Maharastra)'},
{name: 'Puthuchery'},
{name: 'Karapa (Andhra Pradesh)'},
{name: 'Velangi'},
{name: 'kurupumthara'},
{name: 'kaduthuruthy'},
{name: 'Vilupuram'},
{name: 'Aurai(Bihar)'},
{name: 'Macherla (Andhra Pradesh)'},
{name: 'Sagar (Madhya Pradesh)'},
{name: 'Namburu'},
{name: 'Dachepalli'},
{name: 'Porur'},
{name: 'Guindy'},
{name: 'K.K Nagar'},
{name: 'Vadapalani'},
{name: 'Karumattampatti'},
{name: 'Jeelugumilli'},
{name: 'Kanchipuram Byepass'},
{name: 'Vellore Byepass'},
{name: 'Sriperumbudur Byepass'},
{name: 'Kothuru (Andhra Pradesh)'},
{name: 'Bhayander'},
{name: 'Pachora (Maharastra)'},
{name: 'Thiruvanamalai'},
{name: 'Toranagallu'},
{name: 'Damalacheruv'},
{name: 'Timmarajupalem'},
{name: 'Annaphatlavaripalem'},
{name: 'DhindiVanam'},
{name: 'Ranipet'},
{name: 'Udaipur (Rajasthan)'},
{name: 'Singanur'},
{name: 'Pattikonda'},
{name: 'Jammalamadugu'},
{name: 'Velpuru'},
{name: 'Relangi'},
{name: 'Badnapur'},
{name: 'Mevashapatha'},
{name: 'Paldhi (Maharashtra)'},
{name: 'Nashirabad'},
{name: 'Sakegaon'},
{name: 'Supa (Maharashtra)'},
{name: 'Nevasa Phata'},
{name: 'Dhoki (Osmanabad'},
{name: 'Murud (Raigad)'},
{name: 'Chandole (Andhra Pradesh)'},
{name: 'Nathdwara'},
{name: 'Gandhinagar (Chiroda)'},
{name: 'Himmatnagar (Gujarat)'},
{name: 'Kherwada (Dungarpur)'},
{name: 'Keshariyaji (Jain Temple)'},
{name: 'Tekkali'},
{name: 'Manvi'},
{name: 'Kalikiri'},
{name: 'Bhagarapeta'},
{name: 'De Sai Camp'},
{name: 'Amareshwara Camp'},
{name: 'Hyderabad(Up to Miyapur)'},
{name: 'Trivandrum'},
{name: 'Vadali (Gujarat)'},
{name: 'Alleppy'},
{name: 'Kokkanti'},
{name: 'Muddunuru'},
{name: 'Godilanka'},
{name: 'Mogallamuru'},
{name: 'Gaddenapalli'},
{name: 'Shirdi(With Accomdation)'},
{name: 'Ramoji Film City'},
{name: 'Kankipadu'},
{name: 'Vuyyuru'},
{name: 'Pamarru(Krishna Dist.)'},
{name: 'Nuzvid'},
{name: 'Calicutt'},
{name: 'Kozhikodu'},
{name: 'Kuppam (Andhra Pradesh)'},
{name: 'Karatagi'},
{name: 'Amaravathi(AP)'},
{name: 'Yandraye'},
{name: 'Narakullapadu'},
{name: 'Mothadaka'},
{name: 'Nidimukalla'},
{name: 'Lam (Andhra Pradesh)'},
{name: 'Gorantla (Anantapur Dist'},
{name: 'Tadikonda Cross Road'},
{name: 'Manipal'},
{name: 'Humpi'},
{name: 'Eethakota'},
{name: 'Aswapuram'},
{name: 'Thirumangalam'},
{name: 'Viruthunagar'},
{name: 'Sattur'},
{name: 'Kovilpatti'},
{name: 'Valliyur'},
{name: 'Kavalkinar'},
{name: 'Nagercoil'},
{name: 'Marthandam'},
{name: 'Parasala'},
{name: 'Neyyattinkara'},
{name: 'V.Kota'},
{name: 'Mandya'},
{name: 'Srirangapatnam'},
{name: 'Paur'},
{name: 'Sillod Bypass'},
{name: 'Udipi'},
{name: 'BC Road'},
{name: 'Uppinangadi'},
{name: 'Kalikkavilai'},
{name: 'Mahaboobnagar'},
{name: 'Mahabubnagar'},
{name: 'Anantapur By-pass(AP)'},
{name: 'Chipurupalli'},
{name: 'Rajam'},
{name: 'Kurduvadi'},
{name: 'Chakur'},
{name: 'Loha'},
{name: 'Indapur (Pune)'},
{name: 'Dapoli'},
{name: 'Kherdi'},
{name: 'Karanjani'},
{name: 'Sondeghar'},
{name: 'Palgadh'},
{name: 'Madangad'},
{name: 'Pisai (Maharashtra)'},
{name: 'Mandangad'},
{name: 'Nagothane'},
{name: 'Kolad (Maharastra)'},
{name: 'Mangaon (Maharastra)'},
{name: 'Malkapur (Maharashtra)'},
{name: 'Shirala'},
{name: 'Turukwadi'},
{name: 'Shahuwadi'},
{name: 'Bambavade'},
{name: 'Kokrud'},
{name: 'Jinnuru'},
{name: 'Vedangi'},
{name: 'Poduru'},
{name: 'Kavitam'},
{name: 'Penugonda (West Godavari)'},
{name: 'Eletipadu'},
{name: 'Pekeru'},
{name: 'Raapaka'},
{name: 'East Vipparru'},
{name: 'Pedha Ameeram'},
{name: 'Chinna Ameeram'},
{name: 'Kumadhavalli'},
{name: 'Palakoderu'},
{name: 'Gollalakoderu'},
{name: 'Garagaparru'},
{name: 'Yendagandi'},
{name: 'Vundhurru'},
{name: 'Kesavaram (Andhra Pradesh)'},
{name: 'Penumantra'},
{name: 'Unguturu (Andhra Pradesh)'},
{name: 'Nouduru'},
{name: 'Nandyala'},
{name: 'Mhekar(Malegaon)'},
{name: 'Vadapalli'},
{name: 'Vijjeshwaram'},
{name: 'Kashriya ji'},
{name: 'Kharwara (Rajasthan)'},
{name: 'Trichur'},
{name: 'Pullampet'},
{name: 'Kolleru'},
{name: 'Ahmedbad(Airport)'},
{name: 'Ahmedabad (Airport)'},
{name: 'Bijapur (Karnataka)'},
{name: 'Yanam Bridge'},
{name: 'Bijapur Bye-Pass'},
{name: 'Gulbarga Bye-Pass'},
{name: 'Salur'},
{name: 'Palghat'},
{name: 'Giddalur'},
{name: 'Mannuthy'},
{name: 'Ochira'},
{name: 'Siruguppa'},
{name: 'kondotty'},
{name: 'Kechery'},
{name: 'Kuttipuram'},
{name: 'Valanchery'},
{name: 'Tirur(Edarikode)'},
{name: 'Gundalpet'},
{name: 'Sreerangapatna'},
{name: 'Shaktinagar (Raichur'},
{name: 'Madhur'},
{name: 'Kenkerry'},
{name: 'Balaramapuram'},
{name: 'Thampanoor'},
{name: 'Nanjangudu'},
{name: 'Dhampur (Uttar Pradesh)'},
{name: 'Nagina (Uttar Pradesh)'},
{name: 'Gadhinglaj'},
{name: 'Sankeshwar (Karnataka)'},
{name: 'Sankeshwar Highway'},
{name: 'Nipani Highway'},
{name: 'Kagal (Maharashtra)'},
{name: 'Cochin/Ernakulam'},
{name: 'Jamnagar (Gujarat)'},
{name: 'Wai Phata'},
{name: 'Vilupuram byepass'},
{name: 'Chengalpattu'},
{name: 'Aachara'},
{name: 'Aasind'},
{name: 'Aatoor (Tamil Nadu)'},
{name: 'Aavanam'},
{name: 'Abohar'},
{name: 'Adipur'},
{name: 'Adirampattinam'},
{name: 'Ahmadpur'},
{name: 'Akot'},
{name: 'Alangaru'},
{name: 'Alangulam(Tirunelveli)'},
{name: 'Almatti'},
{name: 'Alwaye'},
{name: 'Ambaji'},
{name: 'Ambasamutram'},
{name: 'Ambejogai'},
{name: 'Ambur'},
{name: 'Amdapur (Buldhana'},
{name: 'Amet'},
{name: 'Amingad'},
{name: 'Ampar'},
{name: 'Anjar'},
{name: 'Annur'},
{name: 'Aranthangi'},
{name: 'Arate'},
{name: 'Ariyalur'},
{name: 'Armoor'},
{name: 'Aroor (Alappuzha)'},
{name: 'Arumuganeri'},
{name: 'Aruppukottai'},
{name: 'Athani(Karnataka)'},
{name: 'Athiyuthu'},
{name: 'Attur (Salem)'},
{name: 'Aundha'},
{name: 'Authoor (Tamilnadu)'},
{name: 'Avadi (Andhra Pradesh)'},
{name: 'Ayyampet'},
{name: 'Badami'},
{name: 'Badava'},
{name: 'Badoli'},
{name: 'Bagalkot (vidyagiri)'},
{name: 'Bagdu (Himachal Pradesh)'},
{name: 'Bagodra (Gujarat)'},
{name: 'Bailhongal'},
{name: 'Bajpe'},
{name: 'Balotra'},
{name: 'Banahatti'},
{name: 'Banda(Uttar Pradesh)'},
{name: 'Banga (Punjab)'},
{name: 'Banswara (Rajasthan)'},
{name: 'Bantwady'},
{name: 'Bantwal'},
{name: 'Bar (Rajasthan)'},
{name: 'Bardoli'},
{name: 'Basavakalyan'},
{name: 'Basrur (Karnataka)'},
{name: 'Batala (Punjab)'},
{name: 'Batlagundu'},
{name: 'Bavla'},
{name: 'Bc Road'},
{name: 'Beawer (Rajasthan)'},
{name: 'Belman'},
{name: 'Belthangady'},
{name: 'Belur (Hassan)'},
{name: 'Belvai'},
{name: 'Belve'},
{name: 'Bhachau'},
{name: 'Bhachav'},
{name: 'Bhadragiri (Telangana)'},
{name: 'Bhalki'},
{name: 'Bhandara'},
{name: 'Bhandup'},
{name: 'Bhavani (Tamil Nadu)'},
{name: 'Bhavnagar'},
{name: 'Bheshan'},
{name: 'Bhilai'},
{name: 'Bhiloda'},
{name: 'Bhilwara (Rajasthan)'},
{name: 'Bhim'},
{name: 'Bhinmal'},
{name: 'Bhivandi'},
{name: 'Bhramavar'},
{name: 'Bhujpar'},
{name: 'Bichhiwada'},
{name: 'Bid (Madhya Pradesh)'},
{name: 'Bidakalakatte'},
{name: 'Bidkalkatte'},
{name: 'Bidla'},
{name: 'Bijoliya (Rajasthan)'},
{name: 'Bijoor (Karnataka)'},
{name: 'Bilagi'},
{name: 'Bilkha'},
{name: 'Bodi (Tamil Nadu)'},
{name: 'Borivalli'},
{name: 'Byadagi'},
{name: 'Byndoor'},
{name: 'C.b.d'},
{name: 'Canacona (Goa)'},
{name: 'Chalakkudi'},
{name: 'Chalisgao'},
{name: 'Challakere'},
{name: 'Chanasma'},
{name: 'Charoli'},
{name: 'Chembur'},
{name: 'Cherabmahadevi'},
{name: 'Chikhli (Buldhana'},
{name: 'Chikodi'},
{name: 'Chinamannur'},
{name: 'Chingavanam'},
{name: 'Chinnamanur'},
{name: 'Chitradurga'},
{name: 'Chittarkottai'},
{name: 'Chittorgarh(Rajasthan)'},
{name: 'Chittoor (Kundapura'},
{name: 'Chittor'},
{name: 'Chittore'},
{name: 'Chomu (Rajasthan)'},
{name: 'Cochin(ernakukalm) Bypass'},
{name: 'Cochin(ernakulam)'},
{name: 'Cochin(kochi)'},
{name: 'Coorg'},
{name: 'Courtallam'},
{name: 'Cuddalore'},
{name: 'Dabhol'},
{name: 'Dahibav'},
{name: 'Dahisara'},
{name: 'Dahisar (Maharashtra)'},
{name: 'Dahod (Gujarat)'},
{name: 'Dakor (Gujarat)'},
{name: 'Daman'},
{name: 'Dandeli (Karnataka)'},
{name: 'Darsadi'},
{name: 'Darwa'},
{name: 'Dayapar'},
{name: 'Dayaper'},
{name: 'Deepnagar (Maharashtra)'},
{name: 'Deesa(Gujarat)'},
{name: 'Delhi Airport'},
{name: 'Deolgaon Raja'},
{name: 'Desalpar (Nakhatrana'},
{name: 'Desuri (Rajasthan)'},
{name: 'Devadurga'},
{name: 'Devakottai'},
{name: 'Devgadh (Gujarat)'},
{name: 'Dewas (Madhya Pradesh)'},
{name: 'Dhar (Madhya Pradesh)'},
{name: 'Dharampur (Maharastra)'},
{name: 'Dharamshala (Himachal Pradesh)'},
{name: 'Dharapuram'},
{name: 'Dharmasthala (Karnataka)'},
{name: 'Dharwa'},
{name: 'Dhavangiri'},
{name: 'Dhonera'},
{name: 'Dhoraji'},
{name: 'Dhrol'},
{name: 'Diu (Daman and Diu)'},
{name: 'Dolatpar (Gujarat)'},
{name: 'Dombiwalli'},
{name: 'Dongaon'},
{name: 'Dudhai (Gujarat)'},
{name: 'Dudu'},
{name: 'Dungargadh'},
{name: 'Durg MP'},
{name: 'Dwarka (Gujarat)'},
{name: 'Edappal'},
{name: 'Edarikode'},
{name: 'Elayankudi'},
{name: 'Erattupetta'},
{name: 'Erode Bypass'},
{name: 'Ervadi (Ramanathapuram)'},
{name: 'Ettayapuram'},
{name: 'Faridkot'},
{name: 'Fatehnagar (Rajasthan)'},
{name: 'Gadag (Karnataka)'},
{name: 'Gadu'},
{name: 'Gandhidham (Gujarat)'},
{name: 'Gandhinagar (Gujarat)'},
{name: 'Gangakhed'},
{name: 'Ganganagar (Sri Ganganagar'},
{name: 'Gangolli'},
{name: 'Ganpatipule'},
{name: 'Gerusoppa'},
{name: 'Ghaduli'},
{name: 'Ghatkopar'},
{name: 'Gobi'},
{name: 'Gogunda'},
{name: 'Gokak'},
{name: 'Gokarna'},
{name: 'Gokarna Cross'},
{name: 'Gomti (Rajasthan)'},
{name: 'Gondal'},
{name: 'Gopichettipalayam'},
{name: 'Goregaun (mumbai)'},
{name: 'Guddeangadi'},
{name: 'Gudhshisha'},
{name: 'Guhagar'},
{name: 'Gulabpura (Rajasthan)'},
{name: 'Guledgudda'},
{name: 'Gunthakal'},
{name: 'Gurgaon (hr)'},
{name: 'Gurupura'},
{name: 'Guruvayanakere'},
{name: 'Guruvayankere'},
{name: 'Guruvayur'},
{name: 'Hadpid (Maharashtra)'},
{name: 'Hagaribommanahalli'},
{name: 'Haladi (Karnataka)'},
{name: 'Haliyal'},
{name: 'Hampi'},
{name: 'Harapanahalli'},
{name: 'Harihar (Karnataka)'},
{name: 'Harkur Cross'},
{name: 'Harmannu'},
{name: 'Hassan (Karnataka)'},
{name: 'Hathgaon (Maharashtra)'},
{name: 'Haveri'},
{name: 'Hebri'},
{name: 'Hemmady'},
{name: 'Himmatnagar'},
{name: 'Hiriyadka'},
{name: 'Hiriyur'},
{name: 'Honnali'},
{name: 'Honnavar'},
{name: 'Hosanagara'},
{name: 'Hosangadi(Karnataka)'},
{name: 'Hosnagar'},
{name: 'Hungund'},
{name: 'Hunsemakki'},
{name: 'Hunugunda'},
{name: 'Ichalakarangi'},
{name: 'Idar'},
{name: 'Ilkal'},
{name: 'Illayangudi'},
{name: 'Indi (Karnataka)'},
{name: 'Iritty'},
{name: 'Ishwal'},
{name: 'Jadkal'},
{name: 'Jagadapatnam'},
{name: 'Jagityal'},
{name: 'Jagraon'},
{name: 'Jaisinghpur (Himachal Pradesh)'},
{name: 'Jalandhar(jullender)'},
{name: 'Jalgoan'},
{name: 'Jalore'},
{name: 'Jamkhambhalia'},
{name: 'Jamkhandi'},
{name: 'Jammu (Jammu and Kashmir)'},
{name: 'Jamshedpur'},
{name: 'Jannal'},
{name: 'Jegathapattinam (Tamil Nadu)'},
{name: 'Jetpur (Gujarat)'},
{name: 'Jewargi'},
{name: 'Jhalawar'},
{name: 'Jhalrapatan'},
{name: 'Jindal (Karnataka)'},
{name: 'Jindal Power'},
{name: 'Jindal Steel'},
{name: 'Jintur'},
{name: 'Jogfalls'},
{name: 'Junagadh (Gujarat)'},
{name: 'Kadaiyanallur'},
{name: 'Kadaladi'},
{name: 'Kadara (Karnataka)'},
{name: 'Kadatchapuram'},
{name: 'Kadodra(srt)'},
{name: 'Kadra'},
{name: 'Kaiga'},
{name: 'Kaikamba (Karnataka)'},
{name: 'Kaikatti'},
{name: 'Kakroli'},
{name: 'Kalamb'},
{name: 'Kalayar Kovil'},
{name: 'Kaliakavilai'},
{name: 'Kalladka'},
{name: 'Kallal'},
{name: 'Kallidaikurichi'},
{name: 'Kallupatti'},
{name: 'Kambadakone'},
{name: 'Kammarpally'},
{name: 'Kamrej(srt)'},
{name: 'Kandalur (Tamilnadu)'},
{name: 'Kangayam'},
{name: 'Kapasan'},
{name: 'Karad Byepass'},
{name: 'Karaikudi'},
{name: 'Kareda (Rajasthan)'},
{name: 'Karkala (Karnataka)'},
{name: 'Karunagappally'},
{name: 'Karwar (Karnataka)'},
{name: 'Kasargod'},
{name: 'Katapady(karnataka)'},
{name: 'Kateel'},
{name: 'Katpadi (Tamil Nadu)'},
{name: 'Kattinamakki (Karnataka)'},
{name: 'Kaup'},
{name: 'Kavoor'},
{name: 'Kayalpattinam'},
{name: 'Keelakarai'},
{name: 'Keeranur'},
{name: 'Kej (Maharastra)'},
{name: 'Kelwa (Maharastra)'},
{name: 'Kera (Gujarat)'},
{name: 'Keshariyaji'},
{name: 'Keshod'},
{name: 'Khambhalia'},
{name: 'Khamgoan(bypass)'},
{name: 'Khandala (Satara Dist'},
{name: 'Kharghar'},
{name: 'Khatipudi'},
{name: 'Moti Khavdi'},
{name: 'Khedbramha'},
{name: 'Kherwada (Udaipur Dist'},
{name: 'Kinnigoli'},
{name: 'Kirimanjeshwar'},
{name: 'Kishangadh'},
{name: 'Kishangarh (Rajasthan)'},
{name: 'Kodaikanal'},
{name: 'Koday (Gujarat)'},
{name: 'Kodinar (Gujarat)'},
{name: 'Kokkarne'},
{name: 'Kolathur (Salem Dist'},
{name: 'Kolkata(calcutta)'},
{name: 'Kollur (Mookambika)'},
{name: 'Kollur mookambika'},
{name: 'Koloshi'},
{name: 'Konaje'},
{name: 'Konni'},
{name: 'Koolur (n.h 17)'},
{name: 'Koppal'},
{name: 'Kota (udupi Dist)'},
{name: 'Kotada (Gujarat)'},
{name: 'Kotda (jdr)'},
{name: 'Koteshwar (Gujarat)'},
{name: 'Kothara (Gujarat)'},
{name: 'Kottampatti'},
{name: 'Kottapattinam'},
{name: 'Kozhecherry'},
{name: 'Kozhikode(calicut)'},
{name: 'Krishnankoil'},
{name: 'Kudithini'},
{name: 'Kukke Subramanya'},
{name: 'Kulasekaram'},
{name: 'Kulithalai (Tamil Nadu)'},
{name: 'Kullu'},
{name: 'Kumbanad'},
{name: 'Kumbhashi'},
{name: 'Kumuly'},
{name: 'Kundapur'},
{name: 'Kunjalu'},
{name: 'Kunwariya (Rajasthan)'},
{name: 'Kurumbadi'},
{name: 'Kushal Nagar (Karnataka)'},
{name: 'Kushtagi'},
{name: 'Kuthuparamba'},
{name: 'Kutiyana'},
{name: 'Kuttanad'},
{name: 'Kuttippuram'},
{name: 'Lad Karanja'},
{name: 'Lakshmankudi'},
{name: 'Lekshmankudi'},
{name: 'Lingdal Titha (Maharashtra)'},
{name: 'Lingsugur'},
{name: 'Lokapur (Karnataka)'},
{name: 'Mabukala'},
{name: 'Madathukulam'},
{name: 'Madhapar'},
{name: 'Madhopur (Punjab)'},
{name: 'Madikeri'},
{name: 'Madukkur'},
{name: 'Mahalingpur'},
{name: 'Mahe (Puducherry)'},
{name: 'Mehsana (Gujarat)'},
{name: 'Mahuva (Gujarat)'},
{name: 'Malad'},
{name: 'Malappuram'},
{name: 'Malebennur'},
{name: 'Mallapur (Telangana)'},
{name: 'Malout (Punjab)'},
{name: 'Manali'},
{name: 'Manavalli'},
{name: 'Manchakal'},
{name: 'Mandapam (Tamil Nadu)'},
{name: 'Mandarthi'},
{name: 'Mandarti'},
{name: 'Mandsaur (Madhya Pradesh)'},
{name: 'Mandvi (Bhuj)'},
{name: 'Mangalagiri (by Pass)'},
{name: 'Mangalwad'},
{name: 'Mangrulpir'},
{name: 'Mangwana (Gujarat)'},
{name: 'Manki (Karnataka)'},
{name: 'Mankuva (Gujarat)'},
{name: 'Mannaekhelli'},
{name: 'Mannarkuti'},
{name: 'Manur (Tamil Nadu)'},
{name: 'Mapsa'},
{name: 'Maravamangalam'},
{name: 'Maravanthe'},
{name: 'Marayur'},
{name: 'Margao'},
{name: 'Maski (Karnataka)'},
{name: 'Masthikatte'},
{name: 'Matanomadh'},
{name: 'Mathal'},
{name: 'Mattanur'},
{name: 'Mavinagundi'},
{name: 'Mavli (Rajasthan)'},
{name: 'Mayavaram'},
{name: 'Mellur'},
{name: 'Melur'},
{name: 'Mendrada'},
{name: 'Metpally'},
{name: 'Mettur'},
{name: 'Mijar'},
{name: 'Mimisal'},
{name: 'Mirjan (Kumta'},
{name: 'Mithapur (Gujarat)'},
{name: 'Moodabidri'},
{name: 'Mookambika'},
{name: 'Morbi'},
{name: 'Mothara'},
{name: 'Mount Abu'},
{name: 'Mudalur'},
{name: 'Muddanur'},
{name: 'Muddebihal'},
{name: 'Mudhol (Karnataka)'},
{name: 'Muthukulathur'},
{name: 'Muktainagar'},
{name: 'Muktsar'},
{name: 'Mullanpur'},
{name: 'Mullikatte'},
{name: 'Mumbai(dadar)'},
{name: 'Mumbai(sion)'},
{name: 'Munage'},
{name: 'Munavalli'},
{name: 'Mundakayam'},
{name: 'Mundkur'},
{name: 'Mundra (Gujarat)'},
{name: 'Munirabad (Karnataka)'},
{name: 'Munnar'},
{name: 'Murnad'},
{name: 'Murtijapur'},
{name: 'Muvattupuzha'},
{name: 'Myiladuthurai'},
{name: 'Nada (Gujarat)'},
{name: 'Nagar (Gujarat)'},
{name: 'Nagas Phata'},
{name: 'Nagoor (Tamil Nadu)'},
{name: 'Naidupeta(by-pass)'},
{name: 'Nainital'},
{name: 'Nakhatrana'},
{name: 'Naliya'},
{name: 'Namagiripettai'},
{name: 'Nandgaon (Nashik Dist'},
{name: 'Nandura (Buldhana)'},
{name: 'Nanguneri'},
{name: 'Napoklu'},
{name: 'Naragund'},
{name: 'Naranpar'},
{name: 'Naringre'},
{name: 'Nasirabad (Rajasthan)'},
{name: 'Naukha'},
{name: 'Navunda'},
{name: 'Nazareth'},
{name: 'Nedunggandam'},
{name: 'Neemuch (Madhya Pradesh)'},
{name: 'Nellai'},
{name: 'Nellore(by-pass)'},
{name: 'Ner (Dhule)'},
{name: 'Neralakatte'},
{name: 'Nerul'},
{name: 'New Delhi And Ncr'},
{name: 'Neyvely'},
{name: 'Nimaheda'},
{name: 'Nimbaheda'},
{name: 'Nipani'},
{name: 'Nitte (Karkala'},
{name: 'Nittur (Udipi)'},
{name: 'Noojadi'},
{name: 'Nujadi'},
{name: 'Nyamathi'},
{name: 'Ooty'},
{name: 'Ottanchatharam'},
{name: 'Ottapalam'},
{name: 'Padadhri'},
{name: 'Padukone'},
{name: 'Pahur (Jalgaon)'},
{name: 'Palakkad Bypass'},
{name: 'Palanpur (Gujarat)'},
{name: 'Palghat Byepass'},
{name: 'Pali (Rajasthan)'},
{name: 'Palladam'},
{name: 'Pallathur'},
{name: 'Pamaru (Gujarat)'},
{name: 'Panaikulam'},
{name: 'Panambur'},
{name: 'Panoor (Kerala)'},
{name: 'Panruti'},
{name: 'Papanasam (Tanjore)'},
{name: 'Pappanasam'},
{name: 'Parali (Maharashtra)'},
{name: 'Paramakudi'},
{name: 'Paramathi Velur'},
{name: 'Parkala (Karnataka)'},
{name: 'Patan'},
{name: 'Pattamadai (Tamil Nadu)'},
{name: 'Perambalur'},
{name: 'Peravoorni'},
{name: 'Perdoor'},
{name: 'Periyakulam'},
{name: 'Perungulam'},
{name: 'Perunthurai'},
{name: 'Pethri'},
{name: 'Petnaka'},
{name: 'Phagwara'},
{name: 'Ponnamaravathy'},
{name: 'Prabhani'},
{name: 'Pragpar (Gujarat)'},
{name: 'Pudukkottai'},
{name: 'Puduvalasai'},
{name: 'Puduvayal'},
{name: 'Puliyal'},
{name: 'Puliampatti'},
{name: 'Puliyangudi'},
{name: 'Pumpwel Circle Mangalore'},
{name: 'Pune Chandnichowk'},
{name: 'Pune(poona)'},
{name: 'Punjai Puliyampatti'},
{name: 'Pushkar'},
{name: 'Puttur'},
{name: 'Quilandy'},
{name: 'R S Mangalam'},
{name: 'Rabakavi'},
{name: 'Raibareilly'},
{name: 'Raipur (Chhattisgarh)'},
{name: 'Rajapalayam'},
{name: 'Rajgarh'},
{name: 'Rajnagar (Uttar Pradesh)'},
{name: 'Rajsamand (Rajasthan)'},
{name: 'Ramachandrapuram'},
{name: 'Ramnad'},
{name: 'Rampar'},
{name: 'Ranebennur'},
{name: 'Rasipuram'},
{name: 'Ratanpur (Chhattisgarh)'},
{name: 'Ratnagiri (Maharastra)'},
{name: 'Ravaper'},
{name: 'Rayadurgam'},
{name: 'Rayan (Gujarat)'},
{name: 'Reaya'},
{name: 'Reliance'},
{name: 'Rewa (Madhya Pradesh)'},
{name: 'Rippanpet'},
{name: 'Rona (Karnataka)'},
{name: 'Sagara (Karnataka)'},
{name: 'Sagwara (Rajasthan)'},
{name: 'Sahapur'},
{name: 'Saibarakatte'},
{name: 'Salem Bypass'},
{name: 'Saligrama (Udipi)'},
{name: 'Samarlakot'},
{name: 'Samkhayali'},
{name: 'Samlaji'},
{name: 'Sampekatte'},
{name: 'Samtra'},
{name: 'Sanavat'},
{name: 'Sanderao'},
{name: 'Sangali'},
{name: 'Sanghipuram'},
{name: 'Sangola (Solapur)'},
{name: 'Sankagiri'},
{name: 'Sankarankoil'},
{name: 'Sankeshwara'},
{name: 'Santhekatte'},
{name: 'Sasan Gir'},
{name: 'Sastana'},
{name: 'Sathankulam'},
{name: 'Sathyamangalam'},
{name: 'Saundatti'},
{name: 'Savadatti'},
{name: 'Savalanga'},
{name: 'Sayra (Rajasthan)'},
{name: 'Seerkazhi'},
{name: 'Selu (Parabhani'},
{name: 'Shahapur (Karnataka)'},
{name: 'Shamkhyari'},
{name: 'Shamlaji'},
{name: 'Shankaranarayana'},
{name: 'Shapar'},
{name: 'Shargad'},
{name: 'Shegaon (Buldhana)'},
{name: 'Shenkottai'},
{name: 'Shimoga(Karnataka)'},
{name: 'Shirali'},
{name: 'Shirgaon (Maharashtra)'},
{name: 'Shiroor (Karnataka)'},
{name: 'Shivganj (Rajasthan)'},
{name: 'Shoranur'},
{name: 'Shorapur'},
{name: 'Shreenathji'},
{name: 'Shri Ganganagar'},
{name: 'Shriramnagar (Maharashtra)'},
{name: 'Siddapur'},
{name: 'Siddapura'},
{name: 'Sindagi (Karnataka)'},
{name: 'Singampuneri'},
{name: 'Singarayakonda'},
{name: 'Sinner'},
{name: 'Sion (mumbai)'},
{name: 'Shiracha'},
{name: 'Sirkali'},
{name: 'Sirohi (Rajasthan)'},
{name: 'Sirsi (Karnataka)'},
{name: 'Sivagangai (Tamil Nadu)'},
{name: 'Sivagiri'},
{name: 'Sivakasi'},
{name: 'Sivana'},
{name: 'Sojat City'},
{name: 'Somnath'},
{name: 'Sonda (Karnataka)'},
{name: 'Songadh (Bhavnagar)'},
{name: 'Songhadh'},
{name: 'Sp Pattinam'},
{name: 'Sriperumbudur'},
{name: 'Srirangam (Tamil Nadu)'},
{name: 'Srirangapatna'},
{name: 'Srivilliputhur'},
{name: 'Sukhpar (Bhuj'},
{name: 'Sullia'},
{name: 'Sullurpet'},
{name: 'Sullurupeta(by-pass)'},
{name: 'Sumerpur (Rajasthan)'},
{name: 'Suntikoppa'},
{name: 'Surangudi'},
{name: 'Sutrapada'},
{name: 'T.kallupatti'},
{name: 'T.p.gudem'},
{name: 'Talada (Andhra Pradesh)'},
{name: 'Talaja'},
{name: 'Talala'},
{name: 'Talebazar'},
{name: 'Talikot'},
{name: 'Taliparamba'},
{name: 'Talvana(Gujarat)'},
{name: 'Talwandi (Punjab)'},
{name: 'Tanjore'},
{name: 'Tb Dam'},
{name: 'Thakalai'},
{name: 'Thakkalai'},
{name: 'Thalaguppa'},
{name: 'Thaliparamba'},
{name: 'Thallur'},
{name: 'Thana'},
{name: 'Thekatte'},
{name: 'Thindivanam'},
{name: 'Thirthahalli'},
{name: 'Thiruchenthur'},
{name: 'Thiruchitrambalam'},
{name: 'Thirukadyur'},
{name: 'Thirumangalam (Madurai)'},
{name: 'Thirumayam'},
{name: 'Thirunellar'},
{name: 'Thirunelveli(nellai)'},
{name: 'Thirupattur (Sivagangai)'},
{name: 'Thiruthuraipoondy'},
{name: 'Thiruvadanai'},
{name: 'Thisayanvilai'},
{name: 'Thokottu'},
{name: 'Thondi (Tamil Nadu)'},
{name: 'Thoranagallu'},
{name: 'Thrasi'},
{name: 'Thumbe'},
{name: 'Tiruchengode'},
{name: 'Tiruchirapalli'},
{name: 'Thirunelveli'},
{name: 'Tirupanitura'},
{name: 'Tirupattur'},
{name: 'Tirur (Kerala)'},
{name: 'Tiruvananthapuram'},
{name: 'Tisayanvillai'},
{name: 'Trasi'},
{name: 'Trikaripur'},
{name: 'Tuckalay'},
{name: 'Tumkur'},
{name: 'Tumsar'},
{name: 'Tungabhadra Dam'},
{name: 'Uchila'},
{name: 'Udhyavara'},
{name: 'Udumalpet'},
{name: 'Ujire'},
{name: 'Ujjain (Madhya Pradesh)'},
{name: 'Umerkhed'},
{name: 'Una (Himachal Pradesh)'},
{name: 'Unjha'},
{name: 'Upleta'},
{name: 'Uppinakote'},
{name: 'Uppinangady'},
{name: 'Upunda'},
{name: 'Usmanabad'},
{name: 'Utchipuli'},
{name: 'Uthamapalayam'},
{name: 'V Kota'},
{name: 'Vadakancheri'},
{name: 'Vadalur'},
{name: 'Vadasery (Nagercoil)'},
{name: 'Vairavanpatti'},
{name: 'Vallabh Vidyanagar'},
{name: 'Valunj'},
{name: 'Vamanjoor'},
{name: 'Vandse'},
{name: 'Vanthli'},
{name: 'Varanasi(benares)'},
{name: 'Varangaon'},
{name: 'Vasai (Maharashtra)'},
{name: 'Vasco'},
{name: 'Vasi'},
{name: 'Vasudevanallur'},
{name: 'Vathalagundu'},
{name: 'Vayalapadu'},
{name: 'Vayor'},
{name: 'Vedaranyam'},
{name: 'Vedasandur'},
{name: 'Veeravanallur'},
{name: 'Vembakottai'},
{name: 'Vembur'},
{name: 'Venoor'},
{name: 'Vermanagar'},
{name: 'Vidhyanagar (Gujarat)'},
{name: 'Vijapur (Gujarat)'},
{name: 'Vijay Nagar(Gujarat)'},
{name: 'Vijayanagar(Maharastra)'},
{name: 'Vijayanagara(Karnataka)'},
{name: 'Vikramasingapuram'},
{name: 'Villupuram'},
{name: 'Virpur (Gujarat)'},
{name: 'Virudhachalam'},
{name: 'Virudhunagar'},
{name: 'Visnagar'},
{name: 'Vita (Maharastra)'},
{name: 'Vittla'},
{name: 'Wyra(Gujarat)'},
{name: 'Vytheswarankoil'},
{name: 'Wasim'},
{name: 'Yadthare'},
{name: 'Yadur'},
{name: 'Yeragatti'},
{name: 'Yediyur'},
{name: 'Yellapur (Karnataka)'},
{name: 'Yermal'},
{name: 'Zabuva'},
{name: 'Yanam (Bye-Pass)'},
{name: 'Badaun (Uttar Pradesh)'},
{name: 'Bangaluru'},
{name: 'Tindivanam'},
{name: 'Panaji'},
{name: 'Margaon (Chhattisgarh)'},
{name: 'Kalghatagi'},
{name: 'Kashipur (Uttarakhand)'},
{name: 'Ashta (Madhya Pradesh)'},
{name: 'Erode (sithode)'},
{name: 'Wadakkanchery'},
{name: 'Mantralaya Darshan(Every Wednesday)'},
{name: 'Dharmasthala-Kukke Darshan(Sundays)'},
{name: 'Racharla'},
{name: 'Cumdum'},
{name: 'Markapuram'},
{name: 'V.G-Palem'},
{name: 'Y.G-Palem'},
{name: 'Gaya (Bodhgaya)'},
{name: 'Barhi (Jharkhand)'},
{name: 'Nawada (Bihar)'},
{name: 'Bihar Sharif'},
{name: 'Bodhgaya'},
{name: 'Gooty Byepass'},
{name: 'Shri Shirdi Saibaba Yatra'},
{name: 'Komarolu'},
{name: 'Tanguturu(Bye Pass)'},
{name: 'Dairy Circle'},
{name: 'Vennikulam'},
{name: 'Pampady'},
{name: 'Kanjikuzhi'},
{name: 'Vvazhakkulam'},
{name: 'Vazhakulam'},
{name: 'Beed'},
{name: 'Buldana'},
{name: 'karur'},
{name: 'Sangareddy (Telangana)'},
{name: 'Madanapalle'},
{name: 'Unguturu MainRoad'},
{name: 'Perungalathur'},
{name: 'Chithode (Tamil Nadu)'},
{name: 'Salem bypass'},
{name: 'Chenkalpattu'},
{name: 'Balapur (Telangana)'},
{name: 'Khed (Maharashtra)'},
{name: 'Sangmeshwar'},
{name: 'Thuckalay'},
{name: 'Erraguntapalli'},
{name: 'Seethanagaram(AP)'},
{name: 'Chintalapudi (West Godavari Dist)'},
{name: 'T. Narsapuram'},
{name: 'Burrampalem'},
{name: 'Chickballapur byepass'},
{name: 'Devanahalli byepass'},
{name: 'Delhi (Anand Vihar)'},
{name: 'Delhi (Ajmeri Gate)'},
{name: 'Delhi (Sarai Kale Khan)'},
{name: 'Rambhadrapuram'},
{name: 'Saluru (Andhra Pradesh)'},
{name: 'Muktheswaram'},
{name: 'Black Bridge'},
{name: 'Vemavaram'},
{name: 'Vilasa'},
{name: 'Siripalle (Amalapuram'},
{name: 'P.Gannavaram'},
{name: 'Kadapa (By Pass)'},
{name: 'Jonnada'},
{name: 'Dommeru'},
{name: 'Jagannadhagiri'},
{name: 'Dowlaiswaram'},
{name: 'Thatiparru'},
{name: 'Appikatla'},
{name: 'Yedullapalli'},
{name: 'Desapeta'},
{name: 'Vadaserikkara'},
{name: 'Peruvamthanam'},
{name: 'kuttikanam'},
{name: 'Peerumedu'},
{name: 'Vandiperiyar'},
{name: 'UthamaPalayam'},
{name: 'Kodungoor'},
{name: 'Mainpuri (Uttar Pradesh)'},
{name: 'Pitapuram'},
{name: 'Vemagiri'},
{name: 'Pundrapur'},
{name: 'Mohol'},
{name: 'Temburni'},
{name: 'Kolkata(IBT)'},
{name: 'Barhalganj (Uttar Pradesh)'},
{name: 'Dohrighat (Uttar Pradesh)'},
{name: 'Robertsganj'},
{name: 'Chopan (Uttar Pradesh)'},
{name: 'Daala'},
{name: 'Renukoot (Uttar Pradesh)'},
{name: 'Anpara'},
{name: 'Bina (Madhya Pradesh)'},
{name: 'Etah (Uttar Pradesh)'},
{name: 'Sandila (Uttar Pradesh)'},
{name: 'Hardoi'},
{name: 'Meerganj (Uttar Pradesh)'},
{name: 'Ujayani'},
{name: 'Sahaswan'},
{name: 'Gunnour'},
{name: 'Narora'},
{name: 'Dibai'},
{name: 'Lalkuan (Uttarakhand)'},
{name: 'Sonoro'},
{name: 'Noorpur (Uttar Pradesh)'},
{name: 'Maigalganj (Uttar Pradesh)'},
{name: 'Kartigi'},
{name: 'Basavapatna'},
{name: 'Budugumpa Cross'},
{name: 'Bindaki'},
{name: 'Lalauli'},
{name: 'Nanjangud'},
{name: 'Bhitariya'},
{name: 'Bhelsar'},
{name: 'Chilla (Uttar Pradesh)'},
{name: 'Atra (Uttar Pradesh)'},
{name: 'Lucknow (Polytechnic)'},
{name: 'Raya'},
{name: 'Nunna'},
{name: 'Agiripally'},
{name: 'Kavalkinaru'},
{name: 'Edurlanka(Bridge)'},
{name: 'Gonda (Uttar Pradesh)'},
{name: 'Chitrakoot (Uttar Pradesh)'},
{name: 'Kundara'},
{name: 'Mahbub Nagar'},
{name: 'Koyilandi'},
{name: 'Mahe'},
{name: 'Cheruvathur'},
{name: 'Hosdurg'},
{name: 'Payyanur'},
{name: 'C'},
{name: 'D'},
{name: 'Kanjangad'},
{name: 'F'},
{name: 'G'},
{name: 'H'},
{name: 'Manjeshwaram'},
{name: 'Mangalapuram'},
{name: 'Senkottai'},
{name: 'Thenkasi'},
{name: 'Edamon'},
{name: 'Kunnicode'},
{name: 'Ka'},
{name: 'kb'},
{name: 'kc'},
{name: 'kd'},
{name: 'Thenmala'},
{name: 'Kotralam Byepass'},
{name: 'vasudevanallur'},
{name: 'Aryankav'},
{name: 'Kidangoor'},
{name: 'Neriamangalam'},
{name: 'Adimali'},
{name: 'sriperumbudur'},
{name: 'kdgr'},
{name: 'Muzaffarnagar (Uttar Pradesh)'},
{name: 'Mantralaya (Maharastra)'},
{name: 'Hoskote'},
{name: 'Chintamani (Karnataka)'},
{name: 'Khajipeta'},
{name: 'Kanigiri'},
{name: 'Badvel'},
{name: 'Udayagiri'},
{name: 'Vinjamur (Andhra Pradesh)'},
{name: 'Pamuru'},
{name: 'Kovur (Nellore)'},
{name: 'Nileshwaram'},
{name: 'Mangalapuram (Kerala)'},
{name: 'Manjeshwar'},
{name: 'Kovvuru(Nellore Bypass)'},
{name: 'Raichur (Via Mantralaya)'},
{name: 'Badlapur (Maharastra)'},
{name: 'Chanda (Uttar Pradesh)'},
{name: 'Varanasi GL'},
{name: 'Payagipur'},
{name: 'Vellangallur'},
{name: 'Davanagere Byepass'},
{name: 'Navi Mumbai'},
{name: 'Am'},
{name: 'Bm'},
{name: 'Cm'},
{name: 'Kamothe Signal'},
{name: 'Yeraguntla'},
{name: 'Mahi (Maharashtra)'},
{name: 'Kampli'},
{name: 'Guntur Bye-Pass'},
{name: 'Porumamilla'},
{name: 'Kalasapadu'},
{name: 'Bestavaripeta'},
{name: 'Markapur'},
{name: 'Nenmara'},
{name: 'Kollankodu'},
{name: 'Viregaon'},
{name: 'Waturphata'},
{name: 'Mantha'},
{name: 'Zerophata'},
{name: 'Basmath'},
{name: 'Ategaon'},
{name: 'Malegaon (Nanded)'},
{name: 'Battelanka'},
{name: 'Lankalakoduru'},
{name: 'Green View Hotel'},
{name: 'Annapurna Hotel'},
{name: 'Adc 1'},
{name: 'adc 2'},
{name: 'adc 3'},
{name: 'Tada'},
{name: 'Macherla byepass'},
{name: 'Sagar dam'},
{name: 'L.B Nagar'},
{name: 'X'},
{name: 'Y'},
{name: 'Z'},
{name: 'Karumadai'},
{name: 'Aanjangaon'},
{name: 'Aanjar'},
{name: 'Aathankarai'},
{name: 'Abu'},
{name: 'Ailnabad'},
{name: 'Akolawadi'},
{name: 'Alagankulam'},
{name: 'Alakode'},
{name: 'Almora'},
{name: 'Alwarthirunagari'},
{name: 'Amalner (Jalgaon)'},
{name: 'Amarnath Yatra'},
{name: 'Ambajogai'},
{name: 'Ambala'},
{name: 'Ambalner'},
{name: 'Ambattur'},
{name: 'Ambayathode'},
{name: 'Amber Fort'},
{name: 'Amreli'},
{name: 'Angallu'},
{name: 'Anupgarh'},
{name: 'Aravali'},
{name: 'Areekode'},
{name: 'Arjansar (Rajasthan)'},
{name: 'Ashtavinayak'},
{name: 'Athangudi'},
{name: 'Athikadai'},
{name: 'Atthikadai'},
{name: 'Avanam'},
{name: 'Ayarkunnum'},
{name: 'Ayikkarapadi'},
{name: 'Ayodhya Allhbad Varanasi'},
{name: 'Ayyampettai'},
{name: 'Azhagankulam'},
{name: 'Azhagiyamandapam'},
{name: 'B.D.AJunction/LeeMeridian'},
{name: 'Babapur (Gujarat)'},
{name: 'Badadiya'},
{name: 'Badakara'},
{name: 'Badi Sadri (Rajasthan)'},
{name: 'Badrinath Kedarnath Yatra'},
{name: 'Bagasara'},
{name: 'Bagra'},
{name: 'Bahel'},
{name: 'Bahroad'},
{name: 'Bailur'},
{name: 'Bajpe Kinnigoli'},
{name: 'Mehandipur (Rajasthan)'},
{name: 'Balbleshwar'},
{name: 'Balehonnur'},
{name: 'Balmer'},
{name: 'Bardiya'},
{name: 'Barkur'},
{name: 'Baswada (Madhya Pradesh)'},
{name: 'Bathalapalli'},
{name: 'Batva'},
{name: 'Behrod'},
{name: 'Bhadiyadka'},
{name: 'Bhadra (Rajasthan)'},
{name: 'Bhadravathi (Karnataka)'},
{name: 'Bhalgam'},
{name: 'Bhanpura (Madhya Pradesh)'},
{name: 'Bhatewar'},
{name: 'Bhatiya (Gujarat)'},
{name: 'Bhilad'},
{name: 'Bhiwandi (Maharastra)'},
{name: 'Bhrahanpur'},
{name: 'Bhudagumpa'},
{name: 'Bidasar'},
{name: 'Bilwada (Madhya Pradesh)'},
{name: 'Bindar'},
{name: 'Boat cruise'},
{name: 'Borana'},
{name: 'Borawar'},
{name: 'Borsad'},
{name: 'Brahmancheruvu'},
{name: 'Bramavar'},
{name: 'Buchi Reddy Palem'},
{name: 'Burgur'},
{name: 'Butwal (Nepal)'},
{name: 'Beawar (Madhya Pradesh)'},
{name: 'Chakan (Maharastra)'},
{name: 'Chakshu'},
{name: 'Chala (Kerala)'},
{name: 'Chalala'},
{name: 'Chalode'},
{name: 'Chamberry'},
{name: 'Chandrai (Rajasthan)'},
{name: 'Chandur Bazar(Maharashtra)'},
{name: 'Changuvetty'},
{name: 'Channarayapatana'},
{name: 'Charoti (Maharastra)'},
{name: 'Chemmad'},
{name: 'Chengi'},
{name: 'Cherali'},
{name: 'Cheravathur'},
{name: 'Cherkala'},
{name: 'Cherragada'},
{name: 'Cherupuzha'},
{name: 'Chidava'},
{name: 'Chintaparthi'},
{name: 'Chintpurni'},
{name: 'Chiragaon'},
{name: 'Chittarical'},
{name: 'Chittariparamba'},
{name: 'Chittode Byepass'},
{name: 'Chittoor Rajasthan'},
{name: 'Cholingar'},
{name: 'Chopda'},
{name: 'Chottanikara'},
{name: 'Chouk (Maharastra)'},
{name: 'Dabok'},
{name: 'Dada Bhagwan'},
{name: 'Dhadar (Haryana)'},
{name: 'Data (Gujarat)'},
{name: 'Deccan'},
{name: 'Deedwana'},
{name: 'Degana'},
{name: 'Degav'},
{name: 'Dehra (Himachal Pradesh)'},
{name: 'Delhi Darshan'},
{name: 'Deulgaon Raja'},
{name: 'Devali (Maharashtra)'},
{name: 'Devgad (Maharastra)'},
{name: 'Devgalraja'},
{name: 'Devla (Gujarat)'},
{name: 'Dhandhuka'},
{name: 'Dhangdhra'},
{name: 'Dharangaon'},
{name: 'Dhari (Gujarat)'},
{name: 'Dhariwal'},
{name: 'Dharmajchowkdi'},
{name: 'Dharuheda'},
{name: 'Dholera (Gujarat)'},
{name: 'Dhondaicha'},
{name: 'Dhorimanna'},
{name: 'Didwana'},
{name: 'Digraj'},
{name: 'Dindugal (Tamil Nadu)'},
{name: 'Disa'},
{name: 'Dodavali'},
{name: 'Dol Kamal'},
{name: 'Dolatpura (Rajasthan)'},
{name: 'Dombiwali'},
{name: 'Dondaicha'},
{name: 'Dousa'},
{name: 'Dugla (Chhattisgarh)'},
{name: 'Dungar (Gujarat)'},
{name: 'Dungargarh'},
{name: 'Duriyur'},
{name: 'Dwarkathirumala'},
{name: 'Eachur (Kerala)'},
{name: 'Elanyankudi'},
{name: 'Ellapur'},
{name: 'Erikode'},
{name: 'Ettimadai'},
{name: 'Faizpur (Jalgaon)'},
{name: 'Fathenagar (Telangana)'},
{name: 'Gadhada'},
{name: 'Gangarar'},
{name: 'Gariadhar'},
{name: 'Garsana'},
{name: 'Ghodbandar'},
{name: 'Gngotri Kedarnth Badrinth'},
{name: 'Gogamedi'},
{name: 'Goganda'},
{name: 'Gomti Choraya'},
{name: 'Goraya'},
{name: 'Gudluru (Andhra Pradesh)dia'},
{name: 'Gulapura (Karnataka)'},
{name: 'Gundlupet'},
{name: 'Gurdaspur'},
{name: 'Hadi (Maharashtra)'},
{name: 'Haldipur'},
{name: 'Halvad'},
{name: 'Hanumangarh (Rajasthan)'},
{name: 'Harur (Tamil Nadu)'},
{name: 'Hath Kamba'},
{name: 'Hathawala (Haryana)'},
{name: 'Hedvi'},
{name: 'Hirur'},
{name: 'Hisar (Haryana)'},
{name: 'Horanadu'},
{name: 'Humcha'},
{name: 'Hungund By Pass'},
{name: 'Ilkal By Pass'},
{name: 'Indugunji'},
{name: 'Ingrola (Bhad)'},
{name: 'Irrikkur'},
{name: 'Iswal'},
{name: 'Itava'},
{name: 'Jaitran'},
{name: 'Jalandhar'},
{name: 'Jaliya (Gujarat)'},
{name: 'Jalod'},
{name: 'Jamjodhpur'},
{name: 'Jasdan'},
{name: 'Jasvant Ghadh'},
{name: 'Jwala Ji (Himachal Pradesh)'},
{name: 'Jayankondam'},
{name: 'Jayapura'},
{name: 'Jaysingpur'},
{name: 'Jedcharla'},
{name: 'Jetaran'},
{name: 'Jhalod'},
{name: 'Jhunjhunu'},
{name: 'Jim Corbett National Park'},
{name: 'Julwania'},
{name: 'Kadia (Madhya Pradesh)'},
{name: 'Kadugli'},
{name: 'Kadur (Karnataka)'},
{name: 'Kalachal'},
{name: 'Kalahasthi'},
{name: 'Kalakada (Andhra Pradesh)'},
{name: 'Kalasa'},
{name: 'Kalayarkoil'},
{name: 'Kaleyakavelai'},
{name: 'Kaliakkavilai'},
{name: 'Kalinda'},
{name: 'Kallakurichi'},
{name: 'Kallikandy'},
{name: 'Kalluru (Andhra Pradesh)'},
{name: 'Kaloda (Rajasthan)'},
{name: 'Kalol (Gujarat)'},
{name: 'Kaloor'},
{name: 'Kalpakkam'},
{name: 'Kamatagi'},
{name: 'Kamera Gadh'},
{name: 'Kamligadh'},
{name: 'Kanangad'},
{name: 'Kandola'},
{name: 'Kangra (Himachal Pradesh)'},
{name: 'kanhangad'},
{name: 'Kankroli (Rajasthan)'},
{name: 'Kannad (Maharashtra)'},
{name: 'Kannavam'},
{name: 'Kannur(cannanore)'},
{name: 'Kanod (Rajasthan)'},
{name: 'Kapu (Karnataka)'},
{name: 'Kargal (Karnataka)'},
{name: 'Karungal'},
{name: 'Karuvanchal'},
{name: 'Kashimira'},
{name: 'Kathmandu (Nepal)'},
{name: 'Katapadi'},
{name: 'Katta'},
{name: 'Kattangur (Telangana)'},
{name: 'Kattu Mavidi'},
{name: 'Keelakam'},
{name: 'Potta (Kerala)'},
{name: 'Kesariyaji (Rajasthan)'},
{name: 'Khadiya'},
{name: 'Khambha'},
{name: 'Khamnor'},
{name: 'Khanangad'},
{name: 'Khanna (Punjab)'},
{name: 'Kharepaten'},
{name: 'Kharavada (Gujarat)'},
{name: 'Khatoo'},
{name: 'Khedbrahma'},
{name: 'Khicha'},
{name: 'Khopoli'},
{name: 'Khud'},
{name: 'Khusal Nagar'},
{name: 'Kilakarai'},
{name: 'Kilasevalpatti'},
{name: 'Kodamangalam'},
{name: 'Kolachel'},
{name: 'Kolar (Karnataka)'},
{name: 'Kolidam'},
{name: 'Konapattu'},
{name: 'Koothanallur'},
{name: 'Koppa'},
{name: 'Koradacheri'},
{name: 'Koshital'},
{name: 'KothPutli'},
{name: 'Kottigehara'},
{name: 'Kottiyoor'},
{name: 'krishnan Koil'},
{name: 'Kuchaman'},
{name: 'Kudukkimotta'},
{name: 'Kuduremukha'},
{name: 'Kundrakudi'},
{name: 'Kunigal'},
{name: 'Kurumbur'},
{name: 'Kuruppampady'},
{name: 'Kutiyana Bypass'},
{name: 'Kutuparamba'},
{name: 'Ladnu'},
{name: 'Lakhanpur (Uttar Pradesh)'},
{name: 'Laxmangarh (Rajasthan)'},
{name: 'Laxmeswar'},
{name: 'Loaj'},
{name: 'Losal'},
{name: 'Losing'},
{name: 'Lunkaransar'},
{name: 'M.Cheruvu'},
{name: 'Maanamadurai'},
{name: 'Madangeri'},
{name: 'Madhavpur (Gujarat)'},
{name: 'Madhupur (Jharkhand)'},
{name: 'Magaon'},
{name: 'Mahad'},
{name: 'Mahajan'},
{name: 'Mahal (Gujarat)'},
{name: 'Mahuwa (Tonk'},
{name: 'Makrana (Rajasthan)'},
{name: 'Malia'},
{name: 'Malvan (Maharastra)'},
{name: 'Manakala'},
{name: 'Manali Dhrmshla Vaishno D'},
{name: 'Mananthavady'},
{name: 'Manavadar'},
{name: 'Mancheri'},
{name: 'Mandi (Himachal Pradesh)'},
{name: 'Mandvada (Gujarat)'},
{name: 'Manekvada'},
{name: 'Manglana'},
{name: 'Mangrol (Rajasthan)'},
{name: 'Mangrul Pir'},
{name: 'Manjeri'},
{name: 'Mankuwa'},
{name: 'Mannar'},
{name: 'Mansa (Punjab)'},
{name: 'Mansoorie'},
{name: 'Mansore'},
{name: 'Margoa'},
{name: 'Masura'},
{name: 'Mata Vaishno Devi Yatra'},
{name: 'Mattancheri'},
{name: 'Mavelikara (Kerala)'},
{name: 'Mavungal'},
{name: 'Mecleod Ganj'},
{name: 'Medta'},
{name: 'Meenagadi'},
{name: 'Meghpar (Jamnagar)'},
{name: 'Mehsana'},
{name: 'Mehta Chowk'},
{name: 'Mendarda'},
{name: 'Mokalsar (Rajasthan)'},
{name: 'Mokkam'},
{name: 'Molasar (Rajasthan)'},
{name: 'Molela'},
{name: 'Monday market'},
{name: 'MondayMarket'},
{name: 'Mooka'},
{name: 'Morthad (Telangana)'},
{name: 'MountAbu'},
{name: 'Mt. Abu'},
{name: 'Mudigere'},
{name: 'Mudigubba'},
{name: 'Mudrangandi'},
{name: 'Mulamthurthy'},
{name: 'Mumbai Naka'},
{name: 'Mugling (Nepal)'},
{name: 'Munjiyasar'},
{name: 'Musorie Rishiksh Haridwar'},
{name: 'Mussorie'},
{name: 'Mutthupettai'},
{name: 'Naduvil'},
{name: 'Nagara (Karnataka)'},
{name: 'Nakrekal'},
{name: 'Namakal'},
{name: 'Nan Dishma'},
{name: 'Nandikur'},
{name: 'Nani Monpari'},
{name: 'Nanjangood'},
{name: 'Nanrol'},
{name: 'Nanudi'},
{name: 'Naravi'},
{name: 'Narayangarh (Nepal)'},
{name: 'Nardana'},
{name: 'Nari (Gujarat)'},
{name: 'Narvan'},
{name: 'Naseerabad (Andhra Pradesh)'},
{name: 'Naseerabagh'},
{name: 'Nasirabad (Uttar Pradesh)'},
{name: 'Nawalgarh (Rajasthan)'},
{name: 'Nedumpoil'},
{name: 'Nedumpoyil'},
{name: 'Neem ka thana'},
{name: 'Nelyady'},
{name: 'Nesdi'},
{name: 'Neser pet'},
{name: 'Nette (Karnataka)'},
{name: 'Neyveli'},
{name: 'Nibaiheda'},
{name: 'Nigari'},
{name: 'Nikker'},
{name: 'Nilanggaaurad'},
{name: 'Nileshwar'},
{name: 'Nimbaj'},
{name: 'Nippani'},
{name: 'Nithiravilai'},
{name: 'Nivliphata'},
{name: 'Niwai (Rajasthan)'},
{name: 'Nohar (Rajasthan)'},
{name: 'Nokha (Rajasthan)'},
{name: 'Nova'},
{name: 'Odakkali'},
{name: 'Odumalai'},
{name: 'Olavapadu'},
{name: 'Ootacamund (Tamil Nadu)'},
{name: 'Ootanchathiram'},
{name: 'Orathanadu'},
{name: 'Oriya'},
{name: 'Orthanad'},
{name: 'Ozar (Maharashtra)'},
{name: 'Paakkad By Pass'},
{name: 'Palam (Maharashtra)'},
{name: 'Palayam (Kerala)'},
{name: 'Palcot'},
{name: 'Pallu'},
{name: 'Palsam'},
{name: 'Panathur'},
{name: 'Panchkula (Haryana)'},
{name: 'Paneli (Gujarat)'},
{name: 'Panrutty'},
{name: 'Parad (Kerala)'},
{name: 'Paramathivellore'},
{name: 'Ajara'},
{name: 'Parbatsar (Rajasthan)'},
{name: 'Parmanu'},
{name: 'Parthibanur'},
{name: 'Patan Vav (Gujarat)'},
{name: 'Pathur (Tamil Nadu)'},
{name: 'Patur (Kadapa'},
{name: 'Payangadi'},
{name: 'Payyoli'},
{name: 'Peravoor'},
{name: 'Peria'},
{name: 'Perla'},
{name: 'Phansu'},
{name: 'Philoor'},
{name: 'Pilani'},
{name: 'Pillayarpatti'},
{name: 'Pimpari'},
{name: 'Piravom'},
{name: 'Ponamaravathi'},
{name: 'Ponnani'},
{name: 'Poonthottam'},
{name: 'Porbunder'},
{name: 'Pothakudi'},
{name: 'Pratapgadh (Gujarat)'},
{name: 'Puduwayal'},
{name: 'Pulikkal (Kerala)'},
{name: 'Pulingudi'},
{name: 'Punnapra'},
{name: 'Rajaldesher'},
{name: 'Ramanattukara'},
{name: 'Rambhari'},
{name: 'Ramdevra'},
{name: 'Ramgad'},
{name: 'Rampura'},
{name: 'Ranavav'},
{name: 'Ranuja'},
{name: 'Ratangadh'},
{name: 'Ratangarh (Madhya Pradesh)'},
{name: 'Ravapar'},
{name: 'Raver (Maharashtra)'},
{name: 'Rawatsar'},
{name: 'Ringas'},
{name: 'Rippinpet'},
{name: 'Ruderpur'},
{name: 'Rudki'},
{name: 'Rukhran'},
{name: 'Rupangadh'},
{name: 'Sabarimala'},
{name: 'Sachin'},
{name: 'Sagrun'},
{name: 'Sakleshpur (Karnataka)'},
{name: 'Salangpur'},
{name: 'Salodha'},
{name: 'Sanchore'},
{name: 'Sanderaw'},
{name: 'Sangameshwar (Maharashtra)'},
{name: 'Sangat'},
{name: 'Sankaran Koil'},
{name: 'Sanauli (Uttar Pradesh)'},
{name: 'Sapar'},
{name: 'Sarangpur(Gujarat)'},
{name: 'Sardargarh (Rajasthan)'},
{name: 'SardarShahar'},
{name: 'Satara-karad'},
{name: 'Savanur'},
{name: 'Savarda'},
{name: 'Savarkundla'},
{name: 'Savda'},
{name: 'Sawada'},
{name: 'Sayla (Gujarat)'},
{name: 'Selvas'},
{name: 'Sema (Rajasthan)'},
{name: 'SeminarKoil'},
{name: 'Sendva'},
{name: 'Shahpura (Madhya Pradesh)'},
{name: 'Shanishingnapur'},
{name: 'Shergadh (Gujarat)'},
{name: 'Shihor'},
{name: 'Shil (Gujarat)'},
{name: 'Shipur (Maharashtra)'},
{name: 'Shiror'},
{name: 'Shirwal (Maharashtra)'},
{name: 'Shiv'},
{name: 'Shivajinagar (Ratnagiri Dist'},
{name: 'Shivana'},
{name: 'Shravan'},
{name: 'Shrinathji'},
{name: 'Sihor (Gujarat)'},
{name: 'Sikandra'},
{name: 'Sikka (Gujarat)'},
{name: 'Sira'},
{name: 'Sirikali'},
{name: 'Sirkazi'},
{name: 'Sirsa(Haryana)'},
{name: 'Sojat'},
{name: 'Sorab'},
{name: 'SpicNagar'},
{name: 'Srikantapuram'},
{name: 'Sringar Tadi'},
{name: 'Sringeri'},
{name: 'Sriperumbur'},
{name: 'Srirangapattana'},
{name: 'SriviliPuthur'},
{name: 'Sujangadh (Gujarat)'},
{name: 'Sundarnagar (Himachal Pradesh)'},
{name: 'Surat Garh'},
{name: 'Surpur'},
{name: 'Sutrapara'},
{name: 'Swarofganj'},
{name: 'Takhatgarh'},
{name: 'Talegaon (Wardha)'},
{name: 'Talegav'},
{name: 'Talluru (Andhra Pradesh)'},
{name: 'Tanakallu'},
{name: 'Taragampadi'},
{name: 'Taranagar (Rajasthan)'},
{name: 'Tarapur (Maharashtra)'},
{name: 'Tarikere'},
{name: 'Tarla'},
{name: 'Thalavaipuram'},
{name: 'Thankatchimadam'},
{name: 'Thasgavpur'},
{name: 'Thathiengarpet'},
{name: 'Thiruchendur'},
{name: 'Thirukadaiyur'},
{name: 'Thirunallar (Tamil Nadu)'},
{name: 'Thirupalaikudi'},
{name: 'Thirupathi'},
{name: 'Thiruvallur'},
{name: 'Thoppumpady'},
{name: 'Thrippunithra'},
{name: 'Thuvarankuruchi'},
{name: 'Tiptur'},
{name: 'Tiruchengodu'},
{name: 'Tirumala Temple'},
{name: 'Tirumangalam'},
{name: 'Tirunchenkod'},
{name: 'Tirupati Airport'},
{name: 'Tiruthanni'},
{name: 'Tiruvalla'},
{name: 'Tiruvannamalai'},
{name: 'Toank'},
{name: 'Triambakeshwar'},
{name: 'Trichy By Pass'},
{name: 'Trimbak'},
{name: 'Triprayar'},
{name: 'Tripunatura'},
{name: 'Tulajapur'},
{name: 'Udankudi'},
{name: 'Ulundurpet'},
{name: 'Umarkhed'},
{name: 'Una(Gujarat)'},
{name: 'Unava'},
{name: 'Uppala (Kerala)'},
{name: 'Uppoor (Karnataka)'},
{name: 'Usilampatti'},
{name: 'Utthangarai'},
{name: 'Vadal (Gujarat)'},
{name: 'Vaimedu'},
{name: 'Vajjapur'},
{name: 'Valbhipur'},
{name: 'Valudhur'},
{name: 'Vani'},
{name: 'Vaniambad'},
{name: 'Varda (Rajasthan)'},
{name: 'Varmanagar'},
{name: 'Vasad'},
{name: 'Vatalagundu'},
{name: 'Vataman'},
{name: 'Vati (Gujarat)'},
{name: 'Veerpur'},
{name: 'Vellore Golden Temple'},
{name: 'Vempalli'},
{name: 'Vettavalam'},
{name: 'Vettichira'},
{name: 'Vidhyanagar (Karnataka)'},
{name: 'Vijaypur (Madhya Pradesh)'},
{name: 'Viralimalai'},
{name: 'Visavdar'},
{name: 'Vitta'},
{name: 'Vyas'},
{name: 'Wadakkenchery'},
{name: 'Waluj'},
{name: 'Wayamgani'},
{name: 'Wyra(Telangana)'},
{name: 'Yaval'},
{name: 'Yawal'},
{name: 'Yedapadav'},
{name: 'Hospet(Bye pass)'},
{name: 'Hubli (Bye pass)'},
{name: 'Ponda'},
{name: 'Ranibennur'},
{name: 'Bethery'},
{name: 'Iricty'},
{name: 'Kelakam'},
{name: 'Kuduvalli (Karnataka)'},
{name: 'Kundamangalam'},
{name: 'Meenangadi'},
{name: 'Nodembuil'},
{name: 'Peravioor'},
{name: 'Kolhapur Byepass'},
{name: 'Pune  Byepass'},
{name: 'Satara Byepass'},
{name: 'Kanchipuram VLgate'},
{name: 'Place'},
{name: 'Kadavanthara'},
{name: 'Echal Karanji'},
{name: 'Jallandhar Byepass'},
{name: 'Ludhiana Byepass'},
{name: 'Rajpura Byepass'},
{name: 'Ernakullam  Byepass'},
{name: 'Anegudde'},
{name: 'Bellary Byepass'},
{name: 'Koteshwara (Karnataka)'},
{name: 'Mudabidre'},
{name: 'Vethalagundu'},
{name: 'Kammaradi'},
{name: 'Vellumbarum'},
{name: 'Hemmadi'},
{name: 'Nadagudde Angadi'},
{name: 'Nujari'},
{name: 'Kushtagi (Bye Pass)'},
{name: 'Tellicherry'},
{name: 'Tirupati Drop'},
{name: 'Thalacherry'},
{name: 'Nidugundi'},
{name: 'Kuthattukulam'},
{name: 'Kannepalli (Telangana)'},
{name: 'Jagaluru'},
{name: 'Nayakana Hatty'},
{name: 'Madurai East'},
{name: 'Badiyadka'},
{name: 'Busroor'},
{name: 'Ganjimutt'},
{name: 'Hariharapura (Karnataka)'},
{name: 'Jaipur (Karnataka)'},
{name: 'Kumbla (Kumble'},
{name: 'Mangalore Byepass'},
{name: 'Maravoor'},
{name: 'Marzan'},
{name: 'Muchur (Karnataka)'},
{name: 'Niddodi'},
{name: 'Sithangolli'},
{name: 'Yadapadava'},
{name: 'Tiruchirapally'},
{name: 'Ajekar'},
{name: 'Madhuvana'},
{name: 'Mudradi'},
{name: 'Muniyal'},
{name: 'Varanga'},
{name: 'Channapatna'},
{name: 'Ramanagar'},
{name: 'Besant  Nagar'},
{name: 'Bithlakatte'},
{name: 'Kumbashi  Anegudde'},
{name: 'Mannoothy bypass'},
{name: 'Kudligi'},
{name: 'Atradi'},
{name: 'Konanduru'},
{name: 'Bavani'},
{name: 'Pen (Maharashtra)'},
{name: 'Vadkhal'},
{name: 'Hindle'},
{name: 'Tarele'},
{name: 'Courtalam Falls'},
{name: 'Ankadakatte'},
{name: 'Ankadakatte Kundapura'},
{name: 'Basrur Moorkai'},
{name: 'Gundmi Ambagilu'},
{name: 'Haleyangady'},
{name: 'Katapadi(Udupi)'},
{name: 'KREC'},
{name: 'Mukka(Karnataka)'},
{name: 'Muloor (Karnataka)'},
{name: 'Padu Panambur'},
{name: 'Pangala (Karnataka)'},
{name: 'Pumpwell(Mangalore)'},
{name: 'Udyawara'},
{name: 'Aversa'},
{name: 'Lakdikaphool'},
{name: 'Afzalgunj'},
{name: 'Gadhag'},
{name: 'Udumelpet'},
{name: 'Baikampady'},
{name: 'Haleangady'},
{name: 'Harihara'},
{name: 'ICREC-NITK'},
{name: 'KREC-NITK'},
{name: 'Kulai'},
{name: 'Kulur(Karnataka)'},
{name: 'Sweatkal'},
{name: 'Padapanambur'},
{name: 'Melkar'},
{name: 'Mudarangady'},
{name: 'Thokkottu'},
{name: 'Paripally'},
{name: 'Adivarm'},
{name: 'Kutta'},
{name: 'Manandwadi'},
{name: 'Thamaressy'},
{name: 'Vitry'},
{name: 'Avinasi Byepass'},
{name: 'Cheruthuruthi'},
{name: 'Dharmapuri Byepass'},
{name: 'Hossur Byepass'},
{name: 'Krishnagiri Byepass'},
{name: 'Kulapulli'},
{name: 'Palakkaddu Byepass'},
{name: 'Perunthurai Byepass'},
{name: 'Vaniyamkulam'},
{name: 'Alengar'},
{name: 'Alangar Katte'},
{name: 'Babbarya Katte'},
{name: 'Bannadka'},
{name: 'Bannadka Belvai'},
{name: 'Chempi (Gundmi)'},
{name: 'Gantalkatte'},
{name: 'Guddeyangadi'},
{name: 'Gudmbi'},
{name: 'K V M K Petrol Bunk'},
{name: 'Kannukere'},
{name: 'Karavali (Karnataka)'},
{name: 'Kesargaddhe'},
{name: 'Kesargaddi Karkala'},
{name: 'Kondadi School'},
{name: 'Kotamurukai'},
{name: 'Kubragotu'},
{name: 'M G M'},
{name: 'Madaga'},
{name: 'Murathangadi'},
{name: 'Naguru (Andhra Pradesh)'},
{name: 'Onthibettu'},
{name: 'Padyarabettu'},
{name: 'Sanoor (Karkala)'},
{name: 'Sanoor Karkala'},
{name: 'Shashidara'},
{name: 'Shashri Circle'},
{name: 'Sonthekatte'},
{name: 'Unity  Hall'},
{name: 'Vinayaka'},
{name: 'Amballur'},
{name: 'Tripur Bypass'},
{name: 'Nedumudy'},
{name: 'Ramankary'},
{name: 'Gangimutt'},
{name: 'Crossland'},
{name: 'Godankatte'},
{name: 'Haluvali'},
{name: 'Jundal'},
{name: 'Kalthoor'},
{name: 'Kannar (Karnataka)'},
{name: 'Karje (Karnataka)'},
{name: 'Krushikendra'},
{name: 'Nilavara Cross'},
{name: 'Tetri'},
{name: 'Mananthawady'},
{name: 'Kottara'},
{name: 'N I T K'},
{name: 'P.V.S'},
{name: 'Uchilla'},
{name: 'Urwa Store'},
{name: 'T.B.Dam'},
{name: 'Argodi'},
{name: 'Kadri'},
{name: 'Deralakatte'},
{name: 'Mudipu'},
{name: 'Gurupur'},
{name: 'coilandy'},
{name: 'Balla manja'},
{name: 'Karaya'},
{name: 'Karinja (Karnataka)'},
{name: 'Kotekar'},
{name: 'Maganthyar'},
{name: 'Muguru (Karnataka)'},
{name: 'Murje'},
{name: 'Punjala Katla'},
{name: 'Talatady'},
{name: 'Vogga (Karnataka)'},
{name: 'kadathurathi'},
{name: 'Kurupumthara'},
{name: 'Madanthyar'},
{name: 'Mugeru (Karnataka)'},
{name: 'Punjalakatte'},
{name: 'Thalapady (Karnataka)'},
{name: 'Belgaum Byepass'},
{name: 'Hubli Byepass'},
{name: 'Hasan'},
{name: 'Ankola Baleguli cross'},
{name: 'Agra Bypass'},
{name: 'Nagarkovil (Tamil Nadu)'},
{name: 'Monipally'},
{name: 'Bylakuppe'},
{name: 'Kurupanthara'},
{name: 'Belgaum Town'},
{name: 'Begar'},
{name: 'Bidarugod'},
{name: 'Harogolige'},
{name: 'Padmanabha Swamy'},
{name: 'Anand Rao Circle'},
{name: 'Davangere Byepass'},
{name: 'Hunsur'},
{name: 'Piriyapatna'},
{name: 'Mussoorie'},
{name: 'Kerur (Karnataka)'},
{name: 'Kulgeri Cross'},
{name: 'Karawara (Rajasthan)'},
{name: 'Bail hongala'},
{name: 'Belvadi'},
{name: 'Garag Bail Hongala'},
{name: 'Soraba'},
{name: 'Kudhalli (Karnataka)'},
{name: 'Panavaram (Tamil Nadu)'},
{name: 'Jonpur'},
{name: 'Khatima'},
{name: 'Raiwala (Uttarakhand)'},
{name: 'Sitarganj (Uttarakhand)'},
{name: 'Gullapura'},
{name: 'Kalghatgi'},
{name: 'Gulleda Gudda'},
{name: 'Gaddanakeri'},
{name: 'Sakaleshpur'},
{name: 'Mallakkad'},
{name: 'Sirugumapa'},
{name: 'Marvante'},
{name: 'Uthamapalyam'},
{name: 'Mannaraad'},
{name: 'Koothuparamba'},
{name: 'Perinchalmanna'},
{name: 'Shivmoga'},
{name: 'Udupi Bypass'},
{name: 'Adda Road (Yellamanchili)'},
{name: 'Anugul'},
{name: 'Chandikol'},
{name: 'Dhenkanal'},
{name: 'Kendrapara (Odisha)'},
{name: 'Pattamundai'},
{name: 'Tata (Jharkhand)'},
{name: 'Gajendragadh'},
{name: 'Kuknur'},
{name: 'Yalburga'},
{name: 'Salaser'},
{name: 'Luxmanghad'},
{name: 'Sujanghad'},
{name: 'Anupuram (Tamil Nadu)'},
{name: 'Kalpakkam Township'},
{name: 'Mahabalipuram'},
{name: 'Appapuram'},
{name: 'Endroyi'},
{name: 'Gollapudi'},
{name: 'Ibrahimpatnam (Vijayawada)'},
{name: 'Narakoduru'},
{name: 'Narakulapadu'},
{name: 'Nidimukala'},
{name: 'Vadlamudi'},
{name: 'Vejendla'},
{name: 'Vignan Lara College'},
{name: 'Nidumukkala'},
{name: 'Mantralayam (Andhra Pradesh)'},
{name: 'Devar Hippargi'},
{name: 'Kurnool Byepass'},
{name: 'Sathy'},
{name: 'Kinathukadavu'},
{name: 'IIakal'},
{name: 'Bhuntar'},
{name: 'Ner Chowk'},
{name: 'Bajagoli'},
{name: 'Beluve'},
{name: 'Goliangadi'},
{name: 'Bhavani(Byepass)'},
{name: 'Bellur'},
{name: 'Kanchipuram Bye Pass'},
{name: 'Kanchipuiram Bye Pass'},
{name: 'Krishnagiri Bye Pass'},
{name: 'Poonamalli'},
{name: 'Punamallai'},
{name: 'saibarkatte'},
{name: 'Shiroor Murkai'},
{name: 'Ruraki'},
{name: 'Kholapur'},
{name: 'Kudavali (Maharashtra)'},
{name: 'Korutla'},
{name: 'Kanchi Mahabalipuram'},
{name: 'Kanipakam Golden Temple'},
{name: 'Hubli(Bypass)'},
{name: 'Kapu()'},
{name: 'Y G Palem'},
{name: 'Madurai Bypass'},
{name: 'Udyavara (Karnataka)'},
{name: 'Hatti'},
{name: 'Jalahalli(Devadurga)'},
{name: 'Gangotri (Uttarakhand)'},
{name: 'Gulmarg'},
{name: 'Jungle Safari'},
{name: 'Naimisharanya'},
{name: 'Pahalgam'},
{name: 'MANGALDASROAD'},
{name: 'Channagiri'},
{name: 'Holalkere'},
{name: 'Molakalmuru'},
{name: 'Konnur (Bagalkote)'},
{name: 'N R Pura'},
{name: 'Shankaraghatta'},
{name: 'Mantralayam Drop'},
{name: 'Jbs'},
{name: 'Karimnagar'},
{name: 'Akkalkot'},
{name: 'Tirupur Byepass'},
{name: 'Siddakatte'},
{name: 'Derlakatte'},
{name: 'Karwar Cross'},
{name: 'Katra Drop'},
{name: 'Pullad'},
{name: 'Amballoor'},
{name: 'Karukutty'},
{name: 'Mannuthy Bye Pass'},
{name: 'Bairumbe'},
{name: 'Manchikeri'},
{name: 'Guruvyapalem'},
{name: 'Amruthalur'},
{name: 'Cherukupalli'},
{name: 'Duggiralla'},
{name: 'Govada'},
{name: 'Gudavalli'},
{name: 'Pedapudi'},
{name: 'Pedavadlapudi'},
{name: 'Dasoa'},
{name: 'Kathua (Jammu and Kashmir)'},
{name: 'Rukhri (Haryana)'},
{name: 'Ropar (Himachal Pradesh)'},
{name: 'Tanda (Punjab)'},
{name: 'Kallur (Pudukkottai)'},
{name: 'Kolluru Cross (Karnataka)'},
{name: 'Bylur'},
{name: 'Udupi Karavali'},
{name: 'Vemulapadu'},
{name: 'Kuttikkanam'},
{name: 'Vadasserikkara'},
{name: 'Serkali'},
{name: 'Kolenchery (Kerala)'},
{name: 'Alawaye'},
{name: 'Kaduthuruthy (Kerala)'},
{name: 'Mahe (Kerala)'},
{name: 'Palai'},
{name: 'Sankaran Kovil'},
{name: 'Talayolaparamp'},
{name: 'Sirkali (Sirkazhi)'},
{name: 'Gudalur-Ooty'},
{name: 'Munnuthy'},
{name: 'Vedakkanchery'},
{name: 'Baijnath'},
{name: 'Bir (Himachal Pradesh)'},
{name: 'Palampur (Himachal Pradesh)'},
{name: 'Verna'},
{name: 'Paiga (Uttar Pradesh)'},
{name: 'Thiruthani'},
{name: 'Goa(Panaji)'},
{name: 'Goa(Panajim)'},
{name: 'Talikoti'},
{name: 'Yallapur'},
{name: 'Goa (Panjim)'},
{name: 'Goa(Panjim)'},
{name: 'Chitradruga By Pass'},
{name: 'Nipanni By Pass'},
{name: 'Sankeshwar By Pass'},
{name: 'Mupkal'},
{name: 'Devipattinam (Tamil Nadu)'},
{name: 'Rameshwaram'},
{name: 'Virajpet'},
{name: 'Gonikoppal'},
{name: 'Ahmed Nagar'},
{name: 'Nedumkandam'},
{name: 'Perumba'},
{name: 'Vatakara'},
{name: 'Patancheru'},
{name: 'Isnapur X Roads'},
{name: 'Sangareddy X Roads'},
{name: 'Nedumudi'},
{name: 'Shornur'},
{name: 'Kamalapuram (Telangana)'},
{name: 'Bukkasagara (Karnataka)'},
{name: 'Ramasagar (Karnataka)'},
{name: 'Chikkajantakal'},
{name: 'Virthunagar'},
{name: 'Trichendur'},
{name: 'Cinnamanur'},
{name: 'Tallada'},
{name: 'Kakinada1'},
{name: 'Pudubidri'},
{name: 'Sangam Jagarlamudi'},
{name: 'Angalakuduru'},
{name: 'Pedaravuru'},
{name: 'Kuchipudi (Krishna Dist.)'},
{name: 'Kollipara (Andhra Pradesh)'},
{name: 'Mangalagiri Bi-Pass'},
{name: 'Peddavadla Pudi'},
{name: 'Tumuluru'},
{name: 'Athota'},
{name: 'Mogalturu'},
{name: 'Ramvaramppadu Ring'},
{name: 'Prasadampadu'},
{name: 'Enkepadu'},
{name: 'Atkuru'},
{name: 'Telaprolu'},
{name: 'Nidamanuru'},
{name: 'Vaniyambadi'},
{name: 'Abad (Airport)'},
{name: 'Kalyanpur (Rajasthan)'},
{name: 'Kherwara (Dungarpur Dist'},
{name: 'Limbdi'},
{name: 'Sillod (ByPass)'},
{name: 'Ulhasnagar'},
{name: 'Wattar'},
{name: 'Channarayapatna'},
{name: 'Devdurga'},
{name: 'Hutti'},
{name: 'Jog falls'},
{name: 'K.R.Pet'},
{name: 'Kalladaka'},
{name: 'Kapu(Karnataka)'},
{name: 'Khatraj (Gujarat)'},
{name: 'Kota(Karnataka)'},
{name: 'kustagi'},
{name: 'Mumbai(Thane)'},
{name: 'Navalgund'},
{name: 'Nidagundi'},
{name: 'Ramdurg'},
{name: 'Santekatte'},
{name: 'Sasthama'},
{name: 'Shravanabelagola'},
{name: 'Siddapur(KDR)'},
{name: 'Siddapur (Sirsi)'},
{name: 'Sirwar (Karnataka)'},
{name: 'Yadagiri'},
{name: 'Zaheerabad(AP)'},
{name: 'Parappadi (Tamil Nadu)'},
{name: 'Pannankulam'},
{name: 'ANANTAPURAM'},
{name: 'NAKIREKAL'},
{name: 'RLY.KODURU'},
{name: 'Narsaraopet'},
{name: 'Badampudi'},
{name: 'Bhujabalapatnam'},
{name: 'Bomdayekodu'},
{name: 'Buchireddypalem'},
{name: 'Damaramadugu'},
{name: 'Divancheruvu Rjy'},
{name: 'Eepurapalem'},
{name: 'Enuamudi'},
{name: 'Gandepalli'},
{name: 'Gopalapuram East Godavari Dist.'},
{name: 'Gopavaram (Andhra Pradesh)'},
{name: 'Gundugulanu'},
{name: 'Guruvayyapalem'},
{name: 'Kapulapalem Junction'},
{name: 'Kondayeporu'},
{name: 'Kothakaluva'},
{name: 'Kouvr Nlr'},
{name: 'Kovvuru Rjy'},
{name: 'Kumaragiri (Karnataka)'},
{name: 'Madhavapatnam'},
{name: 'Medapadu'},
{name: 'Munipalli (Nidadavole'},
{name: 'Murpa (Jharkhand)'},
{name: 'Mypalacheruvvu'},
{name: 'Nagullanka'},
{name: 'Nakkapalliadda Road'},
{name: 'Narsipatnam'},
{name: 'Pallivela'},
{name: 'Patnavelli'},
{name: 'Pattipadu'},
{name: 'Pgannavram'},
{name: 'Pippara (Andhra Pradesh)'},
{name: 'Polamuru'},
{name: 'Prathap Nagar Bridge'},
{name: 'Ramesampeta'},
{name: 'S Koduru'},
{name: 'Siddantam'},
{name: 'Veeravalli'},
{name: 'Velvenu'},
{name: 'Vetapalem Bypass'},
{name: 'Vissakoderu'},
{name: 'Yelurpadu'},
{name: 'Palakkad Byepass'},
{name: 'Aadsar (Rajasthan)'},
{name: 'Aahor'},
{name: 'Aambur'},
{name: 'Aaspur'},
{name: 'Aastra'},
{name: 'Abd (airport)'},
{name: 'Abd (c.t.m)'},
{name: 'Abd (naroda Patia)'},
{name: 'Abd (rly Station)'},
{name: 'Abd [sabarmati]'},
{name: 'Abhanpur (Chhattisgarh)'},
{name: 'Abiramam'},
{name: 'Abu Darshan'},
{name: 'Adani Power'},
{name: 'Adesar (Gujarat)'},
{name: 'Adlabad (Uttar Pradesh)'},
{name: 'Aduthurai'},
{name: 'Advana'},
{name: 'Afzal Gunj'},
{name: 'Afzalgarh'},
{name: 'Agar (Madhya Pradesh)'},
{name: 'Airoli'},
{name: 'Ajaygarh'},
{name: 'Akoli'},
{name: 'Akurdi (pune)'},
{name: 'Alagiyamandapam'},
{name: 'Alanthalai'},
{name: 'Alnabad'},
{name: 'Ambikapur (Chhattisgarh)'},
{name: 'Amlapur'},
{name: 'Ammalner'},
{name: 'Amuthapuram'},
{name: 'Anaikarai'},
{name: 'Anand Vihar'},
{name: 'Ananthapalli'},
{name: 'Anchtageri'},
{name: 'Anjangaon'},
{name: 'Anjaniya'},
{name: 'Anjugiramam'},
{name: 'Ankali (Karnataka)'},
{name: 'Aralvaimozhi'},
{name: 'Arang (Chhattisgarh)'},
{name: 'Araria Court'},
{name: 'Araria Zeromile'},
{name: 'Ardhapur'},
{name: 'Arehole'},
{name: 'Areraj'},
{name: 'Arnej'},
{name: 'Arni'},
{name: 'Arni (Maharastra)'},
{name: 'Arumugaveni'},
{name: 'Aruppukottai By Pass'},
{name: 'Aryankavu'},
{name: 'Ashti (Gadchiroli)'},
{name: 'Asind (Rajasthan)'},
{name: 'Aspur'},
{name: 'Assolna'},
{name: 'Atabira (Orissa)'},
{name: 'Atkot'},
{name: 'Athner (Madhya Pradesh)'},
{name: 'Aundha Nagnath'},
{name: 'Ausa'},
{name: 'Avanigadda'},
{name: 'Avinashipalayam'},
{name: 'Ayakkaranpulam(vedaranyam)'},
{name: 'Bar (Uttar Pradesh)'},
{name: 'Babaleshwar (Karnataka)'},
{name: 'Babra (Gujarat)'},
{name: 'Bacheli'},
{name: 'Badarwase'},
{name: 'Badh Nagar'},
{name: 'Badhnawar'},
{name: 'Badmer'},
{name: 'Barnagar (Madhya Pradesh)'},
{name: 'Badnera'},
{name: 'Badnore'},
{name: 'Baggar'},
{name: 'Bagha Purana'},
{name: 'Bagru'},
{name: 'Bagu'},
{name: 'Baharoad'},
{name: 'Bahigaon(Chhattisgarh)'},
{name: 'Baihar'},
{name: 'Bailadila'},
{name: 'Baitalpur'},
{name: 'Baitul'},
{name: 'Bajpur'},
{name: 'Bakewar'},
{name: 'Balachaur (Punjab)'},
{name: 'Balaghat (Madhya Pradesh)'},
{name: 'Baldiya (Gujarat)'},
{name: 'Balesar'},
{name: 'Balesore'},
{name: 'Balgaum Bypass'},
{name: 'Balrampur (Uttar Pradesh)'},
{name: 'Balva (Gujarat)'},
{name: 'Bamanwada'},
{name: 'Bamnod'},
{name: 'Banas (Rajasthan)'},
{name: 'Bandanwara'},
{name: 'Bandra'},
{name: 'Baneri'},
{name: 'Baniagaon (Chhattisgarh)'},
{name: 'Bannadi'},
{name: 'Bansi (Uttar Pradesh)'},
{name: 'Bantakal'},
{name: 'Baradiya'},
{name: 'Bargarh (Orissa)'},
{name: 'Barhmavar'},
{name: 'Bari Sadri'},
{name: 'Barnala'},
{name: 'Baroda By Pass'},
{name: 'Barwala (Gujarat)'},
{name: 'Barwaha (Madhya Pradesh)'},
{name: 'Bashi'},
{name: 'Basna'},
{name: 'Baspa'},
{name: 'Bassi (Rajasthan)'},
{name: 'Bastar'},
{name: 'Bathinda'},
{name: 'Beas (Punjab)'},
{name: 'Bediya (Madhya Pradesh)'},
{name: 'Beenaganj'},
{name: 'Begusarai'},
{name: 'Belthur'},
{name: 'Belukurichi'},
{name: 'Belurghat'},
{name: 'Benur'},
{name: 'Betma (Madhya Pradesh)'},
{name: 'Bhad'},
{name: 'Bhadara (Rajasthan)'},
{name: 'Bhadrajun'},
{name: 'Bhadsoda'},
{name: 'Bhadthar'},
{name: 'Bhaibahan Nala'},
{name: 'Bhairamgarh'},
{name: 'Bhanpuri'},
{name: 'Bhara'},
{name: 'Bharanpur (Haryana)'},
{name: 'Bhatevar'},
{name: 'Bhavanipar'},
{name: 'Bhavnagar (jakatnaka)'},
{name: 'Bhayandar'},
{name: 'Bhayavadar (Gujarat)'},
{name: 'Bhel'},
{name: 'Bherod'},
{name: 'Bherod(Delhi)'},
{name: 'Bherunda'},
{name: 'Bhesada'},
{name: 'Bhigwan'},
{name: 'Bhikangoan'},
{name: 'Bhilkuva (Gujarat)'},
{name: 'Bhinder'},
{name: 'Bhiwani (Harayana)'},
{name: 'Bhiyana'},
{name: 'Bhognipur'},
{name: 'Bhojasar'},
{name: 'Bhorsi'},
{name: 'Bhuj (direct)'},
{name: 'Bhujpur'},
{name: 'Biaora (Madhya Pradesh)'},
{name: 'Bibi'},
{name: 'Bichhiya (Madhya Pradesh)'},
{name: 'Bidada'},
{name: 'Bidsar'},
{name: 'Beejadi (Karnataka)'},
{name: 'Bijolia'},
{name: 'Bijur (Madhya Pradesh)'},
{name: 'Bilimora'},
{name: 'Bilara (Rajasthan)'},
{name: 'Birmitrapur (Orissa)'},
{name: 'Binder'},
{name: 'Birpur'},
{name: 'Bisrasar'},
{name: 'Bita (Gujarat)'},
{name: 'Bodla (Uttar Pradesh)'},
{name: 'Bodwad'},
{name: 'Balangir(Orissa)'},
{name: 'Borada (Rajasthan)'},
{name: 'Borgaon'},
{name: 'Borigumma'},
{name: 'Burla'},
{name: 'Byawara'},
{name: 'Ch-peta'},
{name: 'Chakkarakal'},
{name: 'Challapalli'},
{name: 'Chamardi'},
{name: 'Chandawal (Rajasthan)'},
{name: 'Chandwad'},
{name: 'Chapar (Assam)'},
{name: 'Chapha'},
{name: 'Chapra (Madhya Pradesh)'},
{name: 'Charbhuja'},
{name: 'Charma'},
{name: 'Cheranmahadevi'},
{name: 'Chettiyapathu'},
{name: 'Chhapi'},
{name: 'Chhatral'},
{name: 'Chhattarpur'},
{name: 'Chhegaon Makhan'},
{name: 'Chhoti Sadri'},
{name: 'Chicola'},
{name: 'Chirawa (Rajasthan)'},
{name: 'Chikalthana (Aurangabad)'},
{name: 'Chikhali(bilimora)'},
{name: 'Chikhli (MH)'},
{name: 'Chiloda (Gujarat)'},
{name: 'Chilphi (Madhya Pradesh)'},
{name: 'Chilurpeta'},
{name: 'Chhindgarh (Chhattisgarh)'},
{name: 'Chindwada'},
{name: 'Chinnadharapuram'},
{name: 'Chirana'},
{name: 'Chirawa'},
{name: 'Chiroda'},
{name: 'Chital'},
{name: 'Chitrodi (Gujarat)'},
{name: 'Chittoor (kundapura Karnataka)'},
{name: 'Chogath Dhal'},
{name: 'Choki (Sorath)'},
{name: 'Chotisadri'},
{name: 'Chotiya'},
{name: 'Chourai (Madhya Pradesh)'},
{name: 'Chudapali'},
{name: 'Churu (Rajasthan)'},
{name: 'Chutiya (Maharastra)'},
{name: 'Cross Land'},
{name: 'Cudalore'},
{name: 'Cuddlore'},
{name: 'Dabra (Madhya Pradesh)'},
{name: 'Dabwali (Haryana)'},
{name: 'Dahisara (Gujarat)'},
{name: 'Damakheda'},
{name: 'Damanjodi'},
{name: 'Dantewada'},
{name: 'Dapodi'},
{name: 'Daramali'},
{name: 'Darba (Chhattisgarh)'},
{name: 'Darra'},
{name: 'Darshadi'},
{name: 'Darwha (Maharastra)'},
{name: 'Dasada (Gujarat)'},
{name: 'Datala'},
{name: 'Datia (Madhya Pradesh)'},
{name: 'Datrana'},
{name: 'Davalgaon Raja'},
{name: 'Debari (Rajasthan)'},
{name: 'Dechu (Rajasthan)'},
{name: 'Dediya (Rajasthan)'},
{name: 'Deharda (Madhya Pradesh)'},
{name: 'Delhi Domestic Airport'},
{name: 'Denge Chowk'},
{name: 'Deogarh (Orissa)'},
{name: 'Deoli (Maharastra)'},
{name: 'Deori'},
{name: 'Deshgaon(Madhya Pradesh)'},
{name: 'Deshnokh'},
{name: 'Devalgaon Mai'},
{name: 'Devalkunda'},
{name: 'Devariya'},
{name: 'Devarkulam'},
{name: 'Devkigalol'},
{name: 'Devli (Rajasthan)'},
{name: 'Devri (Rajasthan)'},
{name: 'Dhamdha (Chhattisgarh)'},
{name: 'Dhanaj (Maharastra)'},
{name: 'Dhanera (Gujarat)'},
{name: 'Dharmjaygarh (Chhattisgarh)'},
{name: 'Dharampur (Gujarat)'},
{name: 'Dharni (Madhya Pradesh)'},
{name: 'Dharwad Byepass'},
{name: 'Dhinoj'},
{name: 'Dhokadava'},
{name: 'Dhola (Gujarat)'},
{name: 'Dhaulpur (Rajasthan)'},
{name: 'Dhrangdhara'},
{name: 'Dhudhi'},
{name: 'Dhuma (Madhya Pradesh)'},
{name: 'Didvana'},
{name: 'Dipnagar'},
{name: 'Disha Chokdi'},
{name: 'Dokhdava'},
{name: 'Dondaecha'},
{name: 'Doopadakatte'},
{name: 'Dorimanna'},
{name: 'Dornapal'},
{name: 'Dosa'},
{name: 'Dosuiya'},
{name: 'Dubacherla'},
{name: 'Dudhi (Madhya Pradesh)'},
{name: 'Dungarpur (Rajasthan)'},
{name: 'Dungla (Rajasthan)'},
{name: 'Dusarbid'},
{name: 'Dwarka (darshan)'},
{name: 'Edalabad (Maharastra)'},
{name: 'Edrikode (kottakal)'},
{name: 'Edur'},
{name: 'Eklera (Madhya Pradesh)'},
{name: 'Elora'},
{name: 'Eral (Tamil Nadu)'},
{name: 'Eramalloor'},
{name: 'Ermal'},
{name: 'Erumapatti (Tamil Nadu)'},
{name: 'Essar(Maharashtra)'},
{name: 'Ettayapuram Bypass'},
{name: 'Falna'},
{name: 'Falodi'},
{name: 'Farasgaon'},
{name: 'Fatehgadh (Gujarat)'},
{name: 'Fedra'},
{name: 'Ferozpur'},
{name: 'Gabbur'},
{name: 'Gadchiroli (Maharashtra)'},
{name: 'Gadhshisha'},
{name: 'Gaduli'},
{name: 'Gandai (Chhattisgarh)'},
{name: 'Gangtok'},
{name: 'Ganpati Pule'},
{name: 'Ganpt-pkg'},
{name: 'Gariyadhar'},
{name: 'Garoth (Madhya Pradesh)'},
{name: 'Geedam'},
{name: 'Gevarai'},
{name: 'Ghaghdi'},
{name: 'Ghandhar (Gujarat)'},
{name: 'Ghatabillod (Madhya Pradesh)'},
{name: 'Ghatol'},
{name: 'Ghduli'},
{name: 'Ghodadongri'},
{name: 'Ghodagaon'},
{name: 'Ghugus (Maharashtra)'},
{name: 'Giddarwaha'},
{name: 'Gingla'},
{name: 'Girgathda'},
{name: 'Gobichettipalayam'},
{name: 'Godegaon'},
{name: 'Godegaon (fata)'},
{name: 'Godhra By Pass'},
{name: 'Goga Medi'},
{name: 'Gogava'},
{name: 'Golu Ka Mor'},
{name: 'Gomti Chauraha'},
{name: 'Gopalganj (Bihar)'},
{name: 'Goregaon (Raigad'},
{name: 'Gozaria'},
{name: 'Gumla'},
{name: 'Gummidipundi'},
{name: 'Gundya (Karnataka)'},
{name: 'Gwala (Rajasthan)'},
{name: 'Hadgaon (Maharastra)'},
{name: 'Hajipur (Bihar)'},
{name: 'Halekatte'},
{name: 'Haleyangadi'},
{name: 'Halol (Gujarat)'},
{name: 'Hanumangarh Jn'},
{name: 'Hanumasagar'},
{name: 'Harchowal'},
{name: 'Harda (Madhya Pradesh)'},
{name: 'Harij'},
{name: 'Hata (Uttar Pradesh)'},
{name: 'Hathgoan(bypass)'},
{name: 'Hatkanangale'},
{name: 'Hatkhamba'},
{name: 'Haveri Bypass'},
{name: 'Hazaribagh'},
{name: 'Herur'},
{name: 'Hili (West Bengal)'},
{name: 'Hindon'},
{name: 'Hinganghat'},
{name: 'Hiwara Bk (Hiwra Ashram)'},
{name: 'Hombadi'},
{name: 'Hosanagar'},
{name: 'Hosangabaad'},
{name: 'Hosmar'},
{name: 'Huvinahadagali'},
{name: 'Hyderabad Airport'},
{name: 'Icchapur'},
{name: 'Iit Pawai'},
{name: 'Ilayangudi (Tamil Nadu)'},
{name: 'Irbailu'},
{name: 'Itawa (Madhya Pradesh)'},
{name: 'Jabhuwa'},
{name: 'Jafrabad (Gujarat)'},
{name: 'Jagadapattinam'},
{name: 'Jagat'},
{name: 'Jagrawan'},
{name: 'Jaitaran'},
{name: 'Jaitpur (Rajasthan)'},
{name: 'Jakadevi (Ratnagiri'},
{name: 'Jalansar'},
{name: 'Jalavad'},
{name: 'Jamb'},
{name: 'Jamkandona'},
{name: 'Jamkhed'},
{name: 'Jamner (Maharashtra)'},
{name: 'Jamraval'},
{name: 'Jandiala'},
{name: 'Jaora'},
{name: 'Jasawant Nagar'},
{name: 'Jashpur (Chhattisgarh)'},
{name: 'Jasma'},
{name: 'Jasnagar (Rajastan)'},
{name: 'Jaspur (Uttarakhand)'},
{name: 'Jawara'},
{name: 'Jayal'},
{name: 'Jeypore (Orissa)'},
{name: 'Jhalap (Chhattisgarh)'},
{name: 'Jharsuguda'},
{name: 'Jhawara'},
{name: 'Jikiyali (Gujarat)'},
{name: 'Jogbani (Bihar)'},
{name: 'Jogeshwari'},
{name: 'Jorhat'},
{name: 'K R Nagar'},
{name: 'Kaaylpattinam'},
{name: 'Kada (Maharastra)'},
{name: 'Kadayanallur'},
{name: 'Kadi'},
{name: 'Kagwad (Karnataka)'},
{name: 'Kaikaati'},
{name: 'Kakodi'},
{name: 'Kaladi'},
{name: 'Kalaiyarkovil'},
{name: 'Kalakkad (Tamil Nadu)'},
{name: 'Kalamboli'},
{name: 'Kalandri (Rajasthan)'},
{name: 'Kalappanaickenpatti'},
{name: 'Kalavad'},
{name: 'Kalewadi Fata'},
{name: 'Kalikavu'},
{name: 'Kalol Chokdi'},
{name: 'Kalpa'},
{name: 'Kalwa (Maharastra)'},
{name: 'Kalyan(naka)'},
{name: 'Kamanayakkanpatti'},
{name: 'Kamargaon (Maharashtra)'},
{name: 'Kambada Koni'},
{name: 'Kamli Gath'},
{name: 'Kamliwada'},
{name: 'Kamothe'},
{name: 'Kamrej (gj)'},
{name: 'Kamudi'},
{name: 'Kanadukathan'},
{name: 'Kandalur'},
{name: 'Kandola(bypass)'},
{name: 'Kanker (Chhattisgarh)'},
{name: 'Kannod (Madhya Pradesh)'},
{name: 'Kansari (Gujarat)'},
{name: 'Kansur(sirsi)'},
{name: 'Karajgaon'},
{name: 'Karal (Maharashtra)'},
{name: 'Karanja Lad'},
{name: 'Karebailu'},
{name: 'Karera (Madhya Pradesh)'},
{name: 'Karoli (Haryana)'},
{name: 'Kartarpur'},
{name: 'Karur Byepass'},
{name: 'Kasegaon'},
{name: 'Katghora'},
{name: 'Katni (Madhya Pradesh)'},
{name: 'Kadape (Maharashtra)'},
{name: 'Kavalakki'},
{name: 'Kawardha'},
{name: 'Kazhugumalai'},
{name: 'Kekri (Rajasthan)'},
{name: 'Kelwara'},
{name: 'Kenchanuru'},
{name: 'Kenchiya (Rajasthan)'},
{name: 'Kenjoor'},
{name: 'Kenpura (Rajasthan)'},
{name: 'Kenwad'},
{name: 'Kerala Divine Centre'},
{name: 'Kerara'},
{name: 'Kerlapal'},
{name: 'Kerwadi (Karnataka)'},
{name: 'Kesargadde'},
{name: 'Kesariya (Gujarat)'},
{name: 'Kesavadasapuram'},
{name: 'Keshkal'},
{name: 'Kesinga (Odisha)'},
{name: 'Keskal'},
{name: 'Keshlur (Chhattisgarh)'},
{name: 'Khadadhar'},
{name: 'Khadki'},
{name: 'Khalghat (Madhya Pradesh)'},
{name: 'Khandwa Bypass'},
{name: 'Khapoli'},
{name: 'Khargone (Bareli'},
{name: 'Khawasa'},
{name: 'Kheralu'},
{name: 'Khervada (Gujarat)'},
{name: 'Khetadi(coper)'},
{name: 'Khetiya'},
{name: 'Khimmat'},
{name: 'Khivsar'},
{name: 'Khiwara'},
{name: 'Khodiyar(moti)'},
{name: 'Khur (Rajasthan)'},
{name: 'Kilepal'},
{name: 'Kim'},
{name: 'Kir Ki Chowki'},
{name: 'Kirimanjeshwara'},
{name: 'Kishanganj (Bihar)'},
{name: 'Kishangarh By Pass'},
{name: 'Kochi'},
{name: 'Kodali (Andhra Pradesh)'},
{name: 'Kolkata (easplanade)'},
{name: 'Kollapur (Telangana)'},
{name: 'Kollemcode'},
{name: 'Kolluru(Andhra Pradesh)'},
{name: 'Kombai'},
{name: 'Kondagaon (Chhattisgarh)'},
{name: 'Kondhali (Maharastra)'},
{name: 'Koni (Karnataka)'},
{name: 'Kopargaon'},
{name: 'Kopar Khairane'},
{name: 'Koshithal'},
{name: 'Kota udupi Dist'},
{name: 'Kotda'},
{name: 'Kotada Jadodar'},
{name: 'Kothacheruvu'},
{name: 'Kotkapura'},
{name: 'Kottagudem'},
{name: 'Kovilpatti Byepass'},
{name: 'Kr Puram'},
{name: 'Krishnan Kovil'},
{name: 'Krishnapuram (Rajapalayam)'},
{name: 'Kuanriya'},
{name: 'Kudankulam'},
{name: 'Kudush'},
{name: 'Kukavav'},
{name: 'Kukma'},
{name: 'Kullu Mandi'},
{name: 'Kulur Panambur'},
{name: 'Kumata'},
{name: 'Kumbakonam By Pass'},
{name: 'Kumble'},
{name: 'Kumbrugodu'},
{name: 'Kunchawada'},
{name: 'Kundara (Kerala)'},
{name: 'Kunkuri'},
{name: 'Kurabad'},
{name: 'Kurinchakkulam'},
{name: 'Kurla'},
{name: 'Kurud'},
{name: 'Kushinagar (Uttar Pradesh)'},
{name: 'Kuthanallore'},
{name: 'Kutiyana(bypass)'},
{name: 'Kuttikanam'},
{name: 'Kuwadva'},
{name: 'Kuwkriya'},
{name: 'Kuziparai'},
{name: 'Ladno'},
{name: 'Ladpura (Rajasthan)'},
{name: 'Lakadiya'},
{name: 'Lakhnadon'},
{name: 'Lakhni (Maharashtra)'},
{name: 'Lakkidi (Kunnathidavaka'},
{name: 'Lalbarra'},
{name: 'Lalgadhchawani'},
{name: 'Lamba (Gujarat)'},
{name: 'Lanjoda'},
{name: 'Lathi (Gujarat)'},
{name: 'Laukaha (Bihar)'},
{name: 'Lavale Phata (Maharastra)'},
{name: 'Lavasa'},
{name: 'Laxetipet'},
{name: 'Laxmangadh (Gujarat)'},
{name: 'Lebad (Madhya Pradesh)'},
{name: 'Leh (Jammu and Kashmir)'},
{name: 'Limbadi Jalod'},
{name: 'Lohara (Maharashtra)'},
{name: 'Lucknow (kesharbagh)'},
{name: 'Ludek'},
{name: 'Lukwasa'},
{name: 'Lunawada (Gujarat)'},
{name: 'Lunawada By Pass'},
{name: 'Maanvat'},
{name: 'Madhavpur(gad)'},
{name: 'Madhubani (Bihar)'},
{name: 'Mahableshwar Panchagani'},
{name: 'Mahalaxmi'},
{name: 'Mahua (Bihar)'},
{name: 'Mahurgad (Maharastra)'},
{name: 'Maihar'},
{name: 'Majarsubha'},
{name: 'Majera (Rajasthan)'},
{name: 'Majevdi'},
{name: 'Makarana'},
{name: 'Makhiyala'},
{name: 'Malakoli'},
{name: 'Malanjkhand'},
{name: 'Malegaon (Dhulia)'},
{name: 'Malegaon (Washim)'},
{name: 'Malegaon Bypass'},
{name: 'Malgund (Maharastra)'},
{name: 'Malhargarh'},
{name: 'Maliya (Gujarat)'},
{name: 'Malkangiri (Orissa)'},
{name: 'Malkapur (buldhana)'},
{name: 'Malkapur Bypass'},
{name: 'Mallappally (Kerala)'},
{name: 'Mallapura (Karnataka)'},
{name: 'Mallapuram (Tamil Nadu)'},
{name: 'Mallipattanam'},
{name: 'Malpur (Gujarat)'},
{name: 'Malpur By Pass'},
{name: 'Mana Madurai'},
{name: 'Manarkkad'},
{name: 'Manasa (Madhya Pradesh)'},
{name: 'Manchakal (Shirva)'},
{name: 'Mandal (Gujarat)'},
{name: 'Mandar (Rajasthan)'},
{name: 'Mandarakuppam'},
{name: 'Mandiya (Uttar Pradesh)'},
{name: 'Mandla (Madhya Pradesh)'},
{name: 'Mangarsupa'},
{name: 'Mangliawas'},
{name: 'Mani (Karnataka)'},
{name: 'Mani Nagar (Tamil Nadu)'},
{name: 'Manjarpalke'},
{name: 'Manki Mavinkatte (Karnataka)'},
{name: 'Manoor (Karnataka)'},
{name: 'Manpur (Madhya Pradesh)'},
{name: 'Mansar (Maharastra)'},
{name: 'Mansur (Karnataka)'},
{name: 'Maruthappapuram'},
{name: 'Masoor (Karnataka)'},
{name: 'Mattannur'},
{name: 'Mauda (Maharashtra)'},
{name: 'Mazalgaon'},
{name: 'Medipally'},
{name: 'Meghpar'},
{name: 'Megnanapuram'},
{name: 'Mehdipur Balaji'},
{name: 'Menar (Rajasthan)'},
{name: 'Merath'},
{name: 'Mhow Bypass'},
{name: 'Modasa By Pass'},
{name: 'Modinagar'},
{name: 'Mohala (Maharashtra)'},
{name: 'Mohankheda'},
{name: 'Mohanur'},
{name: 'Molakala Cheruvu'},
{name: 'Moodubelle'},
{name: 'Mopidevi'},
{name: 'Morbi (highway)'},
{name: 'Morga (Chhattisgarh)'},
{name: 'Mortad'},
{name: 'Motala (Buldhana Dist'},
{name: 'Motara'},
{name: 'Motla Kalan (Haryana)'},
{name: 'Motu (Orissa)'},
{name: 'Moulasar'},
{name: 'Movattupuzha'},
{name: 'Movva'},
{name: 'Mudgal (Karnataka)'},
{name: 'Mukaani'},
{name: 'Mukandgarh'},
{name: 'Mukerian'},
{name: 'Mukundgarh'},
{name: 'Mulamthuruthy'},
{name: 'Mulanur'},
{name: 'Mulbagal'},
{name: 'Mullugudde'},
{name: 'Multai'},
{name: 'Mundrigi'},
{name: 'Mungali (Maharashtra)'},
{name: 'Murena'},
{name: 'Murga'},
{name: 'Murickassery'},
{name: 'Murrena'},
{name: 'Murudeshwar'},
{name: 'Muzaffarpur (Bihar)'},
{name: 'Muzffarnagar'},
{name: 'MV79 (Lachipeta'},
{name: 'Mwar-pkg'},
{name: 'N.g.o Quarters Clt'},
{name: 'Nabarangpur (Orissa)'},
{name: 'Nachandpatti'},
{name: 'Nadi Mod'},
{name: 'Nadol'},
{name: 'Nadura'},
{name: 'Nagalapuram (Andhra Pradesh)'},
{name: 'Nagarkoil'},
{name: 'Nagaur'},
{name: 'Nagoor (Karnataka)'},
{name: 'Nagur (Karanataka)'},
{name: 'Naihati'},
{name: 'Nainpur (Madhya Pradesh)'},
{name: 'Nambuthalai'},
{name: 'Namtola'},
{name: 'Nandasan'},
{name: 'Naneghat'},
{name: 'Narasannapeta'},
{name: 'Narayanganj (Madhya pradesh)'},
{name: 'Naredi (Gujarat)'},
{name: 'Nari Chowkdi'},
{name: 'Narsinghgarh (Madhya Pradesh)'},
{name: 'Nashik Fata'},
{name: 'Nathdwara (Shrinathji)'},
{name: 'Navagadh'},
{name: 'Navagam Dhal'},
{name: 'Navalgadh (Gujarat)'},
{name: 'Navapur (Maharashtra)'},
{name: 'Navsari By Pass'},
{name: 'Nawanshahr'},
{name: 'Nawarangpur'},
{name: 'Nayakanakatte'},
{name: 'Nedra'},
{name: 'Needamanglam'},
{name: 'Nelamangala (Karnataka)'},
{name: 'Nellikatte'},
{name: 'Nemathanpatti'},
{name: 'Nerkuppai'},
{name: 'Netra (Gujarat)'},
{name: 'Newai'},
{name: 'Newasa (Maharastra)'},
{name: 'Neyveli (Mantharakuppam)'},
{name: 'Nigadi'},
{name: 'Nimbada'},
{name: 'Nimbahera'},
{name: 'Nimgaon Jali'},
{name: 'Nirmal (bypass)'},
{name: 'Nirmali (Bihar)'},
{name: 'Nittur (Shimoga)'},
{name: 'Nivli (Karnataka)'},
{name: 'Nori Chowkdi'},
{name: 'Oddanchatram'},
{name: 'Ola (Telangana)'},
{name: 'Omkareshwar'},
{name: 'Oraiya'},
{name: 'Oras (Maharastra)'},
{name: 'Osia (Uttar Pradesh)'},
{name: 'Othera (Kerala)'},
{name: 'Ottapidaram'},
{name: 'P Velur'},
{name: 'Paatur'},
{name: 'Pachod (Aurangabad'},
{name: 'Pachore (Madhya Pradesh)'},
{name: 'Pachapachiya'},
{name: 'Padampur (Rajasthan)'},
{name: 'Paddhari'},
{name: 'Padukkapathu (Tuticorin)'},
{name: 'Pakela'},
{name: 'Palayakkayal'},
{name: 'Palghat (bye Pass)'},
{name: 'Pali (mah.)'},
{name: 'Palia'},
{name: 'Pallakkad Bye Pass'},
{name: 'Pallapatti'},
{name: 'Pallipalayam'},
{name: 'Paloda'},
{name: 'Palsana (Rajasthan)'},
{name: 'Panamboor Kuloor'},
{name: 'Panavadali Chatram'},
{name: 'Panayapatti'},
{name: 'Pandalgudi'},
{name: 'Pandarkavda'},
{name: 'Pandariya (Chhattisgarh)'},
{name: 'Pandharkawada'},
{name: 'Pandhro'},
{name: 'Pandhurna (Madhya Pradesh)'},
{name: 'Panna (Madhya Pradesh)'},
{name: 'Pansemal'},
{name: 'Papanad'},
{name: 'Pappireddipatti'},
{name: 'Paramankurichi'},
{name: 'Paramathi'},
{name: 'Paratwada (Maharashtra)'},
{name: 'Paratwada (achalpur)'},
{name: 'Parinthamanna'},
{name: 'Parla (Odisha)'},
{name: 'Parlakhemundi'},
{name: 'Parle'},
{name: 'Parli'},
{name: 'Parsad (Rajasthan)'},
{name: 'Partapur (Rajasthan)'},
{name: 'Parthipadu'},
{name: 'Parvatsar'},
{name: 'Pasuvanthanai'},
{name: 'Patangi'},
{name: 'Patewa (Chhattisgarh)'},
{name: 'Pathalgaon'},
{name: 'Pathamadai'},
{name: 'Patna (Bihar)'},
{name: 'Patnadhal'},
{name: 'Patnagarh'},
{name: 'Patoda (Maharashtra)'},
{name: 'Pattom (Kerala)'},
{name: 'Pattukotai'},
{name: 'Pavithiram'},
{name: 'Pavoorchatram'},
{name: 'Pavur Chattram'},
{name: 'Pehroad'},
{name: 'Pendhurthi'},
{name: 'Pepli'},
{name: 'Peravurani'},
{name: 'Petlawad'},
{name: 'Phillaur'},
{name: 'Phondaghat'},
{name: 'Phulparas'},
{name: 'Pilibanga'},
{name: 'Pillarkhana'},
{name: 'Pimpri'},
{name: 'Pinwara'},
{name: 'Pipavav Chokdi'},
{name: 'Pipavav (port)'},
{name: 'Pipliya'},
{name: 'Pipliya Mandi'},
{name: 'Pithampur (Madhya Pradesh)'},
{name: 'Pithora (Chhattisgarh)'},
{name: 'Pitol'},
{name: 'Podi (Chhattisgarh)'},
{name: 'Pohari'},
{name: 'Pokran'},
{name: 'Poladpur'},
{name: 'Poothotta'},
{name: 'Pothakalan Vilai'},
{name: 'Potla'},
{name: 'Pottalpudur'},
{name: 'Power House'},
{name: 'Ppuliyampatti'},
{name: 'Prasad'},
{name: 'Pratapgarh (Uttar Pradesh)'},
{name: 'Pratapur (Bihar)'},
{name: 'Pudhansanthai'},
{name: 'Pudhur'},
{name: 'Puranpur (Uttar Pradesh)'},
{name: 'Purnia (Bihar)'},
{name: 'Puthiamputhur'},
{name: 'Puttaparthi'},
{name: 'Qadian'},
{name: 'Radhanpur(Gujarat)'},
{name: 'Rahta(Gujarat)'},
{name: 'Rahuri'},
{name: 'Raigarh (Chhattisgarh)'},
{name: 'Rajgadh'},
{name: 'Rajnagar (Rajasthan)'},
{name: 'Rajnand Gav'},
{name: 'Rajpur (Gujarat)'},
{name: 'Rajpura (Punjab)'},
{name: 'Rama Mandi'},
{name: 'Ramanuganj'},
{name: 'Ramjigor'},
{name: 'Ramnagar (Uttar Pradesh)'},
{name: 'Rampara (Gujarat)'},
{name: 'Ramsin'},
{name: 'Raniganj (West Bengal)'},
{name: 'Ranjangaon'},
{name: 'Ranka (Jharkhand)'},
{name: 'Ranoli (Rajasthan)'},
{name: 'Rapar (Gujarat)'},
{name: 'Rasingapuram'},
{name: 'Ratdi'},
{name: 'Rattalli'},
{name: 'Rau (Madhya Pradesh)'},
{name: 'Rawapar (Gujarat)'},
{name: 'Rawatbhata'},
{name: 'Reddiarpatti'},
{name: 'Reliance (khavdi )'},
{name: 'Revas (Gujarat)'},
{name: 'Revdar (Rajasthan)'},
{name: 'Ribda'},
{name: 'Rippinapete'},
{name: 'Rs Mangalam'},
{name: 'Runi (Gujarat)'},
{name: 'S.p.pattinam'},
{name: 'Sabla (Rajasthan)'},
{name: 'Sadri (Rajasthan)'},
{name: 'Sadulshahar'},
{name: 'Sagwada (Gujarat)'},
{name: 'Sahada (Rajasthan)'},
{name: 'Sahajapura'},
{name: 'Sahar'},
{name: 'Sahebganj (Bihar)'},
{name: 'Saidapur (Karnataka)'},
{name: 'Sakada (Chhattisgarh)'},
{name: 'Sakari (Maharashtra)'},
{name: 'Sakol'},
{name: 'Sakoli (Maharashtra)'},
{name: 'Shaktinagar (Uttar Pradesh)'},
{name: 'Salakudi'},
{name: 'Salasar'},
{name: 'Salewada (Madhya Pradesh)'},
{name: 'Salla (Uttarakhand)'},
{name: 'Salumbar'},
{name: 'Samakhiali'},
{name: 'Samatra'},
{name: 'Sami (Gujarat)'},
{name: 'Samlaji By Pass'},
{name: 'Sanand'},
{name: 'Sanawad (Madhya Pradesh)'},
{name: 'Sangagiri'},
{name: 'Sanganer (Rajasthan)'},
{name: 'Sangariya (Rajasthan)'},
{name: 'Sangavi Fata'},
{name: 'Sangrur (Punjab)'},
{name: 'Sankara (Chattisgarh)'},
{name: 'Sankarankovil'},
{name: 'Santacruz'},
{name: 'Santhekatte (hebri)'},
{name: 'Santhekatte (kalyanpura)'},
{name: 'Saoner'},
{name: 'Saraipali (Chhattisgarh)'},
{name: 'Sarangarh'},
{name: 'Sardargadh (Gujarat)'},
{name: 'Sardulgarh'},
{name: 'Sarekha (Madhya Pradesh)'},
{name: 'Sargaon (Chhattisgarh)'},
{name: 'Sarwad (Maharashtra)'},
{name: 'Sarwan (Madhya Pradesh)'},
{name: 'Satana (Maharashtra)'},
{name: 'Sathur'},
{name: 'Satlasana'},
{name: 'Satodad'},
{name: 'Sattur Bye Pass'},
{name: 'Sausar'},
{name: 'Savada'},
{name: 'Savarde (Maharashtra)'},
{name: 'Sawaryia'},
{name: 'Sayalkudi'},
{name: 'Sayan'},
{name: 'Scencottai'},
{name: 'Sehore (Madhya Pradesh)'},
{name: 'Selana'},
{name: 'Selu Bazar'},
{name: 'Senthamangalam'},
{name: 'Seoni (Madhya Pradesh)'},
{name: 'Serenthamaram'},
{name: 'Sethubavachatram'},
{name: 'Shahada (Maharastra)'},
{name: 'Shahgadh (Maharashtra)'},
{name: 'Shahjanpur'},
{name: 'Shajapur'},
{name: 'Shankarpura (Karnataka)'},
{name: 'Shankwali'},
{name: 'Shanmuganallur'},
{name: 'Shanmugapuram (Tamil Nadu)'},
{name: 'Shapura'},
{name: 'Shawa'},
{name: 'Shelapur (Buldhana)'},
{name: 'Shendawa'},
{name: 'Sheshpur (Rajasthan)'},
{name: 'Shikhrapur (Maharashtra)'},
{name: 'Shindkheda'},
{name: 'Shiraguppi'},
{name: 'Shirur Tajband'},
{name: 'Shirur (Anantpal)'},
{name: 'Shivamoga'},
{name: 'Shivni (Maharashtra)'},
{name: 'Shivpori'},
{name: 'Shree Balaji'},
{name: 'Shree Dungargadh'},
{name: 'Shri Ganga Nagar'},
{name: 'Shri Hargobindpur'},
{name: 'Shrirampur (Ahmednagar)'},
{name: 'Shyamalji'},
{name: 'Shamgarh (Madhya Pradesh)'},
{name: 'Siddapura (kpr)'},
{name: 'Sidhpur (Himachal Pradesh)'},
{name: 'Sijora'},
{name: 'Sikohabad'},
{name: 'Sikka Patia'},
{name: 'Silamalai'},
{name: 'Simga'},
{name: 'Similiguda'},
{name: 'Sindanur'},
{name: 'Sindari'},
{name: 'Sindhari'},
{name: 'Sindhgarh'},
{name: 'Sindkhedraja'},
{name: 'Singampunari'},
{name: 'Sirsa (UP)'},
{name: 'Siru Nadar Kudiyiruppu'},
{name: 'Siwan (Bihar)'},
{name: 'Sonarpal'},
{name: 'Sonepur (Orissa)'},
{name: 'Songadh'},
{name: 'Songir (Maharastra)'},
{name: 'Sonipat'},
{name: 'Sonkhed (Nanded)'},
{name: 'Sppattanam'},
{name: 'Sri Ganganagar'},
{name: 'Srirengam'},
{name: 'Subhas Nagar'},
{name: 'Subrahmanya'},
{name: 'Sukhpur (Uttar Pradesh)'},
{name: 'Sukma'},
{name: 'Sulur'},
{name: 'Sunabeda'},
{name: 'Sundankottai'},
{name: 'Sunki (Odisha)'},
{name: 'Supedi'},
{name: 'Surajpar'},
{name: 'Surandai'},
{name: 'Suratgarh (Rajasthan)'},
{name: 'Surva'},
{name: 'Suyat'},
{name: 'Swargate'},
{name: 'Swaroopganj'},
{name: 'T Pet'},
{name: 'Takhtgarh'},
{name: 'Talaguppa'},
{name: 'Talcher'},
{name: 'Talegaon (ngp)'},
{name: 'Talere (Maharashtra)'},
{name: 'Talippa Ramba'},
{name: 'Tallur (Karnataka)'},
{name: 'Talwada (Maharastra)'},
{name: 'Talwandi Chowk'},
{name: 'Tankariya'},
{name: 'Tara (Chhattisgarh)'},
{name: 'Tavaragere (Karnataka)'},
{name: 'Tekanpur (Madhya Pradesh)'},
{name: 'Tekkatte'},
{name: 'Tembinaka'},
{name: 'Tera (Gujarat)'},
{name: 'Thakkali Mukku'},
{name: 'Thaktpur'},
{name: 'Thalanchivilai'},
{name: 'Thalyolaparampa'},
{name: 'Thanakallu'},
{name: 'Thandavankadu'},
{name: 'Thandla'},
{name: 'Thane Bye Pass'},
{name: 'Thapettai'},
{name: 'Tharad'},
{name: 'Thathangar Pettai'},
{name: 'Thawla'},
{name: 'Thevaram'},
{name: 'Thibedi'},
{name: 'Thikari (Madhya Pradesh)'},
{name: 'Thindal'},
{name: 'Thirunagar'},
{name: 'Thiruthuraipoondi'},
{name: 'Thiruvadanai Kaikatti'},
{name: 'Thiruvengadam'},
{name: 'Thiruverumbur'},
{name: 'Thodambail'},
{name: 'Thripunthura'},
{name: 'Thudiyalur'},
{name: 'Thumakur'},
{name: 'Thumdi'},
{name: 'Thumdi (a.b.g)'},
{name: 'Thuvarangurichi'},
{name: 'Tikamgarh'},
{name: 'Tiruchitrampalam'},
{name: 'Tirukadaiyur'},
{name: 'Tiruvarur'},
{name: 'Tokapal'},
{name: 'Toliyasar'},
{name: 'Tonk'},
{name: 'Tonkapal'},
{name: 'Toranagallu(jindal)'},
{name: 'Tp Gudem'},
{name: 'Trikkaripur'},
{name: 'Trissnappilly'},
{name: 'Tumgaon (Chhattisgarh)'},
{name: 'Tural (Maharashtra)'},
{name: 'Thuvakudi'},
{name: 'Udaipurwati'},
{name: 'Ukkirankottai'},
{name: 'Umbri (Maharashtra)'},
{name: 'Umerga(Gujarat)'},
{name: 'Umerkote (Orissa)'},
{name: 'Umranala'},
{name: 'Un (Madhya Pradesh)'},
{name: 'Unai (Gujarat)'},
{name: 'Undri (Buldana)'},
{name: 'Unna'},
{name: 'Uoorvambu'},
{name: 'Uppunda'},
{name: 'Uran'},
{name: 'Uruli Kanchan'},
{name: 'Uthangarai'},
{name: 'Uthumalai'},
{name: 'Utichipuli'},
{name: 'Uvari'},
{name: 'Vada'},
{name: 'Vadachery'},
{name: 'VadgaonPan (Maharashtra)'},
{name: 'Vadlapudi'},
{name: 'Vadnagar'},
{name: 'Vadvadiya'},
{name: 'Vai Fata'},
{name: 'Vaishali (Bihar)'},
{name: 'Vajdi'},
{name: 'Vakaner'},
{name: 'Vakiya'},
{name: 'Valasana'},
{name: 'Vallabhipur'},
{name: 'Vallam'},
{name: 'Valliyur Byepass'},
{name: 'Valsad By Pass'},
{name: 'Vana Tirupathi'},
{name: 'Vanaramutti'},
{name: 'Vannicondendal'},
{name: 'Vansada (Gujarat)'},
{name: 'Vapi By Pass'},
{name: 'Varora'},
{name: 'Vashakulam'},
{name: 'Vasmat'},
{name: 'Vathar'},
{name: 'Veerapandipirive'},
{name: 'Veerasigamani'},
{name: 'Vekra'},
{name: 'Velagoundanpatti'},
{name: 'Velayuthampalayam'},
{name: 'Vella Kovil'},
{name: 'Vembar'},
{name: 'Vengurla-shiroda'},
{name: 'Veppankadu'},
{name: 'Vijaynagar(Karnataka)'},
{name: 'Vikhroli'},
{name: 'Vileparle'},
{name: 'Villathikulam'},
{name: 'Virar'},
{name: 'Virudachalam'},
{name: 'Virudunagar ByePass'},
{name: 'Viruthachalam'},
{name: 'Vizhakapatnam'},
{name: 'Vridhachalam'},
{name: 'Wadgaon'},
{name: 'Walandi'},
{name: 'Waranga Phata (Maharashtra)'},
{name: 'Yadgir'},
{name: 'Yalamanchilli'},
{name: 'Yavat'},
{name: 'Yawat Phatak'},
{name: 'Yermal Thenka'},
{name: 'Yeshwanthapura'},
{name: 'Yevla'},
{name: 'Zaribori'},
{name: 'Toranagallu (JSW)'},
{name: 'Bhatnavilli'},
{name: 'Chichola (Chhattisgarh)'},
{name: 'Chilamkur'},
{name: 'Aghara'},
{name: 'Perinjanam'},
{name: 'Haathkhamba'},
{name: 'Ooty S S'},
{name: 'Potta (Chalakkudy)'},
{name: 'South Goa'},
{name: 'Valankani'},
{name: 'Parvathipuram'},
{name: 'Vijayanagaram(Bye Pass)'},
{name: 'Vemuru'},
{name: 'Vellatur (Kadapa'},
{name: 'Battiprolu'},
{name: 'Kapra'},
{name: 'Jampani (Andhra Pradesh)'},
{name: 'Ahmedabad (Naranpura samaj)'},
{name: 'Bharapar'},
{name: 'Bhilada'},
{name: 'C.B.D Belapur'},
{name: 'Desalpar(Nakhatrana)'},
{name: 'Godhra (Ambe Dham)'},
{name: 'Gondal (By Pass)'},
{name: 'Jindal (Mundra)'},
{name: 'kalamboli (Panvel)'},
{name: 'Karamsad'},
{name: 'Karjan (Gujarat)'},
{name: 'Khakhar (Gujarat)'},
{name: 'KhakhBai'},
{name: 'Khambhaliya'},
{name: 'Khavadi'},
{name: 'Kodaipul'},
{name: 'Kolaras'},
{name: 'Kosamba (Gujarat)'},
{name: 'Kotdi (Gujarat)'},
{name: 'Krushnanagar (Gujarat)'},
{name: 'Kudus (Maharashtra)'},
{name: 'Lalpur (Jamnagar Dist'},
{name: 'MalasaPatia'},
{name: 'Mandvi (Tapi)'},
{name: 'Maul'},
{name: 'Mudeti Patia'},
{name: 'Netramali'},
{name: 'Nimdaheda'},
{name: 'Pathardi (Maharashtra)'},
{name: 'Pratij'},
{name: 'Ramod (Gujarat)'},
{name: 'Ranakandola'},
{name: 'Ruthiyai'},
{name: 'Salal'},
{name: 'Salaya'},
{name: 'Shirva (Karnataka)'},
{name: 'Sion'},
{name: 'Songarh'},
{name: 'Tejpura (Gujarat)'},
{name: 'Timbi'},
{name: 'Tisgaon'},
{name: 'Vele (Maharastra)'},
{name: 'Narsapuram (Bye-Pass)'},
{name: 'Jindal(Toranagallu)'},
{name: 'Alamuru (West Godavari)'},
{name: 'Aatthur'},
{name: 'Angamali'},
{name: 'Daudnagar (Bihar)'},
{name: 'Dehri On Sone'},
{name: 'Jamshedpur Tata'},
{name: 'Kulasekarapattinam'},
{name: 'Laheriasarai'},
{name: 'Naugachia'},
{name: 'Perunthalmana'},
{name: 'Puliankudi'},
{name: 'Puliyankudi'},
{name: 'Pune Nigdi'},
{name: 'Tada Bypass'},
{name: 'Virudunagar'},
{name: 'Chenkalpet'},
{name: 'Melmaruvathur'},
{name: 'Harihara Byepass'},
{name: 'Namakal Byepass'},
{name: 'Palakat'},
{name: 'Pune Katrej'},
{name: 'Aagayamandapam'},
{name: 'Arasarkulam'},
{name: 'Kadalur'},
{name: 'Kandaramanikam'},
{name: 'Kaveripattanam'},
{name: 'Madhagupatti'},
{name: 'Tirupattur Pudukottai'},
{name: 'Tirupatur(via Pudukottai)'},
{name: 'Chittorgarh'},
{name: 'Deoplgaon Raja'},
{name: 'Gangakher'},
{name: 'Malegaon (Nasik)'},
{name: 'Srirampur (West Bengal)'},
{name: 'Washim Malegaon'},
{name: 'Bargi (Madhya Pradesh)'},
{name: 'Araria (Bihar)'},
{name: 'Saraigarh'},
{name: 'Supaul'},
{name: 'Tribeniganj'},
{name: 'Aarni (Rajasthan)'},
{name: 'Pandarkura'},
{name: 'Umarkher'},
{name: 'Gollaprolu'},
{name: 'Achampeta Junc (KKD)'},
{name: 'Thimmapuram'},
{name: 'Chitrada (Andhra Pradesh)'},
{name: 'Karnal (Haryana)'},
{name: 'Pipli (Haryana)'},
{name: 'Phalodi (Rajasthan)'},
{name: 'Osian (Rajasthan)'},
{name: 'Balajimod'},
{name: 'Pachpadra'},
{name: 'Bmr'},
{name: 'Paal (Maharashtra)'},
{name: 'Boranada'},
{name: 'Bhandu (Gujarat)'},
{name: 'Doly'},
{name: 'Kalyanpur (Gujarat)'},
{name: 'Sarvadi'},
{name: 'Tapra (Rajasthan)'},
{name: 'Sindhri'},
{name: 'Payala Kalan'},
{name: 'Sada (Barmer Dist'},
{name: 'Bhatala (Rajasthan)'},
{name: 'Jhali Khera'},
{name: 'Unri'},
{name: 'Gudamalani (Rajasthan)'},
{name: 'Ramji Gol'},
{name: 'Ghandawa (Rajasthan)'},
{name: 'Sivara'},
{name: 'Nenva Gaon'},
{name: 'Unja'},
{name: 'Sabarmati'},
{name: 'Vadaj'},
{name: 'E Tax'},
{name: 'Luharu (Rajasthan)'},
{name: 'Balaji Mandir'},
{name: 'Kumher'},
{name: 'Deeg (Rajasthan)'},
{name: 'Kaman(Rajastan)'},
{name: 'Kaithal (Haryana)'},
{name: 'Hanumangadh (Gujarat)'},
{name: 'Anoopgarh (Rajasthan)'},
{name: 'Tohana'},
{name: 'Merrut'},
{name: 'Saharanpur'},
{name: 'Rohit'},
{name: 'Gundoj'},
{name: 'Kirva'},
{name: 'Bali (Rajasthan)'},
{name: 'Mundara (Rajasthan)'},
{name: 'Ranakpur'},
{name: 'Padrara (Rajasthan)'},
{name: 'Tharpal'},
{name: 'Jaswantgarh'},
{name: 'Eswal'},
{name: 'Narwana (Haryana)'},
{name: 'Jirakpur'},
{name: 'Nagaur (Rajasthan)'},
{name: 'Sujangarh (Rajasthan)'},
{name: 'Sanganer Airport'},
{name: 'Ambala Cantt (Haryana)'},
{name: 'Amritsir'},
{name: 'Pipali (Gujarat)'},
{name: 'Shahbad (Haryana)'},
{name: 'Ambala City'},
{name: 'Sirhand'},
{name: 'Govindgarh (Rajasthan)'},
{name: 'Fagwada'},
{name: 'Patankot'},
{name: 'Behror (Rajasthan)'},
{name: 'Gajrola'},
{name: 'Takurhar'},
{name: 'Menpuri'},
{name: 'Kannoj'},
{name: 'Hairas'},
{name: 'Sikndrau'},
{name: 'Soroji'},
{name: 'Meruth'},
{name: 'Mujjfarnagar'},
{name: 'Bandar Sindhari'},
{name: 'Thawala'},
{name: 'Tehla'},
{name: 'Dodiyana'},
{name: 'Padu Kalan'},
{name: 'Lampolai'},
{name: 'Merta City'},
{name: 'Ren'},
{name: 'Butati'},
{name: 'Sarwar (Rajasthan)'},
{name: 'Ekhlera'},
{name: 'Gurgoun'},
{name: 'Tijara'},
{name: 'Bhiwadi (Rajasthan)'},
{name: 'Hindaun'},
{name: 'Kishangarh Bas'},
{name: 'Swai Madhopur'},
{name: 'Khairthal'},
{name: 'Bandikui Mod'},
{name: 'Chokarwada'},
{name: 'Halaina'},
{name: 'Dehramod'},
{name: 'Rarah (Rajasthan)'},
{name: 'Up Border'},
{name: 'Jajampatti'},
{name: 'Iglash'},
{name: 'Achrol'},
{name: 'Chandwaji'},
{name: 'Manoharpur (Rajasthan)'},
{name: 'Pawta'},
{name: 'Nimrana Mod'},
{name: 'Shahjahanpur (Uttar Pradesh)'},
{name: 'Haryanaboder'},
{name: 'Bawal (Haryana)'},
{name: 'Mandor (Rajasthan)'},
{name: 'Bawri'},
{name: 'Khedapa'},
{name: 'Ratari'},
{name: 'Soyla (Rajasthan)'},
{name: 'Khivser'},
{name: 'Bhakrod'},
{name: 'Kharnal (Rajasthan)'},
{name: 'Bhadana (Rajasthan)'},
{name: 'Deh (Rajasthan)'},
{name: 'Burdi (Rajasthan)'},
{name: 'Jhadeli'},
{name: 'Nimbora'},
{name: 'Surpaliya'},
{name: 'Khardiya (Rajasthan)'},
{name: 'Kanuta'},
{name: 'Hudas'},
{name: 'Bharnath'},
{name: 'Jadiya (Gujarat)'},
{name: 'Barwa Ki Dhani'},
{name: 'Nimbi Jodha'},
{name: 'Jajod'},
{name: 'Singhana'},
{name: 'Raj. Bordar Jhunjhunu'},
{name: 'Hariyana Border'},
{name: 'Narnoul'},
{name: 'Rewadi'},
{name: 'Delhi Bordar'},
{name: 'Malpura (Rajasthan)'},
{name: 'Tarnau'},
{name: 'Dewali (Rajasthan)'},
{name: 'Dehgaon (Madhya Pradesh)'},
{name: 'Rakhiyal'},
{name: 'Harsol (Gujarat)'},
{name: 'Badagaon (Madhya Pradesh)'},
{name: 'Dhansura (Gujarat)'},
{name: 'Gujrat Border'},
{name: 'Tidi (Rajasthan)'},
{name: 'Kalashpuri (Udaipur'},
{name: 'Kuariya'},
{name: 'Kuraj'},
{name: 'Bhujaas'},
{name: 'Karoie'},
{name: 'Pur (Rajasthan)'},
{name: 'Kuchera(Rajastan)'},
{name: 'Transport Nagar Jaipur'},
{name: 'Lalsot'},
{name: 'Karauli (Rajasthan)'},
{name: 'Jhadan A'},
{name: 'Chandawal'},
{name: 'Sendra (Rajasthan)'},
{name: 'Vaishnodevi'},
{name: 'Sarana (Rajasthan)'},
{name: 'Goyla (Ajmer'},
{name: 'Gulgaon (Ajmer Dist'},
{name: 'Sawar'},
{name: 'Pech Ki Bavri'},
{name: 'Hindoli (Rajasthan)'},
{name: 'Talera (Rajasthan)'},
{name: 'Raniwara'},
{name: 'Malwara (Rajasthan)'},
{name: 'Bharudi'},
{name: 'Mandoli (Rajasthan)'},
{name: 'Ahore'},
{name: 'Jod Choraha'},
{name: 'Kulthana (Rajasthan)'},
{name: 'Vayad (Rajasthan)'},
{name: 'Jatav Pura'},
{name: 'Ratri'},
{name: 'Gogelava'},
{name: 'Alai (Nagaur'},
{name: 'Bhamatser'},
{name: 'Palanaa'},
{name: 'Deogarh (Rajasthan)'},
{name: 'Sursura (Rajasthan)'},
{name: 'Roopangarh  Choraha'},
{name: 'Borawad'},
{name: 'Bandikui Gaav'},
{name: 'Diggi(Rajastan)'},
{name: 'Fagi'},
{name: 'Sanganer Gaon'},
{name: 'Vijainagar'},
{name: 'Nimuch'},
{name: 'Goglav'},
{name: 'Mogda'},
{name: 'Bhadajun Dhani'},
{name: 'Nimbla'},
{name: 'Malvad Chowk'},
{name: 'Kuda(Gujarat)'},
{name: 'Sewara (Rajasthan)'},
{name: 'Sankad (Rajasthan)'},
{name: 'Sarnau'},
{name: 'Tarpal'},
{name: 'Isabval'},
{name: 'Palodara (Rajasthan)'},
{name: 'Jaisamand'},
{name: 'Ganada'},
{name: 'Tantoti'},
{name: 'Raisingh Nagar'},
{name: 'Dera Vyas'},
{name: 'Hanumangarh Town'},
{name: 'Riico'},
{name: 'Kothapura'},
{name: 'Gurdas Pur'},
{name: 'Sanghna'},
{name: 'Patrora'},
{name: 'Satrana'},
{name: 'Nai Mandi'},
{name: 'Gharsana'},
{name: 'Jalwali Ggn'},
{name: 'Desli'},
{name: 'Rojdi (Rajasthan)'},
{name: 'Dairy Farm'},
{name: 'Khanuwali'},
{name: 'Chhattargarh (Rajasthan)'},
{name: 'Gogliya Dhani'},
{name: 'Satasar'},
{name: 'Motigarh (Rajasthan)'},
{name: 'K.fanta'},
{name: 'Lakhoosar'},
{name: 'Pugal'},
{name: 'Bharu'},
{name: 'Badrasar'},
{name: 'Shobhasar'},
{name: 'Dhani (Haryana)'},
{name: 'Gogolav'},
{name: 'Khunal'},
{name: 'Norva (Jalore Dist'},
{name: 'Nimla'},
{name: 'Hansi (Haryana)'},
{name: 'Jind (Haryana)'},
{name: 'Yamuna Nagar'},
{name: 'Fatehabad (Haryana)'},
{name: 'Karanpur (Gujarat)'},
{name: 'Maloth (Kerala)'},
{name: 'Muktasar'},
{name: 'Firojpur'},
{name: 'Rupangarh'},
{name: 'Khajuwala'},
{name: 'Rawala (Rajasthan)'},
{name: 'Bakani (Rajasthan)'},
{name: 'Rajastan Bo.'},
{name: 'Machalpur'},
{name: 'Jirapur'},
{name: 'Rajgareh'},
{name: 'Beawara'},
{name: 'Bhodhki'},
{name: 'Mandli'},
{name: 'Mo. Piyaod'},
{name: 'Dend'},
{name: 'Bala (Rajasthan)'},
{name: 'Koorna'},
{name: 'Padarla'},
{name: 'Gura Pratapsingh (Pali'},
{name: 'Sal. Mahadev'},
{name: 'Saali'},
{name: 'Sundelav'},
{name: 'Sakdda Fatak'},
{name: 'Chanod (Gujarat)'},
{name: 'Anuppur (Madhya Pradesh)'},
{name: 'Badgavda'},
{name: 'Lapod'},
{name: 'Birami'},
{name: 'Posaliya'},
{name: 'Paldi (Gujarat)'},
{name: 'Singhrat'},
{name: 'Pawapuri (Bihar)'},
{name: 'Sirodi'},
{name: 'Anadra'},
{name: 'Dabani'},
{name: 'Karoti (Rajasthan)'},
{name: 'Revdar'},
{name: 'Karoti Mod'},
{name: 'Bhatana Mod'},
{name: 'Makawal'},
{name: 'Bhatana(Haryana)'},
{name: 'Raj Border Abu'},
{name: 'Kapasiya (Gujarat)'},
{name: 'Baira'},
{name: 'Baludra'},
{name: 'Ikbalgarh (Gujarat)'},
{name: 'Income Tax'},
{name: 'Boya'},
{name: 'Sewari'},
{name: 'Bhatund'},
{name: 'Beda'},
{name: 'Kothar (Pali Dist'},
{name: 'Bhandar (Uttar Pradesh)'},
{name: 'Chamundery'},
{name: 'Nana (Rajasthan)'},
{name: 'Amliya'},
{name: 'Pindwara (Rajasthan)'},
{name: 'Sirohi Road'},
{name: 'Ujhaa'},
{name: 'Jhadan'},
{name: 'Narayanpura (Rajasthan)'},
{name: 'Kewal Nagar (Rajasthan)'},
{name: 'Mandana (Rajasthan)'},
{name: 'Dabadeh'},
{name: 'Suket'},
{name: 'Soyat'},
{name: 'Susner'},
{name: 'Aagar'},
{name: 'Ghosla (Jharkhand)'},
{name: 'Chapar'},
{name: 'Tatarpur Choraha'},
{name: 'Gulabpura Choraha'},
{name: 'Vijai Nagar'},
{name: 'Badanvada'},
{name: 'Rashidpura'},
{name: 'Nimod'},
{name: 'Kailadevi'},
{name: 'Choti Sadri'},
{name: 'Partapgarh (Uttar Pradesh)'},
{name: 'Jasvantgarh'},
{name: 'Punawali'},
{name: 'Semar (Rajasthan)'},
{name: 'Singhara'},
{name: 'Maga (Rajasthan)'},
{name: 'Kot (Pali'},
{name: 'Nimbonath'},
{name: 'Dujana'},
{name: 'Balana (Gujarat)'},
{name: 'Ummedpur'},
{name: 'Guda Balotan (Rajasthan)'},
{name: 'Godan (Rajasthan)'},
{name: 'Sankarana'},
{name: 'Vishangarh'},
{name: 'Kathadi (Rajasthan)'},
{name: 'Ramniya'},
{name: 'Mokalsar Gaon'},
{name: 'Sewana (Rajasthan)'},
{name: 'Thapan'},
{name: 'Moothali'},
{name: 'Aasoma'},
{name: 'Nakoda (Rajasthan)'},
{name: 'Jasol'},
{name: 'Asotra'},
{name: 'Aasotra'},
{name: 'Pahadpura (Rajasthan)'},
{name: 'Sutharo Ka Guda'},
{name: 'Ghanerav'},
{name: 'Jhilwara'},
{name: 'Siwana'},
{name: 'Mokalsar Station'},
{name: 'Bishangarh (Rajasthan)'},
{name: 'Nimboranath'},
{name: 'Khetlaji'},
{name: 'Ranigaon (Pali Dist'},
{name: 'Gajsinghpur'},
{name: 'Bavri'},
{name: 'Loyara (Rajasthan)'},
{name: 'Mainar'},
{name: 'Keer Ki Chonki'},
{name: 'Mangalwar Gaon'},
{name: 'Biloda (Rajasthan)'},
{name: 'Sangriya'},
{name: 'Jaloda'},
{name: 'Barwada (Rajasthan)'},
{name: 'Karunda (Rajasthan)'},
{name: 'Ramdevji (Rajasthan)'},
{name: 'Ambavali'},
{name: 'Dholapani (Rajasthan)'},
{name: 'Sikkaberi'},
{name: 'Talama'},
{name: 'Bori-parda'},
{name: 'Dhamotar (Rajasthan)'},
{name: 'Ambamata'},
{name: 'Chadi'},
{name: 'Deu (Rajasthan)'},
{name: 'Khood'},
{name: 'Baapgaon'},
{name: 'Baap Fanta'},
{name: 'Kansih Ki Sid'},
{name: 'Diyatra (Rajasthan)'},
{name: 'Sankhala Fata'},
{name: 'Kolayat'},
{name: 'Sheruna'},
{name: 'Dungergarh'},
{name: 'Adsar'},
{name: 'Bhaleri'},
{name: 'Buchawas'},
{name: 'Jumpa Border'},
{name: 'Shivani(Karnataka)'},
{name: 'Badwa'},
{name: 'Shyamli'},
{name: 'Uttr.bor.muz.nag.'},
{name: 'Bilada'},
{name: 'Nimaz (Maharastra)'},
{name: 'Kathoti (Nagaur Dist'},
{name: 'Lordiyan (Rajasthan)'},
{name: 'Lohawat'},
{name: 'Samrau'},
{name: 'Bhikamkor (Rajasthan)'},
{name: 'Mathaniya'},
{name: 'Sawroopganj'},
{name: 'Bandhi Kui Mod'},
{name: 'Sikandra (Dausa Dist'},
{name: 'Saiyan'},
{name: 'Mania (Dholpur'},
{name: 'Nadbai'},
{name: 'Maharajpura (Rajasthan)'},
{name: 'Samshawad'},
{name: 'Tolanadi'},
{name: 'Rajakhera'},
{name: 'Marena (Rajasthan)'},
{name: 'Bhadkha'},
{name: 'Nimbala (Rajasthan)'},
{name: 'Bisu'},
{name: 'Bhiyad'},
{name: 'Kanasar (Rajasthan)'},
{name: 'Undu'},
{name: 'Falsund'},
{name: 'Bhikhodae'},
{name: 'Bhaniyana'},
{name: 'Mandva (Gujarat)'},
{name: 'Ujala'},
{name: 'Bap Gaon'},
{name: 'Nokhda'},
{name: 'Techari Fanta'},
{name: 'Sri Dungar Ghar'},
{name: 'Dadrewa'},
{name: 'Sivani'},
{name: 'Barwa (Haryana)'},
{name: 'Utaranchal Border-u'},
{name: 'Malsisar'},
{name: 'Shergarh (Rajasthan)'},
{name: 'Thana Gaji'},
{name: 'Viratnagar'},
{name: 'Saray-kale-kha'},
{name: 'Kama (Rajasthan)'},
{name: 'Chand Kheri (Rajasthan)'},
{name: 'Shri Mahaveer Ji Temple'},
{name: 'Sapotara'},
{name: 'Sarmathura'},
{name: 'Badi (Madhya Pradesh)'},
{name: 'Bamanvaas'},
{name: 'Pantoda'},
{name: 'Bandhikui Mod'},
{name: 'Mahwa (Dausa'},
{name: 'Jurhera'},
{name: 'Prantij'},
{name: 'Ghambhoie'},
{name: 'Bichiwara'},
{name: 'Kanba (Rajasthan)'},
{name: 'Hirata'},
{name: 'Antri'},
{name: 'Tamtiya'},
{name: 'Sagvara'},
{name: 'Bhiluda (Rajasthan)'},
{name: 'Agarpura'},
{name: 'Ghadi (Gujarat)'},
{name: 'Partapura'},
{name: 'Talwara (Punjab)'},
{name: 'Goverdhan'},
{name: 'Chaksu'},
{name: 'Fathepur'},
{name: 'Kethal'},
{name: 'Ambla'},
{name: 'Hmg.town'},
{name: 'Chilada'},
{name: 'Kawas (Rajasthan)'},
{name: 'Punali'},
{name: 'M.mod'},
{name: 'Punjpur (Rajasthan)'},
{name: 'Pindawal'},
{name: 'Luhariya (Rajasthan)'},
{name: 'Ganoda (Rajasthan)'},
{name: 'Khamera (Rajasthan)'},
{name: 'Pipalkhut'},
{name: 'Suhagpura (Rajasthan)'},
{name: 'Rajpuriya (Rajasthan)'},
{name: 'Mp Border'},
{name: 'Raj Border'},
{name: 'Shambhupura (Rajasthan)'},
{name: 'Sanavada'},
{name: 'Bachhrau (Rajasthan)'},
{name: 'Dhorimaina'},
{name: 'Gandhav (Rajasthan)'},
{name: 'Sivada'},
{name: 'Hadetar'},
{name: 'Bavliya'},
{name: 'Kagmala'},
{name: 'Kodi (Rajasthan)'},
{name: 'Aaldi'},
{name: 'Mohbbat Nagar'},
{name: 'Barlut'},
{name: 'Jawal (Rajasthan)'},
{name: 'Malera (Sirohi Dist'},
{name: 'Moras (Rajasthan)'},
{name: 'Saibandh'},
{name: 'Bekriya'},
{name: 'Malwa Ka Chora'},
{name: 'Hastinapur (Uttar Pradesh)'},
{name: 'Bhawari'},
{name: 'Jalara'},
{name: 'Lohariya (Rajasthan)'},
{name: 'Bhimpur (Rajasthan)'},
{name: 'Sundani'},
{name: 'Gangrar Station'},
{name: 'Bhadesar Choraha'},
{name: 'Bhadesar (Rajasthan)'},
{name: 'Awari Mata Ji'},
{name: 'Nikumbh Choraha'},
{name: 'Bohera (Rajasthan)'},
{name: 'Dhariyavad'},
{name: 'Jawahar Nagar (Rajasthan)'},
{name: 'Mungana'},
{name: 'Parsola (Rajasthan)'},
{name: 'Nithauwa (Rajasthan)'},
{name: 'Rihda'},
{name: 'Movai (Rajasthan)'},
{name: 'Bankoda'},
{name: 'Dovda'},
{name: 'Kheda'},
{name: 'Digal'},
{name: 'Sangasi'},
{name: 'Dudlod'},
{name: 'Dudlod Fatak'},
{name: 'Beri'},
{name: 'Tarpura (Rajasthan)'},
{name: 'Dadiya (Rajasthan)'},
{name: 'Katrathal (Rajasthan)'},
{name: 'Mandawara (Rajasthan)'},
{name: 'Sudrasan (Rajasthan)'},
{name: 'Nua (Rajasthan)'},
{name: 'Dinarpura (Rajasthan)'},
{name: 'Cholukhan (Rajasthan)'},
{name: 'Kodiya (Rajasthan)'},
{name: 'Kerap'},
{name: 'Bathri'},
{name: 'Rodu'},
{name: 'Bavdi'},
{name: 'Kelash Puri'},
{name: 'Kuwariya (Rajasthan)'},
{name: 'Kurajmod'},
{name: 'Karohi'},
{name: 'Ratanghar'},
{name: 'Ratanpur Border'},
{name: 'Jethana'},
{name: 'Garhi (Rajasthan)'},
{name: 'Badnawara'},
{name: 'Vijaynagar Choraha'},
{name: 'Rupaheli'},
{name: 'Kanwaliyas (Rajasthan)'},
{name: 'Raila (Bhilwara Dist'},
{name: 'Mandal (Rajasthan)'},
{name: 'Hamirgarh (Rajasthan)'},
{name: 'Siyakheri'},
{name: 'Damotar'},
{name: 'Lamba Dabra (Rajasthan)'},
{name: 'Padoli (Rajasthan)'},
{name: 'Baiwar'},
{name: 'Farokabad'},
{name: 'Buharanpur'},
{name: 'Bagad'},
{name: 'Bakhtawarpura (Rajasthan)'},
{name: 'Manesar (Gurugram)'},
{name: 'Anta'},
{name: 'Palaita'},
{name: 'Gadepan (Rajasthan)'},
{name: 'Simaliya (Kota Dist'},
{name: 'Taleda'},
{name: 'Gudha (Bundi'},
{name: 'Nim Ka Kheda'},
{name: 'Baka'},
{name: 'Salawatiya'},
{name: 'Aroli (Rajasthan)'},
{name: 'Menal (Rajasthan)'},
{name: 'Mandalgarh (Rajasthan)'},
{name: 'Trivani'},
{name: 'Bigod'},
{name: 'Sawaipur (Haryana)'},
{name: 'Nimaj'},
{name: 'Bhavi'},
{name: 'Kaprada (Gujarat)'},
{name: 'Barana'},
{name: 'Kelwada'},
{name: 'Samraniya'},
{name: 'Kasba Thana (Rajasthan)'},
{name: 'Thikriya'},
{name: 'Goriya (Rajasthan)'},
{name: 'Dundlod Phatak'},
{name: 'Dundlod (Rajasthan)'},
{name: 'Dhigal (Rajasthan)'},
{name: 'Dandhuri (Rajasthan)'},
{name: 'Alsisar'},
{name: 'Gokhari'},
{name: 'Dhabali Ki Dhani'},
{name: 'Sankhu'},
{name: 'Berasar'},
{name: 'Gothiya Bora'},
{name: 'Jhumpa'},
{name: 'Shambhar'},
{name: 'Sri Madhopur'},
{name: 'Fateshs'},
{name: 'Chaksu Stand'},
{name: 'Bhawani Mandi'},
{name: 'Dug (Rajasthan)'},
{name: 'Gomti Choraha'},
{name: 'Nathdwara Station'},
{name: 'Udaipura'},
{name: 'Dehgav'},
{name: 'Baragaon (Jhunjhunu Dist'},
{name: 'Megraj'},
{name: 'Undwa (Rajasthan)'},
{name: 'Simlwara'},
{name: 'Dhambola'},
{name: 'Bansiya'},
{name: 'Chadoli'},
{name: 'Ghata Ka Gaon'},
{name: 'Semaliya (Rajasthan)'},
{name: 'Khumanpur'},
{name: 'Jogpur'},
{name: 'Khadgada'},
{name: 'Panchwati (Rajasthan)'},
{name: 'Khodan (Rajasthan)'},
{name: 'Wajwana'},
{name: 'Swroop Ganj'},
{name: 'Bera'},
{name: 'Rohat (Rajasthan)'},
{name: 'Dey'},
{name: 'Kanta (Nagaur'},
{name: 'Nimijodha'},
{name: 'Dewani (Rajasthan)'},
{name: 'Kuhariya (Rajasthan)'},
{name: 'Parihara (Rajasthan)'},
{name: 'Gorisar'},
{name: 'Sehla (Rajasthan)'},
{name: 'Satra (Rajasthan)'},
{name: 'Binasar'},
{name: 'Lakhau'},
{name: 'Sirsla'},
{name: 'D.station'},
{name: 'Hariyal'},
{name: 'Ratanpura (Uttar Pradesh)'},
{name: 'L Magni'},
{name: 'Laseri (Rajasthan)'},
{name: 'Siwani (Haryana)'},
{name: 'Kalsara (Rajasthan)'},
{name: 'Dhigawara (Rajasthan)'},
{name: 'Kothi Narayanpur (Rajasthan)'},
{name: 'Machari (Rajasthan)'},
{name: 'Nangal (Rajasthan)'},
{name: 'Pinan'},
{name: 'Mandawar (Rajasthan)'},
{name: 'Begun (Rajasthan)'},
{name: 'Singoli (Madhya Pradesh)'},
{name: 'Borav'},
{name: 'Borawas'},
{name: 'Sambhar'},
{name: 'Veshnav Devi'},
{name: 'Dra'},
{name: 'Dhabadeh (Rajasthan)'},
{name: 'Chawli'},
{name: 'Dunger Gav'},
{name: 'Pirawa (Rajasthan)'},
{name: 'Batewar'},
{name: 'Mangalwar'},
{name: 'Sawariya Cho.'},
{name: 'Parsoli (Rajasthan)'},
{name: 'K.mod'},
{name: 'Start Of Rajasthan'},
{name: 'End Of Mp'},
{name: 'Bhesrodgarh'},
{name: 'Anushakti (Rajasthan)'},
{name: 'Khatikheda (Rajasthan)'},
{name: 'Aloda (Rajasthan)'},
{name: 'Salera'},
{name: 'Chechat (Rajasthan)'},
{name: 'Modak (Kota Dist'},
{name: 'Ramganj Mandi'},
{name: 'Bagher (Rajasthan)'},
{name: 'Khanpur (Uttar Pradesh)'},
{name: 'Mohi'},
{name: 'Bapawar'},
{name: 'Bamla'},
{name: 'Paten'},
{name: 'Asnawar'},
{name: 'Chhan (Rajasthan)'},
{name: 'Manhor Thana'},
{name: 'Sikandrarahu'},
{name: 'Singhaniya'},
{name: 'Bhilwadi'},
{name: 'Garnawad'},
{name: 'Pachpahar'},
{name: 'Guradiya Joga (Rajasthan)'},
{name: 'Mahudiya (Rajasthan)'},
{name: 'Mishroli (Rajasthan)'},
{name: 'Silehgarh'},
{name: 'Kuravan'},
{name: 'Pagariya (Rajasthan)'},
{name: 'Harnawada (Rajasthan)'},
{name: 'Kyasra (Rajasthan)'},
{name: 'Jamunya (Madhya Pradesh)'},
{name: 'Gangdhar'},
{name: 'Choumela'},
{name: 'Aalot'},
{name: 'Namli (Madhya Pradesh)'},
{name: 'Danpur (Rajasthan)'},
{name: 'Kutumbi'},
{name: 'Sadasar'},
{name: 'Ghandidham'},
{name: 'Shyamarthali'},
{name: 'Gangodhar'},
{name: 'Aadasar'},
{name: 'Santalpur (Gujarat)'},
{name: 'Varahi'},
{name: 'Dhara'},
{name: 'Siduri'},
{name: 'Khemana (Rajasthan)'},
{name: 'Bhildi'},
{name: 'Iqbalgarh'},
{name: 'Devgaon (Maharastra)'},
{name: 'Badagav'},
{name: 'Mandli (Rajasthan)'},
{name: 'Devgav (Maharastra)'},
{name: 'Sinthal'},
{name: 'Simalwara'},
{name: 'Peeth (Rajasthan)'},
{name: 'Holikampa'},
{name: 'Sendola'},
{name: 'Rohida (Rajasthan)'},
{name: 'Jasaila'},
{name: 'Gariyata (Rajasthan)'},
{name: 'Nadiya (Rajasthan)'},
{name: 'Khedasa (Dungarpur Dist'},
{name: 'Galiakot'},
{name: 'Beneshvar'},
{name: 'Kothari(Gujarat)'},
{name: 'Chawandiya'},
{name: 'Khatu Shyam'},
{name: 'Khairwara'},
{name: 'Rajasthan Border'},
{name: 'Jirkapur'},
{name: 'Pavta'},
{name: 'Kheroda (Rajasthan)'},
{name: 'Arnod'},
{name: 'Rajasthan Seema'},
{name: 'Multhan (Madhya Pradesh)'},
{name: 'Badnawar'},
{name: 'Kanvan (Madhya Pradesh)'},
{name: 'Nagda (Madhya Pradesh)'},
{name: 'Sadalpur (Madhya Pradesh)'},
{name: 'Ghatabilad'},
{name: 'Baitma'},
{name: 'Bheem'},
{name: 'Kamlighat (Rajasthan)'},
{name: 'Pirantani'},
{name: 'Bichund'},
{name: 'Todi (Rajasthan)'},
{name: 'Kailashpuri'},
{name: 'Diver'},
{name: 'Tikswash'},
{name: 'Var'},
{name: 'Javaja'},
{name: 'Piplai'},
{name: 'Khatta'},
{name: 'Basiya'},
{name: 'Obri (Rajasthan)'},
{name: 'Gamda (Rajasthan)'},
{name: 'Oda (Rajasthan)'},
{name: 'Padva (Gujarat)'},
{name: 'Katisor'},
{name: 'Lapiya'},
{name: 'Dunda'},
{name: 'Badau'},
{name: 'Kaiseriya Ji'},
{name: 'Bhaduch'},
{name: 'Mh Border'},
{name: 'Khatunda Mode'},
{name: 'Salvatiya'},
{name: 'Budhpura (Rajasthan)'},
{name: 'Dubbi (Rajasthan)'},
{name: 'Dhaneshwar (Rajasthan)'},
{name: 'Aklera (Rajasthan)'},
{name: 'Khilchipur (Madhya Pradesh)'},
{name: 'Khetri (Rajasthan)'},
{name: 'Baytu'},
{name: 'Bagera (Rajasthan)'},
{name: 'Daloda'},
{name: 'Dhodhra (Rajasthan)'},
{name: 'Nagri (Madhya Pradesh)'},
{name: 'Badnawar Choraha'},
{name: 'Levad Cho'},
{name: 'Simrol (Madhya Pradesh)'},
{name: 'Lay'},
{name: 'Choral (Madhya Pradesh)'},
{name: 'Balwada (Khandwa'},
{name: 'Umriya (Madhya Pradesh)'},
{name: 'Badwad Ch'},
{name: 'Morutka Onkar'},
{name: 'Patan (Gujarat)'},
{name: 'Kakhani'},
{name: 'Neelkheri'},
{name: 'Gharoda'},
{name: 'Samalkhan'},
{name: 'Sahjapur'},
{name: 'Neemrana (Rajasthan)'},
{name: 'Pawata'},
{name: 'Chandbaji'},
{name: 'Chaan'},
{name: 'Saroli (Rajasthan)'},
{name: 'Kolu (Rajasthan)'},
{name: 'Setrawa'},
{name: 'Solkiya Tala'},
{name: 'Bhungra'},
{name: 'Sointra'},
{name: 'Madpura (Rajasthan)'},
{name: 'Thob (Rajasthan)'},
{name: 'Katoti'},
{name: 'Siyana (Rajasthan)'},
{name: 'Barada'},
{name: 'Merta'},
{name: 'Hathma (Rajasthan)'},
{name: 'Bachbar'},
{name: 'Ramsar (Rajasthan)'},
{name: 'Gagriya (Rajasthan)'},
{name: 'Gadra Road'},
{name: 'Atru'},
{name: 'Kankani (Rajasthan)'},
{name: 'Talahati'},
{name: 'Kherli (Alwar Dist'},
{name: 'Basedi'},
{name: 'Vaah'},
{name: 'Varada (Rajasthan)'},
{name: 'Raipuriya'},
{name: 'Mandwala (Rajasthan)'},
{name: 'Nimblana'},
{name: 'Ummedabad'},
{name: 'Bavtra'},
{name: 'Jivana'},
{name: 'Teja Ki Beri'},
{name: 'Chachua Fanta'},
{name: 'Sarnu'},
{name: 'Chava'},
{name: 'Ravatsar'},
{name: 'Bhura Baba'},
{name: 'Malar (Rajasthan)'},
{name: 'Jod'},
{name: 'Hindal Gol (Rajasthan)'},
{name: 'Kherwa (Rajasthan)'},
{name: 'Bhartasar'},
{name: 'Gadna (Rajasthan)'},
{name: 'Nayagaon (Rajasthan)'},
{name: 'Badi Sid (Jodhpur Dist'},
{name: 'Kanji Ki Sird'},
{name: 'Kisneri'},
{name: 'Khedrat'},
{name: 'Bhomiyaji Ka Than'},
{name: 'Shivbari'},
{name: 'Raneri'},
{name: 'Gadiyala Fanta'},
{name: 'Sikh Dera'},
{name: 'Mad (Rajasthan)'},
{name: 'Techri Fanta'},
{name: 'Golri'},
{name: 'Gajner (Rajasthan)'},
{name: 'Darbari'},
{name: 'Gandhi Piyau'},
{name: 'Nal (Rajasthan)'},
{name: 'Porsa'},
{name: 'Khidrat'},
{name: 'Ganjdudwana'},
{name: 'Gudgaon (Madhya Pradesh)'},
{name: 'Javad'},
{name: 'Vikram Ciment'},
{name: 'Tikhi'},
{name: 'Kanodiya Purohit (Rajasthan)'},
{name: 'Babalchock'},
{name: 'Katholi'},
{name: 'Mujjafnagar'},
{name: 'Elana'},
{name: 'Mokni (Rajasthan)'},
{name: 'Megalwa'},
{name: 'Bundwa Ji'},
{name: 'Taaliyana'},
{name: 'Bonli'},
{name: 'Dara'},
{name: 'Monga'},
{name: 'Khimat (Gujarat)'},
{name: 'Pathavada'},
{name: 'Gundri'},
{name: 'Badgaon'},
{name: 'Kharnota'},
{name: 'Gajesinghji Bagal'},
{name: 'Purkaji'},
{name: 'Nangal (Punjab)'},
{name: 'Theyat Fata'},
{name: 'Bhagu Fata'},
{name: 'Bhojka'},
{name: 'Chandar'},
{name: 'Sorhakor'},
{name: 'Dholia (Rajasthan)'},
{name: 'Khetolai (Rajasthan)'},
{name: 'Chacha (Rajasthan)'},
{name: 'Lawa (Rajasthan)'},
{name: 'Bawkan'},
{name: 'Deda'},
{name: 'Shekhala'},
{name: 'Agolai'},
{name: 'Chopad'},
{name: 'Jeera'},
{name: 'Chunawadh (Rajasthan)'},
{name: 'Tatarsar'},
{name: 'Cc Head'},
{name: 'Bhompura'},
{name: 'Sameja Kothi'},
{name: 'Salempur (Uttar Pradesh)'},
{name: 'Banda Colony'},
{name: 'Narayansingh Circule'},
{name: 'Kawnlias'},
{name: 'Senawas'},
{name: 'Onkareswar'},
{name: 'Bassi Chak'},
{name: 'Helna'},
{name: 'Rareh'},
{name: 'Start Of Up'},
{name: 'Mursan (Uttar Pradesh)'},
{name: 'Hathras Janksan'},
{name: 'Pipad City'},
{name: 'Borunda'},
{name: 'Vlara'},
{name: 'Padampura (Rajasthan)'},
{name: 'Masuda'},
{name: 'Kotkasim'},
{name: 'Shamalaji'},
{name: 'Korta (Rajasthan)'},
{name: 'Bamnera'},
{name: 'Poina (Rajasthan)'},
{name: 'Joyla'},
{name: 'Arathwara (Rajasthan)'},
{name: 'Bhev'},
{name: 'Bagseen'},
{name: 'Andore (Rajasthan)'},
{name: 'Goal'},
{name: 'Mandwa (Rajasthan)'},
{name: 'Sindrath'},
{name: 'Krishanganj'},
{name: 'Reodar'},
{name: 'Sandra (Rajasthan)'},
{name: 'Kyari (Rajasthan)'},
{name: 'Chohtan'},
{name: 'Viratara Mata'},
{name: 'Bakhasar'},
{name: 'Chakdhani'},
{name: 'Taleti (Rajasthan)'},
{name: 'Sanwada (Rajasthan)'},
{name: 'Varman'},
{name: 'Magriwada'},
{name: 'Rupawati Khurd'},
{name: 'Jetpura'},
{name: 'Sakad'},
{name: 'Mangta (Rajasthan)'},
{name: 'Ranji Ka Goliya'},
{name: 'Kushalpura (Rajasthan)'},
{name: 'Sarthala'},
{name: 'Daspa'},
{name: 'Pothedi'},
{name: 'Unadi (Gujarat)'},
{name: 'Poshana'},
{name: 'Siyawat'},
{name: 'Maliyo Ki Dhani'},
{name: 'Chorau (Rajasthan)'},
{name: 'Otwala'},
{name: 'Keshwana (Rajasthan)'},
{name: 'Safada'},
{name: 'Palej'},
{name: 'Kim Chorha'},
{name: 'Jay Ram Ki Dhani'},
{name: 'Bhimarlai'},
{name: 'Dudhawa (Chhattisgarh)'},
{name: 'Baghundi'},
{name: 'Balera'},
{name: 'Sangana'},
{name: 'Sirana'},
{name: 'Arniyali'},
{name: 'Sejada'},
{name: 'Khara Fanta'},
{name: 'Guda Reliya (Jalore'},
{name: 'Sarupganj'},
{name: 'Bhujela'},
{name: 'Chhapri Border'},
{name: 'Hadar'},
{name: 'Vadli (Gujarat)'},
{name: 'Harji'},
{name: 'Bithura'},
{name: 'Jhadoli (Rajasthan)'},
{name: 'Kailasnagar'},
{name: 'Naradara'},
{name: 'Madani (Rajasthan)'},
{name: 'Dodwa'},
{name: 'Belangri'},
{name: 'Rajsthan Border Sirohi'},
{name: 'Was (Rajasthan)'},
{name: 'Manadar'},
{name: 'Maitriwara'},
{name: 'Veerana'},
{name: 'Revtra'},
{name: 'Aalasan'},
{name: 'Bu Gav'},
{name: 'Chandur'},
{name: 'Fedani'},
{name: 'Pavti (Rajasthan)'},
{name: 'Dorda'},
{name: 'Golana (Rajasthan)'},
{name: 'Jaswantpura (Rajasthan)'},
{name: 'Dantrai (Rajasthan)'},
{name: 'Jiraval'},
{name: 'Rol'},
{name: 'Baddi (himachal pradesh)'},
{name: 'Koliya (Rajasthan)'},
{name: 'Mundwara'},
{name: 'Denarpura'},
{name: 'Kikrwa'},
{name: 'Bhopalsager'},
{name: 'Singhpur (Rajasthan)'},
{name: 'Sojt'},
{name: 'Wapi'},
{name: 'Manohar'},
{name: 'Chadwal'},
{name: 'Tanwari'},
{name: 'Fugni'},
{name: 'Noon'},
{name: 'Madiya (Rajasthan)'},
{name: 'Haliwara (Rajasthan)'},
{name: 'Sildar'},
{name: 'Punak'},
{name: 'Sundha Mata'},
{name: 'Sankliyavas'},
{name: 'Khatkad (Rajasthan)'},
{name: 'Dei'},
{name: 'Nainwa'},
{name: 'Uniara (Rajasthan)'},
{name: 'Charli (Rajasthan)'},
{name: 'Guda (Rajasthan)'},
{name: 'Borikua'},
{name: 'Mata Ji Ka Nala (Rajasthan)'},
{name: 'Katunda'},
{name: 'Bhichor'},
{name: 'Singpur'},
{name: 'Paboosari'},
{name: 'Kakod'},
{name: 'Kushtala'},
{name: 'Chideramau'},
{name: 'Bhilor (Uttar Pradesh)'},
{name: 'Thalarka'},
{name: 'Gogamari'},
{name: 'Bhadur Garh'},
{name: 'Thirweni'},
{name: 'Slawatiya'},
{name: 'Mala Khera (Rajasthan)'},
{name: 'Kainchi (Uttarakhand)'},
{name: 'Kameen Pura'},
{name: 'Matili (Rajasthan)'},
{name: 'Lakhuwali'},
{name: 'Chaiya'},
{name: 'Parlika'},
{name: 'Ridmalsar'},
{name: 'Biratramata'},
{name: 'Bandhikui'},
{name: 'Baswa (Rajasthan)'},
{name: 'Sirad'},
{name: 'Karipura'},
{name: 'Sumanpur'},
{name: 'Dewari'},
{name: 'Bhawargarh'},
{name: 'Halena'},
{name: 'Ujhani (Uttar Pradesh)'},
{name: 'A. Nagar'},
{name: 'Talwada Jheel'},
{name: 'Rajasthan Border Bhiwadi'},
{name: 'Kothanalloor'},
{name: 'Muttuchira'},
{name: 'Chitvel'},
{name: 'Khagipeta'},
{name: 'Duvuru'},
{name: 'udumalpet'},
{name: 'Govindapuram'},
{name: 'Padru (Rajasthan)'},
{name: 'MAJESTIC'},
{name: 'Nagore Dargah'},
{name: 'Kadaloor'},
{name: 'Chengalpattu (Bye Pass)'},
{name: 'Kolkata (Esplanade)'},
{name: 'Nagari (Andhra Pradesh)'},
{name: 'Kingaon Raja'},
{name: 'Raheri'},
{name: 'Lonar (Buldhana)'},
{name: 'Brahmapur'},
{name: 'Madakasira'},
{name: 'Penukonda(Anantapur)'},
{name: 'Parigi (Telangana)'},
{name: 'Ajanta (ByPass)'},
{name: 'Ellora (ByPass)'},
{name: 'Kasibugga'},
{name: 'Ichapuram'},
{name: 'Tangi'},
{name: 'Jankia'},
{name: 'Khordha'},
{name: 'Sri Kalahasthi(Bye Pass)'},
{name: 'Aatingal'},
{name: 'Abhyathamangala'},
{name: 'Achampet'},
{name: 'Adichunchanagiri'},
{name: 'Adivaram (Kerala)'},
{name: 'Adul'},
{name: 'Adur'},
{name: 'Afzalfur'},
{name: 'Agumbe'},
{name: 'Aihole'},
{name: 'Ajjampura'},
{name: 'Akkialur'},
{name: 'Alamel'},
{name: 'Aldur'},
{name: 'Alike'},
{name: 'Alnavar'},
{name: 'Alursiddapur'},
{name: 'Alwandi'},
{name: 'Ambikanagar'},
{name: 'Amboli'},
{name: 'Ammathi'},
{name: 'Anagola'},
{name: 'Anandapura (Sagara)'},
{name: 'Anekal'},
{name: 'Anmod'},
{name: 'Anmod Ka Ga Border'},
{name: 'Antharkatte'},
{name: 'Aousa'},
{name: 'Arakalgudu'},
{name: 'Arakera (Karnataka)'},
{name: 'Arcot'},
{name: 'Aspari (Andhra Pradesh)'},
{name: 'Assekoli'},
{name: 'Athani Ka Mh Border'},
{name: 'Attibele Ka Tn Border'},
{name: 'Ayanoor'},
{name: 'Banavara'},
{name: 'Bachi'},
{name: 'Bhagamandala'},
{name: 'Bagepalli (Karnataka)'},
{name: 'Bagewadi'},
{name: 'Baichanalli'},
{name: 'Balaganganapalle'},
{name: 'Balehosur'},
{name: 'Balijakandriga'},
{name: 'Ballamavati'},
{name: 'Banavasi (KA)'},
{name: 'Bandigadi'},
{name: 'Bandipur (Karnataka)'},
{name: 'Bankapur'},
{name: 'Bankikodla'},
{name: 'Bannari'},
{name: 'Basavana Bagewadi'},
{name: 'Basruru'},
{name: 'Begur'},
{name: 'Bellatti'},
{name: 'Bellur Cross'},
{name: 'Betageri'},
{name: 'Bhadra Project'},
{name: 'Bhagavathy'},
{name: 'Bherya'},
{name: 'Bicholim (Goa)'},
{name: 'Biligiri Rangana Betta'},
{name: 'Birur Cross'},
{name: 'Bittangala'},
{name: 'Brindavana'},
{name: 'C Hebbur'},
{name: 'Calangute'},
{name: 'Chadachan'},
{name: 'Chamarajanagara'},
{name: 'Chambatti'},
{name: 'Chamundipuram'},
{name: 'Chandanagar (Hyderabad)'},
{name: 'Chandgad'},
{name: 'Chandpur'},
{name: 'Charkala'},
{name: 'Charmadi'},
{name: 'Chavella'},
{name: 'Chengam'},
{name: 'Cheyaidare'},
{name: 'Chickamagaluru'},
{name: 'Chickodi'},
{name: 'Chikkaballapura'},
{name: 'Chikkabyladakere'},
{name: 'Chikkalsandra (bangalore)'},
{name: 'Chikkanayakanahalli'},
{name: 'Chinakurli'},
{name: 'Chincholi (Karnataka)'},
{name: 'Chintakunta'},
{name: 'Chittapur (Karnataka)'},
{name: 'Chorla'},
{name: 'Courtlim'},
{name: 'Cuncolim Bazar'},
{name: 'Dabgal'},
{name: 'Dambala'},
{name: 'Danuli'},
{name: 'Daphlapur'},
{name: 'Darur'},
{name: 'Dasanakoppa'},
{name: 'Devadarshini'},
{name: 'Devanahalli'},
{name: 'Dimbam'},
{name: 'Doddaballapura'},
{name: 'Donimalai (Karnataka)'},
{name: 'Doranhalli'},
{name: 'Dornal (Telangana)'},
{name: 'Echur'},
{name: 'Ekkadbazar'},
{name: 'Erumalai'},
{name: 'Erur'},
{name: 'Eslapuram (Puttur'},
{name: 'Gaanagapur'},
{name: 'Gaganachukki'},
{name: 'Gandasi'},
{name: 'Ghangapur Town'},
{name: 'Ghataprabha'},
{name: 'Gingee'},
{name: 'Giridarshini'},
{name: 'Godchimmalki'},
{name: 'Gokarna Bus Stand'},
{name: 'Gonikoppalu'},
{name: 'Gopal Swamy Betta'},
{name: 'Gowribidanur'},
{name: 'Gubbi (Karnataka)'},
{name: 'Guddapur (Maharashtra)'},
{name: 'Gudiyatam'},
{name: 'Gundlupet Ka Ke Border'},
{name: 'Gunthakal Ap Ka Border'},
{name: 'Gurumitkal'},
{name: 'Guttal'},
{name: 'Gutti'},
{name: 'Guttigar'},
{name: 'H.d.kote'},
{name: 'Hadinbal'},
{name: 'Halageri'},
{name: 'Halebeedu'},
{name: 'Hanagal'},
{name: 'Hanmasagar'},
{name: 'Hansbhavi'},
{name: 'Hanur'},
{name: 'Harugeri'},
{name: 'Harugeri Cross'},
{name: 'Hasanur (Tamil Nadu)'},
{name: 'Havasa'},
{name: 'Heggodu (Sagar)'},
{name: 'Heggodu (Thirtahalli)'},
{name: 'Hipparagi'},
{name: 'Hirekerur'},
{name: 'Hirisave'},
{name: 'Hodalkre'},
{name: 'Hogenakkal'},
{name: 'Holenarasipura'},
{name: 'Hoovinahippargi'},
{name: 'Horti'},
{name: 'Hosadurga'},
{name: 'Hosakoppa'},
{name: 'Hosakote'},
{name: 'Huddkari'},
{name: 'Hukkeri'},
{name: 'Hulakoppa'},
{name: 'Hulikal'},
{name: 'Huliyar (karnataka)'},
{name: 'Hunagund'},
{name: 'Hundimal'},
{name: 'Hunsagi'},
{name: 'Ieeja'},
{name: 'Inamhongal'},
{name: 'Inchal'},
{name: 'Inchalakaranji'},
{name: 'Itagi'},
{name: 'J P Nagar (mys)'},
{name: 'Jadcharla'},
{name: 'Jagalur'},
{name: 'Jakkanahalli'},
{name: 'Jaladarshini'},
{name: 'Jalsoor (Karnataka)'},
{name: 'Jamboti'},
{name: 'Jath(Sangli)'},
{name: 'Javagal'},
{name: 'Jijori'},
{name: 'Joida'},
{name: 'Kaanur'},
{name: 'Kadaba'},
{name: 'Kadanga (Karnataka)'},
{name: 'Kagal  Ka Mh Border'},
{name: 'Kagawad'},
{name: 'Kaggodu'},
{name: 'Kagwad Ka Mh Border'},
{name: 'Kaladgi (Karnataka)'},
{name: 'Kalkurchi'},
{name: 'Kalmane'},
{name: 'Kammakandriga'},
{name: 'Kanakagiri'},
{name: 'Kanakapura'},
{name: 'Kaniyur (Tamil Nadu)'},
{name: 'Kanipakam'},
{name: 'Kankumbi'},
{name: 'Kapoli'},
{name: 'Garepalya'},
{name: 'Karnataka Ap Border Molakalmur'},
{name: 'Kavadi (Karnataka)'},
{name: 'Kaveripakkam'},
{name: 'Kavilpatti'},
{name: 'Kayimane'},
{name: 'Kakkanallah'},
{name: 'Keralapura (Karnataka)'},
{name: 'Keri'},
{name: 'Khanapur(Karnataka)'},
{name: 'Kibbanahalli'},
{name: 'Kikkeri'},
{name: 'Killari (Maharashtra)'},
{name: 'Kittur'},
{name: 'Kod (Haveri Dist'},
{name: 'Kodaganahalli'},
{name: 'Kodangal (Telangana)'},
{name: 'Kodikonda (Andhra Pradesh)'},
{name: 'Kodlipet'},
{name: 'Kokkada'},
{name: 'Kolar Gold Field'},
{name: 'Kollegala'},
{name: 'Koloor'},
{name: 'Konanur'},
{name: 'Kondalhally'},
{name: 'Konsagara'},
{name: 'Kongale'},
{name: 'Koppa/kuppalli'},
{name: 'Koratagere'},
{name: 'Korlahally'},
{name: 'Kotyadi'},
{name: 'Kowdipally'},
{name: 'Krishnapura (Karnataka)'},
{name: 'Kudlagi'},
{name: 'Kudlasangama'},
{name: 'Kudregundi (Karnataka)'},
{name: 'Kukanoor'},
{name: 'Kumbharwada (Maharashtra)'},
{name: 'Kumli [sb Tekdi]'},
{name: 'Kumsi'},
{name: 'Kuppalli'},
{name: 'Kurgodu'},
{name: 'Lakkavalli'},
{name: 'Linganamakki'},
{name: 'Londa (Karnataka)'},
{name: 'M M Hills'},
{name: 'Madapattu'},
{name: 'Madapura'},
{name: 'Madhavaram'},
{name: 'Madhavaram Ap Ka Border'},
{name: 'Madhugiri'},
{name: 'Mailapur (Karnataka)'},
{name: 'Makutta (Karnataka)'},
{name: 'Malavalli'},
{name: 'Mallapuram B.s'},
{name: 'Malleshwara (Karnataka)'},
{name: 'Mallipatna (Karnataka)'},
{name: 'Managoli'},
{name: 'Margao Stand'},
{name: 'Margodu'},
{name: 'Mariyammanahalli'},
{name: 'Masur (Maharashtra)'},
{name: 'Mavinakatte'},
{name: 'Mayasandra'},
{name: 'Melmarvatur'},
{name: 'Melukote'},
{name: 'Miraj Ka Mh Border'},
{name: 'Molem'},
{name: 'Motebennur'},
{name: 'Muchandi'},
{name: 'Mudalagi'},
{name: 'Mudippu'},
{name: 'Mugalkhod'},
{name: 'Muleriya'},
{name: 'Mulkalmur Ka Ap Border'},
{name: 'Munavalli Dam'},
{name: 'Mundgod'},
{name: 'Munirabad Dam'},
{name: 'Muniyala'},
{name: 'Muthinkoppa'},
{name: 'N.s.gate'},
{name: 'Nagaradarshini'},
{name: 'Nagamangala (Karnataka)'},
{name: 'Nalatvad'},
{name: 'Nandihills'},
{name: 'Nandikeshwara'},
{name: 'Nangli (Karnataka)'},
{name: 'Narasimharaja Pura'},
{name: 'Narayanapura (Karnataka)'},
{name: 'Narayanpet (Telangana)'},
{name: 'Naryangaon'},
{name: 'Navanagar Bus Stand'},
{name: 'Needumboil'},
{name: 'Nesargi'},
{name: 'Nilambur (Kerala)'},
{name: 'Nittur/kou'},
{name: 'Pampa(sabarimalai)'},
{name: 'Panchanahalli'},
{name: 'Pandavapura'},
{name: 'Pandharpur(Aurangabad)'},
{name: 'Panja'},
{name: 'Panrutti'},
{name: 'Pargi'},
{name: 'Pattadakal'},
{name: 'Pavagada'},
{name: 'Periyasavali'},
{name: 'Pernambet'},
{name: 'Phalthan'},
{name: 'Polam Ka Ga Border'},
{name: 'Polibetta'},
{name: 'Ponnai'},
{name: 'Ponnampet'},
{name: 'Poonamallee'},
{name: 'Punjallakatte'},
{name: 'Puttur (Andhra Pradesh)'},
{name: 'R.k.pet'},
{name: 'Rajgurunagar(Khed)'},
{name: 'Ramachandrapur Mutt'},
{name: 'Ramakrishnapura'},
{name: 'Ramanagara (Karnataka)'},
{name: 'Rathnapuri Colony'},
{name: 'Ravalapllikaapborder'},
{name: 'Ravulapalli (Telangana)'},
{name: 'Rayalpad'},
{name: 'Raybag'},
{name: 'Roadalbanda Camp'},
{name: 'Sadalga'},
{name: 'Sadashivpet'},
{name: 'Sahebrakatte'},
{name: 'Sakharayapatna'},
{name: 'Sakarpa'},
{name: 'Salethur'},
{name: 'Saligram (Mysore)'},
{name: 'Sambatti'},
{name: 'Sampaje'},
{name: 'Sandur'},
{name: 'Sankhalim'},
{name: 'Saraswathipuram'},
{name: 'Sasthapur'},
{name: 'Sedam (Karnataka)'},
{name: 'Seegodu'},
{name: 'Shahagad'},
{name: 'Shakthinagar Ka Ap Border'},
{name: 'Shaktinagar [ Devasuguru ]'},
{name: 'Shandnagar'},
{name: 'Shanivarasanthe'},
{name: 'Shankari'},
{name: 'Shankaridurgim'},
{name: 'Shanti Nagar'},
{name: 'Shiggaon'},
{name: 'Shikaripur'},
{name: 'Shimsha'},
{name: 'Shiralakoppa'},
{name: 'Shirdon Ka Mh Border'},
{name: 'Sholagiry'},
{name: 'Sholingar'},
{name: 'Siddapur/cdp'},
{name: 'Siddapur/mrc'},
{name: 'Siddapur/srs'},
{name: 'Siddekatte'},
{name: 'Sidlaghatta'},
{name: 'Sigandur'},
{name: 'Singarapatti'},
{name: 'Sirwara'},
{name: 'Somanahalli'},
{name: 'Somvarpet'},
{name: 'Sriharikota'},
{name: 'Srimangala'},
{name: 'Srinivasapura'},
{name: 'Sreekandapuram (Kerala)'},
{name: 'Srkonda'},
{name: 'Sukravarasanthe'},
{name: 'Surapura'},
{name: 'T Narsipura (Karnataka)'},
{name: 'Tadadi'},
{name: 'Tadikal'},
{name: 'Takali (Karnataka)'},
{name: 'Thalaku'},
{name: 'Tamarasseri'},
{name: 'Tangal'},
{name: 'Tangal Ap Tn Border'},
{name: 'Tarale (Maharastra)'},
{name: 'Teradal'},
{name: 'Thalpamba'},
{name: 'Thinthin Bridge'},
{name: 'Thirukoilur'},
{name: 'Thithimathi'},
{name: 'Tholapathi'},
{name: 'Thrashi'},
{name: 'Thugundram'},
{name: 'Tilavalli'},
{name: 'Tiruttani'},
{name: 'Tiskar'},
{name: 'Torgnall'},
{name: 'Tripura'},
{name: 'Turuvekere'},
{name: 'Ulavi'},
{name: 'Umblebyle'},
{name: 'Upponi'},
{name: 'Vairag'},
{name: 'Vaithiri'},
{name: 'Vakkad'},
{name: 'Vamadapadavu'},
{name: 'Vattala Gundi'},
{name: 'Vazikodai Ka Tn Border'},
{name: 'Vazikodava'},
{name: 'Vetavalam'},
{name: 'Vhamanjuru'},
{name: 'Vidyanagar [jindal]'},
{name: 'Vijayapura'},
{name: 'Vinukonda'},
{name: 'Vishalgad'},
{name: 'Vivekananda Circl(mys'},
{name: 'Vkota'},
{name: 'Vkota Ka Tn Border'},
{name: 'Wadi (Maharastra)'},
{name: 'Wakad'},
{name: 'Walajapet (Tamil Nadu)'},
{name: 'Waliyar Tn Ke Border'},
{name: 'Y N Hosakote'},
{name: 'Yadwad'},
{name: 'Yalahanka'},
{name: 'Yamignoor'},
{name: 'Yelandur'},
{name: 'Yelburga'},
{name: 'Yeliyara'},
{name: 'Yelladakere'},
{name: 'Yergatti'},
{name: 'Yewath'},
{name: 'Zaheerabad Ap Ka Border'},
{name: 'Zalki'},
{name: 'Yerraguntla'},
{name: 'Ankolvadi'},
{name: 'Polaki'},
{name: 'Kruthivennu'},
{name: 'Manikonda'},
{name: 'Ventrapragada'},
{name: 'Upparagudem (Andhra Pradesh)'},
{name: 'Kothapalli (Andhra Pradesh)'},
{name: 'Malaparajagudem'},
{name: 'Tummidi'},
{name: 'Jaipuram'},
{name: 'Bantumilli (Andhra Pradesh)'},
{name: 'Penduru'},
{name: 'Yendapalli'},
{name: 'Addapara'},
{name: 'Seetanapalli'},
{name: 'Krishnapatnam'},
{name: 'Bhavani'},
{name: 'Nayveli'},
{name: 'Chhatrapur (Orissa)'},
{name: 'Srikakulam By Pass'},
{name: 'Surathkal N.i.t.k (k.r.e.c)'},
{name: 'Bijapur Bye Pass'},
{name: 'Kulithalai'},
{name: 'Aroor'},
{name: 'Palakonda'},
{name: 'Mohali(Punjab)'},
{name: 'Thoothukudi'},
{name: 'Gurramkonda'},
{name: 'Almel'},
{name: 'Yenkanchi'},
{name: 'Golgera'},
{name: 'Yedrami'},
{name: 'Ijeri'},
{name: 'Sigarthalli'},
{name: 'Basarlapudi'},
{name: 'Golageri (karnataka)'},
{name: 'Malli (Uttarakhand)'},
{name: 'Gogikona'},
{name: 'Gurmitkal'},
{name: 'Afzalpur'},
{name: 'Leela Mahal Circle'},
{name: 'Chagalamarri'},
{name: 'Chagalamarry'},
{name: 'Jaipur Darshan'},
{name: 'Razole By-Pass'},
{name: 'Pedana'},
{name: 'Vadlamannadu'},
{name: 'Kavuparam'},
{name: 'Gudlavannuru'},
{name: 'Kingaon (Maharashtra)'},
{name: 'Vemagiri Bridge'},
{name: 'Pachal (Maharashtra)'},
{name: 'Lota (Jharkhand)'},
{name: 'Savarde'},
{name: 'Nivali (Ratnagiri)'},
{name: 'Oni (Maharastra)'},
{name: 'Saudal'},
{name: 'Muppavaram'},
{name: 'Podili'},
{name: 'Yerragondapalem'},
{name: 'Karampudi'},
{name: 'Sawarda'},
{name: 'Hathkhambha'},
{name: 'Nangaon (Maharashtra)'},
{name: 'Kadayam'},
{name: 'Chanubanda (Andhra Pradesh)'},
{name: 'Chatrai (Andhra Pradesh)'},
{name: 'Gandi (Andhra Pradesh)'},
{name: 'Idupulapaaya'},
{name: 'Lankapalli (Andhra Pradesh)'},
{name: 'Marlapadu'},
{name: 'Marlapalem'},
{name: 'Nandyala Bypass'},
{name: 'Ontimitta (Andhra Pradesh)'},
{name: 'Rejerla'},
{name: 'Thallada'},
{name: 'Verapunaayuni Palli'},
{name: 'Vemsuru'},
{name: 'Venkatapuram (Andhra Pradesh)'},
{name: 'Visannapeta'},
{name: 'VM Banjar (Telangana)'},
{name: 'Himmtanagar'},
{name: 'Varadhalli'},
{name: 'Huncha'},
{name: 'Authoor North'},
{name: 'Authoor South'},
{name: 'Nallumavadi'},
{name: 'Sempatti'},
{name: 'Uthamapalayam Byepass'},
{name: 'Ahmedabad (naroda)'},
{name: 'Ahmedabad (paldi)'},
{name: 'Anida'},
{name: 'Bhalvav'},
{name: 'Bhanchha'},
{name: 'Bhandariya'},
{name: 'Bhavnagar (by Pass)'},
{name: 'Bodhan (Telangana)'},
{name: 'Budhel'},
{name: 'Gandhipuram (Tamil Nadu)'},
{name: 'Baswakalyan'},
{name: 'Ahmedabad Modasa'},
{name: 'Gomati Chouraya'},
{name: 'Keelasevalpatti'},
{name: 'Hebbal'},
{name: 'Valaparla'},
{name: 'Konanki'},
{name: 'Kanchikacherla'},
{name: 'Nandigama (Andhra Pradesh)'},
{name: 'Arasikere'},
{name: 'Chittorgarh(by-pass)'},
{name: 'Mutagi Cross (Karnataka)'},
{name: 'Ramanagar(bengaluru)'},
{name: 'Santhebachalli'},
{name: 'Siddapura (Kundapur'},
{name: 'Bethamcherla'},
{name: 'Banaganapalli'},
{name: 'Somandepalli'},
{name: 'Kalavapamula'},
{name: 'Poranki'},
{name: 'Munipeda'},
{name: 'Kalasipaliyam'},
{name: 'Madivala'},
{name: 'Manapad'},
{name: 'Kattankulathur'},
{name: 'Singanallur (Tamil Nadu)'},
{name: 'Sikkal'},
{name: 'Chinnaganjam'},
{name: 'Darsi'},
{name: 'E.Kota'},
{name: 'Ubalanka'},
{name: 'Kandavalli (Karnataka)'},
{name: 'Allampuram'},
{name: 'Mangalagiri'},
{name: 'Gudlavalleru'},
{name: 'Manchili'},
{name: 'RAJAHMUNDRY LALACHERUV BY-PASS'},
{name: 'RAJAHMUNDRY VEMAGIRI BY-PASS'},
{name: 'Kurichedu'},
{name: 'Kovai'},
{name: 'Nagarjuna Sagar'},
{name: 'Perunthalmanna'},
{name: 'Thangampadi'},
{name: 'Udhagamandalam'},
{name: 'Chinaoutpalli'},
{name: 'Thurimella'},
{name: 'Mokshagundam'},
{name: 'Panyam'},
{name: 'Pusalapadu'},
{name: 'Tharlu Padu'},
{name: 'Saverdem'},
{name: 'Saquelim'},
{name: 'Marcel'},
{name: 'Gondavle'},
{name: 'Kodada'},
{name: 'Kurudwadi'},
{name: 'Yadsi'},
{name: 'Shirur (Taj)'},
{name: 'Andalur Gate'},
{name: 'Dharapuram Bypass'},
{name: 'Paramathi Bypass'},
{name: 'Salem Seelanaickenpatti Bypass'},
{name: 'Velayuthampalayam Bypass'},
{name: 'Kulachel'},
{name: 'RR Nagar(Sattur)'},
{name: 'Panaikudi'},
{name: 'Kambam'},
{name: 'Komaralingam'},
{name: 'Adhirampattinam'},
{name: 'Vaitheeswaran koil'},
{name: 'Peralam (Tamil Nadu)'},
{name: 'Vadasery Pond'},
{name: 'Jojawar(Rajasthan)'},
{name: 'AHEMDABAD'},
{name: 'HIMMAT NAGAR'},
{name: 'Mundara'},
{name: 'Dhanla (Rajasthan)'},
{name: 'Sindarli'},
{name: 'Rajanand Goan'},
{name: 'Cumbum (Andhra Pradesh)'},
{name: 'Polur'},
{name: 'Ambarnath'},
{name: 'Mumbai (Dadar)'},
{name: 'Sumerpur'},
{name: 'Kallady'},
{name: 'Komanapalli'},
{name: 'Palavela'},
{name: 'Timmapuram(East Godavari)'},
{name: 'Pangidi Gudem'},
{name: 'Jalsoor'},
{name: 'Galimukha'},
{name: 'Mulleria'},
{name: 'Poovadukka'},
{name: 'Karadka'},
{name: 'Kuttoor'},
{name: 'Mooliyar'},
{name: 'Bovikanam'},
{name: 'Attayampatti'},
{name: 'Kakapalayam'},
{name: 'Vennandur'},
{name: 'Kariapatti'},
{name: 'Thoppampady'},
{name: 'Swamimalai'},
{name: 'Kottoor'},
{name: 'Bellare(Sullia)'},
{name: 'Pamban'},
{name: 'Thottilpalam'},
{name: 'Kuttiadi'},
{name: 'Perambra'},
{name: 'Ulliyeri'},
{name: 'Balussery'},
{name: 'Bekal'},
{name: 'Uthuma'},
{name: 'Pampa Trip'},
{name: 'Kuttiady'},
{name: 'Kaliyakkavilai'},
{name: 'Kanhangad'},
{name: 'Uduma'},
{name: 'Kannur(Kerala)'},
{name: 'Iliyankudi'},
{name: 'Gudalur'},
{name: 'Derisanamcope'},
{name: 'Belagavi'},
{name: 'Kottara Chowki'},
{name: 'Kaladaka'},
{name: 'Athrady'},
{name: 'Heriyadka'},
{name: 'Beuvei'},
{name: 'Lakshmishwara'},
{name: 'Kurugodu (Karnataka)'},
{name: 'Itanagar'},
{name: 'Nirjuli'},
{name: 'Tinsukia'},
{name: 'Chabua'},
{name: 'Margherita'},
{name: 'Digboi'},
{name: 'Moran (Assam)'},
{name: 'Jagun (Assam)'},
{name: 'Bandardwa'},
{name: 'Naharlagan'},
{name: 'Baroda -vadodara'},
{name: 'Anand Nadiyad'},
{name: 'Ustin'},
{name: 'Sudhagad'},
{name: 'Trimbakeshwar'},
{name: 'Thoseghar'},
{name: 'Sindhudurg'},
{name: 'Karmala (Solapur Dist'},
{name: 'Torna (Maharashtra)'},
{name: 'Tung Tikona'},
{name: 'Lohagad'},
{name: 'Diveagar'},
{name: 'Panhala'},
{name: 'Tapola'},
{name: 'Pandharpur Akkalkot Tuljapur'},
{name: 'Guhaghar'},
{name: 'Harihareshwar'},
{name: 'Vijaydurg'},
{name: 'Karnala (Maharashtra)'},
{name: 'Bhandardara'},
{name: 'Chafal'},
{name: 'Paithan'},
{name: 'Pratapgad (Maharastra)'},
{name: 'Purandhar'},
{name: 'Sinhagad'},
{name: 'Narayanpur'},
{name: 'Vajreshwari'},
{name: 'Mumbai International Airport'},
{name: 'Theur'},
{name: 'Alibagh'},
{name: 'Ajinkyatara'},
{name: 'Chikhaldara'},
{name: 'Harishchandragad (Maharastra)'},
{name: 'Narsi (Nanded)'},
{name: 'Shrigonda'},
{name: 'Shanishignapur'},
{name: 'Deorukh (Devrukh)'},
{name: 'Chinchwad'},
{name: 'Gargoti (Kolhapur)'},
{name: 'Harne (Maharastra)'},
{name: 'Phardapur (Maharashtra)'},
{name: 'Shirol (Maharashtra)'},
{name: 'Vaibhavwadi'},
{name: 'Koynanagar'},
{name: 'Nira (Maharastra)'},
{name: 'Ojhar (Maharashtra)'},
{name: 'Ajra'},
{name: 'Dahanu (Maharashtra)'},
{name: 'Narsobawadi'},
{name: 'Palghar'},
{name: 'Pawas'},
{name: 'Radhanagari'},
{name: 'Jaigad'},
{name: 'Jejuri (Maharashtra)'},
{name: 'Bhuleshwar'},
{name: 'Gaganbavada'},
{name: 'Daulatabad (Maharashtra)'},
{name: 'Naldurg (Osmanabad)'},
{name: 'Pabal (Maharastra)'},
{name: 'Ralegan Siddhi'},
{name: 'Vadhu Tulapur'},
{name: 'Bhaindar'},
{name: 'Dehu'},
{name: 'Janjira'},
{name: 'Lenyadri'},
{name: 'Srivardhan'},
{name: 'Bhimashankar'},
{name: 'Rajmachi'},
{name: 'Kankeshwar'},
{name: 'Sajjangad (Maharashtra)'},
{name: 'Saswad'},
{name: 'Varandha Ghat - Shivtharghal'},
{name: 'Siddhatek'},
{name: 'Karjat'},
{name: 'Akara Maruti Kolhapur Wadi'},
{name: 'Kokan Darshan - 1'},
{name: 'Diglur'},
{name: 'Salvador'},
{name: 'Kokan Darshan - 2'},
{name: 'Manwath'},
{name: 'Aurangabad-ajanta-ellora'},
{name: 'Mahabaleshwar-pratapgad-tapola'},
{name: 'Goa-madgaon'},
{name: 'Waghala Nanded'},
{name: 'Konkan-costal Ganpatipule'},
{name: 'Konkan-royal Sindhudurga'},
{name: 'Aizawl'},
{name: 'Impal'},
{name: 'Kohima'},
{name: 'Silchar'},
{name: 'Amguri'},
{name: 'Hailakandi'},
{name: 'Namrup'},
{name: 'Namsai'},
{name: 'North Lakhimpur (Assam)'},
{name: 'Pasighat'},
{name: 'Silapathar'},
{name: 'Sibsagar'},
{name: 'Haflong'},
{name: 'Dhemaji (Assam)'},
{name: 'Gogamukh'},
{name: 'Rupai (Uttar Pradesh)'},
{name: 'Gohpur (Assam)'},
{name: 'Duliajan'},
{name: 'Doomdooma'},
{name: 'Ledo (Assam)'},
{name: 'Jonai'},
{name: 'Naharkatia'},
{name: 'Dimapur (Nagaland)'},
{name: 'Baltipur'},
{name: 'Tola (Bihar)'},
{name: 'Vishala'},
{name: 'Jambusar'},
{name: 'Tirupur(Avinasi)'},
{name: 'Orambu (Tamil Nadu)'},
{name: 'Nalgonda Bypass'},
{name: 'Miryalguda Bypass'},
{name: 'Chettuva'},
{name: 'Chendrappinni'},
{name: 'Engandiyoor'},
{name: 'Peravaram'},
{name: 'Velicheru'},
{name: 'Vaddiparru'},
{name: 'Vutchili'},
{name: 'Lolla'},
{name: 'Ethakota'},
{name: 'Kuravilagad*'},
{name: 'KG Chavadi'},
{name: 'Karadka (Kerala)'},
{name: 'Mooliyar (Kerala)'},
{name: 'Muttuchira (Kerala)'},
{name: 'Kalyandurgam'},
{name: 'Komarapalayam'},
{name: 'Anthiyur'},
{name: 'Sathi (Bihar)'},
{name: 'Chennimalai'},
{name: 'Syedunganallur'},
{name: 'Penukonda Bye Pass'},
{name: 'Somandepalli Bye Pass'},
{name: 'Dwarakatirumala'},
{name: 'Tirumalapalem'},
{name: 'Bayyana Gudem'},
{name: 'Borrampalem (West Godavari)'},
{name: 'Yerraguntapally'},
{name: 'Mancherial'},
{name: 'Godavarikhani'},
{name: 'Asifabad (Telangana)'},
{name: 'Bhainsa'},
{name: 'Basara'},
{name: 'Kunta (Markapuram)'},
{name: 'Illandu'},
{name: 'Jaggaiahpet'},
{name: 'Kalluru (Telangana)'},
{name: 'Kalvakurthy'},
{name: 'Kamalapuram (Andhra Pradesh)'},
{name: 'Madgao'},
{name: 'Mapusa'},
{name: 'Muringoor'},
{name: 'Mahabubabad'},
{name: 'Madhira'},
{name: 'Mahanandi'},
{name: 'Mahol (Maharastra)'},
{name: 'Makthal (Telangana)'},
{name: 'Mallela'},
{name: 'Mamdabad (Maharashtra)'},
{name: 'Mandalapalli'},
{name: 'Mandamarri (Telangana)'},
{name: 'Mondikunta'},
{name: 'Mangapet (Telangana)'},
{name: 'Maredumilli'},
{name: 'Marikal (Telangana)'},
{name: 'Kadiri Bus Stand'},
{name: 'Madanapalli Bye Pass'},
{name: 'Palmaner RTO Office'},
{name: 'Kuppam Bye Pass'},
{name: 'Mayaluru'},
{name: 'Medak (Telangana)'},
{name: 'Medaram (Telangana)'},
{name: 'Mohammadabad (Andhra Pradesh)'},
{name: 'Munduru (Andhra Pradesh)'},
{name: 'Mothugudem'},
{name: 'Asram Hospital'},
{name: 'Denduluru'},
{name: 'Gundugolanu Center'},
{name: 'Bhimadolu Gate (Dwarka tirumala)'},
{name: 'Chebrolu Gate (W.G)'},
{name: 'Pathapadu By-pass'},
{name: 'Machavaram Bridge'},
{name: 'Pasarlapudi Lakulu'},
{name: 'Gati (East Godavari'},
{name: 'Mvpalem'},
{name: 'Naguluppalapadu'},
{name: 'Nagayalanka'},
{name: 'Nancherla'},
{name: 'Nandikotkur'},
{name: 'Nandipadu (Andhra Pradesh)'},
{name: 'Narketpally'},
{name: 'Narsampet'},
{name: 'Nereducharla'},
{name: 'Nizamsagar'},
{name: 'Nossam'},
{name: 'Owk'},
{name: 'Paderu'},
{name: 'Pamidi'},
{name: 'Pamidipadu'},
{name: 'Parnasala (Telangana)'},
{name: 'Pasra'},
{name: 'Pebbair'},
{name: 'Peddapalli (Telangana)'},
{name: 'Podalakur'},
{name: 'Polavaram'},
{name: 'Pothanal'},
{name: 'Pragadavaram'},
{name: 'Putalpattu'},
{name: 'Putrela'},
{name: 'Pyapili'},
{name: 'R.kothagudem'},
{name: 'Rachapudi'},
{name: 'Raghavapuram (Andhra Pradesh)'},
{name: 'Rajupeta (Telangana)'},
{name: 'Ramayampet'},
{name: 'Rampachodavaram'},
{name: 'Ranasthalam'},
{name: 'Rangapuram'},
{name: 'Rapur (Andhra Pradesh)'},
{name: 'Rayalacheruvu (Andhra Pradesh)'},
{name: 'Rebbana'},
{name: 'Santhi Puram'},
{name: 'Siddipet'},
{name: 'Sileru (Andhra Pradesh)'},
{name: 'Simhadripuram'},
{name: 'Sitaramapuram (Krishna Dist'},
{name: 'Somala'},
{name: 'Srungavarapu Kota'},
{name: 'Sunnipenta'},
{name: 'Suryapet'},
{name: 'T narasapuram'},
{name: 'Tadikalapudi'},
{name: 'Tagarapuvalasa'},
{name: 'Talla Proddutur'},
{name: 'Tandur'},
{name: 'Tekulapalli'},
{name: 'Thallapalli'},
{name: 'Thatha Hotel'},
{name: 'Thipparthy'},
{name: 'Thondur (Andhra Pradesh)'},
{name: 'Tilak Nagar Godavarikhani'},
{name: 'Tiruvuru (Andhra Pradesh)'},
{name: 'Tongpal'},
{name: 'Uppugunduru'},
{name: 'Utnoor (Telangana)'},
{name: 'Valetivari Palem'},
{name: 'Varikuntapadu'},
{name: 'Vakadu'},
{name: 'Veeranarayana Pally'},
{name: 'Veerankilaku'},
{name: 'Veligandla'},
{name: 'Velugodu'},
{name: 'Vemulawada'},
{name: 'Venkatagiri'},
{name: 'Vijayrai'},
{name: 'Vikarabad'},
{name: 'Wazedu'},
{name: 'Yadiki'},
{name: 'Yapa'},
{name: 'Yella Reddy'},
{name: 'Zeelakarra Gudem'},
{name: 'Cherla'},
{name: 'Indravathi'},
{name: 'Ichoda'},
{name: 'Ib Tandur (Telangana)'},
{name: 'Indrevalli (Telangana)'},
{name: 'Jagdalpur (Chhattisgarh)'},
{name: 'Jangoan'},
{name: 'Jannaram'},
{name: 'Jarugumalli'},
{name: 'Julurupadu (Telangana)'},
{name: 'Huzurnagar'},
{name: 'Huzurabad (Telangana)'},
{name: 'Alluru (Krishna Dist)'},
{name: 'Alapadu'},
{name: 'Ankalamma Gudur'},
{name: 'Annaram'},
{name: 'Annasamudram Pet'},
{name: 'B.gudur'},
{name: 'Bacholi'},
{name: 'Baireddipalle'},
{name: 'Bangarupalyem'},
{name: 'Banswada (Telangana)'},
{name: 'Bayyaram'},
{name: 'Bellampally'},
{name: 'Kunta (Bhadrachalam)'},
{name: 'Kundurpi'},
{name: 'Bhupalpally'},
{name: 'Burakayala Kota'},
{name: 'Chandrasekharapuram'},
{name: 'Chakalikonda (Udayagiri)'},
{name: 'Yerraballi (Udayagiri)'},
{name: 'Chintapally(Telangana)'},
{name: 'Mantada (Andhra Pradesh)'},
{name: 'Gajapathinagaram (Andhra Pradesh)'},
{name: 'Nanital'},
{name: 'Atmakur Kurnool'},
{name: 'Atmakur Ananthapur'},
{name: 'Krishnayapalem (W.G)'},
{name: 'Kapavaram'},
{name: 'Pusapati Rega'},
{name: 'Paidi Bhimavaram'},
{name: 'Choutuppal'},
{name: 'Shadnagar (Telangana)'},
{name: 'Rajanagaram Bye Pass'},
{name: 'Shamshabad (Madhya Pradesh)'},
{name: 'AnnambhotlavariPalem'},
{name: 'Pasumarthi'},
{name: 'ThimmarajuPalem'},
{name: 'Nandimandalam'},
{name: 'Pendlimarri'},
{name: 'Kazipet (Telangana)'},
{name: 'Srisailam'},
{name: 'Vempalli IIIT'},
{name: 'Chinnamandem'},
{name: 'Madhepadu'},
{name: 'Kaij'},
{name: 'Chebrolu(West Godavari)'},
{name: 'Ervadi-Nellai'},
{name: 'Idaikal'},
{name: 'Pabanasam'},
{name: 'Sirumugai'},
{name: 'Madugapati'},
{name: 'Donamalai'},
{name: 'Koilakuntla'},
{name: 'Tripuranthakam'},
{name: 'Koduru (Avanigadda'},
{name: 'Amarapuram'},
{name: 'Ambavaram'},
{name: 'Anathasagaram'},
{name: 'Ballarsha'},
{name: 'Bandera'},
{name: 'Baruva'},
{name: 'Battili'},
{name: 'Belsar (Bihar)'},
{name: 'Abbaipalem (Telangana)'},
{name: 'Cheriyal'},
{name: 'Chevella'},
{name: 'Galivedu'},
{name: 'Maddipadu (Prakasam Dist'},
{name: 'Mayapur (West Bengal)'},
{name: 'Morampudi Junction'},
{name: 'Garividi'},
{name: 'Nellimarla'},
{name: 'Kothavalasa'},
{name: 'Mukhiguda Township'},
{name: 'Yellandu'},
{name: 'T.P.Gudem bridge'},
{name: 'Chalakudi'},
{name: 'Nidamolu'},
{name: 'Nimmakuru'},
{name: 'Chinna Goshala'},
{name: 'Pedda Goshala'},
{name: 'Penamaluru'},
{name: 'Pudukadai'},
{name: 'Ashtavinayak Darshan'},
{name: 'Sonari (Assam)'},
{name: 'Baindur(Mookambika Temple)'},
{name: 'Mookambika(Baindur Dropping)'},
{name: 'Nadapuram'},
{name: 'Kallachi'},
{name: 'Sricity'},
{name: 'Kalana (Rajasthan)'},
{name: 'Moti Marad'},
{name: 'Rahmatabad (Nellore Dist)'},
{name: 'Ambardi'},
{name: 'Amrapur (Gujarat)'},
{name: 'Anand Express Highway'},
{name: 'Ankadiya'},
{name: 'Ansodar'},
{name: 'Bayad'},
{name: 'Bhader (Gujarat)'},
{name: 'Bhadrod'},
{name: 'Bhingrad'},
{name: 'Bhurkhiya'},
{name: 'Borala (Maharastra)'},
{name: 'Chavand (Gujarat)'},
{name: 'Chhokarwara'},
{name: 'Dahegam (Gujarat)'},
{name: 'Damnagar'},
{name: 'Datardi (Gujarat)'},
{name: 'Dem (Palitana)'},
{name: 'Devaliya (Gujarat)'},
{name: 'Devpar (Yaksh)'},
{name: 'Devrajiya'},
{name: 'Dhamel'},
{name: 'Dolasa (Gujarat)'},
{name: 'Dudhala'},
{name: 'Engaon'},
{name: 'Fulsar (Gujarat)'},
{name: 'Gal Kotadi (Gujarat)'},
{name: 'Gangada (Gujarat)'},
{name: 'Ghankheda (Maharashtra)'},
{name: 'Ghantvad'},
{name: 'Gharaunda'},
{name: 'GokharVala'},
{name: 'Gopnath'},
{name: 'Gordaka'},
{name: 'Gundala'},
{name: 'HajiraDhar'},
{name: 'Harankhed Phata'},
{name: 'Haripar (Gujarat)'},
{name: 'Hathigadh'},
{name: 'Hemal'},
{name: 'Jambhuldhaba'},
{name: 'Jamvala'},
{name: 'Javra'},
{name: 'Jetalvad'},
{name: 'Kacharadi'},
{name: 'Kalsari (Gujarat)'},
{name: 'Kankot (Gujarat)'},
{name: 'Khambhda'},
{name: 'Khirsara'},
{name: 'Khombhadi (Khombhdi)'},
{name: 'Kobadi'},
{name: 'Krakach'},
{name: 'Kundal (Gujarat)'},
{name: 'Kundheli'},
{name: 'Lapaliya'},
{name: 'Layja Nana'},
{name: 'Liliya'},
{name: 'Limda'},
{name: 'Longadi'},
{name: 'Lonwadi'},
{name: 'Lunki (Gujarat)'},
{name: 'Maldabhadi'},
{name: 'Malpara (Gujarat)'},
{name: 'Malsika'},
{name: 'Manwath Road'},
{name: 'Mashiyala'},
{name: 'Morjar (Gujarat)'},
{name: 'Mota Charodiya'},
{name: 'Moti Kunkavav'},
{name: 'Nageshree (Gujarat)'},
{name: 'Nana Rajkot'},
{name: 'Navagam (Gujarat)'},
{name: 'Oliya'},
{name: 'Padarshinga'},
{name: 'Panchtalavada'},
{name: 'Pardi (Valsad Dist'},
{name: 'Parvala (Gujarat)'},
{name: 'Piplava'},
{name: 'Rajur (Maharastra)'},
{name: 'Raniyala (Gujarat)'},
{name: 'Rohisha'},
{name: 'Samagoga'},
{name: 'Samdhiyala'},
{name: 'Sametar'},
{name: 'Sanaliya'},
{name: 'Sanvav'},
{name: 'Sarkhadiya'},
{name: 'Sevaliya'},
{name: 'Shahpura (Paota'},
{name: 'Shedubhar'},
{name: 'Todiya'},
{name: 'Trapaj'},
{name: 'Umrala (Gujarat)'},
{name: 'Unchadi'},
{name: 'Vadagam'},
{name: 'Vadviyala'},
{name: 'Vaghaniya'},
{name: 'Varkhed'},
{name: 'Varsada'},
{name: 'Vasad Chokdi'},
{name: 'Vasai Phata'},
{name: 'Vekariya'},
{name: 'Vithon'},
{name: 'Harapanalli'},
{name: 'Kora (Rajasthan)'},
{name: 'Mengalwa'},
{name: 'Padra (Gujarat)'},
{name: 'Arthungal Bye Pass'},
{name: 'Umedabad'},
{name: 'Saibrakatte'},
{name: 'Thalguppa'},
{name: 'Porurchatram'},
{name: 'Paravur'},
{name: 'Vatanapally'},
{name: 'Batala Gurdaspur'},
{name: 'Muttanur'},
{name: 'Bylukuppe'},
{name: 'Vaikkom Byepass'},
{name: 'Holehonnur'},
{name: 'Puthiyakavu'},
{name: 'Hassan(Bypass)'},
{name: 'Ichalakaranji By Pass'},
{name: 'Semthamaram'},
{name: 'Manarkadu'},
{name: 'Nellikuzhi (kerala)'},
{name: 'Koorkenchery (Kerala)'},
{name: 'Waknaghat'},
{name: 'Bagdogra'},
{name: 'Modran'},
{name: 'Posana'},
{name: 'Bakra Road'},
{name: 'Borata (Madhya Pradesh)'},
{name: 'Dhansha (Himachal Pradesh)'},
{name: 'Barloot'},
{name: 'Leta (Rajasthan)'},
{name: 'Mandawariya'},
{name: 'Galiveedu'},
{name: 'Dindigul Bye Pass'},
{name: 'Tirunelveli Bye Pass'},
{name: 'Bagodar (Jharkhand)'},
{name: 'Chatra (Jharkhand)'},
{name: 'Hunterganj'},
{name: 'Kancharlavaripalle (Andhra Pradesh)'},
{name: 'Daggubadu'},
{name: 'Nuthalapadu'},
{name: 'Pusapadu X Road'},
{name: 'Vankayalapadu'},
{name: 'Mattu Thavani'},
{name: 'Murdeshwara Bypass'},
{name: 'Magadi'},
{name: 'Keelapavoor'},
{name: 'Racherla'},
{name: 'Mehandipur'},
{name: 'Sriramanagara'},
{name: 'Jagallur'},
{name: 'Ujjani (Maharastra)'},
{name: 'Thodar (Karnataka)'},
{name: 'Devikot (Rajasthan)'},
{name: 'Nellyadi'},
{name: 'Joduraste'},
{name: 'Koilandy'},
{name: 'Hosangadi(Kerala)'},
{name: 'Kottakar(Karnataka)'},
{name: 'Meleparambu'},
{name: 'Mogralputhur'},
{name: 'Naya Bazar'},
{name: 'Palakunnu'},
{name: 'Thalappady (Kerala)'},
{name: 'Udyawar (Kerala)'},
{name: 'Uppala'},
{name: 'Shamshabad Bye Pass'},
{name: 'Jadcherla Bye Pass'},
{name: 'Kottiyam'},
{name: 'Bhadravathi(Bypass)'},
{name: 'Chennur (Andhra Pradesh)'},
{name: 'Singarayakonda Bypass'},
{name: 'Aburoad By Pass'},
{name: 'Karkala Bypass'},
{name: 'Balapura'},
{name: 'Santhu(Rajasthan)'},
{name: 'Virana'},
{name: 'Kaniyapuram'},
{name: 'Kallambalam'},
{name: 'Chinnasalem'},
{name: 'Serndamaram'},
{name: 'Virasigamani'},
{name: 'Dodamarg (Maharashtra)'},
{name: 'Gokarna Bypass'},
{name: 'Chimakurthy'},
{name: 'Manjari (Karnataka)'},
{name: 'NITK - SURATHKAL'},
{name: 'Bijainagar(Rajasthan)'},
{name: 'Gollagudem'},
{name: 'Pokuru'},
{name: 'Chundi (Andhra Pradesh)'},
{name: 'Malakonda'},
{name: 'Botlaguduru'},
{name: 'D.G Peta'},
{name: 'Aaradhana Dham'},
{name: 'Adhewada'},
{name: 'Aghevada'},
{name: 'Akolali-Jaliya'},
{name: 'Amba (Gujarat)'},
{name: 'Anjiyakadi'},
{name: 'Asarana (Orissa)'},
{name: 'Bagdana'},
{name: 'Bapada'},
{name: 'Barapal'},
{name: 'Bavadi (Gujarat)'},
{name: 'Bawari'},
{name: 'Begun By Pass'},
{name: 'Bhadra Patiya'},
{name: 'Bhadrawati (Maharastra)'},
{name: 'Bhoringda'},
{name: 'Bijoliya By Pass'},
{name: 'Dabi (Rajasthan)'},
{name: 'Dahod By Pass'},
{name: 'Delwara'},
{name: 'Dhangala (Gujarat)'},
{name: 'Dhanora (Maharastra)'},
{name: 'Dhoraji By Pass'},
{name: 'EdlaBad Phata'},
{name: 'Fachariya'},
{name: 'Fatsar'},
{name: 'Galthar (Gujarat)'},
{name: 'Godhavadar'},
{name: 'Gundran'},
{name: 'Hajipur Patiya'},
{name: 'Harshad Mandir'},
{name: 'Havtad'},
{name: 'Ingorala (Dand)'},
{name: 'Jadra (Gujarat)'},
{name: 'Karoi (Rajasthan)'},
{name: 'Khari (Gujarat)'},
{name: 'Killa Pardi'},
{name: 'Kumbhariya (Gujarat)'},
{name: 'Kuranga (Gujarat)'},
{name: 'Kutana (Gujarat)'},
{name: 'Lasur (Maharashtra)'},
{name: 'Lukhransar'},
{name: 'Mangaliyawas'},
{name: 'Maulasar'},
{name: 'Mokhadaka'},
{name: 'Monpar'},
{name: 'Mota Asrana'},
{name: 'Mota Surka'},
{name: 'Mota Kapaya'},
{name: 'Moti Vaavdi'},
{name: 'Mundra Samudra TWNSHP'},
{name: 'Nadiad By Pass'},
{name: 'Nadole'},
{name: 'Nagdhanimba'},
{name: 'Nala Sopara'},
{name: 'Nani Vavdi'},
{name: 'Nesvad'},
{name: 'Okha (Gujarat)'},
{name: 'Oras Bypass'},
{name: 'Panchpipla (Gujarat)'},
{name: 'Parippally'},
{name: 'Parvadi'},
{name: 'Pasvi'},
{name: 'Pithalpur (Gujarat)'},
{name: 'Pithampur Byepass'},
{name: 'Potlan'},
{name: 'Punjapadar'},
{name: 'Pushkar Ajmer Bypass'},
{name: 'Raila (Rajasthan)'},
{name: 'Rajpara Khodiyar'},
{name: 'Rajpara (Tansa)'},
{name: 'Rampar Vekra'},
{name: 'Ranghoda'},
{name: 'Rasaliya'},
{name: 'Ratanpar (Gujarat)'},
{name: 'Sanjan'},
{name: 'Sapar Veraval'},
{name: 'Sardargadh By Pass'},
{name: 'Sarigam'},
{name: 'Shakhpur (Gujarat)'},
{name: 'Sheoganj'},
{name: 'Sonaradi (Gujarat)'},
{name: 'Surnagar'},
{name: 'Talod (Gujarat)'},
{name: 'Thadach'},
{name: 'Thaliya (Gujarat)'},
{name: 'Timbdi'},
{name: 'Ulhasnagar Bypass'},
{name: 'Vaalukad'},
{name: 'Vagara'},
{name: 'Vartej'},
{name: 'Vavdi'},
{name: 'Velavadar'},
{name: 'Virar Phata'},
{name: 'Virpur Bypass'},
{name: 'Warora'},
{name: 'Gudalur - Cumbum TN'},
{name: 'Atturbypass'},
{name: 'Thiruppur'},
{name: 'Valappady'},
{name: 'Chethod'},
{name: 'Dindigulbypass'},
{name: 'Cumbum(tamilnadu)'},
{name: 'Dindigul(busstand)'},
{name: 'Chillakallu'},
{name: 'Guduru (Krishna District)'},
{name: 'Jujjavaram'},
{name: 'Kosta (Andhra Pradesh)'},
{name: 'Kurumaddali'},
{name: 'Nidumolu'},
{name: 'Pydibhimavaram'},
{name: 'Chinarikatla'},
{name: 'Gooty Toll Gate'},
{name: 'Kothanalloor (Kerala)'},
{name: 'Devapur(Telangana)'},
{name: 'Sriramnagar (Tamil Nadu)'},
{name: 'Kolenchery'},
{name: 'Puthencruz'},
{name: 'Pambanar'},
{name: 'Chapadu'},
{name: 'Sangam Nellore'},
{name: 'Nesari'},
{name: 'A.S.Peta Bypass'},
{name: 'Kandukuru (Khammam Dist'},
{name: 'Aurad Shahajani'},
{name: 'Aathipatti'},
{name: 'Alwarkurichi'},
{name: 'Kalloorani'},
{name: 'M.Rediapatti'},
{name: 'Mandapasalai'},
{name: 'Paralachi'},
{name: 'Perunali'},
{name: 'Poolangal'},
{name: 'Tori (Jharkhand)'},
{name: 'Manjhi'},
{name: 'Revelganj'},
{name: 'Bairiya'},
{name: 'Coiambatore Bypass'},
{name: 'Athirai'},
{name: 'Bani'},
{name: 'Sahawa (Rajasthan)'},
{name: 'Phephana (Rajasthan)'},
{name: 'Jamal'},
{name: 'Khariyan (Haryana)'},
{name: 'Dhudianwali'},
{name: 'Thasra'},
{name: 'Umreth (Gujarat)'},
{name: 'Pansora (Gujarat)'},
{name: 'Mahudha'},
{name: 'Kathlal'},
{name: 'Kujad'},
{name: 'Kanbha (Gujarat)'},
{name: 'Veeracholan'},
{name: 'Avudaiyarkoil'},
{name: 'Alangudi (Pudhukottai)'},
{name: 'Kallur (Kerala)'},
{name: 'Kandanur (Tamil Nadu)'},
{name: 'Kottaiyur (Tamil Nadu)'},
{name: 'Pudhupatti'},
{name: 'Bhakarapeta (Chittoor Dist'},
{name: 'Madhavgarh (Madhya Pradesh)'},
{name: 'Itempudi'},
{name: 'Aul (Orissa)'},
{name: 'Ersama'},
{name: 'Annadevarapadu'},
{name: 'Gajjaram'},
{name: 'Keela Eral'},
{name: 'Kurukkuchalai'},
{name: 'Thattaramadam'},
{name: 'Therkathur'},
{name: 'Umarikadu'},
{name: 'Vadakathur'},
{name: 'Choppadandi'},
{name: 'Rayapatnam'},
{name: 'Kazipet ByPass'},
{name: 'Patamundai'},
{name: 'Nellikar (Karnataka)'},
{name: 'Vaggampalle'},
{name: 'Narrawada'},
{name: 'Duttalur'},
{name: 'Lingasamudram'},
{name: 'Kondapuram (Pulivendula'},
{name: 'Marripadu'},
{name: 'Pulpally'},
{name: 'Bhilwara - Delhi'},
{name: 'Upleta Byepass'},
{name: 'Epuru Guntur Dist.'},
{name: 'Tettu'},
{name: 'Tettu Bye Pass'},
{name: 'Ulavapadu Bye Pass'},
{name: 'Sarni MP'},
{name: 'Helapuri'},
{name: 'Dharmajigudem'},
{name: 'Kalligudi'},
{name: 'Thiruparankundram'},
{name: 'Garhakota (Madhya Pradesh)'},
{name: 'Bandakpur'},
{name: 'Kumhari (Madhya Pradesh)'},
{name: 'Raipura Muhsam'},
{name: 'Gairatganj'},
{name: 'Begamganj'},
{name: 'Rahatgarh'},
{name: 'Masrakh'},
{name: 'Malarampura'},
{name: 'Madhepura (Bihar)'},
{name: 'Dhone Bye Pass'},
{name: 'Gorihar (Madhya Pradesh)'},
{name: 'Gunour (Madhy Pradesh)'},
{name: 'Khajuraho'},
{name: 'Naugaon (Madhya Pradesh)'},
{name: 'Prathipadu Guntur Dist'},
{name: 'Prathipadu West Godavari Dist'},
{name: 'Seth Vadala'},
{name: 'Korba (Chhattisgarh)'},
{name: 'Kakani By Pass'},
{name: 'Waghrul'},
{name: 'Deulgaon Mahi'},
{name: 'Andhera'},
{name: 'Bharosa'},
{name: 'Chikhli Buldana'},
{name: 'Kelwad (Maharashtra)'},
{name: 'Chikhli Buldhana'},
{name: 'Dhalegaon (Beed)'},
{name: 'Majalgaon'},
{name: 'Sirasdevi'},
{name: 'Pathri (Maharashtra)'},
{name: 'Fekari'},
{name: 'Nari (Maharastra)'},
{name: 'Idayamelur'},
{name: 'Kannirajapuram'},
{name: 'Madukulathur'},
{name: 'Narippaiyur'},
{name: 'Peraiyur'},
{name: 'Soolakkarai'},
{name: 'Meensurutty'},
{name: 'Sethiyarthope'},
{name: 'Thulasiyapattinam'},
{name: 'Vadamadurai'},
{name: 'Kayatharu'},
{name: 'Melapalayam'},
{name: 'Melacheval'},
{name: 'Kanganakulam'},
{name: 'Biyavra'},
{name: 'Nasrullaganj'},
{name: 'Pathapatnam'},
{name: 'Challavanipeta'},
{name: 'Saravakota'},
{name: 'Kotagiri (Telangana)'},
{name: 'Kullu ByPass'},
{name: 'Chamba (Himachal Pradesh)'},
{name: 'Dalhousie'},
{name: 'Bharwain'},
{name: 'Dhalyara'},
{name: 'Bankhandi'},
{name: 'Ranital (Himachal Pradesh)'},
{name: 'Punchrookhi'},
{name: 'Keylong (Himachal Pradesh)'},
{name: 'Sarkaghat'},
{name: 'Gagal (Himachal Pradesh)'},
{name: 'Tulah'},
{name: 'Chadhiar'},
{name: 'Jangal Beri'},
{name: 'Paonta Sahib (Himachal Pradesh)'},
{name: 'Hamirpur (Himachal Pradesh)'},
{name: 'Nahan'},
{name: 'Nurpur (Himachal Pradesh)'},
{name: 'Reckong Peo (Himachal Pradesh)'},
{name: 'Nalagarh (Himachal Pradesh)'},
{name: 'Sandhol'},
{name: 'Uhal (Himachal Pradesh)'},
{name: 'Awah Devi'},
{name: 'Jassur'},
{name: 'Rohru'},
{name: 'Patlandar'},
{name: 'Marotan'},
{name: 'Chamunda'},
{name: 'Janjheli'},
{name: 'Parwanoo'},
{name: 'Kaviti'},
{name: 'Rehan'},
{name: 'Jhandutta'},
{name: 'Ghumarwin'},
{name: 'Raja Ka Talab'},
{name: 'Jawali (Himachal Pradesh)'},
{name: 'Joginder Nagar (Himachal Pradesh)'},
{name: 'Nagrota Surian'},
{name: 'Seoh'},
{name: 'Nagrota Bagwan (Himachal Pradesh)'},
{name: 'Kala Amb'},
{name: 'Nadaun (Himachal Pradesh)'},
{name: 'Bhawanagar (Sungra'},
{name: 'Biasar'},
{name: 'Bhota'},
{name: 'Banikhet'},
{name: 'Narkanda'},
{name: 'Aut (Himachal Pradesh)'},
{name: 'Theog'},
{name: 'Shahpur (Himachal Pradesh)'},
{name: 'Jeori (Himachal Pradesh)'},
{name: 'Jhakhari'},
{name: 'Prachi'},
{name: 'Ukai'},
{name: 'Medabad City'},
{name: 'Kikariya'},
{name: 'Mota Khutavada'},
{name: 'Nana Asarana'},
{name: 'Hadida'},
{name: 'Vijapadi'},
{name: 'Bharuch Bypass'},
{name: 'Ankleshwar Bypass'},
{name: 'Waradh'},
{name: 'Khirkiya (Madhya Pradesh)'},
{name: 'Bhainsdehi'},
{name: 'Vadiya (Gujarat)'},
{name: 'Mirzawali Mer (Tibbi)'},
{name: 'Dawa'},
{name: 'Haripura (Rajasthan)'},
{name: 'Bhakrawali'},
{name: 'Sivarakottai'},
{name: 'Thulukkapatti'},
{name: 'Padanthapuli'},
{name: 'Kalugasalapuram'},
{name: 'Pillaiyarnatham'},
{name: 'Ammanpuram'},
{name: 'Azhagapuri'},
{name: 'Cholapuram'},
{name: 'Sonakanvilai'},
{name: 'Duddukuru (Prakasham District)'},
{name: 'Sasaram (Bihar)'},
{name: 'Kayamozhi'},
{name: 'Senthamaram'},
{name: 'Kakkavayal'},
{name: 'Chelari (Kerala)'},
{name: 'Kunkavav'},
{name: 'Saliwala'},
{name: 'Aralikottai'},
{name: 'Okkur'},
{name: 'Oppilan'},
{name: 'Seithur'},
{name: 'Thirukoshtiyur'},
{name: 'Kamuthi Vilakku'},
{name: 'Muthuramalingapuram'},
{name: 'Devadhanapatti'},
{name: 'Eruvadi (Tirunelveli)'},
{name: 'Garladinne'},
{name: 'Siddipet Bypass'},
{name: 'Shankarpalli'},
{name: 'Chinnalapatti'},
{name: 'Bugga (Tadipatri)'},
{name: 'Kolimigundla'},
{name: 'Ramapuram (Andhra Pradesh)'},
{name: 'Sitamarhi'},
{name: 'Rusera Ghat'},
{name: 'Pipra Kothi'},
{name: 'Kadavakuduru'},
{name: 'Mannarpuram'},
{name: 'Mecheri(Salem)'},
{name: 'Mandapalli'},
{name: 'Satyaveedu'},
{name: 'Chattanchal'},
{name: 'Bhokardan'},
{name: 'Kushalgarh (Rajasthan)'},
{name: 'Samter (Gujarat)'},
{name: 'Victor'},
{name: 'Alamcode'},
{name: 'Chavadimukku'},
{name: 'Engapuzha'},
{name: 'Karyavattom'},
{name: 'Omassery'},
{name: 'Sreekariyam'},
{name: 'Ulloor'},
{name: 'Ugar Khurd'},
{name: 'Ainapur (Karnataka)'},
{name: 'Lakkireddypalli'},
{name: 'Avanathankottai'},
{name: 'Kavutaram'},
{name: 'Kotabommali'},
{name: 'Kathgodam (Uttarakhand)'},
{name: 'Vikasnagar (Uttarakhand)'},
{name: 'Selaqui'},
{name: 'Mandleshwar'},
{name: 'Maheshwar (Madhya Pradesh)'},
{name: 'Katargaon (Madhya Pradesh)'},
{name: 'Piparia'},
{name: 'Bhanvad'},
{name: 'Dinanagar (Punjab)'},
{name: 'Mahabaleshwar (Wia Phata)'},
{name: 'Uppiliyapuram'},
{name: 'Maliyakarai'},
{name: 'Thammampatty'},
{name: 'Thottiyam (Tamil Nadu)'},
{name: 'Kaattuputhur'},
{name: 'Nakodaji (Madhya Pradesh)'},
{name: 'Gudur(Karnataka)'},
{name: 'Sholapur By pass'},
{name: 'Challagulla'},
{name: 'Gangana Palem'},
{name: 'Kanaparthi'},
{name: 'Kesanapalli (Andhra Pradesh)'},
{name: 'Komitanenivaripalem'},
{name: 'Ravipadu (Andhra Pradesh)'},
{name: 'Gurazala'},
{name: 'Hindupur By pass'},
{name: 'Lakhimpur (Uttar Pradesh)'},
{name: 'Tikunia (Uttar Pradesh)'},
{name: 'Dharmapuri (Telengana)'},
{name: 'Banaras'},
{name: 'Mughalsarai (Uttar Pradesh)'},
{name: 'Sherghati'},
{name: 'Gola'},
{name: 'Mohammadi'},
{name: 'Dhangadhi(Nepal)'},
{name: 'Kuthar (Himachal Pradesh)'},
{name: 'Doddipatla'},
{name: 'Jillellamudi'},
{name: 'Palaparru'},
{name: 'Uppalapadu'},
{name: 'Bhimasar'},
{name: 'Mandal(Viramgam)'},
{name: 'Pune Darshan'},
{name: 'BarPatoli'},
{name: 'Katar (Gujarat)'},
{name: 'Samuhkheti'},
{name: 'Vavdi(Aagariya)'},
{name: 'Pati (Gujarat)'},
{name: 'Raydi (Gujarat)'},
{name: 'Sarakadiya'},
{name: 'Raningpara'},
{name: 'Dedan'},
{name: 'Elurpatti'},
{name: 'Pragnapur (Medak Dist.)'},
{name: 'Udangudi'},
{name: 'Palayakayal'},
{name: 'Splc Nagar'},
{name: 'Lambiya (Rajasthan)'},
{name: 'Kelwa (Rajasthan)'},
{name: 'Jasnagar Rajastan'},
{name: 'Inana'},
{name: 'Palana (Rajastan)'},
{name: 'Arjansar Rajastan'},
{name: 'Thansa'},
{name: 'Kayalpatnam'},
{name: 'Paramankuruchi'},
{name: 'Chintalacheruvu'},
{name: 'Shenoli'},
{name: 'Takari (Maharashtra)'},
{name: 'Palus'},
{name: 'Turchi'},
{name: 'Panchvamal'},
{name: 'Nimni (Maharastra)'},
{name: 'Tasgaon'},
{name: 'Bastawade'},
{name: 'Savlaj'},
{name: 'Anjani'},
{name: 'Gavan'},
{name: 'Malangaon (Maharashtra)'},
{name: 'Shirdun'},
{name: 'Landgewadi'},
{name: 'Kavathe Mahankal'},
{name: 'Thoothukudi(Spic)'},
{name: 'Tuticori (Spic Nagar)'},
{name: 'ThalachanVilai'},
{name: 'Aravade'},
{name: 'Colachel'},
{name: 'Manavalakurichi'},
{name: 'Chinnathurai'},
{name: 'Galagali'},
{name: 'Sayla (Rajasthan)'},
{name: 'Rambotlavaripalem'},
{name: 'Kavuru'},
{name: 'Muttil (Kerala)'},
{name: 'Chundale'},
{name: 'Mukkam (Kerala)'},
{name: 'Puthanathani (Kerala)'},
{name: 'Telhara (Maharashtra)'},
{name: 'Hiwarkhed'},
{name: 'Chikmagalur'},
{name: 'Visarwadi'},
{name: 'Dahivel'},
{name: 'Kusumbe'},
{name: 'Fagane'},
{name: 'Mukti (Maharashtra)'},
{name: 'Chorwad (Maharashtra)'},
{name: 'Walwadi'},
{name: 'Bhadgaon (Jalgaon)'},
{name: 'Kolki (Gujarat)'},
{name: 'Kharchiya (Gujarat)'},
{name: 'Sidsar'},
{name: 'Gingani(Gujarat)'},
{name: 'Jamvali (Gujarat)'},
{name: 'Kuchipudi (Guntur Dist.)'},
{name: 'Kajgaon'},
{name: 'Waghadi'},
{name: 'Mehunbare'},
{name: 'Tarvade'},
{name: 'Junwane'},
{name: 'Gadutar'},
{name: 'Medarametla By Pass'},
{name: 'Ponnaluru'},
{name: 'Kamepalli (Prakasam Dist.)'},
{name: 'Nagnesh'},
{name: 'Paliyad'},
{name: 'Vinchhiya'},
{name: 'Lilapur (Gujarat)'},
{name: 'Panchvada'},
{name: 'Jivapar'},
{name: 'Sanathali'},
{name: 'Dodiyara'},
{name: 'Biladi (Gujarat)'},
{name: 'Vasavad'},
{name: 'Keshvala (Gujarat)'},
{name: 'Meta Khambhaliya'},
{name: 'Moti Khilori'},
{name: 'Derdi Kumbhaji'},
{name: 'Ransiki'},
{name: 'Amarnagar'},
{name: 'Juna Pipaliya'},
{name: 'Juna Savar'},
{name: 'Yerramsettivari'},
{name: 'Bellampudi'},
{name: 'Udumudi'},
{name: 'Ganti (Andhra Pradesh)'},
{name: 'Lodhika'},
{name: 'MUKKA(KARNATAKA)'},
{name: 'Anandpar (Gujarat)'},
{name: 'Nikava'},
{name: 'Mota Vadala'},
{name: 'Rajsthali'},
{name: 'Jasapar (Gujarat)'},
{name: 'Sarvaniya'},
{name: 'Makrani Sanosara'},
{name: 'Murila(Gujarat)'},
{name: 'Rinari'},
{name: 'Danidhar'},
{name: 'Bhavabhi Khijadiya'},
{name: 'Kharedi'},
{name: 'Dadvi'},
{name: 'Rajapara(Jamkandorna)'},
{name: 'Naduvakurichi'},
{name: 'Sajjangarh (Rajasthan)'},
{name: 'Balasinor'},
{name: 'Kalinjara(Rajasthan)'},
{name: 'Keeramangalam'},
{name: 'Injaram(Yanam)'},
{name: 'Kolanka'},
{name: 'Kuyyeru'},
{name: 'Balanthram'},
{name: 'Vegayyammapeta'},
{name: 'Pasalapudi'},
{name: 'Lar (Uttar Pradesh)'},
{name: 'Mairwa'},
{name: 'Malmaliya (Bihar)'},
{name: 'Traiya'},
{name: 'Madhaura (Uttar Pradesh)'},
{name: 'Amnaur'},
{name: 'Sunho (Bihar)'},
{name: 'Parsa (Bihar)'},
{name: 'Sitalpur (Bihar)'},
{name: 'Angadipuram'},
{name: 'Maddur(Karnataka)'},
{name: 'Mysore ByePass'},
{name: 'Jamvada'},
{name: 'Sadodar'},
{name: 'Samana (Gujarat)'},
{name: 'Khad Khambhaliya'},
{name: 'Pipartoda'},
{name: 'Falla'},
{name: 'Aala'},
{name: 'Pujapadar'},
{name: 'Pipalva'},
{name: 'Anadiya'},
{name: 'Asodar'},
{name: 'Sabhadiya'},
{name: 'Dahinthara (Gujarat)'},
{name: 'Ayodhyapuram (Gujarat)'},
{name: 'Barvada'},
{name: 'Neradigonda (Telangana)'},
{name: 'Gudihathinur'},
{name: 'Mavala'},
{name: 'Hiramandalam'},
{name: 'Amadalavalasa'},
{name: 'Sarubujjili'},
{name: 'Sindkheda'},
{name: 'Nargadh (Gujarat)'},
{name: 'Gojariya'},
{name: 'Bamangam'},
{name: 'Rudrakota'},
{name: 'Jaladanki'},
{name: 'Peddapadu'},
{name: 'Anampally'},
{name: 'azhagiyamandapam'},
{name: 'Phulbani (Odisha)'},
{name: 'Bhanjanagar'},
{name: 'Keonjhar'},
{name: 'Chitrakonda'},
{name: 'Balimela'},
{name: 'Kantamal'},
{name: 'Khariar Road'},
{name: 'Kotpad (Odisha)'},
{name: 'Narasinghpur(Odisha)'},
{name: 'Parala(Odisha)'},
{name: 'Chandahandi'},
{name: 'Kalimela (Orissa)'},
{name: 'Ambapani(Odisha)'},
{name: 'Anandapuram (Andhra Pradesh)'},
{name: 'Aska'},
{name: 'Bandhabahal'},
{name: 'Baramundali'},
{name: 'Barbil(Odisha)'},
{name: 'Bhela'},
{name: 'Bhelagaon'},
{name: 'Dharmagarh(Odisha)'},
{name: 'Digapahandi'},
{name: 'Gunupur (Odisha)'},
{name: 'Hirakud'},
{name: 'Singhpur(Odisha)'},
{name: 'KAPADWANJ'},
{name: 'MOTHADA ROAD'},
{name: 'Bhabhar (Gujarat)'},
{name: 'Saputara Border'},
{name: 'Ranuj'},
{name: 'Limkheda'},
{name: 'Vaghai'},
{name: 'Pipalner'},
{name: 'Vichiya'},
{name: 'Balisana'},
{name: 'Dabhoi'},
{name: 'Paravadi(Gujarat)'},
{name: 'Taharabad (Maharastra)'},
{name: 'Ugamedi'},
{name: 'Manund (Patan Dist'},
{name: 'Bantva'},
{name: 'Parvat Patiya'},
{name: 'Valod'},
{name: 'Nigala'},
{name: 'Rohishala'},
{name: 'Khariar'},
{name: 'Kiriburu(Odisha)'},
{name: 'Kotagarh (Odisha)'},
{name: 'Lanjigarh'},
{name: 'Meghojholi'},
{name: 'Nayagarh (Odisha)'},
{name: 'NTPC(Odisha)'},
{name: 'Pandripani'},
{name: 'Pangaluru'},
{name: 'Pangam (Orissa)'},
{name: 'Raipur(Rajastan)'},
{name: 'Sandhu (Himachal Pradesh)'},
{name: 'Sandhora(Odisha)'},
{name: 'Sinapali(Odisha)'},
{name: 'Sundergarh(Odisha)'},
{name: 'Tikara (Odisha)'},
{name: 'Gumuda'},
{name: 'Baliguda'},
{name: 'Jatni'},
{name: 'Katingia'},
{name: 'Kerada(Odisha)'},
{name: 'Kundei'},
{name: 'Kundali (Odisha)'},
{name: 'Kundamprevi'},
{name: 'Laxmipur (Orissa)'},
{name: 'Mukhiguda'},
{name: 'Naranapur(Odisha)'},
{name: 'Tentulikhunti'},
{name: 'Pariyaram'},
{name: 'Kakkad'},
{name: 'Pancharamalu'},
{name: 'Morwada'},
{name: 'Garani (Gujarat)'},
{name: 'Panasada'},
{name: 'Ghughrala'},
{name: 'Gama Pipaliya'},
{name: 'Dharai (Gujarat)'},
{name: 'Randal Na Dadava'},
{name: 'Ravna(Gujarat)'},
{name: 'Patkhilori'},
{name: 'Bantwa Devli'},
{name: 'Monpari Moti'},
{name: 'Prempara'},
{name: 'Sarsai (Gujarat)'},
{name: 'Moniya'},
{name: 'Bhutdi'},
{name: 'Kuba'},
{name: 'Dhebar (Gujarat)'},
{name: 'Nani Pindakhai'},
{name: 'Velagapudi'},
{name: 'Lakshettipet'},
{name: 'Ranpur (Gujarat)'},
{name: 'Saalaiputhur'},
{name: 'Kalugumalai'},
{name: 'Kunvara'},
{name: 'Kambadur'},
{name: 'Kattappana (Van Drop )'},
{name: 'Chittoor(Andhra pradesh)'},
{name: 'Ongole Bypass'},
{name: 'Rajampalli'},
{name: 'Ballipalle'},
{name: 'Santhamaguluru'},
{name: 'Thimmareddipalle'},
{name: 'Adilabad (By Pass)'},
{name: 'Allahabad By Pass'},
{name: 'Varanasi By Pass'},
{name: 'Sasaram By Pass'},
{name: 'Aarah By Pass'},
{name: 'Bihta By Pass'},
{name: 'Kanjiyar'},
{name: 'Swaraj (Kerala)'},
{name: 'Parapu(Kerala)'},
{name: 'Chappath (Kerala)'},
{name: 'Chinnar'},
{name: 'Ellapara'},
{name: 'Pallikkunnu(Kerala)'},
{name: 'Murinjapuzha (Kerala)'},
{name: 'Pullupara (Kerala)'},
{name: 'Venjaramoodu'},
{name: 'Ramapuram (Kerala)'},
{name: 'Mutholy (Kerala)'},
{name: 'Mevada (Kerala)'},
{name: 'Kozhuvanal'},
{name: 'Pallickathodu'},
{name: 'Kulanada'},
{name: 'Muttom (Kerala)'},
{name: 'Melukavu'},
{name: 'Manimala (kerala)'},
{name: 'Kariyad'},
{name: 'Pattikkad'},
{name: 'Idagunji'},
{name: 'Malipur (Uttar Pradesh)'},
{name: 'Foolpur'},
{name: 'Sarai Mir'},
{name: 'Chittoor ByePass'},
{name: 'Vembayam'},
{name: 'venjarammood'},
{name: 'Vaamanapuram'},
{name: 'Kilimanoor'},
{name: 'Nilamel'},
{name: 'chadayamangalam'},
{name: 'Ayoor'},
{name: 'Vaalakam'},
{name: 'Murikaserry'},
{name: 'Thopramkudy'},
{name: 'Thangamani'},
{name: 'Erattayar'},
{name: 'Emmiganur (Karnataka)'},
{name: 'Vaniyambadi Bypass'},
{name: 'Enkoor (Telangana)'},
{name: 'Visakhpatanam'},
{name: 'Kattipudi'},
{name: 'Yelmanchile'},
{name: 'Prathipadu'},
{name: 'Addaroad'},
{name: 'Yerravaram'},
{name: 'Jaggampeta'},
{name: 'Rajamundry'},
{name: 'T.P Gudem'},
{name: 'T.Kallupatti'},
{name: 'Chikkerur'},
{name: 'Pazhayangadi'},
{name: 'Parat'},
{name: 'Peringathoor'},
{name: 'Parakkadavu'},
{name: 'Chekkanurani (Tamilnadu)'},
{name: 'Dalkhania'},
{name: 'Monvel (Gujarat)'},
{name: 'Aravakurichi'},
{name: 'Mylavaram'},
{name: 'Bamnasa (Gujarat)'},
{name: 'Borvav'},
{name: 'Ahmedabad (Bapunagar)'},
{name: 'Barwani'},
{name: 'Penuganchiprolu'},
{name: 'Tekurpet'},
{name: 'Kagaznagar'},
{name: 'Kaleshwaram'},
{name: 'Verapoly'},
{name: 'Cherpulassery'},
{name: 'Ulikkal'},
{name: 'Payyavoor'},
{name: 'Chemperi (Kerala)'},
{name: 'Puthiyatheru (Kerala)'},
{name: 'Karivellur'},
{name: 'Kalikkadavu (Kerala)'},
{name: 'Chambanthotty'},
{name: 'Kakkanad'},
{name: 'Kolayad'},
{name: 'Pookode'},
{name: 'Koodali (Kerala)'},
{name: 'Thirukkurungudi'},
{name: 'Anavatti'},
{name: 'Jade (Karnataka)'},
{name: 'Puthankurish'},
{name: 'Karur Bypass'},
{name: 'Sangipur'},
{name: 'Mangapur (Uttar Pradesh)'},
{name: 'Atheha'},
{name: 'Parsadepur'},
{name: 'Inhauna'},
{name: 'Musafirkhana'},
{name: 'Pikhuwa'},
{name: 'Annoor'},
{name: 'Oonnukal (Kerala)'},
{name: 'Paingottoor'},
{name: 'Valakom'},
{name: 'TECHNO PARK'},
{name: 'ANAYARA'},
{name: 'Kumarapuram'},
{name: 'Kovalam'},
{name: 'Thellishery'},
{name: 'Hattargi'},
{name: 'Hindalga'},
{name: 'Sulga'},
{name: 'Uchgaon'},
{name: 'Bekkinakeri'},
{name: 'Atiwad'},
{name: 'Kagani (Maharashtra)'},
{name: 'Kowad'},
{name: 'Vaked'},
{name: 'Hativale (Maharastra)'},
{name: 'Kondivare'},
{name: 'Nadgive (Maharashtra)'},
{name: 'Pinguli (Maharashtra)'},
{name: 'Salgaon (Maharashtra)'},
{name: 'Zarap'},
{name: 'Akeri'},
{name: 'Pedabrahmadevam'},
{name: 'G Medapadu (E Godavari)'},
{name: 'Dharmavaram(W.Godavari)'},
{name: 'Malakapalli'},
{name: 'Peddevam'},
{name: 'Annadevarapeta (West Godavari)'},
{name: 'Chityala(W Godavari)'},
{name: 'Chidipi'},
{name: 'Kumaradevam(W Godavari)'},
{name: 'F K Palem'},
{name: 'Jalluru (East Godavari'},
{name: 'Vadisaleru'},
{name: 'Cochin Airport'},
{name: 'Kochi Airport'},
{name: 'Nedumbassery Airport'},
{name: 'Dummugudem'},
{name: 'Laxminagaram'},
{name: 'Satyanarayanapuram(KMM)'},
{name: 'Poovar'},
{name: 'Garthikere'},
{name: 'Mrugavadhe'},
{name: 'Shedagaru'},
{name: 'Kattehaklu'},
{name: 'Gadikal(kuppahalli)'},
{name: 'Basavani'},
{name: 'Devangi'},
{name: 'Durgi(Macherla)'},
{name: 'Angara (Andhra Pradesh)'},
{name: 'Mulur(Karnataka)'},
{name: 'Hejamadi'},
{name: 'Kottara(Karnataka)'},
{name: 'Nattarasankottai'},
{name: 'Sarugani'},
{name: 'Puliyadithammam'},
{name: 'Anandur (Tamil Nadu)'},
{name: 'Kollangudi'},
{name: 'Natham'},
{name: 'Tankara(Gujarat)'},
{name: 'Nagoor'},
{name: 'Dichpally (Telangana)'},
{name: 'Racharla Gollapally'},
{name: 'Yellareddipet'},
{name: 'Sircilla'},
{name: 'Rajura (Maharashtra)'},
{name: 'Balharshah (Maharashtra)'},
{name: 'Bhanupratappur (Chhattisgarh)'},
{name: 'Jhajjar (Haryana)'},
{name: 'Sirsanna(Adilabad)'},
{name: 'Jainath(Adilabad)'},
{name: 'Nirala(Adilabad)'},
{name: 'Boraj(Adilabad)'},
{name: 'Chanda (T)(Adilabad)'},
{name: 'Perumalmalai'},
{name: 'Oothu'},
{name: 'Kondapuram (Prakasham Dist'},
{name: 'Panoor'},
{name: 'Bhimanapalli (E.G)'},
{name: 'Gollavilli'},
{name: 'Uppalaguptam'},
{name: 'Navi Haliyad'},
{name: 'Pookome'},
{name: 'Mekkunnu'},
{name: 'Keezhmadam'},
{name: 'Kujipalli'},
{name: 'Amethi'},
{name: 'Bachhrawan (Uttar Pradesh)'},
{name: 'Garhmukteshwar'},
{name: 'Rupaidiha'},
{name: 'Vrindavan'},
{name: 'Lingapalem(AP)'},
{name: 'Athani(Kerala)'},
{name: 'Ravinuthala (Andhra Pradesh)'},
{name: 'Kunjipally'},
{name: 'Monthal'},
{name: 'Keezhmadom'},
{name: 'Ananthapuram(Tamilnadu)'},
{name: 'Avudaiyapuram'},
{name: 'INS Kattabomman'},
{name: 'Idinthakarai'},
{name: 'Ittamozhi'},
{name: 'Navaladi'},
{name: 'Seydunganallur'},
{name: 'Sivanthipuram'},
{name: 'Srivaikuntam'},
{name: 'V.M.Chatram'},
{name: 'Vazhaiyadi'},
{name: 'Kumbazha'},
{name: 'Koodal'},
{name: 'Obuladevaracheruvu'},
{name: 'Kanjipuram'},
{name: 'Allagadda Bye Pass'},
{name: 'Manikaran'},
{name: 'Dampal(Chhattisgarh)'},
{name: 'Gadiras'},
{name: 'Nakulnar'},
{name: 'Kodumur(Andhra Pradesh)'},
{name: 'Gonegandla'},
{name: 'Virudachalam Byepass'},
{name: 'Buxwaha'},
{name: 'Bada Malhera'},
{name: 'Heerapur(Madhya Pradesh)'},
{name: 'Sanchi'},
{name: 'Shahgarh(Madhya Pradesh)'},
{name: 'Hosanadu'},
{name: 'Sangameshwarpet(Karnataka)'},
{name: 'Kakkabe (Karnataka)'},
{name: 'Takkalakote'},
{name: 'Kadanur (Karnataka)'},
{name: 'Ghatkeser'},
{name: 'Ghatkeser Bypass'},
{name: 'Bhongir'},
{name: 'Bhongir Bypass'},
{name: 'Yadagirigutta'},
{name: 'Yadagirigutta Bypass'},
{name: 'Jangaon Bypass'},
{name: 'Aleru(Telangana)'},
{name: 'Aleru Bypass(Telangana)'},
{name: 'Ghanpur'},
{name: 'Ghanpur Bypass'},
{name: 'Parkal(Telangana)'},
{name: 'Parkal Bypass(Telangana)'},
{name: 'Chinnakanal'},
{name: 'Pooparai (Kerala)'},
{name: 'Perambavoor'},
{name: 'Mattur(Karnataka)'},
{name: 'Kumta ByPass'},
{name: 'Jahoo (Himachal Pradesh)'},
{name: 'Sujanpur(Himachal Pradesh)'},
{name: 'Tanda (Kangra'},
{name: 'Dharampur (Solan Dist'},
{name: 'Rewalsar'},
{name: 'Jahu(Himachal Pradesh)'},
{name: 'Chennur(Mancherial)'},
{name: 'Chuda(Gujarat)'},
{name: 'Adampur Mandi'},
{name: 'Dattadham Yatra'},
{name: 'Koderma (Jharkhand)'},
{name: 'Pachmarhi (Madhya Pradesh)'},
{name: 'Amargadh (Punjab)'},
{name: 'Bhalchhel'},
{name: 'Loej'},
{name: 'Maktupur'},
{name: 'Rahij'},
{name: 'Ramdhari(Gujarat)'},
{name: 'Sanosara'},
{name: 'Shil(Gujarat)'},
{name: 'Zalansar'},
{name: 'Chikhali (Gujarat)'},
{name: 'Sawantwadi Bypass'},
{name: 'Lakshminarasupeta'},
{name: 'Tirupur(Palladam)'},
{name: 'Kasrawad (Madhya Pradesh)'},
{name: 'Kootampuli(Tuticorin)'},
{name: 'Korampallam(Tuticorin)'},
{name: 'Pandalgudi Bypass'},
{name: 'Pannaivilai'},
{name: 'Sakkammalpuram'},
{name: 'Sawyerpuram'},
{name: 'Servaikaranmadam'},
{name: 'Sivagnanapuram'},
{name: 'Sivathaiyapuram'},
{name: 'Subramaniapuram (Tuticorin)'},
{name: 'Thangammalpuram'},
{name: 'Anthoniyarpuram'},
{name: 'Warud (Maharashtra)'},
{name: 'Morshi (Maharastra)'},
{name: 'Umbraj'},
{name: 'nanguneri toll'},
{name: 'vedasanthoor'},
{name: 'antipatti Toll'},
{name: 'Achalpur (Maharastra)'},
{name: 'Aade (Maharastra)'},
{name: 'Agrav'},
{name: 'Aheri (Maharashtra)'},
{name: 'Jaipur (Telangana)'},
{name: 'Examba'},
{name: 'Gurlapur Cross'},
{name: 'Vijayamangalam'},
{name: 'Hidkal'},
{name: 'Jalna ByPass'},
{name: 'Jhalki Zalki (Karnataka)'},
{name: 'Kalloli(Karnataka)'},
{name: 'Lolasur'},
{name: 'Karunya Nagar Coimbatore'},
{name: 'Nizampatnam'},
{name: 'Sajjavaripalem'},
{name: 'Atchutapuram'},
{name: 'Mulugu'},
{name: 'Naganur (Karnataka)'},
{name: 'Ramnagar(Karnataka)'},
{name: 'Akluj (solapur)'},
{name: 'Alephata'},
{name: 'Akkalkuva'},
{name: 'Amgaon'},
{name: 'Atpadi'},
{name: 'Bhamragarh'},
{name: 'Bhokar'},
{name: 'Bhoom'},
{name: 'Bhor'},
{name: 'Biloli(Maharashtra)'},
{name: 'Boisar'},
{name: 'Chandur Railway (Maharashtra)'},
{name: 'Chimur'},
{name: 'Chousala'},
{name: 'Daryapur (Maharashtra)'},
{name: 'Degloor'},
{name: 'Deogad (Maharastra)'},
{name: 'Etapalli'},
{name: 'Georai'},
{name: 'Ghatanji'},
{name: 'Jawhar'},
{name: 'Kandhar (Nanded'},
{name: 'Katol (Maharashtra)'},
{name: 'Kinwat'},
{name: 'Manchar'},
{name: 'Mangalwedha'},
{name: 'Mohate Devi'},
{name: 'Paoni'},
{name: 'Paranda'},
{name: 'Parner'},
{name: 'Ramtek (Maharastra)'},
{name: 'Risod'},
{name: 'Roha (Maharashtra)'},
{name: 'Sakri (Gujarat)'},
{name: 'Silvassa'},
{name: 'Sironcha (Maharashtra)'},
{name: 'Tala(Maharashtra)'},
{name: 'Taloda(Maharashtra)'},
{name: 'Tirora'},
{name: 'Kondepi'},
{name: 'Mandarmani (West Bengal)'},
{name: 'Sonale (Karnataka)'},
{name: 'Hazipur-Donabanda'},
{name: 'Chittaranjan(West Bengal)'},
{name: 'Bardhaman'},
{name: 'Choppella'},
{name: 'Pottilanka'},
{name: 'Kadiyapulanka'},
{name: 'Dondapudi (Andhra Pradesh)'},
{name: 'Digamarru'},
{name: 'Khalilabad (Uttar Pradesh)'},
{name: 'Nanakmatta'},
{name: 'Banbasa'},
{name: 'Dronadula'},
{name: 'Bobbepalli'},
{name: 'Aagariya(Gujarat)'},
{name: 'Thoradi (Gujarat)'},
{name: 'Jabal'},
{name: 'Badhada'},
{name: 'Kolhar (Ahmednagar)'},
{name: 'Bagdu(Gujarat)'},
{name: 'Bordi Samadhiyala'},
{name: 'Penuballi(Telangana)'},
{name: 'Kondapalli (Andhra Pradesh)'},
{name: 'Sri Rangapatanam'},
{name: 'Periyapatna'},
{name: 'Mani'},
{name: 'BC.road'},
{name: 'Kushal Nagar'},
{name: 'Isukapalle'},
{name: 'Erode(Chithode 4 Rd Jn)'},
{name: 'Adalaj'},
{name: 'Lodhva (Sutrapada)'},
{name: 'Banakal'},
{name: 'Panch Ki Puli'},
{name: 'Sutrapada Fatak'},
{name: 'Meham'},
{name: 'Murudeshwar Bypass'},
{name: 'Koniki(Andhra Pradesh)'},
{name: 'Bagicha'},
{name: 'Baikunthpur (Chhattisgarh)'},
{name: 'Bankombo(Chhattisgarh)'},
{name: 'Batauli(Chhattisgarh)'},
{name: 'Bhaiyathan'},
{name: 'Bijapur(Chhattisgarh)'},
{name: 'Bikram'},
{name: 'Bihta'},
{name: 'Chirimiri'},
{name: 'Bhawanathpur'},
{name: 'Arwal'},
{name: 'Aurangabad(Bihar)'},
{name: 'Bishrampur(Chhattisgarh)'},
{name: 'Charama(Chhattisgarh)'},
{name: 'Chotia(Chhattisgarh)'},
{name: 'Duldula(Chhattisgarh)'},
{name: 'Durg (Chhattisgarh)'},
{name: 'Garhwa (Jharkhand)'},
{name: 'Gidam(Chhattisgarh)'},
{name: 'Hariharganj (Jharkhand)'},
{name: 'Jarhi(Chhattisgarh)'},
{name: 'Khadgawan'},
{name: 'Kusmi(Chhattisgarh)'},
{name: 'Lakhanpuri (Chhattisgarh)'},
{name: 'Nagar(Jharkhand)'},
{name: 'Nagarnar (Chhattisgarh)'},
{name: 'Padwa Mod'},
{name: 'Pali(Chhattisgarh)'},
{name: 'Paliganj'},
{name: 'Pandopara'},
{name: 'Patna(Chhattisgarh)'},
{name: 'Pharasgaon (Chhattisgarh)'},
{name: 'Pratappur(Chhattisgarh)'},
{name: 'Rajpur (Chhattisgarh)'},
{name: 'Ramanuj Ganj'},
{name: 'Ramna (Jharkhand)'},
{name: 'Shankargarh (Chhattisgarh)'},
{name: 'Sitapur(Chhattisgarh)'},
{name: 'Surajpur (Chhattisgarh)'},
{name: 'Udaipur (Chhattisgarh)'},
{name: 'Wadraf Nagar'},
{name: 'Gundlakunta(Telangana)'},
{name: 'Pinjore'},
{name: 'Gagret'},
{name: 'Chowari (Himachal Pradesh)'},
{name: 'Bharmour'},
{name: 'Paprola'},
{name: 'Rurki'},
{name: 'Amber'},
{name: 'Amb(Himachal Pradesh)'},
{name: 'Shoghi'},
{name: 'Ropar (Punjab)'},
{name: 'Barmana (Himachal Pradesh)'},
{name: 'Sanjauli'},
{name: 'Sulyali'},
{name: 'Galore (Himachal Pradesh)'},
{name: 'Bharol'},
{name: 'Mubarikpur (Himachal Pradesh)'},
{name: 'Tarkwari'},
{name: 'Bijhari (Hamirpur'},
{name: 'Daulatpur Una'},
{name: 'Sarimlog'},
{name: 'Bagga'},
{name: 'Dheera (Himachal Pradesh)'},
{name: 'Purba'},
{name: 'Sullah'},
{name: 'Kangoo (Himachal Pradesh)'},
{name: 'Rangas (Himachal Pradesh)'},
{name: 'Sharotri'},
{name: 'Darlaghat (Himachal Pradesh)'},
{name: 'Karadaghat'},
{name: 'Chauntra'},
{name: 'Dunera'},
{name: 'Bharari (Himachal Pradesh)'},
{name: 'Malokhar (Himachal Pradesh)'},
{name: 'Swarghat'},
{name: 'Lathiani (Himachal Pradesh)'},
{name: 'Ladrour'},
{name: 'Bhareli (Haryana)'},
{name: 'Kaloha'},
{name: 'Garli'},
{name: 'Rakkar'},
{name: 'Badda'},
{name: 'Jihan'},
{name: 'Gander (Himachal Pradesh)'},
{name: 'Shiv Nagar (Himachal Pradesh)'},
{name: 'Khera (Himachal Pradesh)'},
{name: 'Pahra (Himachal Pradesh)'},
{name: 'Lambagaon (Himachal Pradesh)'},
{name: 'Harsipattan'},
{name: 'Kufri'},
{name: 'Sainj (Himachal Pradesh)'},
{name: 'Nagani'},
{name: 'Dandroo'},
{name: 'Paraur (Himachal Pradesh)'},
{name: 'Kharapathar'},
{name: 'Hatkoti'},
{name: 'Baldwara'},
{name: 'Langna'},
{name: 'Kumar Hatti'},
{name: 'Badsar (himachal pradesh)'},
{name: 'Kunihar'},
{name: 'Salaper'},
{name: 'Arki'},
{name: 'Subathu (Salon)'},
{name: 'Shalaghat'},
{name: 'Navgaon (Himachal Pradesh)'},
{name: 'Kandaghat'},
{name: 'Thural'},
{name: 'Bangana'},
{name: 'Nalti (Hamirpur'},
{name: 'Badah'},
{name: 'Ropari'},
{name: 'Dhameta (Himachal Pradesh)'},
{name: 'Nadoun'},
{name: 'Nagni (himachal pradesh)'},
{name: 'Nehrian'},
{name: 'Salouni (Himachal Pradesh)'},
{name: 'Anandpur (Himachal Pradesh)'},
{name: 'Patta'},
{name: 'Bairghata'},
{name: 'Jeehan'},
{name: 'Nahlian (Himachal Pradesh)'},
{name: 'Harsi'},
{name: 'Bhuvanagiri (Tamil Nadu)'},
{name: 'Erukkur (Tamil Nadu)'},
{name: 'Kuchinapudi'},
{name: 'Kanjiramattom'},
{name: 'Addanki Byepass'},
{name: 'Devanakonda'},
{name: 'Dagguluru (Andhra Pradesh)'},
{name: 'Kovilpalayam'},
{name: 'Athani(Tamil Nadu)'},
{name: 'Ammapettai(Salem'},
{name: 'Dharmasala(Kerala)'},
{name: 'Ammapettai(Erode'},
{name: 'Malaikovilur'},
{name: 'Vangal (Tamil Nadu)'},
{name: 'Tharuvaikulam'},
{name: 'Ambalavanapuram'},
{name: 'Gosala (Andhra Pradesh)'},
{name: 'Punadipadu'},
{name: 'Nandamuru'},
{name: 'Pedaparupudi'},
{name: 'Kaushambi (Manjhanpur)'},
{name: 'Amrutvel'},
{name: 'Bhacha'},
{name: 'Bhada'},
{name: 'Bhuva'},
{name: 'Borda(Gujarat)'},
{name: 'Chakrava (Gujarat)'},
{name: 'Daldi (Gujarat)'},
{name: 'Dhajdi'},
{name: 'Dhargani'},
{name: 'Gavadka'},
{name: 'Hanumanpura (Gujarat)'},
{name: 'Harmdiya'},
{name: 'Kantala(Gujarat)'},
{name: 'Kerala (Gujarat)'},
{name: 'Khapat'},
{name: 'Khara(Gujarat)'},
{name: 'Khijadiya'},
{name: 'Lodhpur (Gujarat)'},
{name: 'Malila'},
{name: 'Mirzapur(Gujarat)'},
{name: 'Moldi (Gujarat)'},
{name: 'Mota Umarda'},
{name: 'Muliyapat'},
{name: 'Nathal (Gujarat)'},
{name: 'Nathej'},
{name: 'Rameshvar(Gujarat)'},
{name: 'Sajiyavadar'},
{name: 'Siloj'},
{name: 'Talda (Gujarat)'},
{name: 'Tataniya'},
{name: 'Thasa'},
{name: 'Toda (Gujarat)'},
{name: 'Umariya (Gujarat)'},
{name: 'Umedpara(Gujarat)'},
{name: 'Vadhera'},
{name: 'Vankiya'},
{name: 'Vikaliya'},
{name: 'Arrah (Bihar)'},
{name: 'Bemetara'},
{name: 'Bikramganj'},
{name: 'Dobhi (Himachal Pradesh)'},
{name: 'Hanumana(Madhya Pradesh)'},
{name: 'Mirzapur(Bihar)'},
{name: 'Sidhi(Madhya Pradesh)'},
{name: 'Kurellagudem(Andhra Pradesh)'},
{name: 'Kamalapur(Karnataka)'},
{name: 'Sri Ram Nagar(Karnataka)'},
{name: 'Gorebal'},
{name: 'Neermanvi'},
{name: 'Rajkot Bypass'},
{name: 'Kodumudi(Tamil Nadu)'},
{name: 'Thalaivasal'},
{name: 'Thalaivasal Bypass'},
{name: 'Manarkad'},
{name: 'Ayarkunnam'},
{name: 'Marangattupally'},
{name: 'Vyara (Gujarat)'},
{name: 'Dandamudi'},
{name: 'Gottipadu'},
{name: 'Mittapalem'},
{name: 'Palaparthi'},
{name: 'Laluk'},
{name: 'Banderdewa'},
{name: 'Nayanpur (Assam)'},
{name: 'Mulakalacheruvu'},
{name: 'Choondal'},
{name: 'Valluru (West Godavari'},
{name: 'Deoria (Uttar Pradesh)'},
{name: 'Mihinpurwa'},
{name: 'Nighasan'},
{name: 'Palia Kalan'},
{name: 'Panchori'},
{name: 'Paika'},
{name: 'Chotty'},
{name: 'Kasol (Himachal Pradesh)'},
{name: 'Rohtang Pass'},
{name: 'Upputur (Prakasam Dist)'},
{name: 'Veerannapalem(Parchoor)'},
{name: 'Pothukatla'},
{name: 'Komarnenivari Palem(Parchoor)'},
{name: 'Ramanayapalem'},
{name: 'Cherukuru'},
{name: 'Narasayapalem (Andhra Pradesh)'},
{name: 'Kankatapalem'},
{name: 'kuravilangad'},
{name: 'muvatuttupuzha'},
{name: 'Tezu (Arunachal Pradesh)'},
{name: 'Sivasagar'},
{name: 'Omalur (Tamil Nadu)'},
{name: 'Machavaram(Prakasam Dist.)'},
{name: 'Pandamangalam (Tamil Nadu)'},
{name: 'Jedarpalayam'},
{name: 'Velapur (Solapur)'},
{name: 'Pamidi Byepass'},
{name: 'Khatiya(Gujarat)'},
{name: 'Panchasar'},
{name: 'Galla (Gujarat)'},
{name: 'Gulabnagar (Gujarat)'},
{name: 'Chandragadh (Gujarat)'},
{name: 'Manapparai'},
{name: 'Dared'},
{name: 'Uchipuli'},
{name: 'Panamaram (Kerala)'},
{name: 'Melechowa'},
{name: 'Thazhe Chovva'},
{name: 'Vallithode'},
{name: 'Koottupuzha'},
{name: 'Thottada'},
{name: 'Edakkad'},
{name: 'Palloor'},
{name: 'Pilathara'},
{name: 'Koyileri (Kerala)'},
{name: 'Kadirur (Kerala)'},
{name: 'Morochi'},
{name: 'Natepute(Solapur)'},
{name: 'Maloli(Maharashtra)'},
{name: 'Waki Shivane'},
{name: 'Hatid'},
{name: 'Udanwadi'},
{name: 'Junoni (Solapur)'},
{name: 'Nagaj (Sangali Dist'},
{name: 'Arewadi'},
{name: 'Kumbhari (Maharashtra)'},
{name: 'Kosari (Maharashtra)'},
{name: 'Malshiras(Solapur)'},
{name: 'Javala (Solapur)'},
{name: 'Gherdi'},
{name: 'Pare'},
{name: 'Nagthane'},
{name: 'Atit(Satara)'},
{name: 'Kashil (Satara Dist'},
{name: 'Kole(Satara)'},
{name: 'Talmavale(Satara)'},
{name: 'Dhebewadi(Satara)'},
{name: 'Dharmavaram(E.Godavari)'},
{name: 'Nandigama Bypass'},
{name: 'Kodada Bypass'},
{name: 'Bhenkra'},
{name: 'Fifad (Gujarat)'},
{name: 'Gadhakada (Gujarat)'},
{name: 'Krishnapara (Gujarat)'},
{name: 'Likhala (Gujarat)'},
{name: 'Luvara'},
{name: 'Luwara'},
{name: 'Meriyana'},
{name: 'Moda (Gujarat)'},
{name: 'Mota Zinzuda'},
{name: 'Noghanvadar'},
{name: 'Pithavadi'},
{name: 'Piyava (Gujarat)'},
{name: 'Shelana'},
{name: 'Vanda (Gujarat)'},
{name: 'Thiruvaiyaru'},
{name: 'Thanipadi'},
{name: 'Theerthamalai'},
{name: 'Thandarampattu'},
{name: 'Voimedu'},
{name: 'Beerapally(Telangana)'},
{name: 'Moddulagudem'},
{name: 'Kallurugudem'},
{name: 'Rayudupalem(Telangana)'},
{name: 'Kistaram(Telangana)'},
{name: 'Timmapuram(West Godavari)'},
{name: 'Basaithi (Bihar)'},
{name: 'Sukela'},
{name: 'Jadia (Bihar)'},
{name: 'Pipra (Bihar)'},
{name: 'Simrahi (Bihar)'},
{name: 'Makani (Maharashtra)'},
{name: 'Sastur'},
{name: 'Narangwadi'},
{name: 'Gangaram (Andhra Pradesh)'},
{name: 'Edugurallapalli'},
{name: 'Aruvankadu (Tamil Nadu)'},
{name: 'Adigoppula'},
{name: 'Mallepalli (East Godavari Dist'},
{name: 'Ullagallu'},
{name: 'Nuzendla'},
{name: 'Botla Palem'},
{name: 'Gangavaram(E.G Dist.)'},
{name: 'Kulathoor(Vilathikulam)'},
{name: 'Panaiyur (Tuticorin)'},
{name: 'Veppalodai(Tuticorin)'},
{name: 'Dammapeta(Telangana)'},
{name: 'Aathupalam'},
{name: 'Isnapur'},
{name: 'Bangla(Basavakalyan)'},
{name: 'Pune Airport'},
{name: 'Ponduru'},
{name: 'Solasiramani'},
{name: 'Singalanthapuram'},
{name: 'Kumaramangalam(Namakkal Dist.)'},
{name: 'Manickampalayam(Namakkal Dist.)'},
{name: 'Pamba'},
{name: 'Vizhinjam'},
{name: 'Chengannur Railway Station'},
{name: 'Amroha'},
{name: 'Aurai (Uttar Pradesh)'},
{name: 'Badhani (Uttar Pradesh)'},
{name: 'Badshahpur'},
{name: 'Janakpur (Maharastra)'},
{name: 'Karwi (Chitrakoot Dham'},
{name: 'Kasya (Uttar Pradesh)'},
{name: 'Maharajganj (Uttar Pradesh)'},
{name: 'Saifai'},
{name: 'Sikandrabad'},
{name: 'Shamli (Uttar Pradesh)'},
{name: 'Vindhyachal'},
{name: 'Bagoda(Rajasthan)'},
{name: 'Khanagaon'},
{name: 'Hire Bagewadi'},
{name: 'Hulsoor'},
{name: 'Bhatambra'},
{name: 'Charegaon(Maharashtra)'},
{name: 'Sarangpur(Maharashtra)'},
{name: 'Ramnagar(Jalna'},
{name: 'Kamalapur(Maharashtra)'},
{name: 'Kaman(Maharashtra)'},
{name: 'Diggi(Maharashtra)'},
{name: 'Kuda(Maharashtra)'},
{name: 'Bhatana(Maharashtra)'},
{name: 'Shivani(Maharashtra)'},
{name: 'Kothari(Maharashtra)'},
{name: 'Khanapur(Maharashtra)'},
{name: 'Thotlavalluru'},
{name: 'Chandragiri (Andhra Pradesh)'},
{name: 'Bhangor(Gujarat)'},
{name: 'Dharmarao Cheruvupalle'},
{name: 'Pandharpur(Solapur)'},
{name: 'Shevgaon'},
{name: 'Pamarru(East Godavari Dist.)'},
{name: 'Lasalgaon'},
{name: 'Gauriganj(Uttar Pradesh)'},
{name: 'Munshiganj'},
{name: 'Fursatganj'},
{name: 'Raichur Bypass'},
{name: 'Suigam'},
{name: 'Perikkallur'},
{name: 'Kusumanchi'},
{name: 'Palair'},
{name: 'Srirampur Colony(Mancherial)'},
{name: 'Nallacheruvu (Anantapur Dist'},
{name: 'Balkonda'},
{name: 'Ichoda Bypass'},
{name: 'Jaggaiahpet Bypass'},
{name: 'Pochampad X Road'},
{name: 'Toopran'},
{name: 'Unduru'},
{name: 'Veerapandianpattinam'},
{name: 'Keelaselvanur'},
{name: 'Chettikulam'},
{name: 'Mukkudal'},
{name: 'Edambadal'},
{name: 'Vijayapathi'},
{name: 'Kommadikottai'},
{name: 'Kadayalurutti(Tirunelveli)'},
{name: 'Mundur (Kerala)'},
{name: 'Morayur'},
{name: 'Levinjipuram'},
{name: 'Madathupatti'},
{name: 'Thangachimadam'},
{name: 'Valinokkam'},
{name: 'Sithurajapuram'},
{name: 'Andippatti'},
{name: 'Palkulam(Tuticorin)'},
{name: 'Kuthenkuly'},
{name: 'Muthur (Tamil Nadu)'},
{name: 'Panakudi Bypass'},
{name: 'Kallamanaickerpatti'},
{name: 'Alathur(Tamil Nadu)'},
{name: 'Kaikatty(Pudukkottai)'},
{name: 'Pudur (Ilayangudi)'},
{name: 'Melaselvanur'},
{name: 'Mayakulam'},
{name: 'Valluvambram'},
{name: 'Ariyanagipuram(Vilathikulam)'},
{name: 'Ottangadu'},
{name: 'Uppur (Tamil Nadu)'},
{name: 'Villukuri'},
{name: 'Thenthirupperai'},
{name: 'Saravanampatti'},
{name: 'Eachanari'},
{name: 'Malumichampatty'},
{name: 'Eppodumvenran'},
{name: 'Mariyur(Tamil Nadu)'},
{name: 'Karivalamvandanallur'},
{name: 'Dharapura(Karnataka)'},
{name: 'Faridpur (Uttar Pradesh)'},
{name: 'Bilaspur(Chhattisgarh)'},
{name: 'Bilaspur(Uttar Pradesh)'},
{name: 'Alangulam Road(Rajapalayam)'},
{name: 'Essar(Gujarat)'},
{name: 'Amirgadh'},
{name: 'Simran'},
{name: 'Panduva Nagula Varam'},
{name: 'Mogulluru'},
{name: 'Kannapuram(AP)'},
{name: 'Paneli Moti'},
{name: 'Kharsia (Chhattisgarh)'},
{name: 'Sanwer'},
{name: 'Devka(Gujarat)'},
{name: 'Dudhala no.2'},
{name: 'Ahmedabad(Satellite)'},
{name: 'Kami(Gujarat)'},
{name: 'Lakkavaram (West Godavari Dist'},
{name: 'Garal(Gujarat)'},
{name: 'Delvada(Gujarat)'},
{name: 'Gangda(Gujarat)'},
{name: 'Wankaner(Morbi)'},
{name: 'Vanipenta'},
{name: 'Chityala(Telangana)'},
{name: 'Tumkur Bypass'},
{name: 'Gudimangalam'},
{name: 'Mallur(Tamil Nadu)'},
{name: 'Thozhuthur'},
{name: 'Settarkatte'},
{name: 'Karki (Karnataka)'},
{name: 'Idoor'},
{name: 'Hattiangady'},
{name: 'Chittur(Karnataka)'},
{name: 'Hiradi'},
{name: 'Manya'},
{name: 'Bharasar'},
{name: 'Chicholi(Maharashtra)'},
{name: 'Kandagra'},
{name: 'Khategaon(Madhya Pradesh)'},
{name: 'Moti Khakhar'},
{name: 'Nani Khakhar'},
{name: 'Savner'},
{name: 'Kharwandi'},
{name: 'Kolgaon(Maharashtra)'},
{name: 'Madalmohi'},
{name: 'Padalsingi'},
{name: 'Vishrambag'},
{name: 'Raimoha'},
{name: 'Shirur Kasar'},
{name: 'Nemawar'},
{name: 'Vijaynagar (Rajasthan)'},
{name: 'Vijaynagar(Gujarat)'},
{name: 'Gogasar'},
{name: 'Ranasar(Gujarat)'},
{name: 'Ajeetgarh(Rajasthan)'},
{name: 'Babai(Rajasthan)'},
{name: 'Kanwat (Rajasthan)'},
{name: 'Bhinyad'},
{name: 'Peepaliya (Rajasthan)'},
{name: 'Jadan'},
{name: 'Chaba(Rajasthan)'},
{name: 'Phalsund (Rajasthan)'},
{name: 'Thanwala (Rajasthan)'},
{name: 'Kherapa (Jodhpur Dist'},
{name: 'Barnagar(MP)'},
{name: 'Manohar Thana'},
{name: 'Soila(Rajasthan)'},
{name: 'Umednagar(Rajasthan)'},
{name: 'Haripuram(AP)'},
{name: 'Haripuram ByPass(AP)'},
{name: 'Tekkali By Pass'},
{name: 'Palavalasa'},
{name: 'Palavalasa ByPass'},
{name: 'Jadupudi'},
{name: 'Jadupudi ByPass'},
{name: 'Loddaputti'},
{name: 'Loddaputti ByPass'},
{name: 'Girisola'},
{name: 'Girisola ByPass'},
{name: 'Golanthara'},
{name: 'Golanthara ByPass'},
{name: 'Konisi'},
{name: 'Konisi ByPass'},
{name: 'Brahmapur ByPass'},
{name: 'Ganjam'},
{name: 'Ganjam ByPass'},
{name: 'Humma'},
{name: 'Humma ByPass'},
{name: 'Palur Junction(Odisha)'},
{name: 'Rambha'},
{name: 'Rambha ByPass'},
{name: 'Langaleswar (Orissa)'},
{name: 'Langaleswar ByPass'},
{name: 'INS Chilika'},
{name: 'Balugaon (Orissa)'},
{name: 'Balugaon ByPass(Odisha)'},
{name: 'Sunakhala'},
{name: 'Sunakhala ByPass'},
{name: 'Lehenga'},
{name: 'Lehenga ByPass'},
{name: 'Tapang'},
{name: 'Tapang ByPass'},
{name: 'Khordha ByPass'},
{name: 'Pitapalli'},
{name: 'Pitapalli ByPass'},
{name: 'Umadi'},
{name: 'Uttur (Maharashtra)'},
{name: 'Baroda Golden Chowkdi'},
{name: 'Changa'},
{name: 'Navagadh ByPass'},
{name: 'Ranakandola ByPass'},
{name: 'Jamanvaav'},
{name: 'Madhiya(Gujarat)'},
{name: 'Manjarsumbha'},
{name: 'Gadi(Gujarat)'},
{name: 'Mohol (Maharashtra)'},
{name: 'Neknoor'},
{name: 'Shani Shingnapur ByPass'},
{name: 'Shetphal'},
{name: 'Amarpura(RJ)'},
{name: 'Polaki ByPass'},
{name: 'Narasannapeta ByPass'},
{name: 'Kotabommali ByPass'},
{name: 'Kasibugga ByPass'},
{name: 'Sompeta ByPass'},
{name: 'Chatrapur ByPass'},
{name: 'Tangi ByPass'},
{name: 'Chandpur ByPass'},
{name: 'Jankia ByPass'},
{name: 'Dindrud'},
{name: 'Kapashi'},
{name: 'Kasoda (Maharastra)'},
{name: 'Kasoda (Rajasthan)'},
{name: 'Khandvi (Maharastra)'},
{name: 'Lodhikheda (Madhya Pradesh)'},
{name: 'Murgud'},
{name: 'Naigaon (Nanded)'},
{name: 'Narayangaon (Madhya Prades)'},
{name: 'Nidhori'},
{name: 'Pimpalner'},
{name: 'Shelu Bazar'},
{name: 'Sirsala(MH)'},
{name: 'Sirsala(RJ)'},
{name: 'Sirsala(MP)'},
{name: 'Sirsala(HR)'},
{name: 'Talere Naka'},
{name: 'Icchapuram ByPass'},
{name: 'K Kotturu (Andhra Pradesh)'},
{name: 'Palasa ByPass'},
{name: 'Panchama Temple'},
{name: 'Panchama Temple ByPass'},
{name: 'Telgoan(MH)'},
{name: 'Telgaon (Karnataka)'},
{name: 'Uttur (Karnataka)'},
{name: 'Vaduj'},
{name: 'Wadner'},
{name: 'Wadner Bholji'},
{name: 'Wadwani'},
{name: 'Rameswar (Orissa)'},
{name: 'Rameswar ByPass'},
{name: 'Gangapada'},
{name: 'Gangapada ByPass'},
{name: 'Bhadar(UP)'},
{name: 'Ramganj (Uttar Pradesh)'},
{name: 'Mallial'},
{name: 'Buxar(Bihar)'},
{name: 'Bambora (Rajasthan)'},
{name: 'Baroda Vagodiya Chokdi'},
{name: 'Ghodidhal'},
{name: 'Godpar'},
{name: 'Gudha Gorji'},
{name: 'Indore ByPass(Dhar)'},
{name: 'Kavant'},
{name: 'Kundanpur (Gujarat)'},
{name: 'Manjal (Gujarat)'},
{name: 'Mokha (Gujarat)'},
{name: 'Saroda (Rajasthan)'},
{name: 'Sidhmukh'},
{name: 'Bhanduri'},
{name: 'Saputara'},
{name: 'Anantapur(KA)'},
{name: 'Morampalli Banjar'},
{name: 'Lakshmipuram (Khammam Dist'},
{name: 'Perumal Malai(Kodaikanal)'},
{name: 'Kavaraipettai'},
{name: 'Tharangambadi'},
{name: 'Oothu (Kodaikanal)'},
{name: 'Peyanvilai(Tuticorin)'},
{name: 'Sannanallur'},
{name: 'Andipanthal'},
{name: 'Athikadu Thekkur'},
{name: 'Kulipirai'},
{name: 'Rahata (Ahmednagar'},
{name: 'Anandpuri(Rajasthan)'},
{name: 'Anjana(Rajasthan)'},
{name: 'Arthuna'},
{name: 'Bhujodi'},
{name: 'Bori(Rajasthan)'},
{name: 'Chandroda'},
{name: 'Deshalpur (Kanthi)'},
{name: 'Gambhoi'},
{name: 'Gangapur(Rajasthan)'},
{name: 'Khedoi'},
{name: 'Ratnal'},
{name: 'Rishabhdev'},
{name: 'Santrampur'},
{name: 'Vondh'},
{name: 'Siner'},
{name: 'Yaksh'},
{name: 'Omerga (Maharastra)'},
{name: 'Dharoi (Satlasna'},
{name: 'Dobhada'},
{name: 'Hatharva'},
{name: 'Kansa (Gujarat)'},
{name: 'Tarabha'},
{name: 'Valam'},
{name: 'Vav (Gujarat)'},
{name: 'Barodiya Kalan'},
{name: 'Khimlasa'},
{name: 'Madawara (Uttar Pradesh)'},
{name: 'Mahrauni'},
{name: 'Malthone'},
{name: 'Sironj (Madhya Pradesh)'},
{name: 'Nandgaon Titha'},
{name: 'Jakhar Patiya(Gujarat)'},
{name: 'Kanota'},
{name: 'Bansi(Rajasthan)'},
{name: 'Richhed'},
{name: 'Padana(Gujarat)'},
{name: 'Shaitrawa'},
{name: 'Yerpadu'},
{name: 'Mudbi'},
{name: 'Agatrai'},
{name: 'Ahwa'},
{name: 'Alirajpur(Madhya Pradesh)'},
{name: 'Amran'},
{name: 'Bamaniya (Gujarat)'},
{name: 'Becharaji'},
{name: 'Bhogapuram'},
{name: 'Biliya (Gujarat)'},
{name: 'Biliyala'},
{name: 'Bodeli (Gujarat)'},
{name: 'Chansol(Gujarat)'},
{name: 'Charaniya (Gujarat)'},
{name: 'Chhota Udaipur'},
{name: 'Chilakapalem'},
{name: 'Chinturu (Andhra Pradesh)'},
{name: 'Dabhoda(Gujarat)'},
{name: 'Dangar (Maharashtra)'},
{name: 'Dhansa (Rajasthan)'},
{name: 'Dhareshi'},
{name: 'Dholva'},
{name: 'Dhuvaran'},
{name: 'Diyodar'},
{name: 'Ganagapur(Karnataka)'},
{name: 'Ghandla'},
{name: 'Ghantiyan (Gujarat)'},
{name: 'Gunga (RJ)'},
{name: 'Hadala'},
{name: 'Hadmatiya'},
{name: 'Harigadh(Gujarat)'},
{name: 'Huvin Hipparagi'},
{name: 'Idukki Kavala'},
{name: 'Idupulapadu'},
{name: 'Jaila'},
{name: 'Jaspara (Gujarat)'},
{name: 'Jethiyavadar'},
{name: 'Kambham Padu'},
{name: 'Kaniyooru (Karnataka)'},
{name: 'Kanoodar (Gujarat)'},
{name: 'Kathgal(Kumta)'},
{name: 'Kembhavi'},
{name: 'Kesarpura(Gujarat)'},
{name: 'Khambhat'},
{name: 'Khandhera (Gujarat)'},
{name: 'Khanvel'},
{name: 'Kheenvsar (Nagaur Dist'},
{name: 'Korukonda'},
{name: 'Krishnagunj (Rajasthan)'},
{name: 'Kudachi'},
{name: 'Kunavaram (Andhra Pradesh)'},
{name: 'Kuzhalmannam'},
{name: 'Ladnun'},
{name: 'Limbadi'},
{name: 'Lunidhar (Gujarat)'},
{name: 'Matva'},
{name: 'Modhera'},
{name: 'Modpar'},
{name: 'Moka (Karnataka)'},
{name: 'Mokhada (Maharashtra)'},
{name: 'Mota Kandagra'},
{name: 'Mumanvas (Gujarat)'},
{name: 'Najapur'},
{name: 'Narayan Sarovar'},
{name: 'Navavas(Gujarat)'},
{name: 'Nedumangad'},
{name: 'Nedumbassery'},
{name: 'Nepalgunj'},
{name: 'Nigdi (Pune)'},
{name: 'Ninthikallu (Karnataka)'},
{name: 'Pachpipla (Gujarat)'},
{name: 'Palasamudram'},
{name: 'Paniya (Gujarat)'},
{name: 'Periyamedu'},
{name: 'Pithadia'},
{name: 'Railmagra'},
{name: 'Rajpipla'},
{name: 'Ramgadh (Gujarat)'},
{name: 'Rentachintala'},
{name: 'Sarasiya'},
{name: 'Sarat (Gujarat)'},
{name: 'Seethampeta (Andhra Pradesh)'},
{name: 'Sembaliya (Gujarat)'},
{name: 'Sheopur (Madhya Pradesh)'},
{name: 'Siddavatam'},
{name: 'Sudasana'},
{name: 'Talegadh (Gujarat)'},
{name: 'Thanagalol'},
{name: 'Theba'},
{name: 'Valukad'},
{name: 'Vamanapuram'},
{name: 'Vavera'},
{name: 'Vijarkhi'},
{name: 'Waghodia'},
{name: 'Kalamnuri'},
{name: 'Bamanbor'},
{name: 'Khallikot'},
{name: 'Pargaon'},
{name: 'Imagica'},
{name: 'Karanja (Ghadge)'},
{name: 'Khetdi'},
{name: 'Nim Ka Thana'},
{name: 'Prithvipur (Madhya Pradesh)'},
{name: 'Vemula'},
{name: 'Adyar'},
{name: 'Elanthangudi(Tanjore)'},
{name: 'Hanumanthampatti(Theni)'},
{name: 'Kelambakkam'},
{name: 'Kollumangudi(Thiruvarur)'},
{name: 'Maharajapuram(Virudhunagar)'},
{name: 'Manganallur(Nagapattinam)'},
{name: 'Mullakadu (Tamil Nadu)'},
{name: 'T. Nagar'},
{name: 'Thovalai (Nagercoil'},
{name: 'Walajabad'},
{name: 'Watrap(Virudhunagar)'},
{name: 'Idaiyangudi'},
{name: 'Kooduthalai'},
{name: 'Kootappanai'},
{name: 'Kottankadu'},
{name: 'Kuttam'},
{name: 'Marudamalai'},
{name: 'Periyathalai'},
{name: 'Polaiyarpuram(Tuticorin)'},
{name: 'Pudukudi'},
{name: 'Pali (kenpura)'},
{name: 'Panyla Khurd'},
{name: 'Parab Vavdi'},
{name: 'Gandhinagar(kolhapur)'},
{name: 'Adkur'},
{name: 'Dulwa (Madhya Pradesh)'},
{name: 'Ramsinghpur (Rajasthan)'},
{name: 'Ramji Ka Gol (rajasthan)'},
{name: 'Vadtra'},
{name: 'Bagidora'},
{name: 'Bagnan'},
{name: 'Nagpur (Gujarat)'},
{name: 'Manor (Maharashtra)'},
{name: 'Dhanau (Rajasthan)'},
{name: 'Dawer'},
{name: 'Jojawar'},
{name: 'Navinagar (Uttar Pradesh)'},
{name: 'Rajiyasar'},
{name: 'Keerwa (Rajasthan)'},
{name: 'Kharwa (Rajasthan)'},
{name: 'Khushi Nagar'},
{name: 'Chhoti Khatu'},
{name: 'Ramaniya (Rajasthan)'},
{name: 'Mohammadpur (Bihar)'},
{name: 'Ranebennur Bypass'},
{name: 'Tamkuhi (Bihar)'},
{name: 'Lunsapur'},
{name: 'Chitrasar'},
{name: 'Jagdhar (Gujarat)'},
{name: 'Barar'},
{name: 'Khajuria (Bihar)'},
{name: 'Ansing'},
{name: 'Dewal'},
{name: 'Jaitsar'},
{name: 'Sri Vijaynagar'},
{name: 'Babarkot'},
{name: 'Mogra (Rajasthan)'},
{name: 'Serwa (Rajasthan)'},
{name: 'Barahima (Bihar)'},
{name: 'Chakia (Bihar)'},
{name: 'Gida (Rajasthan)'},
{name: 'Dhawa (Rajasthan)'},
{name: 'Fatehgarh (Uttar Pradesh)'},
{name: 'Rania (Uttar Pradesh)'},
{name: 'Dharmaj'},
{name: 'Tupran'},
{name: 'Uttarlai'},
{name: 'Reliance (meghpar)'},
{name: 'Bajipura'},
{name: 'Dharuhera (haryana)'},
{name: 'Khinvsar'},
{name: 'Tibbi (Rajasthan)'},
{name: 'Chakrda'},
{name: 'Marhaura (Bihar)'},
{name: 'Bambor'},
{name: 'Korna (Rajasthan)'},
{name: 'Pareu'},
{name: 'Patodi (Rajasthan)'},
{name: 'Kuchavada'},
{name: 'Bamanpura'},
{name: 'Lothpur (Gujarat)'},
{name: 'Akhara Balapur'},
{name: 'Gaura (Bihar)'},
{name: 'Ishuapur'},
{name: 'Mashrakh'},
{name: 'Nagra (Bihar)'},
{name: 'Patdi (Gujarat)'},
{name: 'Raikot (Punjab)'},
{name: 'Rohisa (Rajasthan)'},
{name: 'Pinpalgaon'},
{name: 'Muradnagar (Uttar Pradesh)'},
{name: 'Nimaj (Rajasthan)'},
{name: 'Bhalsar'},
{name: 'Kamalighat'},
{name: 'Mudal Tita'},
{name: 'Srikaranpur'},
{name: 'Bahadurgarh'},
{name: 'Banthadi'},
{name: 'Mandi Gobindgarh'},
{name: 'Badi Khatu'},
{name: 'Sanju'},
{name: 'Batkanangale'},
{name: 'Chopta (Uttarakhand)'},
{name: 'Aau'},
{name: 'Ellenabad (Haryana)'},
{name: 'Heroor (Udipi'},
{name: 'Kanore (Udaipur Dist'},
{name: 'Beraja (Patiyu)'},
{name: 'Khajurada'},
{name: 'Khimsar'},
{name: 'Shera (Gujarat)'},
{name: 'Bijawar'},
{name: 'Chapda (Madhya Pradesh)'},
{name: 'Dantiwada (Gujarat)'},
{name: 'Panthawada'},
{name: 'Dhardi (Gujarat)'},
{name: 'Lalgarh (Rajasthan)'},
{name: 'Dharmana (Himachal Pradesh)'},
{name: 'Jawala Bazar (Maharashtra)'},
{name: 'Kiratpur Sahib'},
{name: 'Ranakandorna'},
{name: 'Rupnagar (Punjab)'},
{name: 'Sahpura'},
{name: 'Binjbaila'},
{name: 'Bighapur'},
{name: 'Dalmau (Uttar Pradesh)'},
{name: 'Goluwala'},
{name: 'Lal Kuaan (Uttar Pradesh)'},
{name: 'Mungisapur (Uttar Pradesh)'},
{name: 'Pakka Saharana'},
{name: 'Semari (Uttar Pradesh)'},
{name: 'Takiya (Uttar Pradesh)'},
{name: 'Jetda'},
{name: 'Silwala Khurd'},
{name: 'Tandoorwali'},
{name: 'Urai (Uttar Pradesh)'},
{name: 'Por (Guajarat)'},
{name: 'Yedashi'},
{name: 'Balasore (Orissa)'},
{name: 'Karda (Rajasthan)'},
{name: 'Sanodar'},
{name: 'Shihori (Gujarat)'},
{name: 'Agthala'},
{name: 'Dama (Gujarat)'},
{name: 'Dudhva (Gujarat)'},
{name: 'Jahazpur'},
{name: 'Kapil Dhar (Maharashtra)'},
{name: 'Kumbhardi (Gujarat)'},
{name: 'Makakhera'},
{name: 'Mota Chiloda'},
{name: 'Naroli (Gujarat)'},
{name: 'Piluda'},
{name: 'Sanval'},
{name: 'Sherau'},
{name: 'Bandikui'},
{name: 'Unhel (Ujjain'},
{name: 'Asalgaon'},
{name: 'Jalgaon Jamod'},
{name: 'Tansa (Gujarat)'},
{name: 'Alamsar'},
{name: 'Bhadkasar'},
{name: 'Bhutiya Vasna'},
{name: 'Brahmanwada'},
{name: 'Laloi (Gujarat)'},
{name: 'Ludara (Gujarat)'},
{name: 'Madhupur (Gujarat)'},
{name: 'Malanka'},
{name: 'Mandorana (gir)'},
{name: 'Mitha (Gujarat)'},
{name: 'Motidau (Gujarat)'},
{name: 'Nani Khodiyar'},
{name: 'Nanota (Gujarat)'},
{name: 'Raiya (Gujarat)'},
{name: 'Sanadar (Gujarat)'},
{name: 'Shirad Shahpur'},
{name: 'Tarapur (Himachal Pradesh)'},
{name: 'Vadana'},
{name: 'Bajju Tejpura'},
{name: 'Chadotar (Gujarat)'},
{name: 'Gadh (Gujarat)'},
{name: 'Golaniya'},
{name: 'Kamboi'},
{name: 'Kukas (Rajasthan)'},
{name: 'Kumbhasan (Gujarat)'},
{name: 'Metoda (Gujarat)'},
{name: 'Vedancha'},
{name: 'Chanderiya (Rajasthan)'},
{name: 'Chekhala (Gujarat)'},
{name: 'Der (Gujarat)'},
{name: 'Diodarada'},
{name: 'Jagana (Gujarat)'},
{name: 'Jetalvasana'},
{name: 'Jherda'},
{name: 'Jobner'},
{name: 'Kanesara'},
{name: 'Kanoodar'},
{name: 'Khali (Gujarat)'},
{name: 'Khimana (Gujarat)'},
{name: 'Khoda (Gujarat)'},
{name: 'Krushnapur (patiyu)'},
{name: 'Majadar'},
{name: 'Melusan (Gujarat)'},
{name: 'Mevad (Gujarat)'},
{name: 'Nana Badanpar (Patiyu)'},
{name: 'Nayta (Gujarat)'},
{name: 'Ramosana'},
{name: 'Teniwada'},
{name: 'Timarni (Madhya Pradesh)'},
{name: 'Udvada'},
{name: 'Umari (Gujarat)'},
{name: 'Seelayampatti (Theni Dist)'},
{name: 'Murthal'},
{name: 'Sant Kabir Nagar'},
{name: 'Harraiya (Uttar Pradesh)'},
{name: 'Rangampet(Tirupathi)'},
{name: 'Agaly'},
{name: 'Anakkatty'},
{name: 'Arthungal'},
{name: 'Ayantavila'},
{name: 'Bandhaduka'},
{name: 'Chathannoor'},
{name: 'Chembakapara'},
{name: 'Cherambadi'},
{name: 'Cheruthoni'},
{name: 'Elappara(Kerala)'},
{name: 'Idukki'},
{name: 'Kattakada'},
{name: 'Kattakkada'},
{name: 'Konnakkad (Kerala)'},
{name: 'Mattupetty'},
{name: 'Odayanchal (Kerala)'},
{name: 'Panjikal'},
{name: 'Purapuzha'},
{name: 'Sarkara temple'},
{name: 'Thavalam (Kerala)'},
{name: 'Thirumandham kunnu'},
{name: 'Thirunelly'},
{name: 'Vellarikundu'},
{name: 'Amod (Gujrat)'},
{name: 'Anklav'},
{name: 'Bariya (Gujarat)'},
{name: 'Bhandu(Mahesana)'},
{name: 'Buhari'},
{name: 'Charadva'},
{name: 'Charkha'},
{name: 'Dadva'},
{name: 'Denap'},
{name: 'Devadiya'},
{name: 'Dhamrej kdn'},
{name: 'Dhanpur (Gujarat)'},
{name: 'Dholka'},
{name: 'Divasa (Gujarat)'},
{name: 'Dolvan x unai'},
{name: 'Fatehgarh (Punjab)'},
{name: 'Fatepura (Gujarat)'},
{name: 'Gangardi (dahod)'},
{name: 'Garbada (Gujarat)'},
{name: 'Ghoghamba'},
{name: 'Gulbar'},
{name: 'Hipavadali sln'},
{name: 'Ishwariya'},
{name: 'Jakhau salt'},
{name: 'Jambuaa'},
{name: 'Jambugam'},
{name: 'Jambughoda'},
{name: 'Javal (Gujarat)'},
{name: 'Jesar'},
{name: 'Jodiya Jamnagar'},
{name: 'Kalindri'},
{name: 'Karanta'},
{name: 'Keriya Chad'},
{name: 'Kevadia (Gujarat)'},
{name: 'Kodram (Gujarat)'},
{name: 'Kotda Pitha'},
{name: 'Kovaya (Amreli Dist'},
{name: 'Lakhtar'},
{name: 'Latipar dhrl'},
{name: 'Madhi (Gujarat)'},
{name: 'Mahudi'},
{name: 'Malwada (Gujarat)'},
{name: 'Manchi (Maharashtra)'},
{name: 'Mandor (Dahod'},
{name: 'Mangadh'},
{name: 'Memadpur (Vadgam'},
{name: 'Mitana (Rajkot'},
{name: 'Munpur (Gujarat)'},
{name: 'Naran gaonmh'},
{name: 'Nasvadi'},
{name: 'Nizar'},
{name: 'Pavagadh'},
{name: 'Pavijetpur'},
{name: 'Petlad'},
{name: 'Pipalkhed (Vansda)'},
{name: 'Piplod'},
{name: 'Pithvajal'},
{name: 'Prakasha'},
{name: 'Rabari colony abd'},
{name: 'Raigad'},
{name: 'Ramsingh'},
{name: 'Sanjeli'},
{name: 'Shantivan'},
{name: 'Sonipur'},
{name: 'Statue of Unity (Navagam)'},
{name: 'Suliyat'},
{name: 'Sundhamata'},
{name: 'Surendranagar (Gujarat)'},
{name: 'Taraktalav'},
{name: 'Tejgadh'},
{name: 'Thara (Gujarat)'},
{name: 'Tharmal'},
{name: 'Thunthikankasia'},
{name: 'Timbi Road'},
{name: 'Tranpatiya jmn'},
{name: 'Uchhal (Bihar)'},
{name: 'Umargam (Gujarat)'},
{name: 'Undava (Gujarat)'},
{name: 'Vadgam pln'},
{name: 'Vadhvan (Gujarat)'},
{name: 'Valvada'},
{name: 'Vinchhiwada'},
{name: 'Virsad'},
{name: 'Zadkala'},
{name: 'Zagadiya'},
{name: 'Zoz'},
{name: 'Kugti'},
{name: 'Balamba'},
{name: 'Bhamodara(Saverkundla)'},
{name: 'Bhathena'},
{name: 'Bhavandagad (Ahwa)'},
{name: 'Bhunav'},
{name: 'Choda (Gariyadhar)'},
{name: 'Daladi Khamba'},
{name: 'Danta'},
{name: 'Dhangdhra Road'},
{name: 'Dindori (Maharastra)'},
{name: 'Hadad'},
{name: 'Kariyani (Barwala)'},
{name: 'Lakdiya'},
{name: 'Lakhiyani (Barwala)'},
{name: 'Meta'},
{name: 'Nana Pondha'},
{name: 'Olpad'},
{name: 'Ramwadi (MH)'},
{name: 'Pilucha(Kheralu)'},
{name: 'Pipari (Ahwa)'},
{name: 'Prashnavada (Kodinar)'},
{name: 'Ranghola (Gujarat)'},
{name: 'Shehera'},
{name: 'Sander (Patan)'},
{name: 'Sardhar (Rajkot)'},
{name: 'Shetrunji Dam'},
{name: 'Tarana (Gujarat)'},
{name: 'Tulsishyam'},
{name: 'Umarkui (Vansda)'},
{name: 'Umta (Gujarat)'},
{name: 'Vadagam (Dhansra)'},
{name: 'Zamrala (Barwala)'},
{name: 'Bhabua (Bihar)'},
{name: 'Kudra (Bihar)'},
{name: 'Mohania (Bihar)'},
{name: 'Kasar Sirsi'},
{name: 'Kaliyal(kanniyakumari)'},
{name: 'Tirparappu'},
{name: 'Khanapur(Telangana)'},
{name: 'Kanumuru'},
{name: 'Komaravolu (Andhra Pradesh)'},
{name: 'Pedapalaparru'},
{name: 'Allapadu'},
{name: 'Chevuturu (Andhra Pradesh)'},
{name: 'Konduru(Andhra Pradesh)'},
{name: 'Chaudagra'},
{name: 'Kamalganj'},
{name: 'Ralagon'},
{name: 'Chigaralli'},
{name: 'Shedhakhai'},
{name: 'Temagav (Madhya Pradesh)'},
{name: 'Thawariya (Rajasthan)'},
{name: 'Bhadruna'},
{name: 'Bhootel'},
{name: 'Hingolgadh'},
{name: 'Periyanyakanpalayam'},
{name: 'Narsana'},
{name: 'Ponkh'},
{name: 'Bhutiya (Gujarat)'},
{name: 'Bijuwali'},
{name: 'Mota Bhandariya'},
{name: 'Budhanpur(UP)'},
{name: 'Umri (Maharashtra)'},
{name: 'Salkanpur'},
{name: 'Nanasa'},
{name: 'Shohagpur'},
{name: 'Bhorki'},
{name: 'Khandera (Madhya Pradesh)'},
{name: 'Batiyagarh'},
{name: 'Amrat'},
{name: 'Sonkatch'},
{name: 'Umaiyavadar Patiya'},
{name: 'Murum (Maharashtra)'},
{name: 'Khadkala'},
{name: 'Dharvala (Gujarat)'},
{name: 'Amanganj'},
{name: 'Tarn Taran'},
{name: 'Bazpur'},
{name: 'Dhudasiya'},
{name: 'Nani Kunkavav (Gujarat)'},
{name: 'Pandagaon'},
{name: 'Bakriyawali'},
{name: 'Kerbana'},
{name: 'Samastipur (Bihar)'},
{name: 'Jajpur (Orissa)'},
{name: 'Relmangra'},
{name: 'Vatul'},
{name: 'Nana Barman'},
{name: 'Jangar (Gujarat)'},
{name: 'Dadma'},
{name: 'Kolipura (Madhya Pradesh)'},
{name: 'Utavad (Maharashtra)'},
{name: 'Chanaka(Gujarat)'},
{name: 'Ranavav Bypass'},
{name: 'Taalsar'},
{name: 'Kamigadh (Gujarat)'},
{name: 'Lalpar (Gujarat)'},
{name: 'Salpura (Rajasthan)'},
{name: 'Ujani'},
{name: 'Sam'},
{name: 'Padana Patiya (Gujarat)'},
{name: 'Hampankatta'},
{name: 'Gagner Talai'},
{name: 'Jampar'},
{name: 'Silawad (Madhya Pradesh)'},
{name: 'Kanja'},
{name: 'Kothra (Madhya Pradesh)'},
{name: 'Manawar'},
{name: 'Deulgaon Gujari'},
{name: 'Moti Jagadhar'},
{name: 'Tajpur (Bihar)'},
{name: 'Pasumarru'},
{name: 'Jaisinagar (Madhya Pradesh)'},
{name: 'Fuli (Maharashtra)'},
{name: 'Akala'},
{name: 'Gundala Jam'},
{name: 'Kanavadala (Jamkandorna)'},
{name: 'Farhatabad (Karnataka)'},
{name: 'Jalaun'},
{name: 'Trambakpur'},
{name: 'Banapura (Madhya Pradesh)'},
{name: 'Soro (Orissa)'},
{name: 'Dabalchooki'},
{name: 'Bidri'},
{name: 'Khurd'},
{name: 'Sobhavad'},
{name: 'Pilikarar'},
{name: 'Mota Barman'},
{name: 'Kundalpur (Madhya Pradesh)'},
{name: 'Ashiv'},
{name: 'Yenegur'},
{name: 'Sanosari'},
{name: 'Bagan Kheda'},
{name: 'Gadhula (Gujarat)'},
{name: 'Mohanpur'},
{name: 'Nana Liliya'},
{name: 'Bagdhara(Gujarat)'},
{name: 'Khajuri (Gujarat)'},
{name: 'Nawa (Rajasthan)'},
{name: 'Bodegaon(Maharastra)'},
{name: 'Jalkot (Osmanabad'},
{name: 'Jhanjariya'},
{name: 'Tarwadi'},
{name: 'Rahatgaon (Madhya Pradesh)'},
{name: 'Munna Doongar (Rajasthan)'},
{name: 'Nayabas (Rajasthan)'},
{name: 'Garmali Patiyu'},
{name: 'Godda'},
{name: 'Rajpara Patiya'},
{name: 'Bagrul'},
{name: 'Jaljivadi'},
{name: 'Tapi'},
{name: 'Latipar (Gujarat)'},
{name: 'Nava Devka'},
{name: 'Ghatabillod'},
{name: 'Detad'},
{name: 'Sampurna Nagar'},
{name: 'Mangvapal'},
{name: 'Gadhi (Maharashtra)'},
{name: 'Dhargaon (Maharashtra)'},
{name: 'Gangarampur'},
{name: 'Muldharai (Gujarat)'},
{name: 'Rajeshwar'},
{name: 'Mayapadar'},
{name: 'Bhakharpura'},
{name: 'Tamia'},
{name: 'Khunti (Jharkhand)'},
{name: 'Madpura'},
{name: 'Gomta'},
{name: 'Qayamganj'},
{name: 'Mahendra'},
{name: 'Sartanpar'},
{name: 'Bangaon (Madhya Pradesh)'},
{name: 'Dadar(Junagadh Dist'},
{name: 'Sindhaswa Harniyan'},
{name: 'Nivali Phata'},
{name: 'Kanpar (Gujarat)'},
{name: 'Sapda (Gujarat)'},
{name: 'Bodiya(Gujarat)'},
{name: 'Nethrana'},
{name: 'Ganeshpur (buldhana)'},
{name: 'Ghuwara (Madhya Pradesh)'},
{name: 'Deepgaon (Madhya Pradesh)'},
{name: 'Shemba'},
{name: 'Jira (Gujarat)'},
{name: 'Challa (Rajasthan)'},
{name: 'Roan (Madhya Pradesh)'},
{name: 'Champaner(Gujarat)'},
{name: 'Untvad'},
{name: 'Chaparda'},
{name: 'Saliyon Ka Khera'},
{name: 'Chardika'},
{name: 'Juni Dhari Gundali'},
{name: 'Repura (Madhya Pradesh)'},
{name: 'Pilarkhana'},
{name: 'Nana Bhandariya'},
{name: 'Sanjantimba'},
{name: 'Kagdadi'},
{name: 'Joya (Uttarakhand)'},
{name: 'Nanduri (Gujarat)'},
{name: 'Rithi (Madhya Pradesh)'},
{name: 'Sirali (Madhya Pradesh)'},
{name: 'Chhidgaon'},
{name: 'Mandawa (Rajasthan)'},
{name: 'Tanodiya'},
{name: 'Bhogat'},
{name: 'Dumka (Gujarat)'},
{name: 'Sondarda'},
{name: 'Somesar'},
{name: 'Latehar'},
{name: 'Deoda'},
{name: 'Nandakumar'},
{name: 'Khidkya'},
{name: 'Egra'},
{name: 'Bhesan'},
{name: 'Mandalikpur'},
{name: 'Asika'},
{name: 'Bharkachha Kalan'},
{name: 'Dhamangaon Badhe'},
{name: 'Balapur (gujarat)'},
{name: 'Patna (bhal)'},
{name: 'Zirakpur (Punjab)'},
{name: 'Lunghiya'},
{name: 'Joda'},
{name: 'Padukallan'},
{name: 'Giror'},
{name: 'Kodli (Karnataka)'},
{name: 'Chandan (Rajasthan)'},
{name: 'Raisen'},
{name: 'Mukkani'},
{name: 'Karanji (Ahmednagar)'},
{name: 'Bajkul'},
{name: 'Mohammadabad (Uttar Pradesh)'},
{name: 'Sandalpur (Madhya Pradesh)'},
{name: 'Budhni'},
{name: 'Sheohar'},
{name: 'Parasia (Madhya Pradesh)'},
{name: 'Tornale'},
{name: 'Galath'},
{name: 'Katraj'},
{name: 'Kundwa (Rajasthan)'},
{name: 'Chanwara (Rajasthan)'},
{name: 'Dhundhoraji'},
{name: 'Sanala'},
{name: 'Uluberia'},
{name: 'Saldi'},
{name: 'Talasari'},
{name: 'Dungala (Gujarat)'},
{name: 'Droneshwar'},
{name: 'Inaj'},
{name: 'Rojid'},
{name: 'Jambala'},
{name: 'Matkuli (Madhya Pradesh)'},
{name: 'Pandado (Madhya Pradesh)'},
{name: 'Chengta (Karnataka)'},
{name: 'Dhusiya'},
{name: 'Lakhapadar'},
{name: 'Umrethi'},
{name: 'Goriviyali'},
{name: 'Bara (Madhya Pradesh)'},
{name: 'Harsad (Gujarat)'},
{name: 'Bewar(RJ)'},
{name: 'Ranapur (Madhya Pradesh)'},
{name: 'Nana Bhamodra'},
{name: 'Nekarikallu (Andhra Pradesh)'},
{name: 'Sodalpur (Madhya Pradesh)'},
{name: 'Jashipur (Odisha)'},
{name: 'Gad Ganga'},
{name: 'Verul (Maharashtra)'},
{name: 'Borkheda'},
{name: 'Gopalpur (Madhya Pradesh)'},
{name: 'Kharachia'},
{name: 'Jhupa'},
{name: 'Junagarh (Orissa)'},
{name: 'Panchtobra'},
{name: 'Jatruda'},
{name: 'Umru (Gujarat)'},
{name: 'Jangvad'},
{name: 'Mecheda'},
{name: 'Walsang (Maharashtra)'},
{name: 'Zerda'},
{name: 'Khodvadari'},
{name: 'Bhira (Uttar Pradesh)'},
{name: 'Pasaya'},
{name: 'Kolda (Gujarat)'},
{name: 'Dhutarpur'},
{name: 'Shiyawat'},
{name: 'Somrad'},
{name: 'Jambuda'},
{name: 'Rampar Dhal'},
{name: 'Karnawad (Madhya Pradesh)'},
{name: 'Barauni'},
{name: 'Dori'},
{name: 'Thordi (Gujarat)'},
{name: 'Devpur Ranuja'},
{name: 'Bhuibavada'},
{name: 'Goriwala'},
{name: 'Mandavda (Gujarat)'},
{name: 'Meghapipaliya'},
{name: 'Mahidpur (Madhya Pradesh)'},
{name: 'Dariyabad'},
{name: 'Methan'},
{name: 'Navi Dhari Gundali'},
{name: 'Vertej'},
{name: 'Peepaliya Kalan'},
{name: 'Devgam (Gujarat)'},
{name: 'Chhipaner (Madhya Pradesh)'},
{name: 'Ranihati'},
{name: 'Gop'},
{name: 'Paprenda'},
{name: 'Kalgi (karnataka)'},
{name: 'Siwara (Rajasthan)'},
{name: 'Itada'},
{name: 'Dhakan Kunda Patiyu'},
{name: 'Kalidevi'},
{name: 'Janwarkatte'},
{name: 'Surnivash'},
{name: 'Parab'},
{name: 'Kamadhiya'},
{name: 'Chotra'},
{name: 'Chimthana (Dhule Dist'},
{name: 'Shobhapur (Madhya Pradesh)'},
{name: 'Pachhegam'},
{name: 'Dudgaon (Madhya Pradesh)'},
{name: 'Chapoli(Maharastra)'},
{name: 'Contai (West Bengal)'},
{name: 'Bidhuna'},
{name: 'Gagriya'},
{name: 'Gawasen'},
{name: 'Kalwana (Madhya Pradesh)'},
{name: 'Mandan (Gujarat)'},
{name: 'Ingorala Babra'},
{name: 'Karanjia'},
{name: 'Nathusari Chopta'},
{name: 'Silwani (Madhya Pradesh)'},
{name: 'Jagdish'},
{name: 'Ghoghla'},
{name: 'Khudala (Rajasthan)'},
{name: 'Hadmatiya Khakhra'},
{name: 'Bhongoan'},
{name: 'Maliwayan'},
{name: 'Bhundani'},
{name: 'Adawad'},
{name: 'Jobat'},
{name: 'Rehti'},
{name: 'Rujan Khedi'},
{name: 'Mota Gokharwala'},
{name: 'Chaibasa'},
{name: 'Hirapur (Maharastra)'},
{name: 'Rafaliya'},
{name: 'Mithrau'},
{name: 'Khajuri Pipaliya'},
{name: 'Shivrajpur (Gujarat)'},
{name: 'Bagoli'},
{name: 'Gundasari'},
{name: 'Sultanganj (Bihar)'},
{name: 'Jhab'},
{name: 'Turbhe'},
{name: 'Wathar (Maharashtra)'},
{name: 'Ladkui (Madhya Pradesh)'},
{name: 'Masaurhi Buzurg'},
{name: 'Juni Haliyad'},
{name: 'Atraullia ( Uttar Pradesh)'},
{name: 'Odhav'},
{name: 'Konch (Uttar Pradesh)'},
{name: 'Bairagnia'},
{name: 'Charkhadiya'},
{name: 'Shahkot (Punjab)'},
{name: 'Khaira (Maharashtra)'},
{name: 'Randhiya (Gujarat)'},
{name: 'Deoni'},
{name: 'Baktara'},
{name: 'Mailani (Uttar Pradesh)'},
{name: 'Khandela'},
{name: 'Chandankera (Karnataka)'},
{name: 'Vithodar'},
{name: 'Marguthi'},
{name: 'Bargarh (Uttar Pradesh)'},
{name: 'Kalyani (West Bengal)'},
{name: 'Nana Munjiyasar'},
{name: 'Aliganj (Uttar pradesh)'},
{name: 'Pagdhal (Madhya Pradesh)'},
{name: 'Mihona'},
{name: 'Sarai (Bihar)'},
{name: 'Kotada Pitha'},
{name: 'Andur'},
{name: 'Dedakadi'},
{name: 'Sallopat'},
{name: 'Chhipa Barod'},
{name: 'Khal (Madhya Pradesh)'},
{name: 'Charkheda (Madhya Pradesh)'},
{name: 'Habra'},
{name: 'Shivpur (Madhya Pradesh)'},
{name: 'Mota Ankadiya'},
{name: 'Hemaguda'},
{name: 'Bheemarayanagudi'},
{name: 'Khoor (Rajasthan)'},
{name: 'Raikia'},
{name: 'Shrimadhopur (Rajasthan)'},
{name: 'Hirana (Gujarat)'},
{name: 'Butavadar'},
{name: 'Dhekna (Madhya Pradesh)'},
{name: 'Chaprajpur'},
{name: 'Simariya (Madhya Pradesh)'},
{name: 'Belampar'},
{name: 'Sandhida'},
{name: 'Musrigharari'},
{name: 'Chirapatla (Madhya Pradesh)'},
{name: 'Lhagala'},
{name: 'Sarangpur(Madhya Pradesh)'},
{name: 'Palsud'},
{name: 'Targhari'},
{name: 'Joniha'},
{name: 'Mota Samadhiyala (Gujarat)'},
{name: 'Berhampore (West Bengal)'},
{name: 'Khoja Beraja'},
{name: 'Patera (Madhya Pradesh)'},
{name: 'Sarkhani'},
{name: 'Rugnathpur'},
{name: 'Anoppura'},
{name: 'Beraja Pasaya'},
{name: 'Aliabada (patiya)'},
{name: 'Gunda (Gujarat)'},
{name: 'Garia'},
{name: 'Yakutganj'},
{name: 'Ravani'},
{name: 'Galodar (Gujarat)'},
{name: 'Wagdari'},
{name: 'Timba'},
{name: 'Devendra Nagar (Madhya Pradesh)'},
{name: 'Gaglasan'},
{name: 'Rewari (Haryana)'},
{name: 'Mota Mandvada'},
{name: 'Hatta (Maharashtra)'},
{name: 'Miya Khijadiya'},
{name: 'Salvan (Uttar Pradesh)'},
{name: 'Karagadi (karanagiri)'},
{name: 'Malpem'},
{name: 'Surana (Rajasthan)'},
{name: 'Koyalana'},
{name: 'Mota Thavariya'},
{name: 'Kalu (Rajasthan)'},
{name: 'Bhadi(Gujarat)'},
{name: 'Nava Khidjadiya'},
{name: 'Chela (Gujarat)'},
{name: 'Solpatta'},
{name: 'Nagod (Madhya Pradesh)'},
{name: 'Devaliya-dhandhuka'},
{name: 'Harshadpur'},
{name: 'Jesingpara (Rajasthan)'},
{name: 'Polarpur'},
{name: 'Dalimb (Maharashtra)'},
{name: 'Ningala'},
{name: 'Mandasan'},
{name: 'Bamitha'},
{name: 'Dolti'},
{name: 'Gogawan'},
{name: 'Bhachalva'},
{name: 'Bharthana(GJ)'},
{name: 'Dhumbadiya'},
{name: 'Saundal'},
{name: 'Viralimalai Toll'},
{name: 'Karawali (Rajasthan)'},
{name: 'Surajwadi (samkhyari)'},
{name: 'Bargi (rajasthan)'},
{name: 'Daringbadi'},
{name: 'Badwani Plaza'},
{name: 'Khumanpura'},
{name: 'Balel Pipariya'},
{name: 'Jamdadar'},
{name: 'Chitalwana'},
{name: 'Tambesara'},
{name: 'Kantaphod'},
{name: 'Chordi (Gujarat)'},
{name: 'Antaliya(Rajastan)'},
{name: 'Pawai (Madhya Pradesh)'},
{name: 'Luvariya'},
{name: 'Kotha Pipariya'},
{name: 'Seoni Malwa (Madhya Pradesh)'},
{name: 'Kingaon Bk (Yawal'},
{name: 'Khisari (Gujarat)'},
{name: 'Ellora (Aurangabad)'},
{name: 'Gaisabad (Madhya Pradesh)'},
{name: 'Banda(Madhya Pradesh)'},
{name: 'Jhadol (Rajasthan)'},
{name: 'Bheda Pipaliya'},
{name: 'Navagam Kalavad'},
{name: 'Bheeta(Uttar Pradesh)'},
{name: 'Narwali (Rajasthan)'},
{name: 'Magron'},
{name: 'Trakuda'},
{name: 'Bindki'},
{name: 'Magarda'},
{name: 'Padiyad'},
{name: 'Chakkargadh'},
{name: 'Kagdana'},
{name: 'Mahagama'},
{name: 'Jithudi'},
{name: 'Hanol (Uttarakhand)'},
{name: 'Kukshi'},
{name: 'Derasar'},
{name: 'Lalavadar'},
{name: 'Sirur Tajband'},
{name: 'Jahanabad (Bihar)'},
{name: 'Nesadi (Gujarat)'},
{name: 'Lahar (Madhya Pradesh)'},
{name: 'Itkal (Maharastra)'},
{name: 'Fagas'},
{name: 'Ratanvav'},
{name: 'Pipara (Uttar Pradesh)'},
{name: 'Verad'},
{name: 'Dumiyani'},
{name: 'Matli (Madhya Pradesh)'},
{name: 'Nandurbar Crossing'},
{name: 'Sangamner (bypass)'},
{name: 'Mahidpur Road (Madhya Pradesh)'},
{name: 'Jetpur (bypass)'},
{name: 'Khambhaliya Bypass'},
{name: 'Julwaniya Bypass'},
{name: 'Mattimane'},
{name: 'Adiyakkamangalam'},
{name: 'Aravanchal'},
{name: 'Arimalam'},
{name: 'Chandauli (Uttar Pradesh)'},
{name: 'Chittarikkal (Kerala)'},
{name: 'Guthani'},
{name: 'Iringannur'},
{name: 'Kadachira'},
{name: 'Kakkayangad (Kerala)'},
{name: 'Kambalakkad'},
{name: 'Kanichar'},
{name: 'Kankole (Kerala)'},
{name: 'Kodarma'},
{name: 'Kulathur (Vilathikulam)'},
{name: 'Mambaram'},
{name: 'Manathana'},
{name: 'Mangalakudi'},
{name: 'Mathil (Kerala)'},
{name: 'Mehrauna (Uttar Pradesh)'},
{name: 'Moolakaraipatti'},
{name: 'Motipur (Bihar)'},
{name: 'Nawanagar (Bihar)'},
{name: 'Oduvallithattu'},
{name: 'Oriyur'},
{name: 'Padiyottuchal'},
{name: 'Pappinisseri'},
{name: 'Peikulam'},
{name: 'Peralassery'},
{name: 'Peringome (kerala)'},
{name: 'Therthally'},
{name: 'Thettuvazhi'},
{name: 'Thiruthangal'},
{name: 'Thundiyil'},
{name: 'Uruvachal'},
{name: 'Vellayapuram'},
{name: 'Vilathikulam'},
{name: 'Ekma (Bihar)'},
{name: 'Munanjipatti'},
{name: 'Thuneri (Kerala)'},
{name: 'Anbinnagaram'},
{name: 'Ballarpur'},
{name: 'Kayathar Toll'},
{name: 'Vandavasi'},
{name: 'Bela (Telangana)'},
{name: 'Aashta Mode'},
{name: 'Abubshahar (Haryana)'},
{name: 'Adampur (Punjab)'},
{name: 'Adas'},
{name: 'Adsul Phata'},
{name: 'Adtala'},
{name: 'Airwa Katra'},
{name: 'Ajeetpura'},
{name: 'Ajodar'},
{name: 'Alampur (Madhya Pradesh)'},
{name: 'Alasan'},
{name: 'Alote (Madhya Pradesh)'},
{name: 'Amarpatan'},
{name: 'Amba Nagar (MP)'},
{name: 'Ambad(Jalna)'},
{name: 'Ambadi'},
{name: 'Ambaguda'},
{name: 'Ambedkar Nagar'},
{name: 'Amli (Rajasthan)'},
{name: 'Anandpur Sahib (Punjab)'},
{name: 'Anandpuri (RJ)'},
{name: 'Anapur'},
{name: 'Anapur Chhota'},
{name: 'Anjad'},
{name: 'Antagarh'},
{name: 'Anturli'},
{name: 'Armori'},
{name: 'Asada'},
{name: 'Asari sindhiyan'},
{name: 'Ashoknagar (Madhya Pradesh)'},
{name: 'Asnahara (uttar Pradesh)'},
{name: 'Asta Phata'},
{name: 'Aturgaon'},
{name: 'Babhanpur'},
{name: 'Babhulgaon'},
{name: 'Babina'},
{name: 'Babri'},
{name: 'Badawada'},
{name: 'Badodiya (Rajasthan)'},
{name: 'Badwali Chowki'},
{name: 'Bagar'},
{name: 'Bagh (MP)'},
{name: 'Bagor'},
{name: 'Bahadurpur (Madhya Pradesh)'},
{name: 'Bahal'},
{name: 'Bain'},
{name: 'Bajna (Uttar Pradesh)'},
{name: 'Bakor'},
{name: 'Bakra gam'},
{name: 'Balenga'},
{name: 'Ballabgarh (Haryana)'},
{name: 'Baloda Bazar'},
{name: 'Balsamand (Haryana)'},
{name: 'Bamanwas'},
{name: 'Bambhaniya'},
{name: 'Bamnala'},
{name: 'Bamore Kalan'},
{name: 'Bandar Sindri'},
{name: 'Bandarchua'},
{name: 'Bandri (MP)'},
{name: 'Bangla Choraha (Madhya Pradesh)'},
{name: 'Bangra (Uttar Pradesh)'},
{name: 'Bansi Bazar (Uttar Pradesh)'},
{name: 'Baori'},
{name: 'Bap'},
{name: 'Baramkela'},
{name: 'Barghat (Madhya Pradesh)'},
{name: 'Barhani Bazar'},
{name: 'Bari (Rajasthan)'},
{name: 'Baribramana'},
{name: 'Barka Gaon (Bihar)'},
{name: 'Barman (Madhya Pradesh)'},
{name: 'Baroda (Madhya Pradesh)'},
{name: 'Barodameo'},
{name: 'Barodia (Rajasthan)'},
{name: 'Barsur'},
{name: 'Barth Sahib'},
{name: 'Baskhari (Uttar Pradesh)'},
{name: 'Basoda'},
{name: 'Bavada (Gujarat)'},
{name: 'Bawatara (Rajasthan)'},
{name: 'Bazpatti'},
{name: 'Begu (Haryana)'},
{name: 'Belaswadi'},
{name: 'Belkheda'},
{name: 'Belsand'},
{name: 'Belthara Road'},
{name: 'Bemali'},
{name: 'Benipatti'},
{name: 'Beohari'},
{name: 'Berasia'},
{name: 'Bewa'},
{name: 'Bewar (UP)'},
{name: 'Bhagwanpura (Rajasthan)'},
{name: 'Bhaisola'},
{name: 'Bhamodra'},
{name: 'Bhandawpura'},
{name: 'Bhanpur(Uttar Pradesh)'},
{name: 'Bhaptiahi'},
{name: 'Bharauli (Uttar Pradesh)'},
{name: 'Bharsoola Road'},
{name: 'Bhartana (Uttar Pradesh)'},
{name: 'Bharuwa Sumerpur'},
{name: 'Bhattu'},
{name: 'Bhatwan'},
{name: 'Bhawanigarh'},
{name: 'Bhikhodai'},
{name: 'Bhila'},
{name: 'Bhivghat'},
{name: 'Bhiwapur'},
{name: 'Bhod'},
{name: 'Bhopalgarh'},
{name: 'Bhopalpatnam'},
{name: 'Bhopalsagar (Chittorgarh)'},
{name: 'Bhorol'},
{name: 'Bhucho'},
{name: 'Bhunas'},
{name: 'Bhutaha (Bihar)'},
{name: 'Bidadi'},
{name: 'Bidupur'},
{name: 'Binaganj (Madhya Pradesh)'},
{name: 'Birahandi'},
{name: 'Birdha (Madhya Pradesh)'},
{name: 'Birdpur'},
{name: 'Birsinghpur (Madhya Pradesh)'},
{name: 'Bisalpur'},
{name: 'Bissau'},
{name: 'Bisu Kalan'},
{name: 'Bithauli (Bihar)'},
{name: 'Boipariguda'},
{name: 'Bori (MH)'},
{name: 'Boriyapura'},
{name: 'Brahmangaon'},
{name: 'Brahmapuri'},
{name: 'Budsu'},
{name: 'Buhana'},
{name: 'Butibori'},
{name: 'Campirganj (Uttar Pradesh)'},
{name: 'Chabhadiya'},
{name: 'Chanderi'},
{name: 'Chandili'},
{name: 'Chandisar'},
{name: 'Chandrash'},
{name: 'Changodar'},
{name: 'Changoi'},
{name: 'Chapora (Madhya Pradesh)'},
{name: 'Charkhi Dadri'},
{name: 'Charthana'},
{name: 'Chatti'},
{name: 'Chaumahla (Rajasthan)'},
{name: 'Chauri Chaura'},
{name: 'Chauta (Gujarat)'},
{name: 'Chautala'},
{name: 'Chhani Badi'},
{name: 'Chhapara (Madhya Pradesh)'},
{name: 'Chhapra (Madhya Pradesh)'},
{name: 'Chhatapur (Bihar)'},
{name: 'Chhatarpur (Madhya Pradesh)'},
{name: 'Chhatarpur (Jharkhand)'},
{name: 'Chhawani'},
{name: 'Chhibramau'},
{name: 'Chhoti Safer'},
{name: 'Chikarda'},
{name: 'Chilkahar'},
{name: 'Chilma Bazar'},
{name: 'Chinchali (Maharastra)'},
{name: 'Chinchpur'},
{name: 'Chirai'},
{name: 'Chitbaragaon'},
{name: 'Chokawada (Chhattisgarh)'},
{name: 'Choora (Rajasthan)'},
{name: 'Chorai (MP)'},
{name: 'Chorma'},
{name: 'Colonelganj'},
{name: 'Dabhadi (Maharastra)'},
{name: 'Daboh (Madhya Pradesh)'},
{name: 'Dabugaon'},
{name: 'Dahikonga'},
{name: 'Daiya (Gujarat)'},
{name: 'Damua'},
{name: 'Dangiyawas'},
{name: 'Dantaramgarh'},
{name: 'Dapora'},
{name: 'Dasaj'},
{name: 'Daspan (Rajasthan)'},
{name: 'Dasuya (Punjab)'},
{name: 'Daulatgarh (Rajasthan)'},
{name: 'Daurana'},
{name: 'Degaon (Maharastra)'},
{name: 'Dehra Gopipur (Himachal Pradesh)'},
{name: 'Deldar'},
{name: 'Demai'},
{name: 'Deola'},
{name: 'Deotalab'},
{name: 'Dera (Bihar)'},
{name: 'Dera Bassi'},
{name: 'Deradi Janbai'},
{name: 'Deur Budruk'},
{name: 'Devaka (Rajasthan)'},
{name: 'Devda (Gujarat)'},
{name: 'Dewair (Rajasthan)'},
{name: 'Dhakoni'},
{name: 'Dhamanahandi'},
{name: 'Dhanghata'},
{name: 'Dharampuri (Madhya Pradesh)'},
{name: 'Dharmjaygarh'},
{name: 'Dharna (Madhya Pradesh)'},
{name: 'Dharnodhar (Gujarat)'},
{name: 'Dhima (Gujarat)'},
{name: 'Dhingsara'},
{name: 'Dhol (Rajasthan)'},
{name: 'Dholidhar'},
{name: 'Digawa'},
{name: 'Dinara (Madhya Pradesh)'},
{name: 'Dhodar (Madhya Pradesh)'},
{name: 'Doiphodia'},
{name: 'Domaria Ganj'},
{name: 'Dongarkada'},
{name: 'Dongarkinhi'},
{name: 'Dunawa (Madhya Pradesh)'},
{name: 'Dungrana'},
{name: 'Dungri'},
{name: 'Eklingji'},
{name: 'Errabore'},
{name: 'Fazilnagar (Uttar Pradesh)'},
{name: 'Fulki (Gujarat)'},
{name: 'Fundari'},
{name: 'Gadarwara (Madhya Pradesh)'},
{name: 'Galodiya (Rajasthan)'},
{name: 'Gangar Talai (Rajasthan)'},
{name: 'Ganglasan'},
{name: 'Garhshankar (Punjab)'},
{name: 'Gauri Bazar (Uttar Pradesh)'},
{name: 'Gayghat (Uttar Pradesh)'},
{name: 'Ghantiyali (Gujarat)'},
{name: 'Gharghoda'},
{name: 'Ghat Bamuriya'},
{name: 'Ghatnandur'},
{name: 'Gigasan'},
{name: 'Girab (Rajasthan)'},
{name: 'GLA University'},
{name: 'Godhawata'},
{name: 'Gohana'},
{name: 'Gole (Rajasthan)'},
{name: 'Gona (Madhya Pradesh)'},
{name: 'Gopalgram (Gujarat)'},
{name: 'Gopalpura (Rajasthan)'},
{name: 'Gosainganj (Uttar Pradesh)'},
{name: 'Gotan (Rajasthan)'},
{name: 'Gotegaon (Madhya Pradesh)'},
{name: 'Gourjhamar'},
{name: 'Govardhan (Uttar Pradesh)'},
{name: 'Govindpali (Orissa)'},
{name: 'Govindpura (Rajasthan)'},
{name: 'Goyali (Rajasthan)'},
{name: 'Gudamalani'},
{name: 'Gulabganj (Rajasthan)'},
{name: 'Gulaothi (Uttar Pradesh)'},
{name: 'Gursahaiganj'},
{name: 'Gyaraspur'},
{name: 'Hadolti'},
{name: 'Haldirampur'},
{name: 'Halwara'},
{name: 'Hamapur (Gujarat)'},
{name: 'Handia (Uttar Pradesh)'},
{name: 'Harike'},
{name: 'Harpalpur (Madhya Pradesh)'},
{name: 'Hiranagar (Jammu and Kashmir)'},
{name: 'Hodal'},
{name: 'Indergarh (Madhya Pradesh)'},
{name: 'Iqbalgadh'},
{name: 'Isagarh'},
{name: 'Itia Thok'},
{name: 'Itwa (UP)'},
{name: 'Jadupur (Uttar Pradesh)'},
{name: 'Jais'},
{name: 'Jaitane'},
{name: 'Jaithara'},
{name: 'Jakhora (Uttar Pradesh)'},
{name: 'Jalipa (Rajasthan)'},
{name: 'Jamai'},
{name: 'Janana'},
{name: 'Janjali'},
{name: 'Janjariya Nava'},
{name: 'Jantipur (Punjab)'},
{name: 'Jari (Himachal Pradesh)'},
{name: 'Jaruwakheda'},
{name: 'Jassa Khera'},
{name: 'Jaswanthgarh (Rajasthan)'},
{name: 'Jatara'},
{name: 'Javalaga'},
{name: 'Jawad (Madhya Pradesh)'},
{name: 'Jawala Mukhi'},
{name: 'Jawar (Madhya Pradesh)'},
{name: 'Jaynagar (Bihar)'},
{name: 'Jeewana (Rajasthan)'},
{name: 'Jeeyana'},
{name: 'Jethuwal'},
{name: 'Jhijhaniyali'},
{name: 'Jhodge (Malegaon'},
{name: 'Jirawala'},
{name: 'Jiwan Nagar (Haryana)'},
{name: 'Joba (Chhattisgarh)'},
{name: 'Jogidas ka gaon'},
{name: 'Jogni'},
{name: 'Junjani'},
{name: 'Kadaura'},
{name: 'Kadegaon (Sangli Dist'},
{name: 'Kadera (Rajasthan)'},
{name: 'Kadipur (Uttar Pradesh)'},
{name: 'Kahoda'},
{name: 'Kailaras'},
{name: 'Kailash Nagar (Rajasthan)'},
{name: 'Kaimganj'},
{name: 'Kakoshi'},
{name: 'Kaludi'},
{name: 'Kalwari (Uttar Pradesh)'},
{name: 'Kamba (Rajasthan)'},
{name: 'Kaminpura'},
{name: 'Kamol (Rajasthan)'},
{name: 'Kandorna'},
{name: 'Kansabel(Chhattisgarh)'},
{name: 'Kanwa (Punjab)'},
{name: 'Kanwad (Maharastra)'},
{name: 'Karadkhel'},
{name: 'Karahal (Madhya Pradesh)'},
{name: 'Kareli (Madhya Pradesh)'},
{name: 'Karhal (Uttar Pradesh)'},
{name: 'Karjala'},
{name: 'Karkeri'},
{name: 'Karli (Gujarat)'},
{name: 'Karmad (Aurangabad)'},
{name: 'Kasabel'},
{name: 'Katehri'},
{name: 'Kathu Nangal (Punjab)'},
{name: 'Katisour'},
{name: 'Keolari (Madhya Pradesh)'},
{name: 'Kesali (Madhya Pradesh)'},
{name: 'Kesrisinghpur'},
{name: 'Khadka'},
{name: 'Khairagarh (Chhattisgarh)'},
{name: 'Khakhi Jalia'},
{name: 'Khaknar (Madhya Pradesh)'},
{name: 'Khalapur'},
{name: 'Khaniadhana'},
{name: 'Khara (RJ)'},
{name: 'Kharal (Rajasthan)'},
{name: 'Kharar (Punjab)'},
{name: 'Kharsara (UP)'},
{name: 'Kheroj'},
{name: 'Khinwara (Rajasthan)'},
{name: 'Khopa (Bihar)'},
{name: 'Khopala'},
{name: 'Khuldabad'},
{name: 'Khurai (Madhya Pradesh)'},
{name: 'Khutar (Uttar Pradesh)'},
{name: 'Kirawali (Uttar Pradesh)'},
{name: 'Konder (Rajasthan)'},
{name: 'Konta (Chhattisgarh)'},
{name: 'Koregaon Bhima'},
{name: 'Kosi Kalan'},
{name: 'Kotli (Punjab)'},
{name: 'Kotwa (Bihar)'},
{name: 'Kuberpur (Uttar Pradesh)'},
{name: 'Kuchaikote'},
{name: 'Kukdeshwar (Madhya Pradesh)'},
{name: 'Kulpahar'},
{name: 'Kurali (Punjab)'},
{name: 'Kurara (Uttar Pradesh)'},
{name: 'Kurawad (Madhya Pradesh)'},
{name: 'Kurgaon (Rajasthan)'},
{name: 'Kurha (Jalgaon)'},
{name: 'Kurkumbh'},
{name: 'Kurwai'},
{name: 'Lachhura (Rajasthan)'},
{name: 'Lailunga'},
{name: 'Lakhani (Gujarat)'},
{name: 'Lakhanka'},
{name: 'Lakhanpur (Jammu and Kashmir)'},
{name: 'Lakhmapur (Maharashtra)'},
{name: 'Lalganj (Uttar Pradesh)'},
{name: 'Lalitpur (Uttar Pradesh)'},
{name: 'Lamahi (Nepal)'},
{name: 'Lanjhi (Madhya Pradesh)'},
{name: 'Lapsia'},
{name: 'Lasani'},
{name: 'Lateri (Madhya Pradesh)'},
{name: 'Lathidad'},
{name: 'Laxmanpur (Uttar Pradesh)'},
{name: 'Limba Ganesh'},
{name: 'Limbada'},
{name: 'Limda (Hanu bha na)'},
{name: 'Lodam (Chhattisgarh)'},
{name: 'Lodga (Maharastra)'},
{name: 'Lohraiya (Uttar Pradesh)'},
{name: 'Machhali Shahar (Uttar Pradesh )'},
{name: 'Machhaliya (Madhya Pradesh)'},
{name: 'Machiyala'},
{name: 'Madri'},
{name: 'Mahendragarh (HR)'},
{name: 'Mahoba (Uttar Pradesh)'},
{name: 'Maina (Madhya Pradesh)'},
{name: 'Majhari (BR)'},
{name: 'Majiwada(Mumbai)'},
{name: 'Makhu'},
{name: 'Maksudangarh'},
{name: 'Malerkotla'},
{name: 'Malkpur (PN)'},
{name: 'Mandagada (Telangana)'},
{name: 'Mandariya (Rajasthan)'},
{name: 'Mandi Dabwali'},
{name: 'Mandlikpur'},
{name: 'Kesali (MP)'},
{name: 'Mangawan (Madhya Pradesh)'},
{name: 'Mangod (Madhya Pradesh)'},
{name: 'Manjhanpur'},
{name: 'Manjhatoli'},
{name: 'Mankhurd'},
{name: 'Manora (Maharashtra)'},
{name: 'Maroth'},
{name: 'Matel (Dhuva)'},
{name: 'Matwada (Chhattisgarh)'},
{name: 'Maudaha (Uttar Pradesh)'},
{name: 'Mauganj'},
{name: 'Mavjinjava'},
{name: 'Maya Bazar (Uttar Pradesh)'},
{name: 'Medi'},
{name: 'Medshi'},
{name: 'Meethri'},
{name: 'Meghnagar (Madhya Pradesh)'},
{name: 'Mehal Kalan'},
{name: 'Mehgaon (Madhya Pradesh)'},
{name: 'Mehluwa'},
{name: 'Melua Chowraha'},
{name: 'Mhasdi'},
{name: 'Mirganj'},
{name: 'Mirthal (Himachal Pradesh)'},
{name: 'Mohabbat Nagar (Rajasthan)'},
{name: 'Mohana (Madhya Pradesh)'},
{name: 'Mohgaon (Madhya Pradesh)'},
{name: 'Mozari'},
{name: 'Mokhanda'},
{name: 'Mortakka (Madhya Pradesh)'},
{name: 'Mota Gundala'},
{name: 'Moti Monpari'},
{name: 'Mukramabad'},
{name: 'Mullanpur Dakha (Punjab)'},
{name: 'Mundwa (Rajasthan)'},
{name: 'Nabinagar (Bihar)'},
{name: 'Nabipur (Gujarat)'},
{name: 'Nagardevla'},
{name: 'Nana Chiloda'},
{name: 'Nandeshma (Rajasthan)'},
{name: 'Nandghat (Chhattisgarh)'},
{name: 'Nandsha'},
{name: 'Nadur Ghat'},
{name: 'Nangal Chaudhary (Haryana)'},
{name: 'Narahi (Uttar Pradesh)'},
{name: 'Narahia (Bihar)'},
{name: 'Narayangaon (Maharastra)'},
{name: 'Narayangarh (Madhya Pradesh)'},
{name: 'Nariyawal'},
{name: 'Narkhoria (Uttar Pradesh)'},
{name: 'Narnaul'},
{name: 'Narpatganj'},
{name: 'Narsinghpur (Madhya Pradesh)'},
{name: 'Naugarh (Uttar Pradesh)'},
{name: 'Navavaghaniya'},
{name: 'Nawabganj (Uttar Pradesh)'},
{name: 'Nenava'},
{name: 'Nepanagar'},
{name: 'Netrang'},
{name: 'Nikumbh'},
{name: 'Nimba (Maharastra)'},
{name: 'Nimed (Chhattisgarh)'},
{name: 'Ninan (Rajasthan)'},
{name: 'Niphad (Maharashtra)'},
{name: 'Nitur (Maharastra)'},
{name: 'Niwas'},
{name: 'Nizampur (Maharashtra)'},
{name: 'Nowrozabad'},
{name: 'Obedullaganj'},
{name: 'Oros (Maharashtra)'},
{name: 'Pachkhora'},
{name: 'Pachperwa'},
{name: 'Padhar (Madhya Pradesh)'},
{name: 'Padwa (Rajasthan)'},
{name: 'Palera (Madhya Pradesh)'},
{name: 'Palwal'},
{name: 'Pangaon (Maharashtra)'},
{name: 'Pangri (Sinnar)'},
{name: 'Paniar'},
{name: 'Panidhra'},
{name: 'Panjuana'},
{name: 'Pansada'},
{name: 'Pantheri'},
{name: 'Panwari (Uttar Pradesh)'},
{name: 'Paota'},
{name: 'Papadahandi'},
{name: 'Parasoli (Rajasthan)'},
{name: 'Paraspur (Uttar Pradesh)'},
{name: 'Parmanand'},
{name: 'Patas'},
{name: 'Pathari (Madhya Pradesh)'},
{name: 'Patharia (Madhya Pradesh)'},
{name: 'Patra (Punjab)'},
{name: 'Patratoli'},
{name: 'Patsangvi'},
{name: 'Patti (Uttar Pradesh)'},
{name: 'Pauli (Uttar Pradesh)'},
{name: 'Pavapuri (Rajasthan)'},
{name: 'Pedhla'},
{name: 'Peth (Nashik)'},
{name: 'Peth Shivani'},
{name: 'Pethapur (Gujarat)'},
{name: 'Phenhara'},
{name: 'Phephna (Uttar Pradesh)'},
{name: 'Phoolpur (Uttar Pradesh)'},
{name: 'Pichhore (Madhya Pradesh)'},
{name: 'Pipaliya (Rajasthan)'},
{name: 'Pipalkhunt (Rajasthan)'},
{name: 'Powayan'},
{name: 'Prabhat Pattan'},
{name: 'Pratapganj (Bihar)'},
{name: 'Prithvipur (MP)'},
{name: 'Pupri (Bihar)'},
{name: 'Pura Bazar'},
{name: 'Purur'},
{name: 'Pusauli (Bihar)'},
{name: 'Rabarika'},
{name: 'Ragarganj Bazar'},
{name: 'Raghogarh (Madhya Pradesh)'},
{name: 'Raghogarh Vijaypur'},
{name: 'Raighar (Odisha)'},
{name: 'Raikote (Chhattisgarh)'},
{name: 'Rajbera (Rajasthan)'},
{name: 'Rajepur (Bihar)'},
{name: 'Rajgarh (Rajasthan)'},
{name: 'Rajghat (Madhya Pradesh)'},
{name: 'Rajhara'},
{name: 'Rajmarg (madhya Pradesh)'},
{name: 'Ramgarh (Dantaramgarh'},
{name: 'Rampura Phul (Punjab)'},
{name: 'Rani'},
{name: 'Rani Ki Sarai'},
{name: 'Ranjeetbag'},
{name: 'Rasra (Uttar Pradesh)'},
{name: 'Rasulabad (UP)'},
{name: 'Ratangarh (Rajasthan)'},
{name: 'Rayya'},
{name: 'Redhakhol'},
{name: 'Rehli'},
{name: 'Renapur'},
{name: 'Rewatra'},
{name: 'Rithad'},
{name: 'Rondapali'},
{name: 'Rosera'},
{name: 'Sabalgarh (Madhya Pradesh)'},
{name: 'Chak Sahbu'},
{name: 'Sadewala (Haryana)'},
{name: 'Sagarpali (Uttar Pradesh)'},
{name: 'Sahnewali'},
{name: 'Sahuwala (Rajasthan)'},
{name: 'Sailana'},
{name: 'Salon'},
{name: 'Saltauwa (Uttar Pradesh)'},
{name: 'Samalkha'},
{name: 'Samba (Jammu and Kashmir)'},
{name: 'Sambogarh (Rajasthan)'},
{name: 'Samrala (Punjab)'},
{name: 'Sanada (Gujarat)'},
{name: 'Sandwa (Rajasthan)'},
{name: 'Sangarh'},
{name: 'Sangawas'},
{name: 'Sangram'},
{name: 'Sangrampur (Maharastra)'},
{name: 'Sankarna'},
{name: 'Sanna'},
{name: 'Sanwaliya Ji'},
{name: 'Sarambhada'},
{name: 'Sarangi (Madhya Pradesh)'},
{name: 'Sargiguda (Odisha)'},
{name: 'Sarna (Punjab)'},
{name: 'Sasundra'},
{name: 'Sawai Madhopur'},
{name: 'Semra (Chhattisgarh)'},
{name: 'Senawasa'},
{name: 'Seondha'},
{name: 'Shadora'},
{name: 'Shahabad (HR)'},
{name: 'Shahdol'},
{name: 'Shahpura (Bhilwara'},
{name: 'Shahzadpur (Haryana)'},
{name: 'Shambal Pimpri'},
{name: 'Shanichara'},
{name: 'Shemali'},
{name: 'Shendurni'},
{name: 'Sherani Abad'},
{name: 'Shewali'},
{name: 'Shiradhone'},
{name: 'Shivkhori'},
{name: 'Shri Dungargarh'},
{name: 'Shribalaji'},
{name: 'Shyampur (Madhya Pradesh)'},
{name: 'Siddharthnagar (Uttar Pradesh)'},
{name: 'Sikanderpur (Uttar Pradesh)'},
{name: 'Sikriganj'},
{name: 'Singanpur (Chhattisgarh)'},
{name: 'Singheshwar (Bihar)'},
{name: 'Singrauli (Madhya Pradesh)'},
{name: 'Singrauli(Baidhan)'},
{name: 'Sirhind'},
{name: 'Sirsaganj'},
{name: 'Sitamau'},
{name: 'Siyarmau (Madhya Pradesh)'},
{name: 'Siyavat'},
{name: 'Sohagpur (Madhya Pradesh)'},
{name: 'Sohaon'},
{name: 'Soneripura (Gujarat)'},
{name: 'Sonha (Uttar Pradesh)'},
{name: 'Sonpeth'},
{name: 'Soyat Kalan'},
{name: 'Suchi'},
{name: 'Sudhar (Punjab)'},
{name: 'Sujanpur (Punjab)'},
{name: 'Sundariyana'},
{name: 'Sundhia'},
{name: 'Supe (Maharashtra)'},
{name: 'Surera (Rajasthan)'},
{name: 'Surli (Odisha)'},
{name: 'Suthaliya'},
{name: 'Suwasra'},
{name: 'Tadoba'},
{name: 'Tagdi'},
{name: 'Talbahat'},
{name: 'Talbehat (UP)'},
{name: 'Tandulja'},
{name: 'Tapkara (Chhattisgarh)'},
{name: 'Tapukara'},
{name: 'Taravda'},
{name: 'Tariyani'},
{name: 'Tatam (Gujarat)'},
{name: 'Tauliyasar'},
{name: 'Tavav'},
{name: 'Tejmalta'},
{name: 'Telgaon (Maharastra)'},
{name: 'Tembhurni'},
{name: 'Tendukheda'},
{name: 'Teosa (Maharashtra)'},
{name: 'Tetiya'},
{name: 'Thala (Rajasthan)'},
{name: 'Thawar'},
{name: 'Thekma'},
{name: 'Tikar (Rajasthan)'},
{name: 'Tintarwani'},
{name: 'Tintoi'},
{name: 'Toonga'},
{name: 'Toshina'},
{name: 'Tran Patiya (Gujarat)'},
{name: 'Tukaithad'},
{name: 'Tulasipaka'},
{name: 'Tulsipur (Uttar Pradesh)'},
{name: 'Tumnar'},
{name: 'Udaipura (MP)'},
{name: 'Udowal'},
{name: 'Ujlan'},
{name: 'Ukwa (Madhya Pradesh)'},
{name: 'Umaria (Madhya Pradesh)'},
{name: 'Umedpur (Rajasthan)'},
{name: 'Umrain (Uttar Pradesh)'},
{name: 'Umrali (Gujarat)'},
{name: 'Umred'},
{name: 'Unari'},
{name: 'Usrahar'},
{name: 'Vadgam (Gujarat)'},
{name: 'Vankaner'},
{name: 'Vanthali'},
{name: 'Varana (Gujarat)'},
{name: 'Varasada'},
{name: 'Varkhedi'},
{name: 'Velangari'},
{name: 'Verka'},
{name: 'Vinchur'},
{name: 'Viratra'},
{name: 'Virdi (Gujarat)'},
{name: 'Wada (Palghar'},
{name: 'Walwad'},
{name: 'Wandli (Madhya Pradesh)'},
{name: 'Waraseoni'},
{name: 'Wargaon'},
{name: 'Warwat Bakal'},
{name: 'Washi(Osmanabad)'},
{name: 'Wattur Phata'},
{name: 'Wazir Ganj'},
{name: 'Zari (Maharashtra)'},
{name: 'Zira (Punjab)'},
{name: 'Vishesharganj'},
{name: 'Sakri (Bihar)'},
{name: 'Vijaypur (Jammu and Kashmir)'},
{name: 'Hamirpur (UP)'},
{name: 'Manpur (Gujarat)'},
{name: 'Sultanpur (Gujarat)'},
{name: 'Borgaon (Maharastra)'},
{name: 'Samana (Punjab)'},
{name: 'Khatoli (Uttar Pradesh)'},
{name: 'Tanda (Uttar Pradesh)'},
{name: 'Jalalabad (Uttar Pradesh)'},
{name: 'Rania (Haryana)'},
{name: 'Lakhanpur (Chhattisgarh)'},
{name: 'Arambol'},
{name: 'Aronda'},
{name: 'Mandrem'},
{name: 'Nilkanth Dham Poicha'},
{name: 'Banda(Maharashtra)'},
{name: 'G.L.Puram'},
{name: 'Chintapally (Andhra Pradesh)'},
{name: 'Chodavaram (Visakhapatnam)'},
{name: 'Chodavaram (Vijayawada)'},
{name: 'Arakuvalley'},
{name: 'Krishnadevipeta'},
{name: 'Munchingi Puttu'},
{name: 'Kota (Nellore'},
{name: 'Kouthala'},
{name: 'Chejerla'},
{name: 'Kasumuru'},
{name: 'Somasila'},
{name: 'Nakkapally X Road'},
{name: 'Nandavaram'},
{name: 'Nathavalasa'},
{name: 'Nellipakalu'},
{name: 'Nidamanur'},
{name: 'Ontimitta'},
{name: 'Pacherla'},
{name: 'Badarala'},
{name: 'Pakala (Andhra Pradesh)'},
{name: 'Pangidi (Telangana)'},
{name: 'Pedarikatla'},
{name: 'Pedavegi'},
{name: 'Pendurthi'},
{name: 'Bakarapeta (Piler)'},
{name: 'Ponnalur'},
{name: 'Ramabadrapuram'},
{name: 'Rangampet'},
{name: 'Rentachinthala'},
{name: 'Sangam'},
{name: 'Gownipalli (Karnataka)'},
{name: 'ODC'},
{name: 'Simhachalam'},
{name: 'Kanuru (Tanuku)'},
{name: 'Alankhan Pally'},
{name: 'Orwakallu'},
{name: 'Puttur Bypass'},
{name: 'Mullamuru'},
{name: 'Nuzilla-VNK'},
{name: 'Gollapalem-KKD'},
{name: 'Vit Vellur'},
{name: 'Devarapally-rjy'},
{name: 'Peruri Peta Center'},
{name: 'G. Konduru (Krishna Dist)'},
{name: 'Manipal Hospital Waradhi GNT'},
{name: 'State Boarder'},
{name: 'Pondugula'},
{name: 'Kallur (Chittor Dist'},
{name: 'State Border-KA'},
{name: 'Edupulapadu'},
{name: 'Hatti Gudur'},
{name: 'Nallamada'},
{name: 'Nagandla'},
{name: 'Mondemkhallu'},
{name: 'Khadga Valasa'},
{name: 'Yalamanchali'},
{name: 'Mundlamuru'},
{name: 'Ashramam Hptl - Eluru Bypass'},
{name: 'Kondalamma Chinta'},
{name: 'Gopalle (Andhra Pradesh)'},
{name: 'Kooderu (Ananthapur Dist'},
{name: 'Tadvai (Telangana)'},
{name: 'Edumudi'},
{name: 'Muppalla (Prakasam Dist'},
{name: 'Reningavaram'},
{name: 'Marika Valasa'},
{name: 'Brahmamgari matam'},
{name: 'Avidirevu-kothapeta'},
{name: 'T Sundupalli'},
{name: 'Yalamanchali Bypass'},
{name: 'Thondabavi'},
{name: 'Nagalapuram Bypass'},
{name: 'Santhipuram (Andhra Pradesh)'},
{name: 'Nellore Psr Bustand'},
{name: 'Varadayya Palem'},
{name: 'Buchinaidu Kandriga'},
{name: 'Thavanampalli'},
{name: 'Aragonda'},
{name: 'Irala (Andhra Pradesh)'},
{name: 'Gudipala (Andhra Pradesh)'},
{name: 'Srikakulam (Vuyyuru)'},
{name: 'Pachikapalam'},
{name: 'Sirigiripadu-MCL'},
{name: 'Penandipadu'},
{name: 'Sarjapur Circle-BNG'},
{name: 'Vallabhapuram'},
{name: 'Gollagudem-NZD'},
{name: 'Chandrupatla (Nuzividu)'},
{name: 'Mallela-NZD'},
{name: 'Sadum'},
{name: 'Vakkalagadda'},
{name: 'Chitturpu'},
{name: 'Orvakal (Andhra Pradesh)'},
{name: 'Turimella'},
{name: 'Chagantipadu'},
{name: 'Narasapuram(Chatrai'},
{name: 'Upper Sileru'},
{name: 'Mangalagiri Nirmala Convent Bus Stop'},
{name: 'Rajamahendravaram-Diwan Cheruvu Junction'},
{name: 'Narsaraopet Bypass'},
{name: 'Anasagaram'},
{name: 'Kotapad (Odisha)'},
{name: 'Prathipadu-GNT'},
{name: 'Makavarapalem'},
{name: 'Chavatagunta'},
{name: 'Beluguppa'},
{name: 'Apsp-inada-VZM'},
{name: 'Chandragiri (AP) Bypass'},
{name: 'Selam'},
{name: 'Tirupathi Railway Station'},
{name: 'Edukondalu Bus Station-TPT'},
{name: 'Vasili'},
{name: 'Vegavaram'},
{name: 'Lakshmipuram (West Godavari Dist'},
{name: 'Dharbhagudem (West Godavari Dist'},
{name: 'Vayalpadu'},
{name: 'Sivakodu Lock'},
{name: 'Krishnapuram (Near Vuyyuru'},
{name: 'Reddigunta'},
{name: 'Kamma Thimmaiah Palli'},
{name: 'G.Kothapalli'},
{name: 'Puligadda'},
{name: 'Veerapunayini Pally'},
{name: 'Veldurti (Kurnool)'},
{name: 'Pallekona'},
{name: 'Attibele'},
{name: 'Gudur Railway Station'},
{name: 'Panchalingala'},
{name: 'Garikapadu'},
{name: 'Kopparam'},
{name: 'Chirravuru'},
{name: 'Veldurti Bypass - Kurnool'},
{name: 'Kuruva Nagalapuram'},
{name: 'Pyalakuthy'},
{name: 'Vemugodu'},
{name: 'Banavasi (AP)'},
{name: 'Viswabharathi Hospital-KRNL'},
{name: 'Railway Station-VSP'},
{name: 'Rambagicha Busstation-TML'},
{name: 'Kurmannapalem-Steel City'},
{name: 'Yarna Gudem'},
{name: 'Yerpedu'},
{name: 'Borra Caves'},
{name: 'Madnur (Telangana)'},
{name: 'Brahmana Kotkur'},
{name: 'Brodipet'},
{name: 'Sadem'},
{name: 'Dindi Vanam'},
{name: 'Utukotai'},
{name: 'AP Border'},
{name: 'Cement Nagar'},
{name: 'Devadurg'},
{name: 'Hattiguduru'},
{name: 'Hosekota'},
{name: 'Kolar (Himachal Pradesh)'},
{name: 'Chakrayapeta'},
{name: 'Nangali (Karnataka)'},
{name: 'Vaddirala'},
{name: 'Dam X Road'},
{name: 'Umarkot'},
{name: 'Navarangpur'},
{name: 'Vararamachandrapuram'},
{name: 'Vegiwada'},
{name: 'Kopperapadu'},
{name: 'Bangarupalem'},
{name: 'Kanagala (Andhra Pradesh)'},
{name: 'Bukkapatnam'},
{name: 'Karapa'},
{name: 'Kothamajeru'},
{name: 'Hanumathunipadu'},
{name: 'Alavalapadu'},
{name: 'Agraharam (Andhra Pradesh)'},
{name: 'Kotananduru'},
{name: 'Bhakarapet'},
{name: 'Chintamani (Maharastra)'},
{name: 'Yaganti1'},
{name: 'Maalakonda'},
{name: 'Valetivaripalem'},
{name: 'Shanthinagar (Kurnool)'},
{name: 'Kanduru'},
{name: 'H Cross (Karnataka)'},
{name: 'Rompi Cherla'},
{name: 'Sirivella'},
{name: 'Vellaturu (Guntur'},
{name: 'Doddaballapur'},
{name: 'Guttur (Andhra Pradesh)'},
{name: 'Vidyanagar (Kota'},
{name: 'Kothapeta(Tekkali)'},
{name: 'Munnangi'},
{name: 'V.Madugula'},
{name: 'Battiprolu RPL'},
{name: 'Chinamandyam'},
{name: 'Chinnaguttikal'},
{name: 'Alampur X Rd'},
{name: 'Chowdepalle (Palamner)'},
{name: 'Ck.Pally'},
{name: 'Dakkili'},
{name: 'Devanakonda (Andhra Pradesh)'},
{name: 'Devarakonda (Telangana)'},
{name: 'Gaaliveedu'},
{name: 'Gajulapally (Telangana)'},
{name: 'Gandeed (Telangana)'},
{name: 'Gargeypuram'},
{name: 'Gowribidnor'},
{name: 'Gowridevi Peta (Telangana)'},
{name: 'Gundlakunta (Andhra Pradesh)'},
{name: 'Gurubatlagudem'},
{name: 'Haripuram (Andhra Pradesh)'},
{name: 'Jawalagera (Karanataka)'},
{name: 'Kampa Samudram'},
{name: 'Kannapuram (Andhra Pradesh)'},
{name: 'Karatampadu'},
{name: 'Kodmor'},
{name: 'Kodumuru (Telangana)'},
{name: 'Kolluru'},
{name: 'Kommalapadu'},
{name: 'Kothakota'},
{name: 'Kurupam'},
{name: 'Lingala (Andhra Pradesh)'},
{name: 'Maddikera (Andhra Pradesh)'},
{name: 'Manapuram'},
{name: 'Nagalapuram (Tamil Nadu)'},
{name: 'Nagaram (Guntur Dist'},
{name: 'Ananthagiri (Araku)'},
{name: 'Boothpur'},
{name: 'Diguvametta'},
{name: 'Donkarai'},
{name: 'Eepuru'},
{name: 'Eturunagaram'},
{name: 'Govindraopet'},
{name: 'Gummalaxmipuram'},
{name: 'Jolaput'},
{name: 'Kotlapally (Andhra Pradesh)'},
{name: 'Kukkunuru (Andhra Pradesh)'},
{name: 'Machkund (Odisha)'},
{name: 'Mallepally'},
{name: 'Mogalluru'},
{name: 'Nandapur (Odisha)'},
{name: 'Onakadilli'},
{name: 'Pamulapadu (Andhra Pradesh)'},
{name: 'Patapatnam(Boarder)'},
{name: 'Pitchatur'},
{name: 'Poosapati Rega'},
{name: 'Pothavaram'},
{name: 'Ramapuram'},
{name: 'Sabarimalai'},
{name: 'Sirigiri Padu'},
{name: 'Sirivella Metta'},
{name: 'Uthukota'},
{name: 'Vajra Karur'},
{name: 'Yaganti'},
{name: 'Yerragunta (Andhra Pradesh)'},
{name: 'Parlakimidi'},
{name: 'Pottangi'},
{name: 'Chikkaballapur'},
{name: 'Revuru (Andhra Pradesh)'},
{name: 'Mulakalacheruv'},
{name: 'Tallarevu (Andhra Pradesh)'},
{name: 'Devulapalli (Telangana)'},
{name: 'Brahmanapalli'},
{name: 'Pullalacheruvu'},
{name: 'Tungundram'},
{name: 'Vaddadi'},
{name: 'Melmarvathur'},
{name: 'Hanuman Juncation Bypass'},
{name: 'Nivagam'},
{name: 'Balada'},
{name: 'Malakavemula'},
{name: 'Medikonduru'},
{name: 'Neelakunta Puram'},
{name: 'Nanduru'},
{name: 'Mallepalli (Telangana)'},
{name: 'Bheemini (Telangana)'},
{name: 'Vinjamuru Bypass'},
{name: 'Daggupadu'},
{name: 'Pusapadu'},
{name: 'Lambasingi'},
{name: 'Makkuva'},
{name: 'Duggeru (Andhra Pradesh)'},
{name: 'Kulluru (Andhra Pradesh)'},
{name: 'Hanuman Special'},
{name: 'Kasinagar (Orissa)'},
{name: 'Mahimanvita Panchaksetra Darsi'},
{name: 'Navajanardhana Parijathalu'},
{name: 'S.magaluru'},
{name: 'Siva Darshan'},
{name: 'State Border/mpl'},
{name: 'Vadamalapeta (Andhra Pradesh)'},
{name: 'Vps/pylon'},
{name: 'Highway X Rd11'},
{name: 'Chatwa Gate'},
{name: 'Novarangapur'},
{name: 'Bugga-tdp'},
{name: 'Sanipayee'},
{name: 'Klm By Pass'},
{name: 'Pancha Kshetra Darsini'},
{name: 'Trilinga Darshini'},
{name: 'Pancha Shiva Darshan'},
{name: 'Nuchli'},
{name: 'Ashramam Hospital'},
{name: 'Vaishnavakshestra Darshan'},
{name: 'Sivakeshtralu-mhnd'},
{name: 'Ahobhilam'},
{name: 'Uthkota Border'},
{name: 'Kolar Bypass'},
{name: 'Sapta Srinivasa Kshetrala Darsini'},
{name: 'Saptha Srinivasam'},
{name: 'Viruvuru (Udayagiri)'},
{name: 'Medisettivaripalem (Andhra Pradesh)'},
{name: 'Kaluvai'},
{name: 'Dichpally (Atmakur'},
{name: 'Puttapaka'},
{name: 'Rudauli (Uttar Pradesh)'},
{name: 'Kuderu'},
{name: 'Vidapanakal'},
{name: 'Vadakadu'},
{name: 'PHYLON'},
{name: 'MSV PALEM'},
{name: 'Shirdi (Free Darshan)'},
{name: 'Sultanpur (Maharashtra)'},
{name: 'Sultanpur (Rajasthan)'},
{name: 'Wadajee'},
{name: 'Pohore'},
{name: 'Picharde'},
{name: 'Nawal Nagar'},
{name: 'Mhasavad'},
{name: 'Mangrul (Maharastra)'},
{name: 'Kalmadu'},
{name: 'Juwardi'},
{name: 'Gudhe (Maharashtra)'},
{name: 'Chinchgavhan'},
{name: 'Bachchhar'},
{name: 'Adgaon'},
{name: 'Utran (Gujarat)'},
{name: 'Fatehpur(Rajasthan)'},
{name: 'Fatehpur(Himachal Pradesh)'},
{name: 'Fatehpur Rajputan (Punjab)'},
{name: 'Fatehpur (Bihar)'},
{name: 'Fatehpur (Gujarat)'},
{name: 'Bharanipadu'},
{name: 'Bethupally'},
{name: 'Duddepudi'},
{name: 'Lingapalem (Khamam Dist)'},
{name: 'Kistapuram (Telangana)'},
{name: 'Thumbur (Telangana)'},
{name: 'Narayanapuram (Khammam District)'},
{name: 'Balkonda Bypass'},
{name: 'Boath X Road'},
{name: 'Chegunta'},
{name: 'Kamareddy Bypass'},
{name: 'Kelapur'},
{name: 'Mupkal Bypass'},
{name: 'Perkit (Telangana)'},
{name: 'Pimpalkhuti (Maharashtra)'},
{name: 'Ramayanpet Bypass'},
{name: 'Suryapet Bypass'},
{name: 'Toopran Bypass'},
{name: 'Wadki(Maharashtra)'},
{name: 'Kotturu (Karnataka)'},
{name: 'Loni Kalbhor'},
{name: 'Naregal (Karnataka)'},
{name: 'Bondapalli'},
{name: 'Kothakulam'},
{name: 'Fakharpur (Uttar Pradesh)'},
{name: 'Kaisarganj'},
{name: 'Penumudi'},
{name: 'Pedaprolu'},
{name: 'Chhanera (Madhya Pradesh)'},
{name: 'Mundi (Madhya Pradesh)'},
{name: 'Venkatapuram (Mulugu'},
{name: 'Bilaspur'},
{name: 'Mcleod Ganj'},
{name: 'Dharuhera'},
{name: 'Sohna'},
{name: 'Mohan Nagar'},
{name: 'Kosi Kala'},
{name: 'Greater Noida'},
{name: 'Jewar (Uttar Pradesh)'},
{name: 'Bilaspur Kalan'},
{name: 'Bawal (Rajasthan)'},
{name: 'Kotputli'},
{name: 'Shahpura'},
{name: 'Mehandipur Balaji'},
{name: 'Mohali'},
{name: 'Polomajra'},
{name: 'Bhadohi (Uttar Pradesh)'},
{name: 'Sirsa (Rajasthan)'},
{name: 'Kaptanganj (azamgarh)'},
{name: 'Budhanpur (Uttar Pradesh)'},
{name: 'Atraulia'},
{name: 'Mahrua (Uttar Pradesh)'},
{name: 'Pidhi'},
{name: 'Haidargarh'},
{name: 'Lakhanpur (Punjab)'},
{name: 'Barsana (Uttar Pradesh)'},
{name: 'Pantnagar'},
{name: 'Thoriyali'},
{name: 'Bagodara'},
{name: 'Kuvadva'},
{name: 'Bamanbore'},
{name: 'Padadhari'},
{name: 'Badla (Rajasthan)'},
{name: 'Sabarkantha'},
{name: 'Aravalli'},
{name: 'Samkhiyali'},
{name: 'Hansalpur (Gujarat)'},
{name: 'Kommadi'},
{name: 'Madhurawada'},
{name: 'Kasimabad'},
{name: 'Yusufpur (Uttar Pradesh)'},
{name: 'Muhammadabad Gohna (Uttar Pradesh)'},
{name: 'Vellore(TN-AP Border)'},
{name: 'Barodia (Madhya Pradesh)'},
{name: 'Mahagaon Cross (Karnataka)'},
{name: 'Fardapur(Maharashtra)'},
{name: 'Fardapur(Uttar Pradesh)'},
{name: 'Ranipur(Uttar Pradesh)'},
{name: 'Ranipur(Uttarakhand)'},
{name: 'Chandera(Madhya Pradesh)'},
{name: 'Lidhorakhas (madhya Pradesh)'},
{name: 'Lidhorakhas'},
{name: 'Digoda(Madhya Pradesh)'},
{name: 'Mandal (Mahuva)'},
{name: 'Mandideep'},
{name: 'Atarra (Uttar Pradesh)'},
{name: 'Khargapur(Madhya Pradesh)'},
{name: 'Baldeogarh(Madhya Pradesh)'},
{name: 'Baldeogarh(Rajasthan)'},
{name: 'Ashta mod (Maharashtra)'},
{name: 'Murud (Latur)'},
{name: 'Ashta (Maharashtra)'},
{name: 'Yedshi'},
{name: 'Geddanapalli'},
{name: 'Uppudi'},
{name: 'K.Pedapudi'},
{name: 'Jaganadhagiri'},
{name: 'Gummileru'},
{name: 'Kantada'},
{name: 'Sarkadiya'},
{name: 'Mota Gokharwad'},
{name: 'Chhakargadh Patiu'},
{name: 'Ghaghas (Himachal Pradesh)'},
{name: 'Pandoh'},
{name: 'Diva(Maharastra)'},
{name: 'Singhpur'},
{name: 'Kondapi'},
{name: 'Golapangari'},
{name: 'Thummuru (Telangana)'},
{name: 'Diddipudi'},
{name: 'Nimmada'},
{name: 'Jamb Bk'},
{name: 'Latur Road'},
{name: 'Chapoli'},
{name: 'Kolbandre'},
{name: 'Vakavali'},
{name: 'Gharani (Maharashtra)'},
{name: 'Dhamlej'},
{name: 'Dhengli Pimpalgaon (Maharastra)'},
{name: 'Thaticherla Motu'},
{name: 'Deori (Chhattisgarh)'},
{name: 'Rajegaon (Madhya Pradesh)'},
{name: 'Obulavaripalli'},
{name: 'Mangampeta'},
{name: 'Gadwal (Telangana)'},
{name: 'Telgaon(MH)'},
{name: 'Sonale (Maharastra)'},
{name: 'Kanwa (Uttar Pradesh)'},
{name: 'Phoop'},
{name: 'Udi (Uttar Pradesh)'},
{name: 'Bagli (Madhya Pradesh)'},
{name: 'Rodalabanda'},
{name: 'Kodekal (Karnataka)'},
{name: 'Rajankolur'},
{name: 'Arikera (Andhra Pradesh)'},
{name: 'Gurgunta (Karnataka)'},
{name: 'Tinthani Bridge'},
{name: 'Kakkera (Karnataka)'},
{name: 'Mundargi'},
{name: 'Ujjaini (Karnataka)'},
{name: 'Harpanahalli'},
{name: 'Ambalur (Tamil Nadu)'},
{name: 'Changaramkulam (Kerala)'},
{name: 'Chavara'},
{name: 'Kuzhithurai (tamil Nadu)'},
{name: 'Nattika (Kerala)'},
{name: 'Arumanai(Kanniyakumari)'},
{name: 'Attoor (Kanyakumari)'},
{name: 'Menganapuram'},
{name: 'Vikravandi'},
{name: 'Bukkambudhi (Karnataka)'},
{name: 'Thambikottai'},
{name: 'Pongalur'},
{name: 'Karikkottakary'},
{name: 'Edoor'},
{name: 'Anapandi'},
{name: 'Kundannoor'},
{name: 'Panakudi (Tamil Nadu)'},
{name: 'Eppodumvendran'},
{name: 'Kallimandayam'},
{name: 'Gandarvakkottai'},
{name: 'Vaniyappara'},
{name: 'Jawalagera'},
{name: 'Adaiya Karungulam'},
{name: 'Adhanur(nagapattinam)'},
{name: 'Agasthiyarpatti'},
{name: 'Akkur(nagapattinam)'},
{name: 'Alagapuri(virudhunagar)'},
{name: 'Alagiapandiapuram'},
{name: 'Alanganallur'},
{name: 'Amathur'},
{name: 'Anaimalai'},
{name: 'Andichiurani'},
{name: 'Andimadam'},
{name: 'Anjarakandy'},
{name: 'Annanji'},
{name: 'Anthakudi'},
{name: 'Arani (Tamil Nadu)'},
{name: 'Aranmanai Pudhur'},
{name: 'Azhagappapuram'},
{name: 'Bankasana(karnataka)'},
{name: 'Barangi(karnataka)'},
{name: 'Bhogavi (Karnataka)'},
{name: 'Bodimettu'},
{name: 'Chamanal (Yadgir'},
{name: 'Chatrapatti'},
{name: 'Dabbekatte(karnataka)'},
{name: 'Dharmadom'},
{name: 'Eamankulam (tirunelveli)'},
{name: 'Elachipalayam (Tamil Nadu)'},
{name: 'Elankulam (tirunelveli)'},
{name: 'Eraniel (tamilnadu)'},
{name: 'Erichanatham (virudhunagar)'},
{name: 'Erichi (pudukkottai)'},
{name: 'Eriodu'},
{name: 'Gandhinagar (Karatagi'},
{name: 'Gangaikondan (Tamil Nadu)'},
{name: 'Gogi (Yadgir'},
{name: 'Hadalgi(karnataka)'},
{name: 'Hanchinal Camp(raichur)'},
{name: 'Hangal (Karnataka)'},
{name: 'Haunsbhavi'},
{name: 'Hirekonati(karnataka)'},
{name: 'Jangalpatti(theni)'},
{name: 'Jannadi (Karnataka)'},
{name: 'Jogihalli'},
{name: 'K Pudupatti (Tamil Nadu)'},
{name: 'Kadayalumoodu (Tamil Nadu)'},
{name: 'Kainatty'},
{name: 'Kalamandalam (kerala)'},
{name: 'Kalavara (Karnataka)'},
{name: 'Kaluneerkulam'},
{name: 'Kamanavalli(karnataka)'},
{name: 'Kamatchipuram(theni)'},
{name: 'Kanavilakku (tamilnadu)'},
{name: 'Kandamanur (Tamil Nadu)'},
{name: 'Karaichuthupudur'},
{name: 'Kariyapattinam'},
{name: 'Karukurichi (Tamil Nadu)'},
{name: 'Karungalakudi(madurai)'},
{name: 'Karungulam ( Tuticorin )'},
{name: 'Katkeri'},
{name: 'Kattunaickenpatti (Tamilnadu)'},
{name: 'Kavindapadi(chithode)'},
{name: 'Kavital'},
{name: 'Kaluvanthittai'},
{name: 'Keezhapalur'},
{name: 'Koduvilarpatti(theni)'},
{name: 'Kollidam (sirkazi)'},
{name: 'Kollidam(nagapattinam)'},
{name: 'Kotipura(Karnataka)'},
{name: 'Kuchipudi'},
{name: 'Kudala Sangama (Karnataka)'},
{name: 'Kudavasal'},
{name: 'Kunchithanny (Kerala)'},
{name: 'Kunhipally (Kozhikode Dist)'},
{name: 'Kurukkal Patti'},
{name: 'Kuthalam(nagapattinam)'},
{name: 'Lakshmipuram (Theni'},
{name: 'Lalgudi (Tamil Nadu)'},
{name: 'Levengipuram'},
{name: 'Madagupatti'},
{name: 'Madralli (Karnataka)'},
{name: 'Malla (Yadgir)'},
{name: 'Manakudi (Ramnad Dist'},
{name: 'Manalurpettai'},
{name: 'Mayiladi(kanyakumari)'},
{name: 'Melaseval'},
{name: 'Melpuram(kanniyakumari)'},
{name: 'Mirzapuram(Andhra Pradesh)'},
{name: 'Moondradaippu (tirunelveli)'},
{name: 'Mudikondan (Tamilnadu)'},
{name: 'Muppaiyur'},
{name: 'Murambu (Tamil Nadu)'},
{name: 'Muthugapatty'},
{name: 'Muzhappilangad'},
{name: 'Nadal (Kerala)'},
{name: 'Nagudi (Tamil Nadu)'},
{name: 'Nakara Cross (Sevalal)'},
{name: 'Nalumavadi'},
{name: 'Narthamalai (pudukkottai)'},
{name: 'Nilakkal(sabarimala)'},
{name: 'Odaipatti(theni)'},
{name: 'Orkkatteri'},
{name: 'Palappallam (Tamil Nadu)'},
{name: 'Palliyadi (Kanniyakumari)'},
{name: 'Pandaravilai (Tuticorin)'},
{name: 'Pannaipuram (Tamil Nadu)'},
{name: 'Papanasam (Tirunelveli)'},
{name: 'Paulkulam'},
{name: 'Perumpilavu (Kerala)'},
{name: 'Poompuhar(nagapattinam)'},
{name: 'Poovalur'},
{name: 'Pudhukadai'},
{name: 'Pudupatti (cumbum)'},
{name: 'Pudupattinam (nagapattinam)'},
{name: 'Pudur (Uthamanur)'},
{name: 'Purameri'},
{name: 'Rabkavi Banhatti'},
{name: 'Rajakkad'},
{name: 'Salaigramam'},
{name: 'Samayapuram'},
{name: 'Santhebennur (karnataka)'},
{name: 'Savalagi'},
{name: 'Seepalakottai'},
{name: 'Sethukkuvaithan'},
{name: 'Shiriyara (karnataka)'},
{name: 'Silukuvarpatti'},
{name: 'Sinthalakarai'},
{name: 'Siralkoppa (shiralakoppa)'},
{name: 'Siruganur'},
{name: 'Sonaganvilai'},
{name: 'Srirengapuram'},
{name: 'Suchindram'},
{name: 'Sunnari (Karnataka)'},
{name: 'Suthamalli Vilakku'},
{name: 'Thaaniparai ( Tamil Nadu)'},
{name: 'Thachanallur'},
{name: 'Thadicherry (tamilnadu)'},
{name: 'Thalaiyuthu'},
{name: 'Thandupathu (Tuticorin)'},
{name: 'Thanikottagam(nagapattinam)'},
{name: 'Thappukundu (tamilnadu)'},
{name: 'Thavalakuppam'},
{name: 'Theni Medical College'},
{name: 'Thickanamcode'},
{name: 'Thingalnagar'},
{name: 'Thirumanur'},
{name: 'Thirumulaivasal(nagapattinam)'},
{name: 'Thirupuvanam'},
{name: 'Thiruvegampet'},
{name: 'Thiruvenkadu (Tamil Nadu)'},
{name: 'Thiruvidaimaruthur'},
{name: 'Thiyagadurgam'},
{name: 'Thogarsi'},
{name: 'Tholudur'},
{name: 'Thoothoor (Kanyakumari)'},
{name: 'Todalabagi (karnataka)'},
{name: 'Tukkuluru (Andhra Pradesh)'},
{name: 'Uthramcode (Tamil Nadu)'},
{name: 'Vadipatti'},
{name: 'Vagaikulam'},
{name: 'Vaiyappamalai'},
{name: 'Valangaiman'},
{name: 'Vannarpettai (Tirunelveli)'},
{name: 'Veeraganur (Tamil Nadu)'},
{name: 'Veerakeralampudur'},
{name: 'Veeranam'},
{name: 'Venkatachalapuram(theni)'},
{name: 'Veppur (Cuddalore)'},
{name: 'Vidyagiri (Karnataka)'},
{name: 'Vittalpura'},
{name: 'Pernem'},
{name: 'Bangarmau (Uttar pradesh)'},
{name: 'Amliya (Rajasthan)'},
{name: 'KODURU(Avanigadda)'},
{name: 'Bhawla'},
{name: 'Haikadi'},
{name: 'Albadi'},
{name: 'Belanje (Karnataka)'},
{name: 'Mahlan (Rajasthan)'},
{name: 'Mokhampura (Rajasthan)'},
{name: 'Chanduri'},
{name: 'Kenavade'},
{name: 'Masa Belewadi'},
{name: 'Maligre (Maharashtra)'},
{name: 'Hatiwade (Maharashtra)'},
{name: 'Pokharni (Maharastra)'},
{name: 'Sareri (Rajasthan)'},
{name: 'Tolnoor'},
{name: 'Barkhera (Uttar Pradesh)'},
{name: 'Tibri (Punjab)'},
{name: 'Mahuwa (RJ)'},
{name: 'Pankha(Ambada)'},
{name: 'Bairagarh (Madhya Pradesh)'},
{name: 'Wadigodri'},
{name: 'Panchot'},
{name: 'D.C.Pally'},
{name: 'Baribara'},
{name: 'Bhoji Pura (Uttar Pradesh)'},
{name: 'Pulbhatta'},
{name: 'Rithora (Uttar Pradesh)'},
{name: 'Hafizganj (Uttar Pradesh)'},
{name: 'Patran'},
{name: 'Dhanari Kalan (Rajasthan)'},
{name: 'Birloka'},
{name: 'Umar (Madhya Pradesh)'},
{name: 'Madri Chouraha'},
{name: 'Morvan (Madhya Pradesh)'},
{name: 'Sarwaniya (Madhya Pradesh)'},
{name: 'Dudh Talai (Madhya Pradesh)'},
{name: 'Diken'},
{name: 'Athwa (Gujarat)'},
{name: 'Rayta (Rajasthan)'},
{name: 'Tamalwadi'},
{name: 'Mota Agariya'},
{name: 'Nava Agariya'},
{name: 'Dhuliya Agariya (Gujarat)'},
{name: 'Bhamar'},
{name: 'Seetharamapuram (Udayagiri'},
{name: 'Berathal Kalan'},
{name: 'Deu Fanta'},
{name: 'Charwas'},
{name: 'Sanawara (Rajasthan)'},
{name: 'Mehlu'},
{name: 'Nesdi No 2 (Gujarat)'},
{name: 'Matirala'},
{name: 'Krushnagadh (Gujarat)'},
{name: 'Barshi Naka'},
{name: 'Adsang (Gujarat)'},
{name: 'Chaudhariwas'},
{name: 'Dhekan Moha'},
{name: 'Ghatsawali'},
{name: 'Upli (Maharastra)'},
{name: 'Tikuiya'},
{name: 'Baidaula'},
{name: 'Ganaur (Haryana)'},
{name: 'Ateli Mandi'},
{name: 'Triveni Chauraha (Bhilwara)'},
{name: 'Nani Vadal (Gujarat)'},
{name: 'Chhapri (Madhya Pradesh)'},
{name: 'Mashal'},
{name: 'Karjgi (Karnataka)'},
{name: 'Nagansur'},
{name: 'Kalkeri (Karnataka)'},
{name: 'Vakod'},
{name: 'Akodia'},
{name: 'Phulambri'},
{name: 'Kuru (Jharkhand)'},
{name: 'Chandwa'},
{name: 'Kartana'},
{name: 'Nathugadh'},
{name: 'Hanamsagar'},
{name: 'Dongargaon'},
{name: 'Kishunpur (Jharkhand)'},
{name: 'Brahmano ki sareri'},
{name: 'Wadatar'},
{name: 'Padel Pural'},
{name: 'Katradevi (Maharashtra)'},
{name: 'Bagathala'},
{name: 'Rajsitapur'},
{name: 'Vadhvana'},
{name: 'Dheku Khurd (Maharashtra)'},
{name: 'Sarbete'},
{name: 'Khuriya (Rajasthan)'},
{name: 'Satbarwa'},
{name: 'Jhajha (Bihar)'},
{name: 'Perinje'},
{name: 'Paddandadka (Karnataka)'},
{name: 'Pharenda'},
{name: 'Kolhui Bazar (Uttar Pradesh)'},
{name: 'Peppeganj'},
{name: 'Manika (Jharkhand)'},
{name: 'Nokhra'},
{name: 'Lakhisarai'},
{name: 'Fatuha (Bihar)'},
{name: 'Muduba'},
{name: 'Umblebailu'},
{name: 'Khatu Khurd (Rajasthan)'},
{name: 'Jadaunpur'},
{name: 'Bidi'},
{name: 'Kakkeri (Karnataka)'},
{name: 'Kumbarwada (Karnataka)'},
{name: 'Liliya Mota'},
{name: 'Degavada'},
{name: 'Otha (Gujarat)'},
{name: 'Aanganka'},
{name: 'Nana Khuntavada'},
{name: 'Bhilat Dev'},
{name: 'Choutlai'},
{name: 'Misrod'},
{name: 'Tigaria'},
{name: 'Nanpa'},
{name: 'Behadi'},
{name: 'Richa (Uttar Pradesh)'},
{name: 'Veeraghattam'},
{name: 'Kukawal (Maharashtra)'},
{name: 'Sarangkheda'},
{name: 'Bejjavalli'},
{name: 'Aralasurali'},
{name: 'Sahjanwa'},
{name: 'Pandhurna (Maharastra)'},
{name: 'Vichhavad'},
{name: 'Anandpur Kalu (Rajasthan)'},
{name: 'Gudhachandraji'},
{name: 'Nadoti (Rajasthan)'},
{name: 'Maregaon'},
{name: 'Jasrasar'},
{name: 'Kuttar'},
{name: 'Vittal'},
{name: 'Seruna (Rajasthan)'},
{name: 'Gusainsar'},
{name: 'Napasar'},
{name: 'Satjora'},
{name: 'Rajapatti (Bihar)'},
{name: 'Saharghat'},
{name: 'Wankhed'},
{name: 'Vikhran'},
{name: 'Malyabag (Bihar)'},
{name: 'Sonbarsa (Bihar)'},
{name: 'Dingor (Rajasthan)'},
{name: 'Mandar'},
{name: 'Veriya (Rajasthan)'},
{name: 'Triveniganj'},
{name: 'Deoghar (Jharkhand)'},
{name: 'Betawad'},
{name: 'Hirava'},
{name: 'Vopari'},
{name: 'Siriyari (Rajasthan)'},
{name: 'Phulad (Rajasthan)'},
{name: 'Muthagi'},
{name: 'Sengaon (Hingoli)'},
{name: 'Pipalrawan'},
{name: 'Khamkheda (Madhya Pradesh)'},
{name: 'Kusmanya'},
{name: 'Polay'},
{name: 'Gazole (West Bengal)'},
{name: 'KR Pete'},
{name: 'Nana Panch Devda'},
{name: 'Shishang'},
{name: 'Arnay'},
{name: 'Chiknauta'},
{name: 'Mayang'},
{name: 'Satwas (Madhya Pradesh)'},
{name: 'Dheerwas Bara'},
{name: 'Girad (Maharashtra)'},
{name: 'Pimparkhed'},
{name: 'Bakhtiarpur'},
{name: 'Akadiya (Madhya Pradesh)'},
{name: 'Shahjahanpur (Rajasthan)'},
{name: 'Ambada'},
{name: 'Bawadi'},
{name: 'Kasba (Punjab)'},
{name: 'Jalalgarh (Bihar)'},
{name: 'Bhatinda'},
{name: 'Birdhwal'},
{name: 'Simar (Gujarat)'},
{name: 'Ralgoni'},
{name: 'Vairengte'},
{name: 'Sikandra Rao'},
{name: 'Hasan Bazar (Bihar)'},
{name: 'Barachati (Bihar)'},
{name: 'Sikti'},
{name: 'Bhaga Bazar'},
{name: 'Bhojpur (Bihar)'},
{name: 'Simrahi Bazar (Bihar)'},
{name: 'Jetpur (Rajasthan)'},
{name: 'Ghamurwali'},
{name: 'Surawali'},
{name: 'Jandawali'},
{name: 'Maroli (Gujarat)'},
{name: 'Kaladeh (Rajasthan)'},
{name: 'Suraj'},
{name: 'Kidimal'},
{name: 'Katar (Rajasthan)'},
{name: 'Shedhavadar'},
{name: 'Ramseen'},
{name: 'Domkal'},
{name: 'Sontha'},
{name: 'Jehanabad (Bihar)'},
{name: 'Denok (Rajasthan)'},
{name: 'Dharam Kundi'},
{name: 'Hadecha'},
{name: 'Shillong'},
{name: 'Dalkhola'},
{name: 'Dankuni'},
{name: 'DG Peta'},
{name: 'Talgajarda'},
{name: 'Umaniyavadar'},
{name: 'Gundarani'},
{name: 'Roniya'},
{name: 'Dundas (Gujarat)'},
{name: 'Lusadi (Gujarat)'},
{name: 'Samaliya'},
{name: 'Ajnas'},
{name: 'Farangipete (Karnataka)'},
{name: 'Manikpur (Bihar)'},
{name: 'Rewaghat'},
{name: 'Sonaha (Bihar)'},
{name: 'Bheldi'},
{name: 'Rajapara (Gujarat)'},
{name: 'Gadarpur (Uttarakhand)'},
{name: 'Amaria'},
{name: 'Pritampur (Madhya Pradesh)'},
{name: 'Nani Bhagedi'},
{name: 'Bhoura'},
{name: 'Chowdapur (Karnataka)'},
{name: 'Amrutpur'},
{name: 'Ratadiya (Gujarat)'},
{name: 'Khejarla'},
{name: 'Piparcity'},
{name: 'Kandlur (Karnataka)'},
{name: 'Ambapura'},
{name: 'Rajmathai'},
{name: 'Sheo'},
{name: 'Nagardeola'},
{name: 'Mota Panch Devda'},
{name: 'Chhatar (Gujarat)'},
{name: 'Morvadi (Gujarat)'},
{name: 'Davli'},
{name: 'Moti Bhagedi'},
{name: 'Janabad'},
{name: 'Amoda'},
{name: 'Kathari (Rajasthan)'},
{name: 'Mirjapar'},
{name: 'Hajipar (Gujarat)'},
{name: 'Reengus'},
{name: 'Moti Vadal'},
{name: 'Mota Kotda'},
{name: 'Juna Vaghaniya'},
{name: 'Varvada (Gujarat)'},
{name: 'Vishol (Gujarat)'},
{name: 'Chandravati (Rajasthan)'},
{name: 'Panchgaon (Haryana)'},
{name: 'Dudhrej'},
{name: 'Bavdidar'},
{name: 'Taveda'},
{name: 'Panvi (Gujarat)'},
{name: 'Tagadi'},
{name: 'Fedara'},
{name: 'Choraut (Bihar)'},
{name: 'Janwe'},
{name: 'Bamkheda'},
{name: 'Soron'},
{name: 'Saredi'},
{name: 'Chichondi Patil'},
{name: 'Loka'},
{name: 'Loki (Gujarat)'},
{name: 'Nana Jadara'},
{name: 'Tiloli (Rajasthan)'},
{name: 'Mota Devaliya'},
{name: 'Sarvai'},
{name: 'Chandla (Madhya Pradesh)'},
{name: 'Dharpur (Gujarat)'},
{name: 'Dal Devaliya'},
{name: 'Bhesavdi'},
{name: 'Karnu (Rajasthan)'},
{name: 'Nate'},
{name: 'Jaitapur (Maharashtra)'},
{name: 'Narmana'},
{name: 'Paspasiya (Gujarat)'},
{name: 'Ispurli'},
{name: 'Lanva'},
{name: 'Tesgaon'},
{name: 'Deri Pipariya'},
{name: 'Ganagapur'},
{name: 'Kuvadava'},
{name: 'Patwada'},
{name: 'Dholiyo Kuvo'},
{name: 'Byawar'},
{name: 'chandkheda'},
{name: 'Powai (Mumbai)'},
{name: 'Nandgaon[TiTha]'},
{name: 'Aatkot'},
{name: 'Piro'},
{name: 'Jamui (Bihar)'},
{name: 'Dalsinghsarai'},
{name: 'Baruni'},
{name: 'Barh (Bihar)'},
{name: 'Bahadurganj (Bihar)'},
{name: 'Ara (Jharkhand)'},
{name: 'Nirsa'},
{name: 'Jumri Tilaiya'},
{name: 'Gobindpur (Odisha)'},
{name: 'Rath'},
{name: 'Baheri'},
{name: 'Chhabra'},
{name: 'Nilokheri'},
{name: 'Ladwa (Haryana)'},
{name: 'Vejalpur'},
{name: 'Gobindpur (Jharkhand)'},
{name: 'Gobindpur (Bihar)'},
{name: 'Ara (Bihar)'},
{name: 'Malkapur(Kolhapur)'},
{name: 'HATPAHADI'},
{name: 'ISLAMPUR (Maharashtra)'},
{name: 'Kalleru'},
{name: 'Gunjapadugu (Telangana)'},
{name: 'DINDI'},
{name: 'Amangal'},
{name: 'KADTAL'},
{name: 'Devarkadra (Telangana)'},
{name: 'NARAYANKHED'},
{name: 'Penugolanu'},
{name: 'Nemali'},
{name: 'Veppalamadaka'},
{name: 'Siripuram KG (Telangana)'},
{name: 'Devarsugur'},
{name: 'Kaddam'},
{name: 'Bheemaram (Mancherial)'},
{name: 'Sonala (Telangana)'},
{name: 'Varni'},
{name: 'Sirpur (Telangana)'},
{name: 'RAJNAD GAO'},
{name: 'Mustabad(Telangana)'},
{name: 'Boath'},
{name: 'NERADI GONDA'},
{name: 'Koilkonda (telangana)'},
{name: 'Kasipeta (Telangana)'},
{name: 'HUSNABAD'},
{name: 'Gambhiraopet (Telangana)'},
{name: 'Somagudem'},
{name: 'Basanth Nagar (Telangana)'},
{name: 'Vajedu'},
{name: 'NAKULANUR'},
{name: 'Jagannadapuram (Andhra Pradesh)'},
{name: 'Mahadevpur (Telangana)'},
{name: 'Janampeta (Telangana)'},
{name: 'Jogipet'},
{name: 'Bodmatpally'},
{name: 'Alladurg'},
{name: 'Vardannapet'},
{name: 'Kataram (Telangana)'},
{name: 'Garidepalli (telangana)'},
{name: 'Gangadhara X Road (Telangana)'},
{name: 'Gajwel (Telangana)'},
{name: 'Narsapur (Telangana)'},
{name: 'Epuru (Telangana)'},
{name: 'Chelpur (Telangana)'},
{name: 'Nellipaka'},
{name: 'Burgampahad'},
{name: 'LUXETTIPET'},
{name: 'Thorrur'},
{name: 'BEGUMPET MANTHANI'},
{name: 'Patvarigudem (Telangana)'},
{name: 'Mulakalapalli (Telangana)'},
{name: 'Ananthagiri (Telangana)'},
{name: 'Malkapuram (West Godavari Dist'},
{name: 'NAYEEMGAM'},
{name: 'BODMATPALLY'},
{name: 'Bazarhatnoor (telangana)'},
{name: 'Moinabad (Telangana)'},
{name: 'Marellavari Palem'},
{name: 'DECCAN SHIVA KSHETRALU'},
{name: 'Banjar (Khammam'},
{name: 'TIMBURNI'},
{name: 'MOLLEM'},
{name: 'Lakhandur'},
{name: 'GONDYA'},
{name: 'ALLADURG'},
{name: 'Yerravalli'},
{name: 'Wanaparthy'},
{name: 'Solipur'},
{name: 'Sadak Arjuni'},
{name: 'Rudrur'},
{name: 'Pochampadu'},
{name: 'Nasurullabad'},
{name: 'MANERU'},
{name: 'Lingannapet (Telangana)'},
{name: 'Kulkacharla'},
{name: 'Kosgi'},
{name: 'KANAPUR(Telangana)'},
{name: 'Ganpur'},
{name: 'GANDED'},
{name: 'Duddeda'},
{name: 'Dharmaram (Telangana)'},
{name: 'Dadapur'},
{name: 'Jagannadapuram (Telangana)'},
{name: 'Tudur (Karnataka)'},
{name: 'Malur(Karnataka)'},
{name: 'Hosahalli'},
{name: 'ANANTA SAGARAM'},
{name: 'Bakarampet (Kadapa)'},
{name: 'Burugupudi'},
{name: 'Chakrampeta'},
{name: 'Gundlapalli (Andhra Pradesh)'},
{name: 'Jupadu Bangla'},
{name: 'Kowkuntla (Andhra Pradesh)'},
{name: 'Nambula Pulakunta'},
{name: 'Penchalakona'},
{name: 'Raketla'},
{name: 'TULASIPAKALU'},
{name: 'VAGGAMPALLY'},
{name: 'Vangapadu'},
{name: 'Kuneru (Andhra Pradesh)'},
{name: 'KOTTAVALASA'},
{name: 'Komarada'},
{name: 'Rolla'},
{name: 'SOMANADEPALLI'},
{name: 'Sabbavaram'},
{name: 'Ravikamatham'},
{name: 'RAJAMPALLI DARSI ROAD'},
{name: 'Battrepalli'},
{name: 'KOTTAPALLI'},
{name: 'Pedamerangi'},
{name: 'Chinabhogili'},
{name: 'VALAJIPETA'},
{name: 'Lothugedda (Andhra Pradesh)'},
{name: 'AVIDI X ROAD'},
{name: 'Mulasthaanam (West Godavari Dist'},
{name: 'Jiyyammavalasa'},
{name: 'Challapeta'},
{name: 'Chinamerangi'},
{name: 'Ravivalasa'},
{name: 'Matlapalem'},
{name: 'Rachapalli'},
{name: 'Kannurupalem'},
{name: 'Rolugunta'},
{name: 'Mondepu Lanka'},
{name: 'Uppakapadu'},
{name: 'Thurpulanka'},
{name: 'Maruvada'},
{name: 'D POLAVARAM'},
{name: 'Gannavaram Metta (E G Dist'},
{name: 'Srungavaram'},
{name: 'Thandava'},
{name: 'Mulagapudi'},
{name: 'Bypureddypalem'},
{name: 'Gavarayya Koneru (Tuni)'},
{name: 'Yerrakoneru'},
{name: 'Thetagunta'},
{name: 'Bendapudi'},
{name: 'Tatiparthi'},
{name: 'Panduru (West godavari dt)'},
{name: 'Pulugurtha'},
{name: 'Tapeswaram'},
{name: 'V K R PURAM'},
{name: 'K.M.STONE'},
{name: 'RAGAMPETA'},
{name: 'Kirlampudi'},
{name: 'Kattamuru'},
{name: 'Katravulapalli'},
{name: 'Alampuram (West Godavari)'},
{name: 'KOLLAFARAM(West Godavari Dt)'},
{name: 'Nadakuduru'},
{name: 'Vakada'},
{name: 'Narasapurapupeta'},
{name: 'Ramannapalem (Andhra Pradesh)'},
{name: 'DIWANCHERUVU'},
{name: 'Chakradwarabandham'},
{name: 'Vannepudi'},
{name: 'Kotapadu'},
{name: 'PB DEVAM'},
{name: 'Gunipalli (Andhra Pradesh)'},
{name: 'Pamudurthy'},
{name: 'Neelakundilu'},
{name: 'Reddipalli (Padmanabham)'},
{name: 'Yatapaka (Andhra Pradesh)'},
{name: 'Yetapaka (Telangana)'},
{name: 'Peddapalli (Andhra Pradesh)'},
{name: 'Sangrur'},
{name: 'Baghpat'},
{name: 'Baraut (Uttar Pradesh)'},
{name: 'Bhikharipur Patsia Gair'},
{name: 'Hanjiyasar'},
{name: 'Sachana'},
{name: 'Thanabhawan'},
{name: 'Sarola(Maharashtra)'},
{name: 'Mehdawal (Uttar Pradesh)'},
{name: 'Mandawala (Rajasthan)'},
{name: 'Ramnagar (Uttarakhand)'},
{name: 'Balapanur'},
{name: 'Bhilkua (Rajasthan)'},
{name: 'Bishnugarh (Jharkhand)'},
{name: 'Boliya'},
{name: 'Isri (Jharkhand)'},
{name: 'Marhowrah'},
{name: 'Saran (Bihar)'},
{name: 'Sunhao'},
{name: 'Taraiya'},
{name: 'Tumkuhi Road'},
{name: 'Dataliya (Rajasthan)'},
{name: 'Babhaleshwar (Maharashtra)'},
{name: 'Adaikalapattanam'},
{name: 'Addada(Krishna District)'},
{name: 'Ajitmal (Uttar Pradesh)'},
{name: 'Ajitwal (Punjab)'},
{name: 'Alayala (Rajasthan)'},
{name: 'Amiliya (Madhya Pradesh)'},
{name: 'Amujuru'},
{name: 'Anantavaram'},
{name: 'Arandia (Indore)'},
{name: 'Arathi (Gujarat)'},
{name: 'Assaigoli'},
{name: 'Badbirana'},
{name: 'Bajakhana (Punjab)'},
{name: 'Bakhalis (Uttar Pradesh)'},
{name: 'Bandala (Sirsi)'},
{name: 'Bankat Bazar (Uttar Pradesh)'},
{name: 'Bareli (Madhya Pradesh)'},
{name: 'Barhni (Uttar Pradesh)'},
{name: 'Begampur (Maharashtra)'},
{name: 'Belawadi (Belgaum Dist'},
{name: 'Bharat Kup (Uttar Pradesh)'},
{name: 'Bhavardi (Gujarat)'},
{name: 'Bhimnath (Polarpur)'},
{name: 'Bhogavati(Kolhapur)'},
{name: 'Bijwad (Madhya Pradesh)'},
{name: 'Biswanath Chariali'},
{name: 'Bodasakurru'},
{name: 'Borrio (Jharkhand)'},
{name: 'Buttayagudem'},
{name: 'Calicut University'},
{name: 'Chafe (Ratnagiri)'},
{name: 'Chautan RJ (Rajasthan)'},
{name: 'Chinaogirala'},
{name: 'Chinna Kothuru'},
{name: 'Chinnaiyampalayam'},
{name: 'A.Konduru'},
{name: 'Aambala (Gujarat)'},
{name: 'Aansodar (Gujarat)'},
{name: 'Aaravli'},
{name: 'Abbigeri'},
{name: 'Abbineniguntapalem'},
{name: 'Abhapura (Gujarat)'},
{name: 'Abhrampara'},
{name: 'Achara'},
{name: 'Acharra Khas'},
{name: 'Achhroni'},
{name: 'Adamapur (Maharastra)'},
{name: 'Adapaka'},
{name: 'Adhelai'},
{name: 'Adivare'},
{name: 'Adusumalli'},
{name: 'Adyanadka'},
{name: 'Adyar (Karnataka)'},
{name: 'Agasarakone'},
{name: 'Aghar (Gujarat)'},
{name: 'Agrahar (Karnataka)'},
{name: 'Aigali Cross'},
{name: 'Ainavilli'},
{name: 'Aithor'},
{name: 'Ajhuwa'},
{name: 'Ajjinadka'},
{name: 'Ajnar (Uttar Pradesh)'},
{name: 'Akalkot'},
{name: 'Akbarpur (Azamgarh)'},
{name: 'Akbarpur(Kanpur Dehat)'},
{name: 'Akhnoor'},
{name: 'Akla'},
{name: 'Akunuru'},
{name: 'Alabad (Maharastra)'},
{name: 'Aladangady'},
{name: 'Alampur (Himachal Pradesh)'},
{name: 'Alandi'},
{name: 'Alanganatham Pirivu (Sarkarpalayapalayam)'},
{name: 'Alangudi (Thiruvarur)'},
{name: 'Alavayal'},
{name: 'Albaka'},
{name: 'Aler (Telanagana)'},
{name: 'Allaganj'},
{name: 'Allur (Nellore)'},
{name: 'Aloor (Kerala)'},
{name: 'Alur (Kurnool AP)'},
{name: 'Alwada'},
{name: 'Amalazari'},
{name: 'Amalpura (Madhya Pradesh)'},
{name: 'Amarapur (Gujarat)'},
{name: 'Amaravila'},
{name: 'Amarwara'},
{name: 'Ambaghanta'},
{name: 'Ambah (Madhya Pradesh)'},
{name: 'Ambaliya'},
{name: 'Ambari'},
{name: 'Ambet (Raigad)'},
{name: 'Ambli'},
{name: 'Ameengad (Karnataka)'},
{name: 'Amli (Madhya Pradesh)'},
{name: 'Ammaiyappan (Tamil Nadu)'},
{name: 'Ampani'},
{name: 'Amrapara'},
{name: 'Amriya'},
{name: 'Amroli'},
{name: 'Anadur'},
{name: 'Anagawadi'},
{name: 'Anagodu'},
{name: 'Anaji (Karnataka)'},
{name: 'Anakarlapudi'},
{name: 'Anandpur(Madhya Pradesh)'},
{name: 'Anandsar'},
{name: 'Anawali'},
{name: 'Anegundi'},
{name: 'Anekere'},
{name: 'Angarulu'},
{name: 'Anipur (Assam)'},
{name: 'Anjanari'},
{name: 'Ankolada'},
{name: 'Annigeri'},
{name: 'Antarveliya'},
{name: 'Anupshahar'},
{name: 'Arachalur'},
{name: 'Araga'},
{name: 'Arakeri (Karnataka)'},
{name: 'Aralgundgi'},
{name: 'Aranmula'},
{name: 'Arasur'},
{name: 'Ardal'},
{name: 'Areangadi'},
{name: 'Arepalli'},
{name: 'Argaon (Maharastra)'},
{name: 'Arjansukh'},
{name: 'Arjunwadi'},
{name: 'Arla (Gujarat)'},
{name: 'Aron'},
{name: 'Arulla'},
{name: 'Asalade'},
{name: 'Asangi (Jat)'},
{name: 'Ashapur (Madhya Pradesh)'},
{name: 'Ashapur (Rajasthan)'},
{name: 'Ashta (Sangli)'},
{name: 'Ashta Kasar'},
{name: 'Ashti (Beed)'},
{name: 'Asond'},
{name: 'Assonara (Goa)'},
{name: 'Asurde'},
{name: 'Atareji (Mahya Pradesh)'},
{name: 'Atcharla'},
{name: 'Athaikhera (Madhya Pradesh)'},
{name: 'Athela'},
{name: 'Atholi'},
{name: 'Athoor'},
{name: 'Athur (Tirunelveli)'},
{name: 'Atmakur (Telangana)'},
{name: 'Attur(Thoothukudi)'},
{name: 'Atul'},
{name: 'Aundh'},
{name: 'Aurad (Karnataka)'},
{name: 'Auraha (Bihar)'},
{name: 'Avanam Kaikatti (Tamil Nadu)'},
{name: 'Avinahatty'},
{name: 'Avisanavaripalem'},
{name: 'Avoly'},
{name: 'Ayakudi'},
{name: 'Ayavej (Gujarat)'},
{name: 'Ayithampudi'},
{name: 'Aynakhal'},
{name: 'Ayyalur'},
{name: 'Azhagiapandiapuram'},
{name: 'B.Mutlur (Tamil Nadu)'},
{name: 'Baari (Maharashtra)'},
{name: 'Baba Baijnath (Madhya Pradesh)'},
{name: 'Babai (Madhya Pradesh)'},
{name: 'Babanagar'},
{name: 'Babara'},
{name: 'Babarpur'},
{name: 'Babhalgaon'},
{name: 'Babhani (Uttar Pradesh)'},
{name: 'Babhnan (Uttar Pradesh)'},
{name: 'Badalpur'},
{name: 'Badanahatti (Karnataka)'},
{name: 'Badanpur Juna (Gujarat)'},
{name: 'Badarpur (Assam)'},
{name: 'Badarpur (New Delhi)'},
{name: 'Badausa (Uttar Pradesh)'},
{name: 'Badchicholi'},
{name: 'Badevaripalem'},
{name: 'Badhawar (Haryana)'},
{name: 'Badkhal (Haryana)'},
{name: 'Badlapur (Uttar Pradesh)'},
{name: 'Badni'},
{name: 'Bagaha'},
{name: 'Bagbahar (Chhattisgarh)'},
{name: 'Bagdona'},
{name: 'Baghamari'},
{name: 'Baghapurana'},
{name: 'Bagholi (Madhya Pradesh)'},
{name: 'Bagoda (Madhya Pradesh)'},
{name: 'Bagora'},
{name: 'Bagoya'},
{name: 'Bahalgarh'},
{name: 'Baharagora'},
{name: 'Bahirewadi (Kolhapur)'},
{name: 'Bahuwa'},
{name: 'Baikodu'},
{name: 'Bailoor (Udipi)'},
{name: 'Baisi'},
{name: 'Bajrangarh'},
{name: 'Bajud'},
{name: 'Bakawand (Chhattisgarh)'},
{name: 'Balaganur'},
{name: 'Balagatte'},
{name: 'Balapur (Akola)'},
{name: 'Balashettihal (Karnataka)'},
{name: 'Baldevgarh'},
{name: 'Balehole'},
{name: 'Balgaon (Maharastra)'},
{name: 'Balipara'},
{name: 'Balisankara (Orissa)'},
{name: 'Balmiki Nagar (Bihar)'},
{name: 'Balod (Chhattisgarh)'},
{name: 'Balrwa (Rajasthan)'},
{name: 'Balsamud (Madhya Pradesh)'},
{name: 'Balur (Karnataka)'},
{name: 'Balwara (Madhya Pradesh)'},
{name: 'Bamhani (Madhya Pradesh)'},
{name: 'Bamur (Orissa)'},
{name: 'Banashankari(Badami)'},
{name: 'Bandarduwa (Assam)'},
{name: 'Bandhala (Gujarat)'},
{name: 'Bandharada (Gujarat)'},
{name: 'Bandhur (Uttar Pradesh)'},
{name: 'Bandiyod'},
{name: 'Baneriya'},
{name: 'Banhatti'},
{name: 'Bankat (Uttar Pradesh)'},
{name: 'Bankey Bazar (Bihar)'},
{name: 'Banpur (Uttar Pradesh)'},
{name: 'Banpuri'},
{name: 'Banra (Rajasthan)'},
{name: 'Bara Nayagaon'},
{name: 'Barad (Maharastra)'},
{name: 'Barapali (Orissa)'},
{name: 'Barauli (Bihar)'},
{name: 'Baretha (Madhya Pradesh)'},
{name: 'Bargaon (Rajasthan)'},
{name: 'Bargawan (Madhya Pradesh)'},
{name: 'Bargur (Tamil Nadu)'},
{name: 'Barhait'},
{name: 'Barhaj (Uttar Pradesh)'},
{name: 'Barharwa'},
{name: 'Bari (Madhya Pradesh)'},
{name: 'Bari Brahmana (J and K)'},
{name: 'Barkagaon (Jharkhand)'},
{name: 'Barkheda Hat (Madhya Pradesh)'},
{name: 'Barod (Madhya Pradesh)'},
{name: 'Baroodi'},
{name: 'Barrackpore (West Bengal)'},
{name: 'Barri (Madhya Pradesh)'},
{name: 'Barsar (Himachal Pradesh)'},
{name: 'Barua Sagar'},
{name: 'Barwai'},
{name: 'Basai(Madhya Pradesh)'},
{name: 'Basantpur (Bihar)'},
{name: 'Basetha (Bihar)'},
{name: 'Basopatti (Bihar)'},
{name: 'Basra (Bihar)'},
{name: 'Basrikatte (Karnataka)'},
{name: 'Basthihalli'},
{name: 'Batadu (Rajasthan)'},
{name: 'Batamari (Assam)'},
{name: 'Batkurki'},
{name: 'Bausi (Bihar)'},
{name: 'Bavaliary (Gujarat)'},
{name: 'Bavnadi (Ratnagiri)'},
{name: 'Bawada'},
{name: 'Bedeti (Assam)'},
{name: 'Begunia (Orissa)'},
{name: 'Behera (Orissa)'},
{name: 'Bejjipuram'},
{name: 'Bela (Uttar Pradesh)'},
{name: 'Bela (Gujarat)'},
{name: 'Bela(Madhya Pradesh)'},
{name: 'Belaisa (Uttar Pradesh)'},
{name: 'Belane (Maharastra)'},
{name: 'Belatal (Uttar Pradesh)'},
{name: 'Belavadi (Karnataka)'},
{name: 'Belewadi Hubbalagi (MH)'},
{name: 'Belgharia (West Bengal)'},
{name: 'Belkund'},
{name: 'Belladbagewadi'},
{name: 'Beltola (Assam)'},
{name: 'Belur (Badami)'},
{name: 'Beluvayi'},
{name: 'Bembli (Maharastra)'},
{name: 'Benakanahalli (Karnataka)'},
{name: 'Benapur (West Bengal)'},
{name: 'Benibad (Bihar)'},
{name: 'Bero (Jharkhand)'},
{name: 'Berua (Bihar)'},
{name: 'Beva (Rajasthan)'},
{name: 'Bhadajaliya (Gujarat)'},
{name: 'Bhadarda Road (Bnm)'},
{name: 'Bhadi (Rajasthan)'},
{name: 'Bhadragiri (Udupi Dist)'},
{name: 'Bhadwan (Maharastra)'},
{name: 'Bhagabati (West Bengal)'},
{name: 'Bhaguapura (Madhya Pradesh)'},
{name: 'Bhaguda (Gujarat)'},
{name: 'Bhagwantpur (Madhya Pradesh)'},
{name: 'Bhairumbe'},
{name: 'Bhaisamunda (Chhattisgarh)Chhattisgarh'},
{name: 'Bhaluani'},
{name: 'Bhanai (Rajasthan)'},
{name: 'Bhandishegaon'},
{name: 'Bhanegaon (Madhya Pradesh)'},
{name: 'Bhanjraru(Chamba Himachal Pradesh)'},
{name: 'Bhanwargarh (Rajasthan)'},
{name: 'Bharananganam (Kerala)'},
{name: 'Bharatgarh (Punjab)'},
{name: 'Bharmasagara'},
{name: 'Bharunda'},
{name: 'Bhasa (West Bengal)'},
{name: 'Bhatapara (Chhattisgarh)'},
{name: 'Bhatiya (Madhya Pradesh)'},
{name: 'Bhatsan (Gujarat)'},
{name: 'Bhavikere (Karnataka)'},
{name: 'Bhavra'},
{name: 'Bhavrani (Rajasthan)'},
{name: 'Bhawarna'},
{name: 'Bhawatra (Rajashthan)'},
{name: 'Bheemanady'},
{name: 'Bhemal (Gujarat)'},
{name: 'Bhenswadi (Gujarat)'},
{name: 'Bheslay'},
{name: 'Bhetali'},
{name: 'Bhilvan (Gujarat)'},
{name: 'Bhimda'},
{name: 'Bhimpur(Bihar)'},
{name: 'Bhiti (Uttar Pradesh)'},
{name: 'Bhogaon (Uttar Pradesh)'},
{name: 'Bhoginpur'},
{name: 'Bhogpur (Punjab)'},
{name: 'Bhojpur (Rajasthan)'},
{name: 'Bhoma'},
{name: 'Bhomatola (Madhya Pradesh)'},
{name: 'Bhonti (Madhya Pradesh)'},
{name: 'Bhooniya'},
{name: 'Bhosari (Maharastra)'},
{name: 'Bhose (Maharastra)'},
{name: 'Bhuinj'},
{name: 'Bhurakhiya'},
{name: 'Bidarekere (Karnataka)'},
{name: 'Bidhannagar'},
{name: 'Bihar'},
{name: 'Bihpur (Bihar)'},
{name: 'Bihpuria (Assam)'},
{name: 'Bijaipur (Rajasthan)'},
{name: 'Bijargi'},
{name: 'Bijawadi'},
{name: 'Bijaipur (Madhya Pradesh)'},
{name: 'Bikapur (Uttar Pradesh)'},
{name: 'Bilara (Rajsthan)'},
{name: 'Bilchodu (Karnataka)'},
{name: 'Bilkheria (Madhya Pradesh)'},
{name: 'Bindra Bazar (Uttar Pradesh)'},
{name: 'Birnal (Maharastra)'},
{name: 'Birul Bazar'},
{name: 'Boarijore'},
{name: 'Boda'},
{name: 'Bodinayakanur'},
{name: 'Boginadi'},
{name: 'Boinda (Orissa)'},
{name: 'Bokakhat'},
{name: 'Bolegaon (Maharastra)'},
{name: 'Boliyar (Karnataka)'},
{name: 'Bolpur(West Bengal)'},
{name: 'Bommuru'},
{name: 'Bongalmora'},
{name: 'Bonjurdi (Maharastra)'},
{name: 'Bonkatta'},
{name: 'Boradi'},
{name: 'Borala (Gujarat)'},
{name: 'Bordehi (Madhya Pradesh)'},
{name: 'Bordubi (Assam)'},
{name: 'Borgang (Assam)'},
{name: 'Borgaon (Madhya Pradesh)'},
{name: 'Borhat (Assam)'},
{name: 'Bori Arab'},
{name: 'Bori Sawargaon'},
{name: 'Borjhar(Guwahati Airport)'},
{name: 'Borphal (Maharastra)'},
{name: 'Boudh (Orissa)'},
{name: 'Boyanapalli'},
{name: 'Boyapalem'},
{name: 'Kumardi (Madhya Pradesh)'},
{name: 'Brahmadevam'},
{name: 'Brahmagiri (Odisha)'},
{name: 'Brahmpur (Bihar)'},
{name: 'Budampadu'},
{name: 'Budhana (Gujarat)'},
{name: 'Budumuru'},
{name: 'Buggapadu'},
{name: 'Bukkaraya samudram'},
{name: 'Buniadpur'},
{name: 'Burhanpur(Maharashtra)'},
{name: 'Burndabahal (Orissa)'},
{name: 'Burugagudem (Andhra Pradesh)'},
{name: 'Burugudem (Telangana)'},
{name: 'Campbellabad'},
{name: 'Chabi (Madhya Pradesh)'},
{name: 'Chachaura Binaganj'},
{name: 'Chadotara (Orissa)'},
{name: 'Chainpur (Bihar)'},
{name: 'Chakai (Bihar)'},
{name: 'Chakalipalem'},
{name: 'Chakghat'},
{name: 'Champa (Chhattisgarh)'},
{name: 'Champua (Orissa)'},
{name: 'Chamravattom'},
{name: 'Chamu (Rajasthan)'},
{name: 'Chamunda Devi (Himachal Pradesh)'},
{name: 'Chanchal (West Bengal)'},
{name: 'Chand (Madhya Pradesh)'},
{name: 'Chandausi (Uttar Pradesh)'},
{name: 'Chandavar (Karnataka)'},
{name: 'Chandeswar (Orissa)'},
{name: 'Chandragutti (Karnataka)'},
{name: 'Changotola'},
{name: 'Chaprada (Gujarat)'},
{name: 'Charan Samadhiyala'},
{name: 'Charkhari'},
{name: 'Charkhera (Madhya Pradesh)'},
{name: 'Charubhuja'},
{name: 'Chatadiya (Gujarat)'},
{name: 'Chatbahuriya (Madhya Pradesh)'},
{name: 'Chatia (Orissa)'},
{name: 'Chatradiya (Gujarat)'},
{name: 'Chatro (Jharkhand)'},
{name: 'Chatwara (Rajasthan)'},
{name: 'Chauke (Maharastra)'},
{name: 'Chaukiman (Punjab)'},
{name: 'Chauparan (Jharkhand)'},
{name: 'Chaurai (Madhya Pradesh)'},
{name: 'Chaure Bazar (Uttar Pradesh)'},
{name: 'Chelluru (Andhra Pradesh)'},
{name: 'Chendra (Chhattisgarh)'},
{name: 'Cherai (Kerala)'},
{name: 'Cherpunkal (Kerala)'},
{name: 'Cherukawada'},
{name: 'Chhalda'},
{name: 'Chhaoni (Maharastra)'},
{name: 'Chhapari (Gujarat)'},
{name: 'Chhapiya (Uttar Pradesh)'},
{name: 'Chhatadiya (Gujarat)'},
{name: 'Chhatrari'},
{name: 'Chhelana (Gujarat)'},
{name: 'Chhidgaon Mel (Madhya Pradesh)'},
{name: 'Chhodavadi'},
{name: 'Chhokarwara Kalan (Rajasthan)'},
{name: 'Chhota Kirara'},
{name: 'Chhuikhadan'},
{name: 'Chicholi (Madhya Pradesh)'},
{name: 'Chieraidongri'},
{name: 'Chikhale (Maharastra)'},
{name: 'Chikhali Kalan (Madhya Pradesh)'},
{name: 'Chikhalwadi'},
{name: 'Chikharde'},
{name: 'Chikkaunshi Hosur (Karnataka)'},
{name: 'Chiklod (Madhya Pradesh)'},
{name: 'Chiklonda (Madhya Pradesh)'},
{name: 'Chilakapadu'},
{name: 'Chilchila (Orissa)'},
{name: 'Chilika'},
{name: 'Chinamallam'},
{name: 'Chinaravuru (Andhra Pradesh)'},
{name: 'Chincharaki (Karnataka)'},
{name: 'Chinchondi Patil'},
{name: 'Chinchoti (Maharastra)'},
{name: 'Chindar (Maharastra)'},
{name: 'Chinnagadavilli'},
{name: 'Chinnampet'},
{name: 'Chinnayagudem'},
{name: 'Chintalapudi(Guntur Dist)'},
{name: 'Chiragpally (Telangana)'},
{name: 'Chirakkal (Kerala)'},
{name: 'Agar (Maharashtra)'},
{name: 'Ahmednagar (Telangana)'},
{name: 'Akola (Rajasthan)'},
{name: 'Alur (Karnataka)'},
{name: 'Amberi (Maharastra)'},
{name: 'Amberia (West Bengal)'},
{name: 'Anandur (Punjab)'},
{name: 'Asansol (Jharkhand)'},
{name: 'Aurangabad (Andhra Pradesh)'},
{name: 'Aurangabad (Uttar Pradesh)'},
{name: 'Badodiya (Madhya Pradesh)'},
{name: 'Balrampur (Azamgarh)'},
{name: 'Balrampur (Chhattisgarh)'},
{name: 'Barhampur (Assam)'},
{name: 'Barman (Gujarat)'},
{name: 'Barwala (Haryana)'},
{name: 'Barwala (Punjab)'},
{name: 'Barwala (Rajasthan)'},
{name: 'Bassi (Himachal Pradesh)'},
{name: 'Bayan (Madhya Pradesh)'},
{name: 'Bediya (Gujarat)'},
{name: 'Bhabhuwa (Uttar Pradesh)'},
{name: 'Bhadra (Gujarat)'},
{name: 'Bhadra (Punjab)'},
{name: 'Bhansi (Chhattisgarh)'},
{name: 'Bhonrasa (Madhya Pradesh)'},
{name: 'Bijapur (Odisha)'},
{name: 'Bilaspur (Haryana)'},
{name: 'Birlamakki (Karnataka)'},
{name: 'Borda (Madhya Pradesh)'},
{name: 'Borgaon (Chhattisgarh)'},
{name: 'Borgaon (Telangana)'},
{name: 'Burhanpur (Telangana)'},
{name: 'Burhanpur (Uttar Pradesh)'},
{name: 'Chakan (Madhya Pradesh)'},
{name: 'Chakrawa (Uttar Pradesh)'},
{name: 'Chanaka (Assam)'},
{name: 'Chattarpur (Bihar)'},
{name: 'Chhapar (Rajasthan)'},
{name: 'Chhapra (Bihar)'},
{name: 'Chincholi (Maharastra)'},
{name: 'Chirgaon (Himachal Pradesh)'},
{name: 'Chitamba'},
{name: 'Chitguppa (Karnataka)'},
{name: 'Chitrakoot Dham (Karwi'},
{name: 'Chitravad (Jamkandorna)'},
{name: 'Chittur (Kerala)'},
{name: 'Chivaluru'},
{name: 'Chodavaram (Narasarao Peta)'},
{name: 'Chok (Gujarat)'},
{name: 'Chorade'},
{name: 'Choradi (Karnataka)'},
{name: 'Chorali Moli (Gujarat)'},
{name: 'Chordi (Rajasthan)'},
{name: 'Chorochi (Maharashtra)'},
{name: 'Chorwad (Gujarat)'},
{name: 'Chota Chilora'},
{name: 'Chuda Sorath'},
{name: 'Chudva'},
{name: 'Chumbli (Osmanabad'},
{name: 'Churcha (Chhattisgarh)'},
{name: 'Churhat'},
{name: 'Cooch Behar (West Bengal)'},
{name: 'Cucolium (Goa)'},
{name: 'D Muppavaram'},
{name: 'Dabhad (Gujarat)'},
{name: 'Dabhala (Gujarat)'},
{name: 'Dabhol (Ratnagiri)'},
{name: 'Dabhole (Maharastra)'},
{name: 'Dadoli (Himachal Pradesh)'},
{name: 'Dadri (Uttar Pradesh)'},
{name: 'Dagad Khedi (Madhya Pradesh)'},
{name: 'Daginakatte (Karnataka)'},
{name: 'Dahagaon'},
{name: 'Dahegaon'},
{name: 'Dahiwadi'},
{name: 'Dakachya'},
{name: 'Dalauda'},
{name: 'Dalla (Uttar Pradesh)'},
{name: 'Dalmia Puram'},
{name: 'Dalot (Rajasthan)'},
{name: 'Dambal (Karnataka)'},
{name: 'Damercherla (Telangana)'},
{name: 'Damjipura'},
{name: 'Damrala'},
{name: 'Damtal'},
{name: 'Danda'},
{name: 'Dangeru (Andhra Pradesh)'},
{name: 'Danteri (Rajasthan)'},
{name: 'Dantwara (Rajasthan)'},
{name: 'Dapora (Madhya Pradesh)'},
{name: 'Darbhagudem (andhra Pradesh)'},
{name: 'Dariba (Rajasthan)'},
{name: 'Darjikuwa (Uttar Pradesh)'},
{name: 'Dasaniyan (Rajasthan)'},
{name: 'Dasmegaon (Maharashtra)'},
{name: 'Daspalla'},
{name: 'Dasturabad (Telangana)'},
{name: 'Dasturi (Ratnagiri)'},
{name: 'Dasur (Solapur)'},
{name: 'Datora (Madhya Pradesh)'},
{name: 'Daudwa (Madhya Pradesh)'},
{name: 'Daulatwadi (Maharashtra)'},
{name: 'Davol (Gujarat)'},
{name: 'Debagram (West Bengal)'},
{name: 'Dedkadi (Gujarat)'},
{name: 'Dedtalai (Madhya Pradesh)'},
{name: 'Deeh (Uttar Pradesh)'},
{name: 'Deevanganj (Madhya Pradesh)'},
{name: 'Degalavaripalem'},
{name: 'Deidas (Rajasthan)'},
{name: 'Delakhari (Madhya Pradesh)'},
{name: 'Delol (Gujarat)'},
{name: 'Demow (Assam)'},
{name: 'Deo (Bihar)'},
{name: 'Deoband'},
{name: 'Deobhog (Chhattisgarh)'},
{name: 'Deolapar'},
{name: 'Deoli (Rajasthan)'},
{name: 'Deoli Auwa'},
{name: 'Deopur (Madhya Pradesh)'},
{name: 'Deori (Gondia'},
{name: 'Deori (Madhya Pradesh)'},
{name: 'Deori (Rajasthan)'},
{name: 'Deosugur'},
{name: 'Depalpur (Madhya Pradesh)'},
{name: 'Derdi (Gujarat)'},
{name: 'Dergaon'},
{name: 'Derol'},
{name: 'Dethali (gujarat)'},
{name: 'Dethi (Madhya Pradesh)'},
{name: 'Devade'},
{name: 'Devakigalol (Gujarat)'},
{name: 'Devathanapatti'},
{name: 'Devbag (Maharashtra)'},
{name: 'Devulapalli (Andhra Pradesh)'},
{name: 'Dewar (West Bengal)'},
{name: 'Dewaria (Bihar)'},
{name: 'Dhabi Kalan'},
{name: 'Dhakamore (Bihar)'},
{name: 'Dhakuakhana'},
{name: 'Dhalgaon (Sangali)'},
{name: 'Dhalli'},
{name: 'Dhalpur'},
{name: 'Dhamanse'},
{name: 'Dhamelpara'},
{name: 'Dhanaula'},
{name: 'Dhandhava'},
{name: 'Dhanera (Madhya Pradesh)'},
{name: 'Dhank (Gujarat)'},
{name: 'Dhanodharda'},
{name: 'Dhanora (Beed)'},
{name: 'Dhanora (Yawal)'},
{name: 'Dhar (Gujarat)'},
{name: 'Dharagar (Gujarat)'},
{name: 'Dharagni'},
{name: 'Dhareshvar (Gujarat)'},
{name: 'Dharmapuri (Andhra Pradesh)'},
{name: 'Dharmapuri (Maharastra)'},
{name: 'Dharur (Maharastra)'},
{name: 'Dharwala (Himachal Pradesh)'},
{name: 'Dhaudar (Madhya Pradesh)'},
{name: 'Dhauhal Buzurg (Uttar Pradesh)'},
{name: 'Dhavda Mota'},
{name: 'Dhawadwadi (Maharashtra)'},
{name: 'Dhawana'},
{name: 'Dhoda (Punjab)'},
{name: 'Dhodra (Orissa)'},
{name: 'Dhola (Rajasthan)'},
{name: 'Dholarva (Gujarat)'},
{name: 'Dholpur (Uttar Pradesh)'},
{name: 'Dhulian (West Bengal)'},
{name: 'Dhundhavana (Gujarat)'},
{name: 'Dhutroli'},
{name: 'Dibiyapur'},
{name: 'Didauli (Uttar Pradesh)'},
{name: 'Diddupudi (Telangana)'},
{name: 'Digavale (Maharastra)'},
{name: 'Dighanchi'},
{name: 'Dihapahandi'},
{name: 'Dihwa (Uttar Pradesh)'},
{name: 'Dinara (Bihar)'},
{name: 'Dindori (Chhattisgarh)'},
{name: 'Dindori (Madhya Pradesh)'},
{name: 'Divgi (Karnataka)'},
{name: 'Dobhi (Bihar)'},
{name: 'Dodi (Madhya Pradesh)'},
{name: 'Dolhar (Madhya Pradesh)'},
{name: 'Donepudi (Andhra Pradesh)'},
{name: 'Dongar'},
{name: 'Dongargarh (Chhattisgarh)'},
{name: 'Dontikurru'},
{name: 'Doranala (Andhra Pradesh)'},
{name: 'Double Chowki (Madhya Pradesh)'},
{name: 'Dudhai (Uttar Pradesh)'},
{name: 'Dumarshan (Bihar)'},
{name: 'Dumka (Jharkhand)'},
{name: 'Dumriya Deeh (Uttar Pradesh)'},
{name: 'Dunguripali (Orissa)'},
{name: 'Durjanpur (Uttar Pradesh)'},
{name: 'Duttnagar'},
{name: 'DVC Chowk Gola'},
{name: 'Edakkara'},
{name: 'Edhira (Telangana)'},
{name: 'Eguvaramapuram (Andhra Pradesh)'},
{name: 'Enathu (Kerala)'},
{name: 'Errapothavaram'},
{name: 'Erravvalli Cross Road (Telangana)'},
{name: 'Ervadi (Ramnad)'},
{name: 'Esanatham'},
{name: 'Etcherla (Andhra Pradesh)'},
{name: 'Ethamukkala'},
{name: 'Etukuru'},
{name: 'Etwa (Uttar Pradesh)'},
{name: 'Evurivaripalem'},
{name: 'Falakata (West bengal)'},
{name: 'Fariha (Uttar Pradesh)'},
{name: 'Fatehgarh (Rajasthan)'},
{name: 'Fathenagar(Rajasthan)'},
{name: 'Fattepur (Maharastra)'},
{name: 'Feroke (Kerala)'},
{name: 'Fuljar (Gujarat)'},
{name: 'Fulmal (Madhya Pradesh)'},
{name: 'Fulra (Gujarat)'},
{name: 'Furus (Ratnagiri'},
{name: 'Gadag Betageri'},
{name: 'Gadasarai (Madhya Pradesh)'},
{name: 'Gadodar'},
{name: 'Gaighat (Uttar Pradesh)'},
{name: 'Gajanur (Karnataka)'},
{name: 'Gajraula (Uttarakhand)'},
{name: 'Galag (Karnataka)'},
{name: 'Gandhinagar (Tamil Nadu)'},
{name: 'Gandugulapalli (Telangana)'},
{name: 'Ganesunipalli'},
{name: 'Gangaganj'},
{name: 'Gangavalli'},
{name: 'Gangeruwa (Madhya Pradesh)'},
{name: 'Gangva (Gujarat)'},
{name: 'Ganj Basoda (Basoda)'},
{name: 'Gannavaram (Telangana)'},
{name: 'Gaonkhadi (Maharastra)'},
{name: 'Garag'},
{name: 'Garamali Moti (Gujarat)'},
{name: 'Garautha (Uttar Pradesh)'},
{name: 'Garnala (Haryana)'},
{name: 'Gattuvaripalem'},
{name: 'Gaurella (Chhattisgarh)'},
{name: 'Gaurisagar (Assam)'},
{name: 'Gautampura (Madhya Pradesh)'},
{name: 'Gavane (Maharastra)'},
{name: 'Gavtale (Maharastra)'},
{name: 'Gawan (Jharkhand)'},
{name: 'Gejjelagatta (Karnataka)'},
{name: 'Gerabari (Bihar)'},
{name: 'Ghaigaon (Maharastra)'},
{name: 'Ghaitya (Madhya Pradesh)'},
{name: 'Ghanauli (Punjab)'},
{name: 'Ghanghali (Gujarat)'},
{name: 'Gharmura (Assam)'},
{name: 'Ghatampur'},
{name: 'Ghatiya (Madhya Pradesh)'},
{name: 'Ghatiyabhana (Madhya Pradesh)'},
{name: 'Ghatnandre (Sangli Dist'},
{name: 'Ghatoli'},
{name: 'Ghatotand (Jharkhand)'},
{name: 'Ghatpipriya (Madhya Pradesh)'},
{name: 'Ghatprabha (Karnataka)'},
{name: 'Ghatshila (Jharkhand)'},
{name: 'Gheti'},
{name: 'Ghilamara'},
{name: 'Ghiror (Uttar Pradesh)'},
{name: 'Ghoba'},
{name: 'Ghoghavadar (Gujarat)'},
{name: 'Ghonsla (Madhya Pradesh)'},
{name: 'Ghoradongri'},
{name: 'Ghorasahan'},
{name: 'Ghorichak (Jharkhand)'},
{name: 'Ghorthamba (Jharkhand)'},
{name: 'Ghosi (Uttar Pradesh)'},
{name: 'Ghosla (Madhya Pradesh)'},
{name: 'Ghuggar'},
{name: 'Ghunsiya (Gujarat)'},
{name: 'Gilgit (Jammu And Kashmir)'},
{name: 'Gilund'},
{name: 'Ginigera (Karnataka)'},
{name: 'Giniya (Gujarat)'},
{name: 'Girgadhada'},
{name: 'Giriak'},
{name: 'Girwa (Madhya Pradesh)'},
{name: 'Girye'},
{name: 'Goalpara'},
{name: 'Gobarwahi (Maharashtra)'},
{name: 'Gohad'},
{name: 'Gohand (Uttar Pradesh)'},
{name: 'Gohparu (Madhya Pradesh)'},
{name: 'Gojwada (Osmanabad)'},
{name: 'Gol (Rajasthan)'},
{name: 'Gol Gumbaz(Bijapur)'},
{name: 'Gola (Jharkhand)'},
{name: 'Golakotivaripalem (andhra Pradesh)'},
{name: 'Golamunda'},
{name: 'Golihole'},
{name: 'Gollapalli (Andhra Pradesh)'},
{name: 'Gomvadi (Gujarat)'},
{name: 'Gona (Orissa)'},
{name: 'Gonasapudi'},
{name: 'Gondhla (Himachal Pradesh)'},
{name: 'Gonewadi'},
{name: 'Goniana'},
{name: 'Gopadi (Karnataka)'},
{name: 'Gopalpatti (Tamil Nadu)'},
{name: 'Gopamau'},
{name: 'Goradka (Gujarat)'},
{name: 'Goraganamudi'},
{name: 'Gorakhpur (Haryana)'},
{name: 'Gorakhpur (Madhya Pradesh)'},
{name: 'Gorebal Camp (Raichur)'},
{name: 'Gormi (Madhya Pradesh)'},
{name: 'Gorripudi'},
{name: 'Gothane (Maharastra)'},
{name: 'Govindgarh (Madhya Pradesh)'},
{name: 'Guddekeri'},
{name: 'Guddekoppa'},
{name: 'Gudimellanka'},
{name: 'Gudipadu (Andhra Pradesh)'},
{name: 'Gujiliamparai (Tamil Nadu)'},
{name: 'Gulabbagh (Bihar)'},
{name: 'Gulabganj (Madhya Pradesh)'},
{name: 'Gulabpura (Andhra Pradesh)'},
{name: 'Gulganj'},
{name: 'Gullanpete'},
{name: 'Gullapalli (Andhra Pradesh)'},
{name: 'Gullapur (Karwar)'},
{name: 'Gundaran (Gujarat)'},
{name: 'Gundarana (Gujarat)'},
{name: 'Gunja (Gujarat)'},
{name: 'Gurh (Madhya Pradesh)'},
{name: 'Gursarai (Uttar Pradesh)'},
{name: 'Gurvaigudem'},
{name: 'Gv Satram'},
{name: 'Hadalge (Maharashtra)'},
{name: 'Hadigallu'},
{name: 'Hadmatiya Khajuri'},
{name: 'Hadongri (Osmanabad)'},
{name: 'Haiderganj (Uttar Pradesh)'},
{name: 'Haisar (Uttar Pradesh)'},
{name: 'Hajipur (Gujarat)'},
{name: 'Hajipur (Telangana)'},
{name: 'Halagali (Karnataka)'},
{name: 'Halariya'},
{name: 'Hali Handarguli (Latur)'},
{name: 'Haliyad'},
{name: 'Hallikhed'},
{name: 'Hameerpur (Rajasthan)'},
{name: 'Hampanoor (Karnataka)'},
{name: 'Hampasagara'},
{name: 'Hanagal (Chitradurga)'},
{name: 'Hanagal (Haveri)'},
{name: 'Hanamanal (Chitradurga)'},
{name: 'Handi (Karnataka)'},
{name: 'Handiaya (Punjab)'},
{name: 'Hangewadi'},
{name: 'Hansdiha (Jharkhand)'},
{name: 'Hanswar (Uttar Pradesh)'},
{name: 'Harali'},
{name: 'Harapur(Karnataka)'},
{name: 'Harapur(Orissa)'},
{name: 'Haripara (Gujarat)'},
{name: 'Harishchandrapur (West Bengal)'},
{name: 'Harishi (Karnataka)'},
{name: 'Harli (Maharashtra)'},
{name: 'Harmoti'},
{name: 'Harnahalli (Karnataka)'},
{name: 'Harnaut'},
{name: 'Harshad (Gujarat)'},
{name: 'Harsud'},
{name: 'Harsurpur Devaliya'},
{name: 'Hasanabada (Andhra Pradesh)'},
{name: 'Hasanapur (Uttar Pradesh)'},
{name: 'Hasul (Maharashtra)'},
{name: 'Hateri (Madhya Pradesh)'},
{name: 'Hathsani (Gujarat)'},
{name: 'Hatma (Jharkhand)'},
{name: 'Hatpipliya (Madhya Pradesh)'},
{name: 'Hatta (Madhya Pradesh)'},
{name: 'Havambhavi (Karnataka)'},
{name: 'Hebsur (Chitradurga)'},
{name: 'Heenta (Rajasthan)'},
{name: 'Heerpura (Rajasthan)'},
{name: 'Helem (Assam)'},
{name: 'Hemtabad'},
{name: 'Herbertpur (Uttarakhand)'},
{name: 'Heruru Sringeri'},
{name: 'Himayatnagar (Maharashtra)'},
{name: 'Hindale'},
{name: 'Hirebyle (Karnataka)'},
{name: 'Hirehadagali (karnataka)'},
{name: 'Hirejawoor (karnataka)'},
{name: 'Hiresindhogi (Karnataka)'},
{name: 'Hirgana (Karnataka)'},
{name: 'Hisua'},
{name: 'Hiwara (Osmanabad)'},
{name: 'Holalu (Karnataka)'},
{name: 'Holekoppa (Karnataka)'},
{name: 'Holi (Himachal Pradesh)'},
{name: 'Honnahalli (Karnataka)'},
{name: 'Honnekatte (Karnataka)'},
{name: 'Horti (Karnataka)'},
{name: 'Hosabale (Karnataka)'},
{name: 'Hosabettu (Karnataka)'},
{name: 'Hosagadde (Karnataka)'},
{name: 'Hosuru (Karnataka)'},
{name: 'Howajan'},
{name: 'Huligi (Karnataka)'},
{name: 'Huljanti (Maharashtra)'},
{name: 'Hullapur'},
{name: 'Huma (Orissa)'},
{name: 'Hunasekatte (Karnataka)'},
{name: 'Hunginhal (Maharastra)'},
{name: 'Hunnar'},
{name: 'Icchapur (Madhya Pradesh)'},
{name: 'Ichhapur (Maharashtra)'},
{name: 'Ichhawar'},
{name: 'Idayakottai'},
{name: 'Iduru (Karnataka)'},
{name: 'Ieet (Maharashtra)'},
{name: 'Ilayangudi (Puducherry)'},
{name: 'Imamganj'},
{name: 'Indapur (Raigad)'},
{name: 'Indapur (Washi'},
{name: 'Ippagudem (Telangana)'},
{name: 'Irumpoothipatty (Karur)'},
{name: 'Isha (Coimbatore)'},
{name: 'Ishwaramangala'},
{name: 'Islampur (West Bengal)'},
{name: 'Issamudra (Karnataka)'},
{name: 'Itahar (West Bengal)'},
{name: 'Itawa (Rajasthan)'},
{name: 'Itikalapalli'},
{name: 'Itkal (Karnataka)'},
{name: 'Ittigi'},
{name: 'Jadadevi'},
{name: 'Jafrabad (Maharashtra)'},
{name: 'Jagarlamudi'},
{name: 'Jagasari Tal'},
{name: 'Jagatsinghapur'},
{name: 'Jagdispur (Bihar)'},
{name: 'Jagiroad'},
{name: 'Jahanabad (Uttar Pradesh)'},
{name: 'Jahangirganj (Uttar Pradesh)'},
{name: 'Jainagar (Jharkhand)'},
{name: 'Jaisinghpura (Rajasthan)'},
{name: 'Jaitasar'},
{name: 'Jaithal (Madhya Pradesh)'},
{name: 'Jakhalabandha (Assam)'},
{name: 'Jaleswar (Orissa)'},
{name: 'Jalone (Uttar Pradesh)'},
{name: 'Jam Dudhai (Gujarat)'},
{name: 'Jamakhandi'},
{name: 'Jamb Bk (Nanded)'},
{name: 'Jamgod (Madhya Pradesh)'},
{name: 'Jamrunda (Orissa)'},
{name: 'Jamsande (Maharastra)'},
{name: 'Jamua (Jharkhand)'},
{name: 'Jamugurihat'},
{name: 'Janakpur (Madhya Pradesh)'},
{name: 'Janjariya Juna'},
{name: 'Jantra (Karnataka)'},
{name: 'Jaraka (Orissa)'},
{name: 'Jarandi (Maharastra)'},
{name: 'Jarang (Bihar)'},
{name: 'Jargali (Gujarat)'},
{name: 'Jarigekatte (Karnataka)'},
{name: 'Jarkhiya (Gujarat)'},
{name: 'Jarod (Gujarat)'},
{name: 'Jaspura (Uttar Pradesh)'},
{name: 'Jaswant Nagar (Uttar Pradesh)'},
{name: 'Jatabal (Orissa)'},
{name: 'Javali (Karanataka)'},
{name: 'Javaraj (Gujarat)'},
{name: 'Javoor'},
{name: 'Jawaja (Rajasthan)'},
{name: 'Jawalethar'},
{name: 'Jawar Jod (Madhya Pradesh)'},
{name: 'Jayamkondacholapuram'},
{name: 'Jayant (Madhya Pradesh)'},
{name: 'Jeelakarragudem'},
{name: 'Jeeyapuram (Trichy)'},
{name: 'Jejad (Gujarat)'},
{name: 'Jeran (Rajasthan)'},
{name: 'Jeratagi (Karnataka)'},
{name: 'Jeron Khalsa (Madhya Pradesh)'},
{name: 'Jethwara (Uttar Pradesh)'},
{name: 'Jhabra (Rajasthan)'},
{name: 'Jhakri (Himachal Pradesh)'},
{name: 'Jhaliwada'},
{name: 'Jhallara (Rajasthan)'},
{name: 'Jhanji (Assam)'},
{name: 'Jhansi (Madhya Pradesh)'},
{name: 'Jharda (Madhya Pradesh)'},
{name: 'Jharia (Jharkhand)'},
{name: 'Jharkheda'},
{name: 'Jhiriyatola (Chhattisgarh)'},
{name: 'Jiragadh (Dhar)'},
{name: 'Jispa (Himachal Pradesh)'},
{name: 'Jithari (Bhavnagar)'},
{name: 'Jiyanpur (Uttar Pradesh)'},
{name: 'Jiyapur (Uttar Pradesh)'},
{name: 'Jogandabhavi'},
{name: 'Jolhupur'},
{name: 'Jooniya (Rajasthan)'},
{name: 'Jorhat Nimati Ghat (Assam)'},
{name: 'Jotingada'},
{name: 'Joura (Madhya Pradesh)'},
{name: 'Junaidganj Chowk (uttar Pradesh)'},
{name: 'Junavaghniya'},
{name: 'Juni Chavand'},
{name: 'Juvathi (Maharashtra)'},
{name: 'Jwala Ji'},
];