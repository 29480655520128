import React from "react";
import {
	Text,
	Group,
	Paper,
	Button,
} from "@mantine/core";


export function SearchResultCard({
	item,
	number,
	from,
	to,
	fromStation,
	toStation,
	from_std,
	to_std,
	startDate,
	name,
	duration
}) {




	

return (
	<Paper withBorder p="md" radius="md" my={10}>
		<Group position="apart" noWrap>
			<Group align="flex-end" spacing="xs">
				<Text size="xl" weight={600}>
					{name}
				</Text>
				<Text color="teal" size="md" weight={600}>
					({number})
				</Text>
			</Group>
			
		</Group>
		<Group position="apart" align="center" noWrap>
			<Text>
				{fromStation} | {from_std}
				<Text color="dimmed" size="xs">
					Duration: {duration} | Journey Date: {startDate}
				</Text>
			</Text>
			
			<Text align="right">
				{toStation} | {to_std}
			</Text>
		</Group>

		

		<Button
			color={"#ff55ff"}
			fullWidth
			onClick={() => {
				window.open("https://www.railyatri.in/trains-between-stations-v2?from_code=" + from_std + "&from_name=" + from_std + "&journey_date=" + startDate + "&src=ttb_landing&to_code=" + to_std + "&to_name=" + to_std, '_blank');
					
					
						
					
				
			}}
		>
			Book Now{" "}
			
		</Button>
		</Paper>
	);
}
