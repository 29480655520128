import useFetch from "../../hooks/useFetch";
import "./propertyList.css";
import { useNavigate } from "react-router-dom";

const PropertyList = () => {
  const { data, loading } = useFetch("/holidays/countByHolidayType");
  const navigate = useNavigate();

  const handleTypes = (type) => {
    navigate("/holidayslist/"+type)
  };
  

  const images = [
    "type/romantic.jpeg",
    "type/religious.jpeg",
    "type/family.jpeg",
    "type/solo.jpeg",
  ];
  return (
    <div className="pList">
      {loading ? (
        "loading"
      ) : (
        <>
          {data &&
            images.map((img,i) => (
              <div className="pListItem" key={i}>
                
                <img
                  src={img}
                  alt=""
                  className="pListImg"
                  onClick={() => handleTypes(data[i]?.type)}
                />
                 
                <div className="pListTitles">
                  <h1>{data[i]?.type}</h1>
                  <h2>{data[i]?.count} {data[i]?.type}</h2>
                </div>
               
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default PropertyList;