import "./featured.css";
import useFetch from "../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { SearchContext } from "../../context/SearchContext";
import React, { useEffect } from 'react';

const Featured = () => {
  const navigate = useNavigate();
  const [destination, setDestination] = useState("");
  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [openOptions, setOpenOptions] = useState(false);
  const [options, setOptions] = useState({
    adult: 1,
    children: 0,
    room: 1,
  });

  const searchKashmirPackage = (destination) => {
    navigate("/holidays", { state: { destination, dates, options } });
  };

  const { dispatch } = useContext(SearchContext);

  const handleKashmir = (destination) => {
    dispatch({ type: "NEW_SEARCH", payload: { destination, dates, options } });
    navigate("/holidays", { state: { destination, dates, options } });
  };
  
  const { data, loading, error } = useFetch("/holidays/countByfromCity?cities=Kashmir,Kerala,Rajasthan,Tirupati,Andaman,Gujarat")

  useEffect(() => {
    // Your refresh logic
    var refresh = window.localStorage.getItem('refresh');
    console.log(refresh);
    if (refresh === null) {
      window.location.reload();
      window.localStorage.setItem('refresh', '1');
    }
  }, []); // Empty dependency array to run the effect only once

  
  return (
    <div className="featured">
    { loading ? (
      "Loading please wait"
    ):( 
      <>
      <div id="img-wrapper">
        <div className="featuredItem">
            <img
              src={"titlefolder/Kashmir.jpeg"}
              alt=""
              className="featuredImg"
              onClick={() => handleKashmir("Kashmir")}
            />

            <div className="featuredTitles">
              <h1>Kashmir</h1>
              <h3>{data[0]} Packages</h3>
            </div>

        </div>
        
        <div className="featuredItem">
          <img
            src={"titlefolder/Kerela.jpeg"}
            alt=""
            className="featuredImg"
            onClick={() => handleKashmir("Kerala")}
          />
          <div className="featuredTitles">
            <h1>Kerala</h1>
            <h3>{data[1]} Packages</h3>
          </div>
        </div>
        
        
        <div className="featuredItem">
          <img
            src={"titlefolder/Rajasthan.jpeg"}
            alt=""
            className="featuredImg"
            onClick={() => handleKashmir("Rajasthan")}
          />
          <div className="featuredTitles">
            <h1>Rajasthan</h1>
            <h3>{data[2]} Packages</h3>
          </div>
        </div>

        <div className="featuredItem">
          <img
            src={"titlefolder/Tirupati.jpeg"}
            alt=""
            className="featuredImg"
            onClick={() => handleKashmir("Tirupati")}
          />
          <div className="featuredTitles">
            <h1>Tirupati</h1>
            <h3>{data[3]} Packages</h3>
          </div>
        </div>

        <div className="featuredItem">
          <img
            src={"titlefolder/Andaman.jpeg"}
            alt=""
            className="featuredImg"
            onClick={() => handleKashmir("Andaman")}
          />
          <div className="featuredTitles">
            <h1>Andaman</h1>
            <h3>{data[4]} Packages</h3>
          </div>
        </div>

        <div className="featuredItem">
          <img
            src={"titlefolder/Gujarat.jpeg"}
            alt=""
            className="featuredImg"
            onClick={() => handleKashmir("Gujarat")}
          />
          <div className="featuredTitles">
            <h1>Gujarat</h1>
            <h3>{data[5]} Packages</h3>
          </div>
        </div>



        </div>
     
       </>
      )}
    </div>
  );
};

export default Featured;
