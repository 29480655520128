import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/home/Home";
import Hotel from "./pages/hotel/Hotel";
import List from "./pages/list/List";
import ListHolidaysByCity from "./pages/list/ListHolidaysByCity";
import ListHolidaysByRegion from "./pages/list/ListHolidaysByRegion";
import Holidays from "./pages/holidays/Holidays";
import Travel from "./pages/travel/Travel";
import ContactUs from "./pages/contactUs/ContactUs"


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/hotels" element={<List/>}/>
        <Route path="/hotels/:id" element={<Hotel/>}/>
        <Route path="/holidays" element={<List/>}/>
        <Route path="/holidays/:id" element={<Holidays/>}/>

        <Route path="/travel" element={<Travel/>}/>
        <Route path="/holidayslist/:type" element={<ListHolidaysByCity/>}/>
        <Route path="/holidaysbyregion/:region" element={<ListHolidaysByRegion/>}/>

        <Route path="/contactUs" element={<ContactUs/>}/>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
