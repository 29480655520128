export const stations = [
	{ name: "Amb Andavra", code: "AADR" },
	{ name: "Ambikapur", code: "ABKP" },
	{ name: "Abu Road", code: "ABR" },
	{ name: "ABOHAR", code: "ABS" },
	{ name: "Adoni", code: "AD" },
	{ name: "Adilabad ", code: "ADB" },
	{ name: "Ahmedabad", code: "ADI" },
	{ name: "AHMEDABAD JN", code: "ADIJ" },
	{ name: "Adra", code: "ADRA" },
	{ name: "Agra Fort", code: "AF" },
	{ name: "Agra Cantt.", code: "AGC" },
	{ name: "Agartala", code: "AGTL" },
	{ name: "ACHHNERA JN", code: "AH" },
	{ name: "Ajmer", code: "AII" },
	{ name: "AIT", code: "AIT" },
	{ name: "Jhajha J", code: "AJ" },
	{ name: "Arakkonam", code: "AJJ" },
	{ name: "Ajni", code: "AJNI" },
	{ name: "Akola", code: "AK" },
	{ name: "Anakapalle", code: "AKP" },
	{ name: "Allahabad", code: "ALD" },
	{ name: "Aligarh", code: "ALJN" },
	{ name: "Alappuzha", code: "ALLP" },
	{ name: "ALLAHABAD CITY", code: "ALY" },
	{ name: "Azamgarh", code: "AMH" },
	{ name: "Amravati", code: "AMI" },
	{ name: "Amla", code: "AMLA" },
	{ name: "Samuktala Road", code: "AMTA" },
	{ name: "Amalner", code: "AN" },
	{ name: "ADARSH NAGAR DELHI", code: "ANDI" },
	{ name: "Anand Nagar", code: "ANDN" },
	{ name: "Ahmadnagar", code: "ANG" },
	{ name: "Angul", code: "ANGL" },
	{ name: "Anand", code: "ANND" },
	{ name: "Annavaram", code: "ANV" },
	{ name: "Anand Vihar Terminus", code: "ANVT" },
	{ name: "Alipurduar Jn.", code: "APDJ" },
	{ name: "ANUPGARH", code: "APH" },
	{ name: "Anuppur", code: "APR" },
	{ name: "Ara", code: "ARA" },
	{ name: "Aunrihar", code: "ARJ" },
	{ name: "Arvi", code: "ARVI" },
	{ name: "AISHBAGH", code: "ASH" },
	{ name: "Arsikere", code: "ASK" },
	{ name: "Asansol", code: "ASN" },
	{ name: "Amritsar", code: "ASR" },
	{ name: "AISHBAGH", code: "ASSH" },
	{ name: "Anantapur", code: "ATP" },
	{ name: "ATARI", code: "ATT" },
	{ name: "ALUABARI ROAD", code: "AUB" },
	{ name: "AVADI", code: "AVD" },
	{ name: "Abhanpur Junction", code: "AVP" },
	{ name: "Aurangabad", code: "AWB" },
	{ name: "Alwar", code: "AWR" },
	{ name: "Aluva", code: "AWY" },
	{ name: "Ayodhya", code: "AY" },
	{ name: "Azimganj Jn.", code: "AZ" },
	{ name: "Banka", code: "BAKA" },
	{ name: "Balugaon", code: "BALU" },
	{ name: "BRAHMAPUR", code: "BAM" },
	{ name: "Belapur", code: "BAP" },
	{ name: "Bari", code: "BARI" },
	{ name: "Burhanpur", code: "BAU" },
	{ name: "Bellary Jn.", code: "BAY" },
	{ name: "Baran", code: "BAZ" },
	{ name: "BENOY BADAL DINESH GARDENS", code: "BBDB" },
	{ name: "Barabanki Jn.", code: "BBK" },
	{ name: "Barabil", code: "BBN" },
	{ name: "Bhubaneswar", code: "BBS" },
	{ name: "BAREILLY CITY", code: "BC" },
	{ name: "Mumbai Central", code: "BCT" },
	{ name: "VARANASI CITY", code: "BCY" },
	{ name: "Badnera Jn.", code: "BD" },
	{ name: "Bandel Jn.", code: "BDC" },
	{ name: "Bhadrachalam Road", code: "BDCR" },
	{ name: "Bodeli", code: "BDE" },
	{ name: "Bodhan", code: "BDHN" },
	{ name: "Vadakara", code: "BDJ" },
	{ name: "VINDHYACHAL", code: "BDL" },
	{ name: "Budhma", code: "BDMA" },
	{ name: "Baidyanathdham", code: "BDME" },
	{ name: "Bhadran", code: "BDRN" },
	{ name: "Bandra (T)", code: "BDTS" },
	{ name: "Bareilly", code: "BE" },
	{ name: "Beas", code: "BEAS" },
	{ name: "Budaun", code: "BEM" },
	{ name: "Beawar", code: "BER" },
	{ name: "Budge Budge", code: "BGB" },
	{ name: "Baghbahar", code: "BGBR" },
	{ name: "Bagalkot", code: "BGK" },
	{ name: "Bhagat Ki Kothi", code: "BGKI" },
	{ name: "BHAGAT KI KOTHI", code: "BGKT" },
	{ name: "Belgaum", code: "BGM" },
	{ name: "Balgona", code: "BGNA" },
	{ name: "Bhagalpur", code: "BGP" },
	{ name: "BEGU SARAI", code: "BGS" },
	{ name: "BAIRGANIA", code: "BGU" },
	{ name: "Bangriposi", code: "BGY" },
	{ name: "Bharuch", code: "BH" },
	{ name: "Bathua Bazar", code: "BHBR" },
	{ name: "Bhadrak", code: "BHC" },
	{ name: "Bihariganj", code: "BHGJ" },
	{ name: "Barhaj Bazar", code: "BHJ" },
	{ name: "Bhilwara", code: "BHL" },
	{ name: "Bolpur Shantiniketan", code: "BHP" },
	{ name: "Bhairabi", code: "BHRB" },
	{ name: "Vidisha", code: "BHS" },
	{ name: "Bhuj", code: "BHUJ" },
	{ name: "BARHARWA JN", code: "BHW" },
	{ name: "BARI SADRI", code: "BI" },
	{ name: "BILIMORA JN", code: "BIM" },
	{ name: "Bina", code: "BINA" },
	{ name: "Bir", code: "BIR" },
	{ name: "Biraul", code: "BIRL" },
	{ name: "Bhind", code: "BIX" },
	{ name: "Bhojudih Junction", code: "BJE" },
	{ name: "Bijnor", code: "BJO" },
	{ name: "Bijapur", code: "BJP" },
	{ name: "BAIJNATH PAPROLA", code: "BJPL" },
	{ name: "Barauni Jn.", code: "BJU" },
	{ name: "Bhikhna Thori", code: "BKF" },
	{ name: "Bandikui Jn.", code: "BKI" },
	{ name: "Bikaner", code: "BKN" },
	{ name: "Bakthiyarpur", code: "BKP" },
	{ name: "Bokaro Steel City", code: "BKSC" },
	{ name: "Valsad", code: "BL" },
	{ name: "BHILAD", code: "BLD" },
	{ name: "BHILDI", code: "BLDI" },
	{ name: "BALANGIR", code: "BLGR" },
	{ name: "Balurghat", code: "BLGT" },
	{ name: "BALAMU JN", code: "BLM" },
	{ name: "Balasore", code: "BLS" },
	{ name: "Barmer", code: "BME" },
	{ name: "BOLARUM", code: "BMO" },
	{ name: "Badampahar", code: "BMPR" },
	{ name: "Bangalore Cantt.", code: "BNC" },
	{ name: "Banda Jn.", code: "BNDA" },
	{ name: "Banmankhi", code: "BNKI" },
	{ name: "Banarhat", code: "BNQ" },
	{ name: "Binnaguri", code: "BNV" },
	{ name: "BHANVAD", code: "BNVD" },
	{ name: "Bhiwani", code: "BNW" },
	{ name: "Barhni", code: "BNY" },
	{ name: "Barsoi", code: "BOE" },
	{ name: "Barog", code: "BOF" },
	{ name: "BHOGPUR", code: "BOP" },
	{ name: "Bhadohi", code: "BOY" },
	{ name: "Badarpur", code: "BPB" },
	{ name: "Berhampore Court", code: "BPC" },
	{ name: "Bhopal", code: "BPL" },
	{ name: "Baghpat Road", code: "BPM" },
	{ name: "Baripada", code: "BPO" },
	{ name: "Bapatla", code: "BPP" },
	{ name: "Balharshah", code: "BPQ" },
	{ name: "Bankura", code: "BQA" },
	{ name: "Vadodara", code: "BRC" },
	{ name: "Bargarh Road", code: "BRGA" },
	{ name: "Barkakana", code: "BRKA" },
	{ name: "Biramitrapur", code: "BRMP" },
	{ name: "Baramati", code: "BRMT" },
	{ name: "Barapa Lli", code: "BRPL" },
	{ name: "Barapalli", code: "BRPS" },
	{ name: "Biyavara Rajgarh", code: "BRRG" },
	{ name: "Barwadih", code: "BRWD" },
	{ name: "BAREILLY", code: "BRY" },
	{ name: "Varanasi", code: "BSB" },
	{ name: "Bulandshahr", code: "BSC" },
	{ name: "Bhusaval", code: "BSL" },
	{ name: "Bilaspur Jn.", code: "BSP" },
	{ name: "Banspani", code: "BSPX" },
	{ name: "Vasai Road", code: "BSR" },
	{ name: "Basti", code: "BST" },
	{ name: "Basar", code: "BSX" },
	{ name: "Barasat", code: "BT" },
	{ name: "Balaghat", code: "BTC" },
	{ name: "Botad", code: "BTD" },
	{ name: "BOTAD JN", code: "BTDF" },
	{ name: "Bharatpur", code: "BTE" },
	{ name: "Bettiah", code: "BTH" },
	{ name: "Bathinda Jn.", code: "BTI" },
	{ name: "Bhatkal", code: "BTJL" },
	{ name: "Bhatni", code: "BTT" },
	{ name: "Bitragunta", code: "BTTR" },
	{ name: "Baraut", code: "BTU" },
	{ name: "Bundi", code: "BUDI" },
	{ name: "Ballia", code: "BUI" },
	{ name: "Burhwal", code: "BUW" },
	{ name: "Bhavnagar (T)", code: "BVC" },
	{ name: "Borivali", code: "BVI" },
	{ name: "BHAVANAGAR PARA", code: "BVP" },
	{ name: "Bhimavaram Jn.", code: "BVRM" },
	{ name: "Bhimavaram Town", code: "BVRT" },
	{ name: "Barddhaman Jn.", code: "BWN" },
	{ name: "Bhawanipatna", code: "BWPI" },
	{ name: "Bangarapet", code: "BWT" },
	{ name: "Barsuan", code: "BXF" },
	{ name: "Bayana", code: "BXN" },
	{ name: "Buxar", code: "BXR" },
	{ name: "Bamanhat", code: "BXT" },
	{ name: "Bhatapara", code: "BYT" },
	{ name: "Siswa Bazar S", code: "BZ" },
	{ name: "Vijayawada", code: "BZA" },
	{ name: "Betul", code: "BZU" },
	{ name: "Chakia", code: "CAA" },
	{ name: "Chanda Fort", code: "CAF" },
	{ name: "Kannur", code: "CAN" },
	{ name: "Chatrapur", code: "CAP" },
	{ name: "Kanyakumari", code: "CAPE" },
	{ name: "Chunar", code: "CAR" },
	{ name: "Coimbatore Jn.", code: "CBE" },
	{ name: "Chik Ballapur", code: "CBP" },
	{ name: "Sitapur Cantt. S", code: "CC" },
	{ name: "KAKINADA TOWN", code: "CCT" },
	{ name: "Chandrapur", code: "CD" },
	{ name: "Chandod", code: "CDD" },
	{ name: "Chandigarh", code: "CDG" },
	{ name: "Chidambaram", code: "CDM" },
	{ name: "Canning", code: "CG" },
	{ name: "Chengalpattu Jn.", code: "CGL" },
	{ name: "Chandausi", code: "CH" },
	{ name: "CHANDIA ROAD", code: "CHD" },
	{ name: "Srikakulam Road", code: "CHE" },
	{ name: "Sri", code: "CHHATRAPATI" },
	{ name: "Chiplun", code: "CHI" },
	{ name: "Chakki Bank", code: "CHKB" },
	{ name: "Chirmiri", code: "CHRM" },
	{ name: "CHARVATTUR", code: "CHV" },
	{ name: "Kanchipuram", code: "CJ" },
	{ name: "Chakradharpur", code: "CKP" },
	{ name: "Chitrakootdham Karvi", code: "CKTD" },
	{ name: "Silchar S", code: "CL" },
	{ name: "CASTLE ROCK", code: "CLR" },
	{ name: "Kozhikode", code: "CLT" },
	{ name: "Chirala", code: "CLX" },
	{ name: "CHAMARAJANAGAR", code: "CMNR" },
	{ name: "Chanderiya", code: "CNA" },
	{ name: "Kanpur Central", code: "CNB" },
	{ name: "Chengannur", code: "CNGR" },
	{ name: "Chandil", code: "CNI" },
	{ name: "Canacona", code: "CNO" },
	{ name: "Kakinada Port", code: "COA" },
	{ name: "Chittaurgarh", code: "COR" },
	{ name: "Kanpur Anwarganj", code: "CPA" },
	{ name: "Champa", code: "CPH" },
	{ name: "Kaptanganj", code: "CPJ" },
	{ name: "Chaparmukh", code: "CPK" },
	{ name: "Chhapra", code: "CPR" },
	{ name: "Chopan", code: "CPU" },
	{ name: "Chittaranjan", code: "CRJ" },
	{ name: "Choranda Junction", code: "CRND" },
	{ name: "Chandrapura", code: "CRP" },
	{ name: "Shahu Maharaj (T)", code: "CSMT" },
	{ name: "Chalisgaon", code: "CSN" },
	{ name: "Mumbai Cst", code: "CSTM" },
	{ name: "Chitradurg", code: "CTA" },
	{ name: "Cuttack", code: "CTC" },
	{ name: "Chhota Udepur", code: "CTD" },
	{ name: "Chittoor", code: "CTO" },
	{ name: "Cuddalore Port", code: "CUPJ" },
	{ name: "Churu", code: "CUR" },
	{ name: "Chhindwara", code: "CWA" },
	{ name: "Dharmabad", code: "DAB" },
	{ name: "Dhasa", code: "DAS" },
	{ name: "Dabhoi Junction", code: "DB" },
	{ name: "Dhubri", code: "DBB" },
	{ name: "Darbhanga", code: "DBG" },
	{ name: "Dibrugarh", code: "DBRG" },
	{ name: "Dibrugarh Town", code: "DBRT" },
	{ name: "Daund", code: "DD" },
	{ name: "Dehradun", code: "DDN" },
	{ name: "MUMBAI DADAR WEST", code: "DDR" },
	{ name: "Delhi Cantt.", code: "DEC" },
	{ name: "Delhi Sarai Rohilla", code: "DEE" },
	{ name: "Deoriasadar", code: "DEOS" },
	{ name: "Dindigul Jn.", code: "DG" },
	{ name: "Dongargarh", code: "DGG" },
	{ name: "Sri Dungargarh S", code: "DGH" },
	{ name: "Digha", code: "DGHA" },
	{ name: "Durgapur", code: "DGR" },
	{ name: "Diamond Harbour", code: "DH" },
	{ name: "Dhaca Cantt.", code: "DHCA" },
	{ name: "Dahod", code: "DHD" },
	{ name: "Dhrangadhra", code: "DHG" },
	{ name: "Dhule", code: "DHI" },
	{ name: "Devanhalli", code: "DHL" },
	{ name: "Dhanbad", code: "DHN" },
	{ name: "Dhone", code: "DHNE" },
	{ name: "Dhaulpur", code: "DHO" },
	{ name: "DIVA", code: "DIVA" },
	{ name: "Darjeeling", code: "DJ" },
	{ name: "To Darjeeling", code: "DJRZ" },
	{ name: "DANKUNI", code: "DKAE" },
	{ name: "DEKARGAON", code: "DKGN" },
	{ name: "Dornakal", code: "DKJ" },
	{ name: "Dullabcherra", code: "DLCR" },
	{ name: "Delhi", code: "DLI" },
	{ name: "Dhola", code: "DLJ" },
	{ name: "Dildarnagar", code: "DLN" },
	{ name: "Dalgaon", code: "DLO" },
	{ name: "Dauram Madhepura", code: "DMH" },
	{ name: "Dharmavaram", code: "DMM" },
	{ name: "Dhamangaon", code: "DMN" },
	{ name: "Damoh", code: "DMO" },
	{ name: "Dharmanagar", code: "DMR" },
	{ name: "Dimapur", code: "DMV" },
	{ name: "DEGANA JN", code: "DNA" },
	{ name: "Danapur", code: "DNR" },
	{ name: "Dungarpur", code: "DNRP" },
	{ name: "Dhenuanal", code: "DNUL" },
	{ name: "Dehri-On-Sone", code: "DOS" },
	{ name: "Dharmapuri", code: "DPJ" },
	{ name: "Dadar", code: "DR" },
	{ name: "Dahanu Road", code: "DRD" },
	{ name: "Dallirajhara", code: "DRZ" },
	{ name: "Delhi Shahdara", code: "DSA" },
	{ name: "Daltonganj", code: "DTO" },
	{ name: "Dhamtari", code: "DTR" },
	{ name: "Dhuri", code: "DUI" },
	{ name: "Dumka", code: "DUMK" },
	{ name: "Durg", code: "DURG" },
	{ name: "Delvada", code: "DVA" },
	{ name: "Duvvada", code: "DVD" },
	{ name: "Davangere", code: "DVG" },
	{ name: "Devlali", code: "DVL" },
	{ name: "Dwarka", code: "DWK" },
	{ name: "Dharwad", code: "DWR" },
	{ name: "Erode", code: "ED" },
	{ name: "Ledo L", code: "EDO" },
	{ name: "Eluru", code: "EE" },
	{ name: "Achalpur", code: "ELP" },
	{ name: "Ernakulam Town", code: "ERN" },
	{ name: "Ernakulam Jn.", code: "ERS" },
	{ name: "Itarsi", code: "ET" },
	{ name: "Etah", code: "ETAH" },
	{ name: "Etawah", code: "ETW" },
	{ name: "Farrukhabad", code: "FBD" },
	{ name: "Forbesganj", code: "FBG" },
	{ name: "Faizabad", code: "FD" },
	{ name: "Faridabad", code: "FDB" },
	{ name: "Lower Halflong L", code: "FG" },
	{ name: "Fazilka", code: "FKA" },
	{ name: "Furkating", code: "FKG" },
	{ name: "FAKIRAGRAM JN", code: "FKM" },
	{ name: "Phulera", code: "FL" },
	{ name: "KACHEGUDA FALAKNUMA", code: "FM" },
	{ name: "Farukhnagar", code: "FN" },
	{ name: "Fatehabad Chand", code: "FTD" },
	{ name: "Fatehpur", code: "FTP" },
	{ name: "Fatuha", code: "FUT" },
	{ name: "Firozabad", code: "FZD" },
	{ name: "Firozpur City", code: "FZP" },
	{ name: "Firozpur Cantt.", code: "FZR" },
	{ name: "Gondia", code: "G" },
	{ name: "Gevra Road", code: "GAD" },
	{ name: "Gandhinagar", code: "GADJ" },
	{ name: "Gaya", code: "GAYA" },
	{ name: "GARHBETA", code: "GBA" },
	{ name: "Gonda", code: "GD" },
	{ name: "Godhra", code: "GDA" },
	{ name: "Gadag", code: "GDG" },
	{ name: "Gudur", code: "GDR" },
	{ name: "Gudivada", code: "GDV" },
	{ name: "Gede", code: "GEDE" },
	{ name: "GANDHIGRAM", code: "GG" },
	{ name: "Gangapur City", code: "GGC" },
	{ name: "Gurgaon", code: "GGN" },
	{ name: "Garwa Road", code: "GHD" },
	{ name: "Guwahati", code: "GHY" },
	{ name: "Gandhidham", code: "GIM" },
	{ name: "GANDHIDHAM BG", code: "GIMB" },
	{ name: "Gajraula", code: "GJL" },
	{ name: "Gola Gokarannath", code: "GK" },
	{ name: "Gorakhpur", code: "GKP" },
	{ name: "Lalgola L", code: "GL" },
	{ name: "Goalpara Town", code: "GLPT" },
	{ name: "Chandra Bose Gomoh", code: "GMO" },
	{ name: "Gandhinagar Capital", code: "GNC" },
	{ name: "Gunupur", code: "GNPR" },
	{ name: "Guntur", code: "GNT" },
	{ name: "Sagour S", code: "GO" },
	{ name: "Gossaingaon Hat", code: "GOGH" },
	{ name: "GUMMIDIPUNDI", code: "GPD" },
	{ name: "Gulbarga", code: "GR" },
	{ name: "Giridih", code: "GRD" },
	{ name: "Guntakal", code: "GTL" },
	{ name: "Ghatsila", code: "GTS" },
	{ name: "Gua", code: "GUA" },
	{ name: "Guna", code: "GUNA" },
	{ name: "Siliguri Town S", code: "GUT" },
	{ name: "Guruvay Ur", code: "GUV" },
	{ name: "Gwalior", code: "GWL" },
	{ name: "Gwalior NG", code: "GWO" },
	{ name: "Gooty", code: "GY" },
	{ name: "Gyanpur Road", code: "GYN" },
	{ name: "Ghaziabad", code: "GZB" },
	{ name: "Hapa", code: "HAPA" },
	{ name: "Hoshangabad", code: "HBD" },
	{ name: "Howbadh", code: "HBG" },
	{ name: "Habibganj", code: "HBJ" },
	{ name: "Harda", code: "HD" },
	{ name: "Haldibari", code: "HDB" },
	{ name: "Hilsa", code: "HIL" },
	{ name: "Hajipur", code: "HJP" },
	{ name: "Jakhal J", code: "HL" },
	{ name: "Haldia", code: "HLZ" },
	{ name: "Hanumangarh", code: "HMH" },
	{ name: "Himmat Nagar", code: "HMT" },
	{ name: "Hasanabad", code: "HNB" },
	{ name: "Hingoli", code: "HNL" },
	{ name: "Hamiltonganj", code: "HOJ" },
	{ name: "Harpalpur", code: "HPP" },
	{ name: "Hospet", code: "HPT" },
	{ name: "Hapur", code: "HPU" },
	{ name: "Hathras Kila", code: "HRF" },
	{ name: "Harihar", code: "HRR" },
	{ name: "HATHRAS JN", code: "HRS" },
	{ name: "Hasimara", code: "HSA" },
	{ name: "Hisar", code: "HSR" },
	{ name: "Hosur", code: "HSRA" },
	{ name: "Hoshiarpur", code: "HSX" },
	{ name: "Hatia", code: "HTE" },
	{ name: "Silghat S", code: "HTT" },
	{ name: "Hindupur", code: "HUP" },
	{ name: "Haridwar", code: "HW" },
	{ name: "Howrah", code: "HWH" },
	{ name: "Cuddapah", code: "HX" },
	{ name: "Hyderabad", code: "HYB" },
	{ name: "HAZARIBAGH ROAD", code: "HZD" },
	{ name: "Indara", code: "IAA" },
	{ name: "Igatpuri", code: "IGP" },
	{ name: "Indore", code: "INDB" },
	{ name: "Indore Junction MG", code: "INDM" },
	{ name: "Islampur", code: "IPR" },
	{ name: "ITWARI", code: "ITR" },
	{ name: "Jalna", code: "J" },
	{ name: "YAMUNA BRIDGE AGRA", code: "JAB" },
	{ name: "JHAJHA", code: "JAJ" },
	{ name: "Jamnagar", code: "JAM" },
	{ name: "Jammu Tawi", code: "JAT" },
	{ name: "Jogbani", code: "JBN" },
	{ name: "Jabalpur", code: "JBP" },
	{ name: "Jagdalpur", code: "JDB" },
	{ name: "JOGINDER NAGAR", code: "JDNX" },
	{ name: "Jhargram", code: "JGM" },
	{ name: "JAKHAL JN", code: "JHL" },
	{ name: "Jhansi", code: "JHS" },
	{ name: "Jind", code: "JIND" },
	{ name: "Jaijon Doaba", code: "JJJ" },
	{ name: "Jajpur Keonjhar Road", code: "JJKR" },
	{ name: "Jhanjharpur", code: "JJP" },
	{ name: "Jalgaon", code: "JL" },
	{ name: "Jetalsar", code: "JLR" },
	{ name: "Jalamb", code: "JM" },
	{ name: "Jambusar Junction", code: "JMB" },
	{ name: "JAWLMUKHI ROAD", code: "JMKR" },
	{ name: "Jamner", code: "JMNR" },
	{ name: "Jamalpur", code: "JMP" },
	{ name: "JUNAGADH JN", code: "JND" },
	{ name: "Janghai", code: "JNH" },
	{ name: "Junnor Deo", code: "JNO" },
	{ name: "Junagarh Jn. J", code: "JNRD" },
	{ name: "Jaunpur Jn.", code: "JNU" },
	{ name: "Jaipur", code: "JP" },
	{ name: "Jalpaiguri", code: "JPG" },
	{ name: "JIRIBAM", code: "JRBM" },
	{ name: "Jalandhar Cantt. J", code: "JRC" },
	{ name: "Chikjajur J", code: "JRU" },
	{ name: "Jharsuguda Js", code: "JSG" },
	{ name: "Jaisalmer", code: "JSM" },
	{ name: "Jasidih", code: "JSME" },
	{ name: "Jolarpettai", code: "JTJ" },
	{ name: "Jorhat Town", code: "JTTN" },
	{ name: "Jodhpur", code: "JU" },
	{ name: "Jalandhar City J", code: "JUC" },
	{ name: "Jaynagar", code: "JYG" },
	{ name: "Jaypur", code: "JYP" },
	{ name: "Kavi", code: "KAVI" },
	{ name: "Karwar", code: "KAWR" },
	{ name: "Kabakaputtur", code: "KBPR" },
	{ name: "KADAMBATTUR", code: "KBT" },
	{ name: "Kalchini", code: "KCF" },
	{ name: "Kacheguda", code: "KCG" },
	{ name: "Kochuveli", code: "KCVL" },
	{ name: "Khed Brahma", code: "KDBM" },
	{ name: "Kendujhargarh", code: "KDJR" },
	{ name: "Khekra", code: "KEX" },
	{ name: "Khagaria", code: "KGG" },
	{ name: "Kathgodam", code: "KGM" },
	{ name: "Kurseong", code: "KGN" },
	{ name: "Kharagpur", code: "KGP" },
	{ name: "Kasaragod", code: "KGQ" },
	{ name: "KOLAGHAT GOODS", code: "KIG" },
	{ name: "Karaikal", code: "KIK" },
	{ name: "Katihar", code: "KIR" },
	{ name: "Kiul", code: "KIUL" },
	{ name: "Krishnarajapuram", code: "KJM" },
	{ name: "KARJAT", code: "KJT" },
	{ name: "Khijadiya Junction", code: "KJV" },
	{ name: "Kurukshetra", code: "KKDE" },
	{ name: "Karaikkudi Jn.", code: "KKDI" },
	{ name: "Kot Kapura", code: "KKP" },
	{ name: "Khalilabad", code: "KLD" },
	{ name: "Kalka", code: "KLK" },
	{ name: "Kalol", code: "KLL" },
	{ name: "KAMAREDDI", code: "KMC" },
	{ name: "Khammam", code: "KMT" },
	{ name: "Kumbakonam", code: "KMU" },
	{ name: "Katni Murwara", code: "KMZ" },
	{ name: "Kishanganj", code: "KNE" },
	{ name: "Konch", code: "KNH" },
	{ name: "Krishnanagar City", code: "KNJ" },
	{ name: "KANALAS JN", code: "KNLS" },
	{ name: "Khandwa", code: "KNW" },
	{ name: "KOLKATA CHITPUR", code: "KOAA" },
	{ name: "Kolkata", code: "KOAAKO" },
	{ name: "Kodinar", code: "KODR" },
	{ name: "C SHAHU M RAJ KOLHAPUR TERM", code: "KOP" },
	{ name: "Kota", code: "KOTA" },
	{ name: "Katpadi", code: "KPD" },
	{ name: "Kopergaon", code: "KPG" },
	{ name: "KASHIPUR", code: "KPV" },
	{ name: "Kandhla", code: "KQL" },
	{ name: "Kodaikanal Road", code: "KQN" },
	{ name: "KODERMA", code: "KQR" },
	{ name: "Korba", code: "KRBA" },
	{ name: "Kirandul", code: "KRDL" },
	{ name: "Khurja", code: "KRJ" },
	{ name: "Kurnool Town", code: "KRNT" },
	{ name: "Koraput", code: "KRPU" },
	{ name: "Karur", code: "KRR" },
	{ name: "KOSAMBA JN", code: "KSB" },
	{ name: "Kishangarh", code: "KSG" },
	{ name: "Kasganj", code: "KSJ" },
	{ name: "Kasganj Mg", code: "KSJF" },
	{ name: "Kesinga", code: "KSNG" },
	{ name: "KOSI KALAN", code: "KSV" },
	{ name: "Katni", code: "KTE" },
	{ name: "Kathana", code: "KTNA" },
	{ name: "Kotdwara", code: "KTW" },
	{ name: "Kottayam", code: "KTYM" },
	{ name: "Kundapura", code: "KUDA" },
	{ name: "Kumarghat", code: "KUGT" },
	{ name: "Karnal", code: "KUN" },
	{ name: "Khurda Road", code: "KUR" },
	{ name: "KHAJURAHO", code: "KURJ" },
	{ name: "Katwa", code: "KWAE" },
	{ name: "Katwa", code: "KWF" },
	{ name: "Kurduwadi", code: "KWV" },
	{ name: "Karimganj", code: "KXJ" },
	{ name: "Kalyani", code: "KYI" },
	{ name: "KAYANKULAM JN", code: "KYJ" },
	{ name: "Kalyan", code: "KYN" },
	{ name: "Kamakhya", code: "KYQ" },
	{ name: "Kazipet", code: "KZJ" },
	{ name: "Sirpur Kagaznagar S", code: "KZR" },
	{ name: "Surathkal S", code: "L" },
	{ name: "Lohardaga", code: "LAD" },
	{ name: "LAKHO", code: "LAK" },
	{ name: "Lalitpur", code: "LAR" },
	{ name: "Londa", code: "LD" },
	{ name: "Ludhiana", code: "LDH" },
	{ name: "Ledo", code: "LEDO" },
	{ name: "Lower Haflong", code: "LFG" },
	{ name: "Lalgarh", code: "LGH" },
	{ name: "Lalgola", code: "LGL" },
	{ name: "Balangir B", code: "LGR" },
	{ name: "Loharu", code: "LHU" },
	{ name: "LUCKNOW JN", code: "LJN" },
	{ name: "Lucknow", code: "LJN/LKO" },
	{ name: "Lal Kuan", code: "LKKA" },
	{ name: "LUCKNOW", code: "LKO" },
	{ name: "Laukaha Bazar", code: "LKQ" },
	{ name: "Lalkuan Jn.", code: "LKU" },
	{ name: "Lumding", code: "LMG" },
	{ name: "LAKHIMPUR", code: "LMP" },
	{ name: "LOHIAN KHAS JN", code: "LNK" },
	{ name: "LINGAMPALLI", code: "LPI" },
	{ name: "LAJPAT NAGAR", code: "LPNR" },
	{ name: "Laksar", code: "LRJ" },
	{ name: "LOKMANYA TILAK TERM", code: "LTT" },
	{ name: "Luni", code: "LUNI" },
	{ name: "Latur", code: "LUR" },
	{ name: "Alnawar Jn.", code: "LWR" },
	{ name: "Maddur", code: "MAD" },
	{ name: "Matheran", code: "MAE" },
	{ name: "Mangalore Jn.", code: "MAJN" },
	{ name: "Manu", code: "MANU" },
	{ name: "Madgaon (Goa)", code: "MAO" },
	{ name: "Mangalore Central", code: "MAQ" },
	{ name: "Chennai Central", code: "MAS" },
	{ name: "Mau", code: "MAU" },
	{ name: "Moradabad", code: "MB" },
	{ name: "Mahoba", code: "MBA" },
	{ name: "Munabao", code: "MBF" },
	{ name: "Madhubani", code: "MBI" },
	{ name: "Shri Mahabirji S", code: "MBJ" },
	{ name: "Mahbubnagar", code: "MBNR" },
	{ name: "Mairabari", code: "MBO" },
	{ name: "MANCHIRYAL", code: "MCI" },
	{ name: "Macherla", code: "MCLA" },
	{ name: "Manendragarh", code: "MDGR" },
	{ name: "Mandar Hill", code: "MDLE" },
	{ name: "Midnapore", code: "MDN" },
	{ name: "Madhupur", code: "MDP" },
	{ name: "Modasa", code: "MDSA" },
	{ name: "Madarihat", code: "MDT" },
	{ name: "Madurai Jn.", code: "MDU" },
	{ name: "MEDCHAL", code: "MED" },
	{ name: "Vanchi Maniyachchi Jn.", code: "MEJ" },
	{ name: "Shimoga Town S", code: "MET" },
	{ name: "Muzaffarpur", code: "MFP" },
	{ name: "Mandla Fort", code: "MFR" },
	{ name: "Munger", code: "MGR" },
	{ name: "Mughalsarai", code: "MGS" },
	{ name: "Manihari", code: "MHI" },
	{ name: "Mhow", code: "MHOW" },
	{ name: "Mahuva", code: "MHV" },
	{ name: "Sitamarhi S", code: "MI" },
	{ name: "Marwar Jn.", code: "MJ" },
	{ name: "Mokama", code: "MKA" },
	{ name: "Maksi ", code: "MKC" },
	{ name: "Motihari", code: "MKI" },
	{ name: "Marikuppam", code: "MKM" },
	{ name: "Manikpur", code: "MKP" },
	{ name: "Moti Koral", code: "MKRL" },
	{ name: "Malda Town", code: "MLDT" },
	{ name: "Mailani ", code: "MLN" },
	{ name: "Malsar", code: "MLSR" },
	{ name: "Manmad", code: "MMR" },
	{ name: "Mansi", code: "MNE" },
	{ name: "Muniguda", code: "MNGD" },
	{ name: "Somnath S", code: "MNH" },
	{ name: "Manamadurai ", code: "MNM" },
	{ name: "Moga", code: "MOF" },
	{ name: "Muzaffar Nagar", code: "MOZ" },
	{ name: "Lakhimpur L", code: "MP" },
	{ name: "Mannargudi", code: "MQ" },
	{ name: "Miryalaguda", code: "MRGA" },
	{ name: "Murliganj", code: "MRIJ" },
	{ name: "Miraj", code: "MRJ" },
	{ name: "MARKAPUR ROAD", code: "MRK" },
	{ name: "Chennai Egmore", code: "MS" },
	{ name: "CHENNAI BEACH", code: "MSB" },
	{ name: "Mahesana", code: "MSH" },
	{ name: "Mahasamund", code: "MSMD" },
	{ name: "Meerut City", code: "MTC" },
	{ name: "Merta Road", code: "MTD" },
	{ name: "METUR DAM", code: "MTDM" },
	{ name: "Mathura", code: "MTJ" },
	{ name: "Machilipatnam", code: "MTM" },
	{ name: "Mettupalayam", code: "MTP" },
	{ name: "Mudkhed", code: "MUE" },
	{ name: "Manuguru", code: "MUGR" },
	{ name: "Mankapur", code: "MUR" },
	{ name: "Muri ", code: "MURI" },
	{ name: "MEERUT CANT", code: "MUT" },
	{ name: "Manduadih", code: "MUV" },
	{ name: "Mayiladuturai Jn.", code: "MV" },
	{ name: "MAVLI JN", code: "MVJ" },
	{ name: "Mariani", code: "MXN" },
	{ name: "MIYAGAM KARJAN", code: "MYG" },
	{ name: "MIYAGAM KARJAN JUNCTION NG", code: "MYGL" },
	{ name: "MEMARI", code: "MYM" },
	{ name: "Mysore", code: "MYS" },
	{ name: "MIRZAPALI", code: "MZL" },
	{ name: "Mirzapur", code: "MZP" },
	{ name: "Murtajapur", code: "MZR" },
	{ name: "Murkeongselek", code: "MZS" },
	{ name: "Sultanpur Sl", code: "N" },
	{ name: "Nagbhir", code: "NAB" },
	{ name: "Nagda", code: "NAD" },
	{ name: "Najibabad", code: "NBD" },
	{ name: "New Bongaigaon", code: "NBQ" },
	{ name: "New Coochbehar", code: "NCB" },
	{ name: "Nagercoil Jn.", code: "NCJ" },
	{ name: "Nagore", code: "NCR" },
	{ name: "Nadiad", code: "ND" },
	{ name: "Nabadwipdham ", code: "NDAE" },
	{ name: "Nandurbar", code: "NDB" },
	{ name: "Nidadavolu", code: "NDD" },
	{ name: "Nadikudi", code: "NDKD" },
	{ name: "Nandyal", code: "NDL" },
	{ name: "New Delhi", code: "NDLS" },
	{ name: "Nidubrolu", code: "NDO" },
	{ name: "Hazur Sahib Nanded", code: "NED" },
	{ name: "New Farakka", code: "NFK" },
	{ name: "Nandgaon", code: "NGN" },
	{ name: "Nagpur", code: "NGP" },
	{ name: "Nagappatt Inam", code: "NGT" },
	{ name: "NANDOL DEHEGAM", code: "NHM" },
	{ name: "Nilambur Road", code: "NIL" },
	{ name: "Nainpur", code: "NIR" },
	{ name: "New Jalpaiguri", code: "NJP" },
	{ name: "Nasik Road", code: "NK" },
	{ name: "Nagarkata", code: "NKB" },
	{ name: "Narkatiaganj", code: "NKE" },
	{ name: "Lonavla L", code: "NL" },
	{ name: "Nalanda", code: "NLD" },
	{ name: "Nalgonda", code: "NLDA" },
	{ name: "Nangal Dam", code: "NLDM" },
	{ name: "North Lakhimpur", code: "NLP" },
	{ name: "Nellore", code: "NLR" },
	{ name: "Nirmali", code: "NMA" },
	{ name: "Nimach", code: "NMH" },
	{ name: "Nidamangalam", code: "NMJ" },
	{ name: "Namkhana", code: "NMKA" },
	{ name: "Namakkal", code: "NMKL" },
	{ name: "Newmal Jn.", code: "NMZ" },
	{ name: "Noli", code: "NOLI" },
	{ name: "New Alipurduar", code: "NOQ" },
	{ name: "Nepalganj Road", code: "NPR" },
	{ name: "Nandalur", code: "NRE" },
	{ name: "NERAL", code: "NRL" },
	{ name: "Narwana", code: "NRW" },
	{ name: "Narasapur", code: "NS" },
	{ name: "Nagarsol ", code: "NSL" },
	{ name: "New Tinsukia", code: "NTSK" },
	{ name: "Nautanwa", code: "NTV" },
	{ name: "NANJANGUD TOWN", code: "NTW" },
	{ name: "Narsingpur", code: "NU" },
	{ name: "Naugarh", code: "NUH" },
	{ name: "Nizamabad", code: "NZB" },
	{ name: "Nizamuddin", code: "NZM" },
	{ name: "Samalkot Sl", code: "O" },
	{ name: "Sojat Road S", code: "OD" },
	{ name: "Odlabari", code: "ODB" },
	{ name: "Ongole", code: "OGL" },
	{ name: "Sihor Gujarat S", code: "OJN" },
	{ name: "Okha", code: "OKHA" },
	{ name: "Unnao", code: "ON" },
	{ name: "Boinda B", code: "ONA" },
	{ name: "Coonoor", code: "ONR" },
	{ name: "Orai", code: "ORAI" },
	{ name: "Purna", code: "PAU" },
	{ name: "Pilibhit", code: "PBE" },
	{ name: "Pratapgarh", code: "PBH" },
	{ name: "Parbhani", code: "PBN" },
	{ name: "Porbandar", code: "PBR" },
	{ name: "Pachora", code: "PC" },
	{ name: "Pudukottai", code: "PDKT" },
	{ name: "Puducherry", code: "PDY" },
	{ name: "Phaphamau", code: "PFM" },
	{ name: "PRAGATI MAIDAN", code: "PGMD" },
	{ name: "Palakkad", code: "PGT" },
	{ name: "Palakkad Town", code: "PGTN" },
	{ name: "Palitana", code: "PIT" },
	{ name: "PANSKURA", code: "PKU" },
	{ name: "Phalodi", code: "PLC" },
	{ name: "Paliakalan", code: "PLK" },
	{ name: "Palani", code: "PLNI" },
	{ name: "Pulgaon", code: "PLO" },
	{ name: "Patna", code: "PNBE" },
	{ name: "Patna Sahib", code: "PNC" },
	{ name: "Parasnath", code: "PNME" },
	{ name: "Panipat", code: "PNP" },
	{ name: "Palanpur", code: "PNU" },
	{ name: "Panvel", code: "PNVL" },
	{ name: "PONNERI", code: "PON" },
	{ name: "POLLACHI JN", code: "POY" },
	{ name: "Puranpur", code: "PP" },
	{ name: "Pipariya", code: "PPI" },
	{ name: "PIPAR ROAD JN", code: "PPR" },
	{ name: "PATLIPUTRA", code: "PPTA" },
	{ name: "Sri Sathyasai", code: "PRASHANTI" },
	{ name: "Paradeep", code: "PRDP" },
	{ name: "Pattabiram East", code: "PRES" },
	{ name: "PRAYAG", code: "PRG" },
	{ name: "PARLI VAIJNATH", code: "PRLI" },
	{ name: "Purnia", code: "PRNA" },
	{ name: "Purulia", code: "PRR" },
	{ name: "Pratapnagar", code: "PRTN" },
	{ name: "Palasa", code: "PSA" },
	{ name: "Patiala", code: "PTA" },
	{ name: "Podanur", code: "PTJ" },
	{ name: "Pathankot", code: "PTK" },
	{ name: "PATAN", code: "PTN" },
	{ name: "Pune Jn.", code: "PUNE" },
	{ name: "Puri", code: "PURI" },
	{ name: "Punalur", code: "PUU" },
	{ name: "PALLAVARAM", code: "PV" },
	{ name: "Parvatipuram Town", code: "PVPT" },
	{ name: "Pandharapur", code: "PVR" },
	{ name: "PALWAL", code: "PWL" },
	{ name: "KULEM", code: "QLM" },
	{ name: "Llam", code: "QLN" },
	{ name: "Raipur", code: "R" },
	{ name: "Repalle", code: "RAL" },
	{ name: "Rae Bareli", code: "RBL" },
	{ name: "Raichur", code: "RC" },
	{ name: "Ramagundam", code: "RDM" },
	{ name: "Radhikapur", code: "RDP" },
	{ name: "Rewari ", code: "RE" },
	{ name: "Rewa", code: "REWA" },
	{ name: "Sagar Jambagaru S", code: "RF" },
	{ name: "Rajgir", code: "RGD" },
	{ name: "Rayagada", code: "RGDA" },
	{ name: "Ringus", code: "RGS" },
	{ name: "Rathopur", code: "RGV" },
	{ name: "Ranaghat", code: "RHA" },
	{ name: "Raigarh", code: "RIG" },
	{ name: "Rajim", code: "RIM" },
	{ name: "Rawatganj ", code: "RJ" },
	{ name: "Raja-Ka-Sahaspur", code: "RJK" },
	{ name: "Rajmahal", code: "RJL" },
	{ name: "Rajnandgaon", code: "RJN" },
	{ name: "RAJENDRA NAGAR BIHAR", code: "RJPB" },
	{ name: "Rajendranagar", code: "RJQ" },
	{ name: "Rajkot", code: "RJT" },
	{ name: "Rajula City", code: "RJU" },
	{ name: "Rajahmundry", code: "RJY" },
	{ name: "Roorkee", code: "RK" },
	{ name: "Raja-Ki-Mandi", code: "RKM" },
	{ name: "RISHIKESH", code: "RKSH" },
	{ name: "Ramanathapuram", code: "RMD" },
	{ name: "Rameswaram", code: "RMM" },
	{ name: "Ramakona", code: "RMO" },
	{ name: "Ramnagar", code: "RMR" },
	{ name: "Rampur Jn.", code: "RMU" },
	{ name: "Ratnagiri ", code: "RN" },
	{ name: "Ranchi", code: "RNC" },
	{ name: "Rangiya", code: "RNY" },
	{ name: "Raninagar", code: "ROJ" },
	{ name: "Rohtak", code: "ROK" },
	{ name: "Rourkela", code: "ROU" },
	{ name: "ROZA JN", code: "ROZA" },
	{ name: "Rangapara North", code: "RPAN" },
	{ name: "Rampurhat", code: "RPH" },
	{ name: "Rajpura", code: "RPJ" },
	{ name: "RAGHUNATHPUR", code: "RPR" },
	{ name: "Shoranur Jn. S", code: "RR" },
	{ name: "Birur", code: "RRB" },
	{ name: "Raghuraj Singh", code: "RRS" },
	{ name: "Ratangarh", code: "RTGH" },
	{ name: "Ramtek", code: "RTK" },
	{ name: "Ratlam", code: "RTM" },
	{ name: "Renigunta", code: "RU" },
	{ name: "RANUJ", code: "RUJ" },
	{ name: "Runnisaidpur", code: "RUSD" },
	{ name: "Raxaul", code: "RXL" },
	{ name: "Salem", code: "SA" },
	{ name: "Sattenapalle", code: "SAP" },
	{ name: "Bangalore City", code: "SBC" },
	{ name: "Sahibganj ", code: "SBG" },
	{ name: "Subrahmanya Road", code: "SBHR" },
	{ name: "SABARMATI JN", code: "SBI" },
	{ name: "Sabalgarh", code: "SBL" },
	{ name: "Sambalpur", code: "SBP" },
	{ name: "Sambalpur City", code: "SBPY" },
	{ name: "Secunderabad", code: "SC" },
	{ name: "SITAPUR CANT", code: "SCC" },
	{ name: "Sensoa", code: "SCF" },
	{ name: "SILCHAR", code: "SCL" },
	{ name: "Sengottai ", code: "SCT" },
	{ name: "Sealdah", code: "SDAH" },
	{ name: "Shahabad", code: "SDB" },
	{ name: "SHAHDOL", code: "SDL" },
	{ name: "Sadulpur", code: "SDLP" },
	{ name: "Sonpur", code: "SEE" },
	{ name: "Sewagram", code: "SEGM" },
	{ name: "Sagauli ", code: "SGL" },
	{ name: "Sriganga Nagar", code: "SGNR" },
	{ name: "SAUGOR", code: "SGO" },
	{ name: "Singrauli", code: "SGRL" },
	{ name: "Siliguri", code: "SGUJ" },
	{ name: "Shamgarh", code: "SGZ" },
	{ name: "Saharsa", code: "SHC" },
	{ name: "Shahganj ", code: "SHG" },
	{ name: "Shalimar", code: "SHM" },
	{ name: "Sambhal Hatim Sarai", code: "SHTS" },
	{ name: "Silghat Town", code: "SHTT" },
	{ name: "Sikar", code: "SIKR" },
	{ name: "Singur", code: "SIU" },
	{ name: "Shikohabad", code: "SKB" },
	{ name: "SAKRI JN", code: "SKI" },
	{ name: "Shaktinagar", code: "SKTN" },
	{ name: "SIRPUR KAGAZNAGAR", code: "SKZR" },
	{ name: "Simaluguri", code: "SLGR" },
	{ name: "Sangli", code: "SLI" },
	{ name: "SULTANPUR", code: "SLN" },
	{ name: "Shimoga Town", code: "SMET" },
	{ name: "SITAMARHI JN", code: "SMI" },
	{ name: "Shimla", code: "SML" },
	{ name: "SOMNATH", code: "SMNH" },
	{ name: "Shamli", code: "SMQL" },
	{ name: "Samdari ", code: "SMR" },
	{ name: "Sindri Town", code: "SNDT" },
	{ name: "Sai Nagar Shirdi ", code: "SNSI" },
	{ name: "Sheopur Kalan", code: "SOE" },
	{ name: "Suratgarh", code: "SOG" },
	{ name: "Solan", code: "SOL" },
	{ name: "Sitapur City S", code: "SPC" },
	{ name: "SULLURUPETA", code: "SPE" },
	{ name: "Samastipur", code: "SPJ" },
	{ name: "SHAHJEHANPUR", code: "SPN" },
	{ name: "Shahpur Patoree", code: "SPP" },
	{ name: "Sonarpur Junction", code: "SPR" },
	{ name: "Sompeta", code: "SPT" },
	{ name: "Santragachi", code: "SRC" },
	{ name: "Sardarshahr", code: "SRDR" },
	{ name: "Saharanpur S", code: "SRE" },
	{ name: "Sirmuttra", code: "SRMT" },
	{ name: "Sarnath", code: "SRNT" },
	{ name: "SHORANUR JN", code: "SRR" },
	{ name: "Salempur S", code: "SRU" },
	{ name: "SIRPUR TOWN", code: "SRUR" },
	{ name: "Sirsa", code: "SSA" },
	{ name: "SHAKURBASTI", code: "SSB" },
	{ name: "SASARAM", code: "SSM" },
	{ name: "Nilayam Ss", code: "SSPN" },
	{ name: "Surat", code: "ST" },
	{ name: "Satna", code: "STA" },
	{ name: "Shantipur", code: "STB" },
	{ name: "ST THOMAS MOUNT", code: "STM" },
	{ name: "SITARAMPUR", code: "STN" },
	{ name: "SITAPUR", code: "STP" },
	{ name: "Satara", code: "STR" },
	{ name: "Neta Ji", code: "SUBHASH" },
	{ name: "Sujangarh", code: "SUJH" },
	{ name: "Surendra Nagar", code: "SUNR" },
	{ name: "Solapur", code: "SUR" },
	{ name: "Siuri", code: "SURI" },
	{ name: "Siwan", code: "SV" },
	{ name: "S M V D KATRA", code: "SVDK" },
	{ name: "Shivpuri", code: "SVPI" },
	{ name: "Sawaimadhopur", code: "SWM" },
	{ name: "SAWANTWADI ROAD", code: "SWV" },
	{ name: "Shahjahanpur S", code: "SZP" },
	{ name: "Jorhat J", code: "T" },
	{ name: "Tarakeswar", code: "TAK" },
	{ name: "Tatanagar", code: "TATA" },
	{ name: "Telibandha", code: "TBD" },
	{ name: "Tambaram", code: "TBM" },
	{ name: "Tiruchendur", code: "TCN" },
	{ name: "Thrisur", code: "TCR" },
	{ name: "Tadepalligudem", code: "TDD" },
	{ name: "Tundla", code: "TDL" },
	{ name: "TANDUR", code: "TDU" },
	{ name: "Tenali", code: "TEL" },
	{ name: "Tirunelveli", code: "TEN" },
	{ name: "Thawe Junction", code: "THE" },
	{ name: "Titlagarh", code: "TIG" },
	{ name: "Tirur", code: "TIR" },
	{ name: "Thanjavur", code: "TJ" },
	{ name: "TUMKUR", code: "TK" },
	{ name: "TILAK BRIDGE", code: "TKJ" },
	{ name: "Talguppa", code: "TLGP" },
	{ name: "TALCHER", code: "TLHR" },
	{ name: "Thalassery", code: "TLY" },
	{ name: "TIRUMALPUR", code: "TMLP" },
	{ name: "Tumsar Road", code: "TMR" },
	{ name: "Tuticorin", code: "TN" },
	{ name: "Tiruvannama Lai", code: "TNM" },
	{ name: "TINPAHAR JN", code: "TPH" },
	{ name: "Tiruchchirappalli Jn.", code: "TPJ" },
	{ name: "Tantpur", code: "TPO" },
	{ name: "TIRUPATTUR JN", code: "TPT" },
	{ name: "Tirupati", code: "TPTY" },
	{ name: "Tanakpur", code: "TPU" },
	{ name: "TIKUNIA", code: "TQN" },
	{ name: "Tirodi", code: "TRDI" },
	{ name: "Tarighat", code: "TRG" },
	{ name: "TIRUVALLUR", code: "TRL" },
	{ name: "TIRUTTANI", code: "TRT" },
	{ name: "Tenkasi", code: "TS" },
	{ name: "Tinsukia Junction", code: "TSK" },
	{ name: "Lokmanya Tilak (T) L", code: "TT" },
	{ name: "Tiruturaipudi Junction", code: "TTP" },
	{ name: "Tuni", code: "TUNI" },
	{ name: "Tiruppur", code: "TUP" },
	{ name: "Thiruvananthapuram", code: "TVC" },
	{ name: "Thiruvarur.", code: "TVR" },
	{ name: "Tezpur", code: "TZTB" },
	{ name: "Udagamandalam", code: "UAM" },
	{ name: "Hubli", code: "UBL" },
	{ name: "Unchahar", code: "UCR" },
	{ name: "Udupi", code: "UD" },
	{ name: "ANDAL JN", code: "UDL" },
	{ name: "Udhna", code: "UDN" },
	{ name: "Udaipur City", code: "UDZ" },
	{ name: "Una Himachal", code: "UHL" },
	{ name: "Udhampur", code: "UHP" },
	{ name: "Ujjain", code: "UJN" },
	{ name: "Ambala Cantt.", code: "UMB" },
	{ name: "Umarpada", code: "UMPD" },
	{ name: "Una", code: "UNA" },
	{ name: "Varkala", code: "VAK" },
	{ name: "VAPI", code: "VAPI" },
	{ name: "Bobbili", code: "VBL" },
	{ name: "VERNA", code: "VEN" },
	{ name: "Viramgam", code: "VG" },
	{ name: "VIKARABAD JN", code: "VKB" },
	{ name: "Velachery", code: "VLCY" },
	{ name: "Velankanni", code: "VLKN" },
	{ name: "VELANKANNI", code: "VLNK" },
	{ name: "VELLORE CANT", code: "VLR" },
	{ name: "Villupuram Jn.", code: "VM" },
	{ name: "Virudunagar Jn.", code: "VPT" },
	{ name: "VIRAR", code: "VR" },
	{ name: "Vriddhachalam Jn.", code: "VRI" },
	{ name: "Veraval", code: "VRL" },
	{ name: "Vasco-Da-Gama", code: "VSG" },
	{ name: "Visakhapatnam", code: "VSKP" },
	{ name: "Vadtal Swaminarayan", code: "VTL" },
	{ name: "Vizianagaram", code: "VZM" },
	{ name: "Wadi", code: "WADI" },
	{ name: "Waghai", code: "WGI" },
	{ name: "Wankaner", code: "WKR" },
	{ name: "Warangal ", code: "WL" },
	{ name: "Wardha Jn.", code: "WR" },
	{ name: "Wadsa", code: "WSA" },
	{ name: "Sawantwadi Road S", code: "WV" },
	{ name: "Yesvantpur", code: "YPR" },
	{ name: "Yavatmal", code: "YTL" },
	{ name: "Zafarabad", code: "ZBD" },
];
