import "./holidays.css";
import Navbar from "../../components/navbar/Navbar";
import Header from "../../components/header/Header";
import MailList from "../../components/mailList/MailList";
import Footer from "../../components/footer/Footer";
import ImageSlider from '../../components/imageSlider/ImageSlider';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import useFetch from "../../hooks/useFetch";
import { useLocation, Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const Holidays = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];

  const [slideNumber, setSlideNumber] = useState(0);
  const [holidayText, setHolidayText] = useState(1);
  const [open, setOpen] = useState(false);

  console.log(id);
  const { data, loading } = useFetch(`/holidays/find/${id}`);
  console.log(data);
  const { setAuthModalOpened, currentUser, logOut } = useAuth();
  


  const handleOpen = (i) => {
    setSlideNumber(i);
    setOpen(true);
  };

  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber = slideNumber === 0 ? 5 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === 5 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber)
  };

  return (
    <div>
      <Navbar />
      <Header type="list" />
      {loading ? (
        "loading"
      ) : (
        <div className="holidaysContainer">
          {open && (
            <div className="slider">
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="close"
                onClick={() => setOpen(false)}
              />
              <FontAwesomeIcon
                icon={faCircleArrowLeft}
                className="arrow"
                onClick={() => handleMove("l")}
              />
              <div className="sliderWrapper">
                <img src={"/destinations/"+data.photos[slideNumber]} alt="" className="sliderImg" />
              </div>
              <FontAwesomeIcon
                icon={faCircleArrowRight}
                className="arrow"
                onClick={() => handleMove("r")}
              />
            </div>
          )}
          
          <div className="holidaysWrapper">  
            <h1 className="holidaysTitle">{data.name}</h1>
            <div className="holidaysImages">
              <ImageSlider images={data.photos} />
            </div>
          <div className="holidaysDetails">
            <div className="holidaysDetailsTexts">
              <h1 className="holidaysSubTitle">Tour Itinerary</h1>
              <p className="holidaysDesc">
                  {data.desc && data.desc.split(/\s+(?=Day\d+:)/).map((dayContent, index) => (
                    <div key={index}>
                      <h3>{dayContent.substring(0, dayContent.indexOf(':') + 1)}</h3>
                      <p>{dayContent.substring(dayContent.indexOf(':') + 1)}</p>
                    </div>
                  ))}
              </p>
            </div>
            
           <div className="holidaysDetailsPrice">
              <h1>Perfect for a {data.duration} stay!</h1>
              <span>
                {data.subtitle}
              </span>
              <h1>Cities Covered:</h1>
                <div style={{ display: 'flex' }}>
                    {data.tocity && data.tocity.map((element, index) => (
                      <div key={index} style={{ marginRight: '5px' }}>{element},</div>
                    ))}
                </div>
              <h1>Price:</h1>
              <span>₹ {data.price}</span>
                  
              


              <h1>
                  <b>Package Provided By:</b>
              </h1>
              <span>{data.packageProvidedBy}</span>
              
              

              {currentUser ? (
                  <Link to={data.link}>
                      <button className="siCheckButton">Reserve or Book Now!</button>
                  </Link>
              ):(
                  <button className="siCheckButton" onClick={() => setAuthModalOpened(true)}>Reserve or Book Now!</button>
                  
              )}

            </div>
          </div>
        </div>
        <MailList />
        <br></br>
        <Footer />
      </div>
      )}
    </div>
  );
};

export default Holidays;
