import "./list.css";
import useFetch from "../../hooks/useFetch";
import Navbar from "../../components/navbar/Navbar";
import Header from "../../components/header/Header";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { format } from "date-fns";
import { DateRange } from "react-date-range";
import SearchItem from "../../components/searchItem/SearchItem";

const ListHolidaysByRegion = () => {
  const location = useLocation();
  const query = location.pathname.split("/").slice(-1)[0];
  console.log(query)

  const regionCategory = ["east","west","north","south","north-east"];

  const { data, loading, error, reFetch } = useFetch("/holidays?region="+query);
  const handleClick = () => {
    reFetch();
  };

  return (
    <div>
     <Navbar />
      <Header type="list" />
      <div className="listContainer">
        <div className="listWrapper">
          
          <div className="listResult">
            {loading ? (
              "loading"
            ) : (
              <>
                {data.map((item) => (
                  <SearchItem item={item} key={item._id} />
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListHolidaysByRegion;
