import "./travel.css";
import Navbar from "../../components/navbar/Navbar";
import Header from "../../components/header/Header";
import MailList from "../../components/mailList/MailList";
import Footer from "../../components/footer/Footer";
import { useState } from "react";

import {
  Group,
  Select,
  Paper,
  Button,
  Center,
  Collapse,
} from "@mantine/core";

import {
  IoPinOutline,
  IoLocationOutline,
  IoTrainOutline,
  IoBusOutline,
  IoAirplaneOutline
} from "react-icons/io5";

import { useForm } from "@mantine/hooks";
import { DatePicker } from "@mantine/dates";
import { BsCalendar4Event } from "react-icons/bs";

import { stations } from "../../config/stations";
import { busStops } from "../../config/busStops";
import { cities } from "../../config/cities";

import SearchResult from "./SearchResult";

import dayjs from "dayjs";
import axios from "axios";

import { useEffect } from "react"

const headerStyle = {
    color: 'white',
    textAlign: 'center',
    };

const Travel = () => {

  const form = useForm({
    initialValues: {
      onewayOrRound: "one-way",
      trainClass: "all",
      fromStation: "",
      toStation: "",
      startDate: new Date(),
      endDate: new Date(dayjs(new Date()).add(1, "day")),
    },

    validationRules: {
      onewayOrRound: (value) => value === "one-way" || value === "two-way",
      adultPassenger: (value) => value > 0,
      childPassenger: (value) => value >= 0,
      trainClass: (value) =>
        value === "sl" ||
        value === "3a" ||
        value === "2a" ||
        value === "1a" ||
        value === "all",
      fromStation: (value) => value !== "",
      toStation: (value) => value !== "",
    },
  });

  const state = {
      button: 1
    };

  const [result, setResult] = useState();
  const [loading, setLoading] = useState();

  const stationData = stations.map((station, idx) => ({
    value: `${station.code}`,
    label: `${station.name} - ${station.code}`,
  }));


  const busStopData = busStops.map((busstop, idx) => ({
    value: `${busstop.Id}`,
    label: `${busstop.name} - ${busstop.Id}`,
  }));


  const cityData = cities
    .map((city, idx) => city.name) // Extract city names
    .filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates
    .sort() // Sort alphabetically
    .map((cityName) => ({
        value: cityName,
        label: cityName,
    }));

    useEffect(() => {
    window.scrollTo(0,0)
  },[])
  
  return (
    <div>
      <Navbar />
      <Header type="list" />
        <div className="homeContainer">
        
      <h1 style={headerStyle}>Compare Train,Bus and Flight Prices From Anywhere to Anywhere</h1>

        <form
          onSubmit={form.onSubmit(async (values) => {
            setLoading(true);
            if (state.button === 1) {
              console.log(stationData)
              var fromStationCode = ""
              var toStationCode = ""

              var srcCity = ""
              var destCity= ""
              for (const item of stationData) {
                //console.log(values.fromStation.split(':')[0])
                //console.log(item.label.split('-')[0].replace(/"/g,''))
                if(item.label.split('-')[0].replace(/"/g,'').includes(values.fromStation.split(':')[0]))
                {
                  fromStationCode = item.label.split('-')[1].replace(/"/g,'')
                  srcCity = values.fromStation.split(':')[0] 
                  //const sourceCity = values.fromStation.split(':')[0]
                  //console.log("fromStationCode" + fromStationCode)
                  //fromStationCode = "BNC"
                  //console.log("fromStationCode" + fromStationCode)

                }

                if(item.label.split('-')[0].replace(/"/g,'').includes(values.toStation.split(':')[0]))
                {
                  toStationCode = item.label.split('-')[1].replace(/"/g,'')
                  destCity = values.toStation.split(':')[0]
                  //const destCity = values.toStation.split(':')[0]
                  //console.log("toStationCode" + toStationCode)

                }
              }

              const url = "https://www.railyatri.in/trains-between-stations-v2?from_code=" + fromStationCode + "&from_name=" + fromStationCode + "&journey_date=" + values.startDate + "&src=ttb_landing&to_code=" + toStationCode + "&to_name=" + toStationCode;
              console.log(url)
              const trains = [];
              axios.get(url)
                  .then(response => {
                    const data = response.data;
                    
                    // initializing substrings
                    const sub1 = "var tbs_data =";
                    const sub2 = "classWiseData(tbs_data, boarding_date);";

                    // getting index of substrings
                    const idx1 = data.indexOf(sub1);
                    const idx2 = data.indexOf(sub2);

                    let res = '';
                    // getting elements in between
                    for (let idx = idx1 + sub1.length + 1; idx < idx2; idx++) {
                        res = res + data[idx];
                    }

                    let result = res.replace(/'/g,'');
                    result = result.replace(/;/g,'');

                    const finalres = result.split('train_number');
                    finalres.shift();

                    //console.log(finalres)

                    for (const line of finalres) {
                        const items = line.split(',');
                        const train = items[0].split('"=>"');
                        const train_number = train[1].replace(/"/g,'');
                        items.shift();
                        const train_dict = {
                            train_number: train_number,
                            showOnMap: true,
                            citySrc: srcCity,
                            cityDest: destCity
                        };

                        //console.log("test")

                        for (const item of items) {
                            if (item.includes('train_name') && item.includes("extended_train_name")) {
                                const train_name = item.split('"=>"')[1].replace(/"/g,'');
                                train_dict.train_name = train_name;
                            } else if (item.includes('from_station_name')) {
                                const train_source = item.split('"=>"')[1].replace(/"/g,'');
                                train_dict.train_source = train_source;
                            } else if (item.includes('to_station_name')) {
                                const train_destination = item.split('"=>"')[1].replace(/"/g,'');
                                train_dict.train_destination = train_destination;
                            } else if (item.includes('from_std')) {
                                const from_std = item.split('"=>"')[1].replace(/"/g,'');
                                train_dict.from_std = fromStationCode;
                            } else if (item.includes('to_std')) {
                                const to_std = item.split('"=>"')[1].replace(/"/g,'');
                                train_dict.to_std = toStationCode;
                            } else if (item.includes('from') && !item.includes('valid_from') && !item.includes('from_std') && !item.includes('from_sta') && !item.includes('local_train_from_sta') && !item.includes('from_day') && !item.includes('from_on_time_rating') && !item.includes('from_si_no') && !item.includes('from_station_name')) {
                                const from = item.split('"=>"')[1].replace(/"/g,'');
                                train_dict.from = from;
                            } 

                            else if (item.includes('to') && !item.includes('valid_to') && !item.includes('to_sta') && !item.includes('to_std') && !item.includes('to_day') && !item.includes('to_on_time_rating') && !item.includes('to_si_no') && !item.includes('to_station_name')) {
                                const to = item.split('"=>"')[1].replace(/"/g,'');
                                train_dict.to = to;
                            }

                            else if (item.includes('duration') && !item.includes('duration_rating') && !item.includes('score_duration')) {
                                const duration = item.split('"=>"')[1].replace(/"/g,'');
                                train_dict.duration = duration;
                            } else if (item.includes('train_date')) {
                                const train_date = item.split('"=>"')[1].replace(/"/g,'').replace(/}/g,'').replace(/]/g,'').replace(/\n/g,'').replace(/ /g,'');
                                train_dict.train_date = train_date;
                            }
                        }
                        //console.log("test1")
                        trains.push(train_dict);
                        
                      }
                  console.log(trains)
                setResult(trains);
                })
                .catch(error => {
                console.log(trains)
                setResult(trains);
                  });
                setLoading(false);
            }
            if (state.button === 2) {
              //console.log("*************8")
              var fromStationCode = ""
              var toStationCode = ""
              //console.log(busStopData)
              // Parse the input date string
                const parsedDate = new Date(values.startDate);

                parsedDate.setDate(parsedDate.getDate() + 1);
    
                // Format the parsed date as "YYYY-MM-DD"
                const formattedDate = parsedDate.toISOString().split('T')[0];
              
              for (const item of busStopData) {
                //console.log(values.fromStation.split(':')[0])
                
                if(item.label.split('-')[0].replace(/"/g,'').trim().toLowerCase() === values.fromStation.split(':')[0].trim().toLowerCase())
                //if(item.label.split('-')[0].replace(/"/g,'').includes(values.fromStation.split(':')[0]))
                {
                  fromStationCode = item.label.split('-')[1].replace(/"/g,'')
                  //console.log("fromStationCode" + fromStationCode)
                  //fromStationCode = "BNC"
                  //console.log("fromStationCode" + fromStationCode)

                }

                if(item.label.split('-')[0].replace(/"/g,'').trim().toLowerCase() === values.toStation.split(':')[0].trim().toLowerCase())
                //if(item.label.split('-')[0].replace(/"/g,'').includes(values.toStation.split(':')[0]))
                {
                  toStationCode = item.label.split('-')[1].replace(/"/g,'')
                  //console.log("toStationCode" + toStationCode)

                }
              }
              const busUrl = 'https://www.abhibus.com/getonewayservices/' + formattedDate + '/' + fromStationCode.trim() + '/' + toStationCode.trim();
              //console.log(url)
              const headers = {
                'Access-Control-Allow-Origin' : '*',
                "Access-Control-Request-Headers": 'Content-Type, Authorization'
              };

              //axios.get(url, { headers })
              console.log(busUrl)

              //axios.get(busUrl)
              axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
              const proxy = "https://cors-anywhere.herokuapp.com/";
              axios.get(proxy + busUrl)
                .then((response) => {
                console.log(response.data)
                  const res = response.data;
                  const buses = [];

                  for (const key in res) {
                    if (key === 'serviceDetailsList') {
                      for (const i of res[key]) {
                        const bus_dict = {
                          source: res.source,
                          destination: res.destination,
                          formated_date: res.formated_date,
                          bus_name: i.busTypeName,
                          bus_agent: i.travelerAgentName,
                          startTime: i.startTime,
                          arriveTime: i.arriveTime,
                          travelTime: i.travelTime,
                        };
                        buses.push(bus_dict);
                      }
                    }
                  }

                  console.log(buses)
                  {/*buses.forEach((bus) => {
                    
                  });*/}
                })
                .catch((error) => {
                  
                });
            }
            setLoading(false);
          })}
        >

        
          <Paper
            p="xl"
            withBorder
            sx={(theme) => ({
              borderRadius: theme.radius.md,
              boxShadow: theme.shadows.lg,
              backgroundColor: 'white',
            })}
          >
            
            <Group
              align="center"
              sx={(theme) => ({
                justifyContent: "space-around",
              })}
            >
              <Select
                size="md"
                icon={<IoPinOutline />}
                placeholder="From where?"
                searchable
                nothingFound="No options"
                maxDropdownHeight={350}
                data={cityData}
                {...form.getInputProps("fromStation")}
                limit={50}
              />
              <Select
                size="md"
                icon={<IoLocationOutline />}
                placeholder="To where?"
                searchable
                nothingFound="No options"
                maxDropdownHeight={280}
                data={cityData}
                {...form.getInputProps("toStation")}
                limit={50}
              />

              <DatePicker
                  clearable={true}
                  size="md"
                  inputFormat="MMM D, YYYY"
                  icon={<BsCalendar4Event />}
                  minDate={new Date()}
                  {...form.getInputProps("startDate")}
              />
              

              
            </Group>
            <Center m={14}>
              <Button leftIcon={<IoTrainOutline fontSize={24} />} type="submit" onClick={() => (state.button = 1)}>
                Train
              </Button>
              <pre>  </pre>
              <Button leftIcon={<IoBusOutline fontSize={24} />} type="submit" onClick={() => (state.button = 2)}>
                Bus (Coming Soon)
              </Button>
              <pre>  </pre>
              <Button leftIcon={<IoAirplaneOutline fontSize={24} />} type="submit" onClick={() => (state.button = 3)}>
                Flight (Coming Soon)
              </Button>
              {/*<pre>  </pre>
              <Button leftIcon={<IoCarOutline fontSize={24} />} type="submit" onClick={() => (state.button = 4)}>
                Cab
              </Button>*/}

            </Center>
            

            <Collapse in={result}>
              {<SearchResult
                  number = {form.values.train_number}
                  from = {form.values.from}
                  to = {form.values.to}
                  fromStation={form.values.fromStation}
                  toStation={form.values.toStation}
                  startDate={form.values.startDate}
                  from_std = {form.values.from_std}
                  to_std = {form.values.to_std}
                  result={result}
                  setResult={setResult}
                  loading={loading}
                />
              }
            </Collapse>
          </Paper>

          
        </form>
      
          
          <br></br>
          <MailList/>
        <Footer/>
        </div>
    </div>
  );
};

export default Travel;
