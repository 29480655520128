import React from "react";
import {
	Title,
	Text,
	Button,
	createStyles,
} from "@mantine/core";
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
	highlight: {
		color: "black",
	},
	control: {
		height: 42,
		fontSize: theme.fontSizes.md,
		backgroundColor: "black",

		"&:not(:first-of-type)": {
			marginLeft: theme.spacing.md,
		},

		"@media (max-width: 520px)": {
			"&:not(:first-of-type)": {
				marginTop: theme.spacing.md,
				marginLeft: 0,
			},
		},
	},
}));

const BookNowComponent = () => {
	const { classes, cx } = useStyles();
	const navigate = useNavigate();

	return (
	    <div className="mail">
	      <Title className="mailTitle">
					Compares {" "}
					<Text component="span" inherit className={classes.highlight}>
						Flight, Train, Bus {" "}
					</Text>
					Prices
					
				</Title>
	      <span className="mailDesc">Book ticket from any device, any time.</span>
	      <Button
				radius="xl"
				size="md"
				className={classes.control}
				onClick={() => navigate('/travel')}
			>
				Compare Now
			</Button>
    </div>

  )
}
;

export default BookNowComponent;
