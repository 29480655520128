import React from "react";
import {
	createStyles,
	Container,
	Title,
	Button,
	Group,
	Text,
	List,
	ThemeIcon,
} from "@mantine/core";
import { AiOutlineCheck } from "react-icons/ai";

const useStyles = createStyles((theme) => ({
	inner: {
		display: "flex",
		flexWrap: "wrap",
	},

	content: {
		maxWidth: 450,
		marginRight: theme.spacing.lg,

		[theme.fn.smallerThan("lg")]: {
			maxWidth: "100%",
			marginRight: 0,
		},
	},

	list:{
		backgroundColor: "#FA5F55",
	},

	title: {
		color: "black",
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		fontSize: 44,
		lineHeight: 1.2,
		fontWeight: 900,

		[theme.fn.smallerThan("xs")]: {
			fontSize: 28,
		},
	},

	control: {
		backgroundColor: "#FA5F55",
		[theme.fn.smallerThan("xs")]: {
			flex: 1,
		},
	},

	image: {
		flex: 1,

		[theme.fn.smallerThan("md")]: {
			display: "none",
		},
	},

	highlight: {
		position: "relative",
		borderRadius: theme.radius.sm,
		padding: "4px 12px",
	},
}));

export function Banner() {
	const { classes } = useStyles();

	return (
		<Container my={120}>
			<div className={classes.inner}>
				<div className={classes.content}>
					<Title className={classes.title}>
						About Us
					</Title>
					<Text color="dimmed" mt="md">
						At Holidyzmart, we believe that travel is not just about reaching a destination; it's about the journey, the moments, and the memories that last a lifetime.
					</Text>

					<List
						mt={30}
						spacing="sm"
						size="md"
						icon={
							<ThemeIcon size={20} radius="xl">
								<AiOutlineCheck size={12}/>
							</ThemeIcon>
						}
					>
						<List.Item>
							<b>Quality Assurance</b> – We prioritize quality at every step, ensuring that each experience meets the highest standards of safety, service, and satisfaction. 
						</List.Item>
						<List.Item>
						<b>Personalized Service</b> – Our team is dedicated to understanding your unique travel preferences. Whether you're a solo traveler, a couple, or a family, we tailor our recommendations to create a bespoke journey just for you.
						</List.Item>
						<List.Item>
							<b>Community-Centric Approach</b> – Holidyzmart is not just a marketplace; it's a community of like-minded explorers. We foster a sense of camaraderie among our users, encouraging them to share their stories, tips, and recommendations to enhance the overall travel experience. 
						</List.Item>
					</List>
					<br></br>
					<p>Join us on an adventure of a lifetime. Discover the world&apos;s wonders with a team that&apos;s dedicated to making your travel dreams come true.</p>

					<Group mt={30}>
						<Button
							radius="xl"
							size="md"
							className={classes.control}
							onClick={() => {
								if (window) {
									window.scrollTo(0, 0);
								}
							}}
						>
							Book Now
						</Button>
					</Group>
				</div>
				<div style={{ height: "200px", width: "400px"}}>
					<img src={'./about-banner.png'} alt="banner"/>
				</div>
			</div>
		</Container>
	);
}

export default Banner;