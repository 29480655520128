// HorizontalSlider.js

import React, { useState } from 'react';
import './imageSlider.css'; // Import the CSS file for styling

const HorizontalSlider = ({ images }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const nextImage = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="slider-container">
      <div
        className="slider-wrapper"
        style={{ transform: `translateX(-${activeIndex * 100}%)` }}
      >
        {images ? images.map((image, index) => (
          <div key={index} className="slider-item">
            <img src={"/destinations/" + image} alt={`Slide ${index + 1}`} className="slider-image" />
          </div>
        )): null}
      </div>
      <button onClick={prevImage} className="slider-button prev">
        &lt;
      </button>
      <button onClick={nextImage} className="slider-button next">
        &gt;
      </button>
    </div>
  );
};

export default HorizontalSlider;
