import useFetch from "../../hooks/useFetch";
import { Link } from "react-router-dom";
import "./featuredProperties.css";

const FeaturedProperties = () => {
  const { data, loading } = useFetch("/holidays?featured=true");

  return (
    <div className="fp">
      {loading ? (
        "Loading"
      ) : (
        <>
        <div id="img-wrapper">
          {data.slice(0, 8).map((item) => (
            
            <div className="featuredItem1" key={item._id}>
              <Link to={`/holidays/${item._id}`}>
               
                  <img
                    src={"destinations/"+item.photos[0]}
                    alt=""
                    className="fpImg"
                  />
                  
                
              </Link>
                <div className="fpTitles">

                  <h1>{item.name}</h1>
                  <h2>Starting from ₹{item.price}</h2>
                </div>


              
            </div>
           
          ))}
           </div>
        </>
      )}
    </div>
  );
};

export default FeaturedProperties;